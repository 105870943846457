@import '../../../../scss/variable';
@import '../../../../scss/mixin.scss';

.accardion {
  min-width: 600px;
  width: 100%;
  border-radius: 5px;
  margin-top: 1rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);

  &_lesson {
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: 7px;
    background: #f5f5f5;

    &_name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
      margin-left: 0.75rem;
      text-align: left;
      &_name {
        @include font(500, 12px, 150%);
        color: #9ca3af;
        letter-spacing: -0.05em;
      }
      &_status {
        @include font(500, 9px, 100%);
        color: rgb(204, 139, 236);
        letter-spacing: 0.08em;
      }
      &_mark {
        margin-left: 10px;
        @include font(500, 11px, 100%);
        color: rgb(204, 139, 236);
      }
    }
  }

  &_lessons_block {
    display: flex;
    flex-direction: column;
    margin-top: 0.75rem;
    gap: 0.625rem;
  }

  &_item {
    margin-top: 1.25rem;
    &:first-child {
      margin-top: 0;
    }
    &_name {
      @include font(600, 12px, 150%);
      color: $font-title;
      text-align: left;
    }
    // overflow: hidden;
    // transition: height 0.3s ease;
  }

  &_content {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-bottom: 1.25rem;
    margin-top: 1.75rem;
    // height: 0;
    // opacity: 0;
    transition: height 0.3s ease, opacity 0.3s ease;
    max-height: 250px;

   &_check {
      display: flex;
     justify-content: center;
     align-items: center;

      &_span {
        margin-left: 10px;
        @include font(500, 16px, 150%);
        letter-spacing: 0;
        color: #4d5766;
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          font-size: 12px;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          font-size: 14px;
        }
      }
    }

    &_buttons {
      width: 100%;
      margin-top: 20px;
      display: inline-flex;
      justify-content: space-between;

      &_btn {
        //margin-left: auto;
        @include font(600, 12px, 150%);
        letter-spacing: 0.04em;
        color: rgba(28, 26, 26, 0.56);
        &:hover:not(:disabled) {
          color: rgba(28, 26, 26, 0.56);
          background: #f3f4f6;}

        &_right {
          color: #ffffff;
          background: rgb(53, 126, 235);

          @include font(600, 12px, 150%);
          &:hover:not(:disabled) {
          color: #ffff;
          background: rgb(53, 126, 235);
    }
        }
      }
    }

    &_fake {
      min-height: 30px;
    }

    &_checkbox {
      display: flex;
      margin-top: 20px;

      & span {
        padding-top: 4px;
        font-size: $fs14;
        color: #b592bd;
      }
    }
  }
}

.accardion_content:hover {
    scrollbar-color: #ba75ff #e0dced80;
}