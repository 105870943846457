@import '../../../../../../../scss/mixin.scss';

.block_code {
  width: 100%;
  border-radius: 9px;
}

.adminlesson {
  // margin-left: 50%;
  // transform: translateX(-50%);
  // -webkit-transform: translateX(-50%);
  // -moz-transform: translateX(-50%);
  // -ms-transform: translateX(-50%);
  // -o-transform: translateX(-50%);

  min-width: 30vw;
  max-width: 100%;

  &_wrapper {
    display: flex;
    gap: 30px;
    width: 100%;
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    @include display(flex, column, flex-start, center);
    width: 280px;
    margin-top: 20px;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    @include display(flex, column, flex-start, center);
    margin-top: 20px;
  }

  &__codeWraper {
    margin: 5px 0;
    background: #f3f4f6;
    padding: 15px;
    border-radius: 7px;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      padding: 10px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
    }
  }

  &__block_name {
    display: block;
    margin-bottom: 7px;
    font-weight: 600;
    font-size: 15px;
  }

  &__code_text {
    font-weight: 600;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      font-size: 8px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
    }
  }

  &__navBack {
    display: flex;
    align-items: center;
    cursor: pointer;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      align-self: flex-start;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      align-self: flex-start;
    }
  }

  &__navBack_text {
    margin-left: 5px;
    @include font(400, 12px, 15px);
    letter-spacing: -0.03em;
    color: #4d5766;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include font(500, 15px, 15px);
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include font(500, 14px, 15px);
    }
  }

  &__name {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #4d5766;
    margin: 20px 0;
    margin-left: 45px;
  }

  &__content {
    // padding: 0 32px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      padding: 0;
      figure {
        width: 100%;
      }
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      padding: 0;
      figure {
        width: 100%;
      }
    }
  }

  &__name_mini {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #4d5766;
    margin-bottom: 18px;
    text-align: center;
  }

  &__blocks {
    display: flex;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include display(flex, column, flex-start, center);
      max-width: 100vw;
      padding: 0 1rem 0 1rem;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include display(flex, column, flex-start, center);
      max-width: 100vw;
      padding: 0 1rem 0 1rem;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    max-width: 100%;

    // @media only screen and (min-width: 320px) and (max-width: 375px) {
    //   @include display(flex, column, flex-start, center);
    // }
    // @media only screen and (min-width: 375px) and (max-width: 480px) {
    //   @include display(flex, column, flex-start, center);
    // }
  }

  &__card {
    margin-top: 1em;
    // padding: 10px;
    min-width: 100%;
    width: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      min-width: 99%;
      width: 99%;
      iframe {
        height: 160px;
      }
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      min-width: 99%;
      width: 99%;
      iframe {
        height: 180px;
      }
    }
  }

  &__btns {
    @include display(flex, row, flex-start, center);
    margin-top: 32px;
    margin-right: 5px;
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include display(flex, row, flex-start, center);
    }
  }

  &__desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #4d5766;
    margin-bottom: 26px;
    display: inline-block;

    p {
      word-break: break-all;
    }
  }

  &__materials {
    display: inline-block;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #4d5766;
    margin: 24px 0 12px;
  }

  &__block {
    padding: 26px 14px 37px 12px;
    max-width: 330px;
    width: 100%;
    box-shadow: 0 0 8px #d9d9d9;
    box-sizing: border-box;
    background: #fff;
    border-radius: 7px;
    max-height: 455px;
    overflow-y: auto;
    flex-shrink: 0;
    margin-top: 85px;
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      max-width: 100%;
    }

    &::-webkit-scrollbar {
      width: 9px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e1dbe6;
      height: 128px;
      border: 3px solid #ffff;
      border-top: 8px solid #ffff;
      border-bottom: 8px solid #ffff;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      border-radius: 5px;
    }

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      margin: 32px 0 0 0;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      margin: 32px 0 0 0;
    }
  }

  &__block_title {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.03em;
    color: #4d5766;
    text-align: center;
    margin-bottom: 18px;
  }

  &__item_name {
    margin-left: 8px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 15px 13px;
    width: 100%;
    background: #fff;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.02em;
    color: #ba75ff;
    margin-bottom: 5px;
  }

  &__item_active {
    display: flex;
    align-items: center;
    padding: 15px 13px;
    width: 100%;
    background: #f9f2ff;
    border-radius: 7px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.02em;
    color: #4d5766;
    margin-bottom: 5px;
  }

  &__item_disabled {
    color: gray !important;
    cursor: not-allowed !important;
  }
}

.math {
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 100%;
    overflow: scroll;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    width: 100%;
    overflow: scroll;
  }
}
