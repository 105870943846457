@import './src/scss/variable';
@import '../../scss/mixin.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../assets/fonts/SFProText/SFProText-Regular.ttf) format('opentype');
}

.wrapper {
  // @include position_t_l (absolute, 0, 0, 15);
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 15;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;

  &_item_init {
    margin-top: 15px;
    height: 40px !important;
    opacity: 0.5;

    & label {
      color: #ba75ff;
      background-color: #e0d9fc !important;
      user-select: none;
    }
  }

  @media (max-width: 480px) {
    background: $color-white;
  }
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.649);
  z-index: 9999;
}

.warning {
  padding: 30px;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 16px;
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 59px 16px 60px 16px;
  }

  &_main {
    position: relative;
    @include display(flex, column, center);
    padding: 39px 53px 27px 52px;
    background: #ffffff;
    font-family: 'SFPRORegular';
    margin: auto;
    border-radius: 20px;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: visible;
    max-width: 600px;
    transition: 400ms ease-out;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      width: 98%;
      position: absolute;
      top: 10%;
      right: 1%;
    }

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      width: 98%;
      position: absolute;
      top: 10%;
      right: 1%;
    }

    @media only screen and (min-width: 481px) and (max-width: 1024px) {
      position: absolute;
      left: 3%;
      right: 3%;
    }
  }

  &_wrapper {
    width: 100%;
    justify-content: center;
    display: flex;
    flex-direction: row;
    gap: 24px;

    & button {
      width: 115px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;

      &:first-child {
        padding: 14px;

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          padding: 5px;
        }

        @media only screen and (min-width: 375px) and (max-width: 480px) {
          padding: 5px;
        }
      }

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        font-size: 14px;
        width: 145px;
        padding: 7px;
      }

      @media only screen and (min-width: 375px) and (max-width: 480px) {
        font-size: 14px;
        width: 145px;
        padding: 7px;
      }
    }
  }

  &_title {
    max-width: 495px;
    width: 100%;
    @include font(700, 32px, 38.19px);
    color: $color-text;
    margin-bottom: 30px;
    text-align: center;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include font(700, 20px, 22px);
    }

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include font(700, 20px, 22px);
    }
  }
}

.container {
  padding: 60px 53px 55px 52px;
  font-family: 'SFPRORegular';

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    margin: 16px;
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    margin: 59px 16px 60px 16px;
  }
}

.main_school_name_input input {
  width: 100%;
  height: 100%;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  text-overflow: ellipsis;
}

.main {
  @include position_t_l(absolute, 50%, 50%, 10);
  @include display(flex, column);
  align-items: center;
  padding: 54px 34px 40px 34px;
  background: #ffffff;
  text-align: center;
  margin: 0 auto;
  border-radius: 24px;
  box-shadow: 0px 0px 8px 0px #3241954d;
  // overflow: hidden;
  transform: translate(-50%, -50%);
  font-family: $font-family-inter;
  max-width: 667px;
  width: 90%;
  max-height: 90dvh;
  overflow-y: scroll;
  height: auto;
  ::-webkit-scrollbar {
    display: none;
    width: 0;
  }

  // position: relative;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 100%;
    padding: 2rem 1rem 0 1rem;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    width: 100%;
    padding: 2rem 1rem 0 1rem;
  }

  &_feedback {
    max-width: 952px;
    width: 100%;
    position: relative;
    @include display(flex, column, center);
    border-radius: 30px;
    margin: auto;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: visible;
    border: 1px solid #357eeb;
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: blur(23px);

    &::after {
      content: '';
      position: absolute;
      border-radius: 30px;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      -webkit-mask: linear-gradient(180deg, #cfe2ff 0%, #bbceeb 100%), linear-gradient(90deg, #3170e7 13.5%, #7a90f7 100%);
    }
  }

  &_login {
    position: relative;
    @include display(flex, column, center);
    background: $color-white;
    font-family: 'Manrope', sans-serif;
    margin: auto;
    border-radius: 7px;
    flex-shrink: 0;
    flex-grow: 0;
    overflow: visible;
    width: 400px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      width: 90%;
      position: absolute;
      top: 10%;
      right: 6%;
    }

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      width: 90%;
      position: absolute;
      top: 10%;
      right: 6%;
    }

    @media only screen and (min-width: 481px) and (max-width: 1024px) {
      position: absolute;
      left: 30%;
      height: 95%;
    }
  }

  &_closed {
    @include position_t_r(absolute, 20px, 20px);
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      right: 10%;
    }

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      right: 10%;
    }

    svg:hover path {
      fill: $color-blue;
    }
  }

  &_registr {
    @include position_t_r(absolute, 0.5em, 5em);
    cursor: pointer;
    color: #6f6f6f;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 9px;

    &:hover {
      color: #000000;
      transition: color 0.3s;
    }
  }

  &_title {
    @include font(800, 30px);
    color: #000000;
    text-align: center;
    margin-bottom: 1em;

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      font-size: 18px;
      margin-bottom: 1em;
    }
  }

  &_blockDescReg {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 30em;

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      width: 100%;
    }

    &_link {
      color: #b280b1;
      text-decoration: none;
      transition: color 0.4s;

      &:hover {
        color: #af35acff;
      }
    }

    &_description {
      font-size: 14px;
      font-family: 'Inter', sans-serif;
      margin: 10px 0;
      opacity: 0.5;
    }
  }

  &_blockDesc {
    display: flex;
    align-items: center;
    justify-content: left;
    width: 30em;

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      width: 100%;
    }

    &_checkbox {
      display: inline-block;
    }

    &_description {
      font-size: 14px;
      font-family: 'Inter', sans-serif;
      margin: 10px 0;
    }

    &_link {
      color: #0359ff;
      text-decoration: none;
      transition: color 0.4s;

      &:hover {
        color: #4483ff;
      }
    }
  }

  &_btn {
    margin-top: 0.5em;
    text-align: center;

    &_href {
      color: #000000;
    }
  }
}

.main_employee {
  @include position_t_l(absolute, 50%, 50%, 10);
  @include display(flex, column);
  width: 558px;
  background: $color-white;
  font-family: 'Manrope', sans-serif;
  margin: 0 auto;
  border-radius: 20px;
  transform: translate(-50%, -50%);
  max-height: 100vh;
  overflow: auto;
  padding: 2rem;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 100%;
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    width: 100%;
  }

  &_name {
    margin: 0;
    @include font(500, 18px, 22px);
    letter-spacing: 0.03em;
    color: $font-title;
  }

  &_chosenRole {
    margin-top: 25px;

    & span {
      text-transform: lowercase;
    }
  }

  &_info {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    align-items: center;
  }

  &_closedModal {
    @include position_t_r(absolute, 17px, 16px);
    //background: #f1f1f1;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e0dced;

    &:hover {
      color: #357eeb;
      transition: color 0.3s;
    }
  }

  &_title {
    @include font(500, 18px, 22px);
    letter-spacing: 0.03em;
    color: #4d5766;
    text-align: center;
  }

  &_subs {
    @include font(400, 14px);
    color: #65696d;
    display: inline-block;
    margin-top: 15px;
  }

  &_invite {
    margin-top: 32px;

    label {
      color: #4d5766;
      font-size: 14px;
      display: inline-flex;
      margin-bottom: 7px;
    }

    &_input {
      // display: flex;
      // height: 60px;
      line-height: normal;
      padding: 11px 0 12px 20px;
      border: 1px solid #e5e7eb;
      border-radius: 5px;
      text-overflow: ellipsis;
    }

    & input {
      border: none;
      outline: none;
      width: 100%;

      &::placeholder {
        color: #d4d7dd;
      }
    }
  }

  &_radiowrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    // margin-top: 10px;
    padding: 1rem;
    // margin-right: 400px;
  }

  &_role {
    margin: 16px 0;

    &_radio {
      margin-bottom: 6px;
    }

    &_desc {
      font-size: 14px;
      color: #949aa3;
      margin-left: 32px;
    }
  }

  &_btn {
    margin-top: 20px;
    text-align: center;
    display: flex;
    align-items: stretch;
    gap: 15px;
    justify-content: center;

    & button {
      padding: 10px 20px;
    }
  }

  &_course {
    @include display(flex, column);
    font-weight: 400;
    margin-top: 25px;

    &_title {
      @include font(400, 14px);
      margin-bottom: 8px;
      color: $font-title;
    }

    &_checkbox {
      @include display(flex, row, flex-start, center);
      margin-bottom: 8px;
      color: $font-title;
      font-size: 14px;
    }
  }

  &_change {
    margin-bottom: 12px;

    &_button {
      width: 60%;
      @include font(400, 12px);
      margin: 0 auto;
    }

    &_checkbox {
      @include display(flex, row, flex-start, center);
      margin-bottom: 6px;
      margin-left: 10px;
      color: $font-title;
      font-size: 12px;
      width: fit-content;
    }
  }
}

.inputs_block {
  width: 100%;
  margin-bottom: 56px;

  & :nth-child(2) {
    margin-bottom: 16px;
  }
}

.restorePass {
  @include font(800, 14px);
  color: black;
  text-align: center;
  margin: 16px auto 0 auto;
  padding: 15px 0;
  font-family: 'Manrope', sans-serif;
  border: none;
  outline: none;
  cursor: pointer;
  width: 240px;
  background: #ddd7fa;
  border-radius: 10px;
}

.mainCourse {
  @include position_t_l(absolute, 50%, 50%, 10);
  @include display(flex, column);
  background: #ffffff;
  box-shadow: 0px 0px 8px 0px rgba(50, 65, 149, 0.3);
  font-family: 'SFPRORegular';
  margin: 0 auto;
  padding: 24px 30px 40px 34px;
  border-radius: 24px;
  transform: translate(-50%, -50%);
  max-width: 677px;
  width: 100%;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 99%;
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    width: 99%;
  }

  &_container {
    position: relative;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &_header {
    @include display(flex, column, flex-start, center);
  }

  &_title {
    @include font(700, 36px, 42.96px);
    text-align: center;
    color: #000000;
    margin: 36px 0;
  }

  &_desc {
    @include font(400, 16px);
    margin-bottom: 16px;
    color: #be4848;
    text-align: center;
  }
}

.classesContainer {
  @include position_t_l(absolute, 50%, 50%, 10);
  @include display(flex, column, center);
  padding: 39px 53px 27px 52px;
  background: #ffffff;
  text-align: center;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  border-radius: 24px;
  transform: translate(-50%, -50%);
  font-family: 'SFPRORegular';
  box-shadow: 0px 0px 8px 0px rgba(50, 65, 149, 0.3);

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-content: space-around;
  }

  @media only screen and (max-width: 620px) {
    padding: 44px 10px 20px 10px;
    width: 95%;
  }

  &_closed {
    @include position_t_r(absolute, 0px, 0px);
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s;

    svg {
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        @include position_t_r(absolute, 10px, 10px);
        width: 34px;
        height: 34px;
      }

      @media only screen and (min-width: 375px) and (max-width: 480px) {
        @include position_t_r(absolute, 10px, 10px);
        width: 34px;
        height: 34px;
      }
    }

    svg:hover path {
      fill: $color-blue;
    }
  }

  &_main {
    width: 100%;
  }

  &_title {
    @include font(700, 32px, 38.19px);
    color: $color-text;
    margin-bottom: 45px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include font(700, 20px, 22px);
      margin-bottom: 40px;
    }

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include font(700, 20px, 22px);
      margin-bottom: 40px;
    }
  }

  &_type {
    margin: 30px 0 24px 0;
    display: flex;
    justify-content: center;
    gap: 22px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      gap: 5%;
    }

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      gap: 5%;
    }

    &_classes {
      @include display(flex, column, center, center);
      @include font(600, 20px, 23.87px);
      width: 150px;
      height: 184px;
      box-shadow: 0px 0px 8px 0px rgba(50, 65, 149, 0.3);
      border-radius: 7px;
      background: $color-white;
      color: $color-text;
      cursor: pointer;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        @include font(600, 12px, 14.32px);
        width: 96px;
        height: 128px;
      }

      @media only screen and (min-width: 375px) and (max-width: 480px) {
        @include font(600, 12px, 14.32px);
        width: 96px;
        height: 128px;
      }

      svg {
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          width: 60px;
          height: 60px;
        }

        @media only screen and (min-width: 375px) and (max-width: 480px) {
          width: 60px;
          height: 60px;
        }
      }
    }

    &_subs {
      @include font(400, 16px);
      max-width: 372px;
      width: 100%;
      margin: 0 auto;
      color: $color-text;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        width: 100%;
        margin-bottom: 2%;
        @include font(400, 10px, 11.93px);
        color: $color-grey;
      }

      @media only screen and (min-width: 375px) and (max-width: 480px) {
        width: 100%;
        margin-bottom: 2%;
        @include font(400, 10px, 11.93px);
        color: $color-grey;
      }
    }

    &_btnBlock {
      @include display(flex, row, space-between, center);
      max-width: 495px;
      width: 100%;

      @media only screen and (max-width: 620px) {
        max-width: 570px;
      }

      & button {
        max-width: 235px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;

        &:first-child {
          padding: 14px;

          @media only screen and (min-width: 320px) and (max-width: 375px) {
            padding: 5px;
          }

          @media only screen and (min-width: 375px) and (max-width: 480px) {
            padding: 5px;
          }
        }

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          font-size: 13px;
          padding: 7px;
        }

        @media only screen and (min-width: 375px) and (max-width: 480px) {
          font-size: 13px;
          padding: 7px;
        }

        @media only screen and (max-width: 600px) {
          width: 49%;
        }
      }
    }
  }
}

.errors {
  color: #ff3c3c;
  font-size: 12px;
  margin-top: 2px;
  margin-left: 8px;

  &_forgot {
    color: #ff3c3c;
    font-size: 12px;
    margin-left: 8px;
    font: bold;
  }
}

.isSubmitting {
  color: #ba75ff;
  font-size: 16px;
  margin-top: -8px;
  margin-left: 8px;

  &_forgot {
    color: #ff3c3c;
    font-size: 16px;
    margin-top: -8px;
    margin-left: 8px;
  }
}

.isSubmitting {
  color: #ba75ff;
  font-size: 16px;
  margin-top: -8px;
  margin-left: 8px;

  &_forgot {
    color: #ff3c3c;
    font-size: 16px;
    margin-top: -8px;
    margin-left: 8px;
  }
}

.isSubmitting {
  color: #ba75ff;
  font-size: 16px;
  margin-top: -8px;
  margin-left: 8px;

  &_forgot {
    color: #ff3c3c;
    font-size: 16px;
    margin-top: -8px;
    margin-left: 8px;
  }
}

.isSubmitting {
  color: #ba75ff;
  font-size: 16px;
  margin-top: -8px;
  margin-left: 8px;
}

.active {
  background: #2051d1;
  box-shadow: 0px 0px 8px 0px rgba(50, 65, 149, 0.3);
  color: $color-white;
}

.usually {
  &_header {
    @include display(flex, column, flex-start, center);

    & svg {
      margin-bottom: 24px;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        margin-bottom: 0;
      }

      @media only screen and (min-width: 375px) and (max-width: 480px) {
        margin-bottom: 0;
      }
    }
  }

  &_input {
    width: 100%;
    text-overflow: ellipsis;
    text-align: left;

    div {
      div {
        height: 56px;
        flex-direction: row-reverse;
        border: 1px solid transparent;
        background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(90deg, #0d28bb 0%, #357eeb 100%) border-box;

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          border: 2px solid #357eeb;
          background: transparent;
          height: 34px;
          padding: 10px;
        }

        @media only screen and (min-width: 375px) and (max-width: 480px) {
          border: 2px solid #357eeb;
          background: transparent;
          height: 34px;
          padding: 10px;
        }
      }
    }

    input {
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        font-size: 16px !important;
      }

      @media only screen and (min-width: 375px) and (max-width: 480px) {
        font-size: 16px !important;
      }
    }

    input::placeholder {
      font-weight: 400;
      font-size: 16px;
      color: #808080;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        font-size: 12px;
      }

      @media only screen and (min-width: 375px) and (max-width: 480px) {
        font-size: 12px;
      }
    }

    svg {
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        width: 18px;
        height: 18px;
      }

      @media only screen and (min-width: 375px) and (max-width: 480px) {
        width: 18px;
        height: 18px;
      }

      path {
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          fill: $color-grey;
        }

        @media only screen and (min-width: 375px) and (max-width: 480px) {
          fill: $color-grey;
        }
      }
    }
  }

  &_title {
    @include font(400, 14px);
    color: $font-title;
  }

  &_grade {
    @include display(flex, row, center, center);
    @include font(400, 12px);
    margin: 16px 0 30px 0;

    &_points {
      border: 1px solid #e5e7eb;
      width: 94px;
      height: 33px;
      display: flex;
      text-align: right;
      background: url('../../assets/img/createCourse/star.svg') 14px 6px no-repeat;
      padding-right: 2px;
    }

    & span {
      margin-left: 16px;
      color: $font-title;
    }
  }
}

.tasks {
  @include display(flex, column, center, center);

  & svg {
    margin-bottom: 26px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      margin-bottom: 0;
    }

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      margin-bottom: 0;
    }
  }

  &_checkbox {
    @include display(flex, row, flex-start, flex-start);
    margin-left: 15px;

    &_desc {
      text-align: left;

      & span {
        @include font(400, 14px);
        color: $font-title;
      }

      & p {
        @include font(400, 14px, 21px);
        color: #949aa3;
      }
    }
  }

  &_credit {
    @include display(flex, row, flex-start, center);
    width: 90%;

    &_desc {
      @include font(400, 14px);
      color: $font-title;
      width: 140px;
      text-align: left;
    }

    &_select {
      @include display(flex, row, space-evenly);
      width: 100%;
    }
  }

  &_editor {
    text-align: left;
    margin-top: 15px;

    &_desc {
      font-size: 16px;
      color: #68717d;
    }
  }
}

.test {
  @include display(flex, column, flex-start, center);
  text-align: center;

  &_title {
    @include font(500, 20px, 23.87px);
    color: #000000;
    text-align: left;
    margin-bottom: 12px;

    @media only screen and (max-width: 600px) {
      @include font(600, 12px, 16px);
    }
  }

  &_input {
    width: 100%;
    text-overflow: ellipsis;
    text-align: left;

    div {
      div {
        height: 56px;
        margin-bottom: 24px;
        flex-direction: row-reverse;
        border: 1px solid transparent;
        background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(90deg, #0d28bb 0%, #357eeb 100%) border-box;

        @media only screen and (max-width: 600px) {
          border: 2px solid #357eeb;
          background: transparent;
          height: 34px;
          padding: 10px;
          margin-bottom: 10px;
        }

        @media only screen and (min-width: 601px) and (max-width: 1000px) {
          margin-bottom: 14px;
        }
      }
    }

    input {
      @media only screen and (max-width: 600px) {
        font-size: 16px !important;
      }
    }

    input::placeholder {
      font-weight: 400;
      font-size: 16px;
      color: #808080;

      @media only screen and (max-width: 600px) {
        font-size: 12px;
      }
    }

    svg {
      @media only screen and (max-width: 600px) {
        width: 18px;
        height: 18px;
      }

      path {
        @media only screen and (max-width: 600px) {
          fill: $color-grey;
        }
      }
    }
  }

  &_checkboxPack {
    margin-bottom: 32px;

    @media only screen and (max-width: 600px) {
      margin-bottom: 10px;
    }

    @media only screen and (min-width: 601px) and (max-width: 1000px) {
      margin-bottom: 20px;
    }
  }

  &_checkbox {
    @include display(flex, row, flex-start);
    gap: 24px;
    @include font(500, 20px, 23.87px);
    color: #000000;
    width: 100%;
    text-align: left;

    &_attempts {
      width: 50px;
      margin-left: 16px;
      padding: 6px 0 6px 16px;
      border: 1px solid #e5e7eb;
      outline: none;
      border-radius: 5px;
    }

    &_text {
      @include display(flex, column);
      margin-top: 11px;

      &_desc {
        @include font(500, 16px, 19.09px);
        text-align: left;
        color: #808080;

        @media only screen and (max-width: 600px) {
          @include font(500, 10px, 11.93px);
        }
      }
    }
  }

  &_grade {
    width: 100%;
    text-align: left;
    margin-bottom: 32px;

    &_title {
      @include font(500, $fs12);
      letter-spacing: 0.04em;
    }

    &_radio {
      margin-top: 8px;
      margin-bottom: 16px;

      &_input {
        @include display(flex, row, flex-start, center);
        margin: 8px 0 0 26px;

        & span {
          @include font(400, $fs12);
          margin-left: 16px;
        }
      }
    }
  }
}

.toggle_switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.toggle_switch input {
  display: none;
}

.toggle_switch .switch {
  @include position_t_l(absolute, 0, 0);
  right: 0;
  bottom: 0;
  cursor: pointer;
  background: rgba(128, 128, 128, 1);
  border-radius: 36px;
  transition: background-color 0.2s ease;
}

.toggle_switch .switch::before {
  @include position_t_l(absolute, 2px, 2px);
  content: '';
  width: 16px;
  height: 16px;
  background-color: $color-white;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.toggle_switch input:checked + .switch::before {
  @include position_t_l(absolute, 1px, 1px);
  transform: translateX(20px);
  background-color: $color-blue;
}

.toggle_switch input:checked + .switch {
  background-color: $color-white;
  border: 1px solid $color-blue;
}

.webinar {
  @include display(flex, column, flex-start, center);
  text-align: center;
  margin-bottom: 23px;

  & svg {
    margin-bottom: 13px;
  }

  &_checkbox {
    @include display(flex, row, flex-start, flex-start);
    width: 90%;
    text-align: left;
    margin-top: 10px;

    &_text {
      @include display(flex, column);
      text-align: left;

      &_for {
        @include font(400, $fs14);
        color: $font-color-rose;
      }

      &_desc {
        @include font(400, $fs14);
        padding-top: 4px;
        color: #949aa3;
      }
    }
  }

  &_addReminder {
    @include font(400, $fs14);
    color: $font-color-rose;
    width: 90%;
    text-align: left;
    margin-left: 27px;
    cursor: pointer;
  }
}

.btnBlock {
  display: flex;
  align-items: stretch;
  gap: 20px;

  & button {
    padding: 10px 20px;
    font-size: 14px;
    border-radius: 7px;

    &:first-child {
      color: #7b818e;
      font-weight: 500;
    }
  }
}

.navBtn {
  margin-bottom: 15px;

  & :nth-child(1) {
    margin-right: 15px;
  }

  &_btn {
    padding: 8px 15px;
    color: #b3b8c2;
    cursor: pointer;
    text-align: center;
  }

  &_active {
    border-radius: 7px;
    color: $font-color-rose;
    border: 1px solid $font-color-rose;
    background-color: #e0dced;
  }

  &_btn:hover {
    border: 1px solid #e1e0e0;
    border-radius: 7px;
  }
}

.module {
  &_title {
    @include font(700, 32px);
    color: $color-text;
    line-height: 38.19px;
    width: 100%;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include font(700, 20px, 16px);
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include font(700, 20px, 16px);
    }
  }

  &_button {
    button {
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        font-size: 13px !important;
        padding: 10px !important;
      }

      @media only screen and (min-width: 375px) and (max-width: 480px) {
        font-size: 13px !important;
        padding: 10px !important;
      }
    }
  }
}

.settings {
  &_header {
    @include display(flex, column, center, center);
    width: 100%;
    text-align: center;
    margin-bottom: 26px;

    & svg {
      margin-bottom: 16px;
    }
  }

  &_block {
    margin-top: 16px;

    &_input {
      text-align: left;
      margin-bottom: 20px;

      &_title {
        font-size: 14px;
        font-family: 'SFPRORegular';
        color: $color-text;
      }

      &_isPublished {
        color: $color-grey;
        font-family: 'SFPRORegular';
        text-align: center;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          font-size: 14px;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          font-size: 14px;
        }
      }

      &_switch {
        div {
          width: 40px;
          height: 20px;
          background: #e3e3e3;
          border: none;

          &[data-isOn='true'] {
            background: transparent;
            border: 1px solid rgba(53, 126, 235, 1);

            div {
              &::before {
                background: rgba(53, 126, 235, 1);
                border: none;
              }
            }
          }

          div {
            width: 1rem;
            height: 1rem;

            &::before {
              width: 1rem;
              height: 1rem;
              background: #bebebe;
              border: none;
            }
          }
        }
      }

      &_btnChangeNameLesson {
        max-width: 496px;
        width: 100%;
      }

      div {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}

.settings_list {
  list-style-type: none;
  @include display(flex, column);
}

.settings_title {
  user-select: none;
  @include font(500, 20px, 24px);
  text-align: center;
  font-style: normal;
  color: #4d5766;
}

.main_school {
  @include position_t_l(absolute, 50%, 50%, 10);
  @include display(flex, column);
  width: 558px;
  background: $color-white;
  font-family: 'Manrope', sans-serif;
  margin: 0 auto;
  border-radius: 7px;
  transform: translate(-50%, -50%);

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 100%;
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    width: 100%;
  }

  &_container {
    margin: 36px 31px 30px 31px;
    padding: 0 30px 20px 30px;
  }

  &_closedModal {
    @include position_t_r(absolute, 17px, 16px);
    background: #f1f1f1;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e0dced;

    &:hover {
      color: #4d5766;
      transition: color 0.3s;
    }
  }

  &_title {
    @include font(500, 18px, 22px);
    letter-spacing: 0.03em;
    color: #4d5766;
    text-align: center;
    font-size: 20px;
  }

  &_name {
    margin-top: 32px;

    & label {
      color: #4d5766;
      font-size: 16px;
      display: inline-block;
      margin-bottom: 12px;
    }

    &_input {
      padding: 2px 2px 2px 2px;
      width: 100%;
      height: 40px;
      border: 1px solid #e5e7eb;
      border-radius: 5px;
      text-overflow: ellipsis;
    }

    & input {
      border: none;
      outline: none;

      &::placeholder {
        color: #d4d7dd;
      }
    }
  }

  &_btn {
    margin-top: 20px;
    text-align: center;
    display: flex;
    align-items: stretch;
    gap: 15px;
    justify-content: center;

    & button {
      padding: 10px 20px;
    }
  }

  //&_course {
  //  @include display(flex, column);
  //  font-weight: 400;
  //  margin-top: 25px;
  //
  //  &_title {
  //    @include font(400, 14px);
  //    margin-bottom: 8px;
  //    color: $font-title;
  //  }
  //
  //  &_checkbox {
  //    @include display(flex, row, flex-start, center);
  //    margin-bottom: 8px;
  //    color: $font-title;
  //    font-size: 14px;
  //  }
  //}
}

.feedback {
  @include display(flex, column, center, center);
  box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  position: relative;
  z-index: 2;

  &_img {
    margin-left: 40px;
  }

  &_content {
    @include display(flex, column, center, center);
    max-width: 592px;
    width: 100%;
    padding-bottom: 45px;

    @media only screen and (min-width: 1400px) and (max-width: 1890px) {
      padding-bottom: 63px;
    }

    &_title {
      @include font(700, 25px, 42.96px);
      font-family: 'SFPRORegular';
      color: #332f36;
      text-align: center;
      margin-bottom: 25px;

      @media only screen and (min-width: 1400px) and (max-width: 1890px) {
        @include font(400, 36px, 23.87px);
        margin-bottom: 36px;
      }
    }

    &_info {
      @include display(flex, column, center, initial);
      width: 100%;

      &_rating {
        @include display(flex, row, space-between, center);

        &_title {
          @include font(400, 16px, 23.87px);
          font-family: 'SFPRORegular';
          color: #000000;

          @media only screen and (min-width: 1400px) and (max-width: 1890px) {
            @include font(400, 20px, 23.87px);
          }
        }

        &_value {
          display: flex;
          gap: 16px;
          align-items: center;
        }

        span {
          gap: 16px;
        }

        label {
          color: #faaf00;

          svg path {
            fill: #faaf00;
          }
        }
      }

      &_textarea {
        width: 100%;
        border-radius: 10px;
        border: 1px solid transparent;
        background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(90deg, #0d28bb 0%, #357eeb 100%) border-box;

        textarea {
          overflow: auto;
          border: none;
          width: 100%;
          min-height: 120px;
          border-radius: 10px;
          outline: none;
          resize: none;
          padding: 0.5rem 1rem;
          font-family: 'SFPRORegular';
          @include font(400, 16px, 23.87px);
          color: #357eeb;

          @media only screen and (min-width: 1400px) and (max-width: 1890px) {
            @include font(400, 20px, 23.87px);
          }

          &::placeholder {
            @include font(400, 16px, 23.87px);
            color: #808080;
            font-family: 'SFPRORegular';

            @media only screen and (min-width: 1400px) and (max-width: 1890px) {
              @include font(400, 20px, 23.87px);
            }
          }
        }
      }

      &_input {
        width: 100%;
        margin: 9px 0;

        div {
          div {
            border: 1px solid transparent;
            background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(90deg, #0d28bb 0%, #357eeb 100%) border-box;
          }
        }

        &::placeholder {
          @include font(400, 16px, 23.87px);
          color: #808080;

          @media only screen and (min-width: 1400px) and (max-width: 1890px) {
            @include font(400, 20px, 23.87px);
          }
        }
      }
    }
  }
}

.banner_groups {
  position: relative;
  @include display(flex, column, center);
  background: $color-white;
  font-family: 'Manrope', sans-serif;
  margin: auto;
  border-radius: 34px;
  border: 1px solid #3170e7;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: visible;
  width: 600px;
  transition: 400ms ease-out;
  height: auto;

  .container_buttons {
    @include display(flex, column, flex-start, flex-start);
    gap: 18px;
    margin-bottom: 25px;
  }

  .courses_container {
    @include display(flex, column, space-between, flex-start);
    max-height: 400px;
    overflow-y: auto;
    margin: 20px 20px;
    gap: 5px;

    div {
      @include display(flex, row, flex-start, center);
      height: auto;
      color: #808080;
      font-weight: 700;
      font-size: 18px;
      gap: 5px;
    }

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #cfe2ff;
      border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: #332f36;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    ::-webkit-scrollbar-button {
      background: #332f36;
      height: 20px;
      width: 20px;
    }
  }

  .course_container {
    @include display(flex, row, space-between, center);
    justify-content: space-between !important;
    width: 100%;
  }

  .to_select_groups {
    @include display(flex, row, center, center);
    margin-right: 10px;
    background-color: transparent;
    border: none;
    width: 20px;
    height: 20px;
  }

  .group_container {
    margin: 0 20px;
  }

  .groups_warning {
    width: 100%;
    text-align: center;
    margin: 20px 0 0 0;
    color: transparent;

    &_active {
      color: red;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 98%;
    position: absolute;
    top: 10%;
    right: 1%;
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    width: 98%;
    height: 50%;

    font-size: 16px;
    position: relative !important;
    margin: auto auto;

    div {
      padding: 1px;
      box-shadow: none;

      span {
        right: 5px;
      }
    }
  }

  @media only screen and (min-width: 700px) and (max-width: 1024px) {
    width: 75vw;
  }

  @media only screen and (min-width: 481px) and (max-width: 700px) {
    // position: absolute;
    // left: 30%;
    // height: 95%;
    width: 90vw;
  }
}
