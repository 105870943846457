@import 'src/scss/variable';
@import '../../scss/mixin.scss';

.chart_header_container {
    display: flex;
    justify-content: space-between;

    .chart_header {
        color: #000000;
        font-weight: 500;
        font-size: 24px;

    }
}

.selectInput {
    width: 150px;

    &_wrapper {
        position: relative;
        display: flex;


    }

    div {
        svg {
            display: none;
        }

        button {
            background-color: #357EEB;
            border-radius: 10px;
            border: none;
            color: #fff;
            padding: 10px 25px;
        }

        ul {
            box-shadow: 0px 0px 8px 0px #3241954D;
            background-color: #fff;
            color: #332F36;
            border-radius: 10px;

            li {
                padding-left: 40px;
                font-size: 16px;
                font-weight: 500;
            }
        }
    }


}

.select_arrowDown {
    position: absolute;
    right: 20px;
    top: 15px;
    transform: rotate(-180deg);
    pointer-events: none;
    transition-duration: $trans-dur02;

    path {
        fill: #fff;
    }
}

.select_arrowdown_open {
    transform: rotate(0deg);
}

.clicks_counter {
    color: #357EEB;
    font-weight: 500;
    font-size: 46px;


    &_container {
        position: absolute;
    }

    &_header {
        color: #B2B2B2;
        font-size: 20px;
    }
}