@import '../../scss/mixin.scss';
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Tourney:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'SFPROBold';
  src: url(../../assets/fonts/SFProText/SFProText-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'SFPROMedium';
  src: url(../../assets/fonts/SFProText/SFProText-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'SFPROHeavy';
  src: url(../../assets/fonts/SFProText/SFProText-Heavy.ttf) format('opentype');
}

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../assets/fonts/SFProText/SFProText-Regular.ttf) format('opentype');
}

p {
  font-family: 'SFPRORegular';
}

h1 {
  font-family: 'SFPRORegular';
}

h2 {
  font-family: 'SFPRORegular';
}

h3 h5 {
  font-family: 'SFPROHeavy';
}

li {
  font-family: 'SFPRORegular';
  font-weight: 700;
  font-size: 20px;
  line-height: 23.87px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.animatedIcon {
  animation: zoomInOut 0.8s infinite alternate;
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.init {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 24px 0;
  max-width: 100vw;
  width: 100%;
  min-height: 100vh;
  height: 100%;
  font-family: 'Manrope', sans-serif;

  &_header {
    @media only screen and (min-width: 1400px) {
      width: auto;
      max-width: 100vw;
      height: 80px;
      border: 3px solid #357eeb;
      border-radius: 30px;
      background: #cfe2ff;
      gap: 18px;
      box-shadow: 0px -2px 4px 0px #357eeb, 0px 2px 4px 0px #357eeb;
      @include display(flex, row, center, center);
      padding: 0px 162px 0px 162px;
      position: fixed;
      z-index: 99;
      opacity: 0.9;
    }

    @media only screen and (min-width: 490px) and (max-width: 1400px) {
      width: auto;
      max-width: 93vw;
      height: 70px;
      border: 3px solid #357eeb;
      border-radius: 30px;
      background: #cfe2ff;
      gap: 0.5vw;
      box-shadow: 0px -2px 4px 0px #357eeb, 0px 2px 4px 0px #357eeb;
      @include display(flex, row, center, center);
      padding: 0px 15px 0px 15px;
      position: fixed;
      z-index: 99;
      opacity: 0.9;
    }

    @media only screen and (min-width: 320px) and (max-width: 490px) {
      width: auto;
      max-width: 93vw;
      height: 40px;
      border: 3px solid #357eeb;
      border-radius: 30px;
      background: #cfe2ff;
      gap: 0.5vw;
      box-shadow: 0px -2px 4px 0px #357eeb, 0px 2px 4px 0px #357eeb;
      @include display(flex, row, center, center);
      padding: 0px 15px 0px 15px;
      position: fixed;
      z-index: 99;
      opacity: 0.9;
    }

    &_logo {
      @media only screen and (min-width: 1400px) {
        height: 55px;
        width: 98.78px;
        display: flex;
        margin-right: 1rem;
      }

      @media only screen and (min-width: 320px) and (max-width: 1400px) {
        position: relative;
        display: flex;
        width: 6vw;
        margin-right: 1.4rem;
      }

      img {
        @media only screen and (min-width: 320px) and (max-width: 1400px) {
          width: 5vw;
          overflow: auto;
          margin: auto;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }
    }
  }

  &_main {
    position: relative;
    margin-top: 110px;
    margin-bottom: 70px;
    width: 100%;
    max-width: 1600px;
    @include display(flex, column, space-between, center);

    &_slider {
      width: 100%;
      height: auto;
    }

    &_header {
      padding: 1rem 0;
      @media only screen and (min-width: 1400px) {
        font-size: 36px;
        font-weight: 800;
        line-height: 42.96px;
        color: #324195;
      }

      @media only screen and (min-width: 490px) and (max-width: 1400px) {
        font-size: 3vw;
        font-weight: 800;
        line-height: 42.96px;
        color: #324195;
        margin-left: 97vh;
      }

      @media only screen and (min-width: 320px) and (max-width: 490px) {
        font-size: 3vw;
        font-weight: 800;
        line-height: 42.96px;
        color: #324195;
        margin-left: 770px;
      }
    }

    &_info {
      width: 100%;
      max-width: 85vw;
      display: flex;
      margin: 0 auto;
      flex-direction: column;
      margin-bottom: 110px;

      &_quality {
        @media only screen and (min-width: 1270px) {
          display: flex;
          flex-direction: row;
          gap: 240px;
          align-items: center;
        }

        @media only screen and (min-width: 320px) and (max-width: 1270px) {
          display: flex;
          flex-direction: row;
          gap: 20vw;
          align-items: center;
        }

        &_text {
          @media only screen and (min-width: 1435px) {
            width: 680px;
            margin-top: 45px;
          }

          @media only screen and (min-width: 320px) and (max-width: 1435px) {
            width: 50vw;
            margin-top: 45px;
          }

          h3 {
            @media only screen and (min-width: 1150px) {
              font-family: 'SFPRORegular';
              font-weight: 700;
              font-size: 20px;
              color: #2643dc;
              background: -webkit-linear-gradient(#0d28bb, #357eeb);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              line-height: 23.87px;
              margin-bottom: 70px;
            }

            @media only screen and (min-width: 320px) and (max-width: 1150px) {
              font-family: 'SFPRORegular';
              font-weight: 700;
              font-size: 1.7vw;
              color: #2643dc;
              background: -webkit-linear-gradient(#0d28bb, #357eeb);
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              line-height: 3vw;
              margin-bottom: 70px;
            }
          }

          h1 {
            @media only screen and (min-width: 1150px) {
              font-family: 'SFPRORegular';
              font-weight: 500;
              font-size: 46px;
              color: #332f36;
              line-height: 54.89px;
              margin-bottom: 20px;
            }

            @media only screen and (min-width: 320px) and (max-width: 1150px) {
              font-family: 'SFPRORegular';
              font-weight: 500;
              font-size: 3vw;
              color: #332f36;
              line-height: 40px;
              margin-bottom: 20px;
            }
          }

          p {
            @media only screen and (min-width: 1150px) {
              font-family: 'SFPROBold';
              font-size: 20px;
              font-weight: 700;
              line-height: 23.87px;
              color: #324195;
              // margin-bottom: 30px;
            }

            @media only screen and (min-width: 320px) and (max-width: 1150px) {
              font-family: 'SFPROBold';
              font-size: 1.7vw;
              font-weight: 700;
              line-height: 15px;
              color: #324195;
              margin-bottom: 30px;
            }
          }

          span {
            color: #357eeb;
          }
        }

        &_pic {
          @media only screen and (min-width: 1435px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 377px;
            height: 377px;
            border-radius: 50%;
            background: linear-gradient(to right, #0d28bb, #357eeb);
            margin-top: 45px;
          }

          @media only screen and (min-width: 490px) and (max-width: 1435px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 27vw;
            height: 27vw;
            border-radius: 50%;
            background: linear-gradient(to right, #0d28bb, #357eeb);
            margin-top: 3vw;
            margin-left: -70px;
          }

          @media only screen and (min-width: 320px) and (max-width: 490px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 27vw;
            height: 27vw;
            border-radius: 50%;
            background: linear-gradient(to right, #0d28bb, #357eeb);
            margin-top: 3vw;
            margin-left: -20px;
          }

          &_info {
            @media only screen and (min-width: 1435px) {
              width: 500px;
              height: 440px;
              position: absolute;
              border-radius: 50%;
              background-image: url(../../assets/img/common/infoPic.png);
              top: -25px;
            }

            @media only screen and (min-width: 320px) and (max-width: 1435px) {
              background-position: 50% 50%;
              background-size: cover;
              background-image: url(../../assets/img/common/infoPic.png);
              height: 100%;
              border-radius: 50%;
              padding-bottom: 110%;
              position: relative;
              width: 37vw;
              top: -7vw;
            }
          }

          p {
            @media only screen and (min-width: 1150px) {
              font-weight: 600;
              font-size: 20px;
              line-height: 23.87px;
              color: #324195;
            }

            @media only screen and (min-width: 320px) and (max-width: 1150px) {
              font-weight: 600;
              font-size: 1.7vw;
              line-height: 2vw;
              color: #324195;
            }
          }

          &_removebg {
            @media only screen and (min-width: 1435px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 260px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -25px;
              left: -250px;
            }

            @media only screen and (min-width: 1150px) and (max-width: 1435px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 260px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -365px;
              left: -250px;
            }

            @media only screen and (min-width: 800px) and (max-width: 1150px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 220px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -265px;
              left: -150px;
            }

            @media only screen and (min-width: 600px) and (max-width: 800px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 180px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -210px;
              left: -150px;
            }

            @media only screen and (min-width: 490px) and (max-width: 600px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 145px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -190px;
              left: -120px;
            }

            @media only screen and (min-width: 320px) and (max-width: 490px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 35px;
              width: 85px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -120px;
              left: -80px;
            }

            img {
              @media only screen and (min-width: 320px) and (max-width: 490px) {
                width: 20px;
              }

              @media only screen and (min-width: 800px) and (max-width: 1150px) {
                width: 50px;
              }

              @media only screen and (min-width: 490px) and (max-width: 800px) {
                width: 40px;
              }
            }
          }

          &_analytics {
            @media only screen and (min-width: 1435px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 179px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -25px;
              left: 230px;
            }

            @media only screen and (min-width: 1150px) and (max-width: 1435px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 189px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -365px;
              left: 160px;
            }

            @media only screen and (min-width: 800px) and (max-width: 1150px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 179px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -265px;
              left: 130px;
            }

            @media only screen and (min-width: 600px) and (max-width: 800px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 120px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -205px;
              left: 105px;
            }

            @media only screen and (min-width: 490px) and (max-width: 600px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 100px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -180px;
              left: 95px;
            }

            @media only screen and (min-width: 320px) and (max-width: 490px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 55px;
              width: 65px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              left: 45px;
              top: -100px;
            }

            img {
              @media only screen and (min-width: 320px) and (max-width: 490px) {
                width: 20px;
              }

              @media only screen and (min-width: 800px) and (max-width: 1150px) {
                width: 50px;
              }

              @media only screen and (min-width: 490px) and (max-width: 800px) {
                width: 40px;
              }
            }
          }

          &_chatst {
            @media only screen and (min-width: 1435px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 70px;
              width: 277px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
            }

            @media only screen and (min-width: 1150px) and (max-width: 1435px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 70px;
              width: 277px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              left: 10px;
              top: -345px;
            }

            @media only screen and (min-width: 800px) and (max-width: 1150px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 70px;
              width: 207px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              left: 10px;
              top: -248px;
            }

            @media only screen and (min-width: 600px) and (max-width: 800px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 165px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -210px;
              left: 10px;
            }

            @media only screen and (min-width: 490px) and (max-width: 600px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 145px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -180px;
              left: 10px;
            }

            @media only screen and (min-width: 320px) and (max-width: 490px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 55px;
              width: 85px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              left: 7px;
              top: -115px;
            }

            img {
              @media only screen and (min-width: 320px) and (max-width: 490px) {
                width: 20px;
              }

              @media only screen and (min-width: 800px) and (max-width: 1150px) {
                width: 50px;
              }

              @media only screen and (min-width: 490px) and (max-width: 800px) {
                width: 40px;
              }
            }
          }

          &_dateInf {
            @media only screen and (min-width: 1435px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 299px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: 70px;
              left: -230px;
            }

            @media only screen and (min-width: 1150px) and (max-width: 1435px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 299px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -290px;
              left: -215px;
            }

            @media only screen and (min-width: 800px) and (max-width: 1150px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 209px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -180px;
              left: -100px;
            }

            @media only screen and (min-width: 600px) and (max-width: 800px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 180px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -105px;
              left: -20px;
            }

            @media only screen and (min-width: 490px) and (max-width: 600px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 65px;
              width: 180px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -90px;
              left: -20px;
            }

            @media only screen and (min-width: 320px) and (max-width: 490px) {
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 55px;
              width: 75px;
              border-radius: 20px;
              background: #cfe2ff;
              position: relative;
              top: -55px;
              left: 5px;
            }

            img {
              @media only screen and (min-width: 320px) and (max-width: 490px) {
                width: 20px;
              }

              @media only screen and (min-width: 800px) and (max-width: 1150px) {
                width: 50px;
              }

              @media only screen and (min-width: 490px) and (max-width: 800px) {
                width: 40px;
              }
            }
          }
        }
      }
    }

    &_pros {
      @media only screen and (min-width: 490px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100vw;
        height: 350px;
        margin-bottom: 110px;
        justify-content: space-around;
      }

      @media only screen and (min-width: 320px) and (max-width: 490px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100vw;
        height: 0px;
        margin-bottom: 100px;
        justify-content: space-around;
      }

      h3 {
        @media only screen and (min-width: 1280px) {
          margin-top: 246px;
          font-weight: 600;
          font-size: 24px;
          line-height: 28.64px;
        }

        @media only screen and (min-width: 320px) and (max-width: 1280px) {
          margin-top: 19vw;
          font-weight: 600;
          font-size: 2vw;
          line-height: 3vw;
        }
      }

      p {
        @media only screen and (min-width: 1280px) {
          font-weight: 600;
          font-size: 16px;
          line-height: 19.09px;
        }

        @media only screen and (min-width: 320px) and (max-width: 1280px) {
          font-weight: 600;
          font-size: 1.5vw;
          line-height: 2vw;
        }
      }

      &_unlimited {
        @media only screen and (min-width: 1280px) {
          width: 400px;
          height: 350px;
          border-radius: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: url(../../assets/img/common/unlimited.png), #cfe2ff;
          background-repeat: no-repeat;
          background-position: top 34px left 85px;
          color: #324195;
          transition: 0.3s ease;
        }
        @media only screen and (min-width: 320px) and (max-width: 1280px) {
          width: 30vw;
          height: 30vw;
          border-radius: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: url(../../assets/img/common/unlimited.png), #cfe2ff;
          background-repeat: no-repeat;
          background-position: 50% 10%;
          background-size: 60%;
          color: #324195;
          transition: 0.3s ease;
        }

        &:hover {
          @media only screen and (min-width: 1280px) {
            background: url(../../assets/img/common/unlimited2.png), #0d28bb;
            background-repeat: no-repeat;
            background-position: top 20px left 75px;
            color: #ffffff;
          }
          @media only screen and (min-width: 320px) and (max-width: 1280px) {
            background: url(../../assets/img/common/unlimited2.png), #0d28bb;
            background-repeat: no-repeat;
            background-position: 50% 10%;
            background-size: 70%;
            color: #ffffff;
          }
        }
      }

      &_annex {
        @media only screen and (min-width: 1280px) {
          width: 400px;
          height: 350px;
          border-radius: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: url(../../assets/img/common/annex.png), #cfe2ff;
          background-repeat: no-repeat;
          background-position: top 34px left 75px;
          color: #324195;
          transition: 0.3s ease;
        }
        @media only screen and (min-width: 320px) and (max-width: 1280px) {
          width: 30vw;
          height: 30vw;
          border-radius: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: url(../../assets/img/common/annex.png), #cfe2ff;
          background-repeat: no-repeat;
          background-position: 50% 10%;
          background-size: 60%;
          color: #324195;
          transition: 0.3s ease;
        }

        &:hover {
          @media only screen and (min-width: 1280px) {
            background: url(../../assets/img/common/annex2.png), #0d28bb;
            background-repeat: no-repeat;
            background-position: top 20px left 75px;
            color: #ffffff;
          }
          @media only screen and (min-width: 320px) and (max-width: 1280px) {
            background: url(../../assets/img/common/annex2.png), #0d28bb;
            background-repeat: no-repeat;
            background-position: 50% 10%;
            background-size: 70%;
            color: #ffffff;
          }
        }
      }

      &_gb {
        @media only screen and (min-width: 1280px) {
          width: 400px;
          height: 350px;
          border-radius: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: url(../../assets/img/common/gb.png), #cfe2ff;
          background-repeat: no-repeat;
          background-position: top 34px left 85px;
          color: #324195;
          transition: 0.3s ease;
        }
        @media only screen and (min-width: 320px) and (max-width: 1280px) {
          width: 30vw;
          height: 30vw;
          border-radius: 30px;
          display: flex;
          flex-direction: column;
          align-items: center;
          background: url(../../assets/img/common/gb.png), #cfe2ff;
          background-repeat: no-repeat;
          background-position: 50% 10%;
          background-size: 60%;
          color: #324195;
          transition: 0.3s ease;
        }

        &:hover {
          @media only screen and (min-width: 1280px) {
            background: url(../../assets/img/common/gb2.png), #0d28bb;
            background-repeat: no-repeat;
            background-position: top 34px left 85px;
            color: #ffffff;
          }
          @media only screen and (min-width: 320px) and (max-width: 1280px) {
            background: url(../../assets/img/common/gb2.png), #0d28bb;
            background-repeat: no-repeat;
            background-position: 50% 10%;
            background-size: 70%;
            color: #ffffff;
          }
        }
      }
    }

    &_intern {
      @media only screen and (min-width: 1300px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 1280px;
        height: 463px;
        border-radius: 30px;
        background: linear-gradient(to right, #0d28bb, #357eeb);
        margin-bottom: 110px;
      }

      @media only screen and (min-width: 320px) and (max-width: 1300px) {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 85vw;
        height: 40vw;
        border-radius: 30px;
        background: linear-gradient(to right, #0d28bb, #357eeb);
        margin-bottom: 110px;
      }

      &_img {
        @media only screen and (min-width: 1280px) {
          background: url(../../assets/img/common/rocket.png);
          width: 600px;
          height: 463px;
        }

        @media only screen and (min-width: 320px) and (max-width: 1280px) {
          background: url(../../assets/img/common/rocket.png);
          background-position: 50% 50%;
          background-size: cover;
          height: 0;
          padding-bottom: 47%;
          position: relative;
          width: 45%;
        }

        &:hover {
          @media only screen and (min-width: 1150px) {
            background: url(../../assets/img/common/rocket2.png);
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }

          @media only screen and (min-width: 320px) and (max-width: 1150px) {
            background: url(../../assets/img/common/rocket2.png);
            background-position: 50% 50%;
            background-size: cover;
            height: 0;
            padding-bottom: 47%;
            position: relative;
            width: 45%;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            transition: all 0.3s ease;
          }
        }
      }

      &_text {
        color: #ffffff;
        font-family: 'SFPROMedium';

        h2 {
          @media only screen and (min-width: 1280px) {
            font-weight: 800;
            font-size: 36px;
            line-height: 42.96px;
            margin-bottom: 1rem;
          }

          @media only screen and (min-width: 320px) and (max-width: 1280px) {
            font-weight: 800;
            font-size: 3vw;
            line-height: 1vw;
            margin-bottom: 1rem;
          }
        }

        h3 {
          @media only screen and (min-width: 1280px) {
            font-weight: 600;
            font-size: 24px;
            line-height: 28.64px;
            margin-bottom: 1rem;
          }
          @media only screen and (min-width: 320px) and (max-width: 1280px) {
            font-weight: 600;
            font-size: 2vw;
            line-height: 2vw;
            margin-bottom: 1rem;
          }
        }

        ul {
          @media only screen and (min-width: 1280px) {
            margin-bottom: 1rem;
          }
          @media only screen and (min-width: 490px) and (max-width: 1280px) {
            margin-bottom: 1rem;
          }
          @media only screen and (min-width: 320px) and (max-width: 490px) {
            margin-bottom: 0.5rem;
          }
        }

        li {
          @media only screen and (min-width: 320px) and (max-width: 1150px) {
            font-size: 1.7vw;
            line-height: 2vw;
          }
        }
      }
    }

    &_create {
      @media only screen and (min-width: 1240px) {
        display: flex;
        width: 1240px;
        height: 572px;
        margin-top: 50px;
      }

      @media only screen and (min-width: 320px) and (max-width: 1240px) {
        display: flex;
        width: 90vw;
        height: 572px;
        margin-top: 50px;
      }

      &_swiper {
        @media only screen and (min-width: 320px) and (max-width: 1280px) {
          height: 50vw;
        }

        &_navigationButtons {
          @media only screen and (min-width: 1280px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 40px;
          }

          @media only screen and (min-width: 490px) and (max-width: 1280px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
          }

          @media only screen and (min-width: 320px) and (max-width: 490px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2px;
          }

          &_button {
            @media only screen and (min-width: 1280px) {
              width: 400px;
              height: 54px;
              background-color: #cfe2ff;
              border-radius: 10px;
            }

            @media only screen and (min-width: 320px) and (max-width: 1280px) {
              width: 27vw;
              height: 5vw;
              background-color: #cfe2ff;
              border-radius: 10px;
            }

            h5 {
              @media only screen and (min-width: 1280px) {
                font-weight: 500;
                font-size: 16px;
                line-height: 19.09px;
                background: -webkit-linear-gradient(#0d28bb, #357eeb);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }

              @media only screen and (min-width: 320px) and (max-width: 1280px) {
                font-weight: 500;
                font-size: 1.4vw;
                align-items: center;
                display: flex;
                justify-content: center;
                background: -webkit-linear-gradient(#0d28bb, #357eeb);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
          }

          &_activeButton {
            @media only screen and (min-width: 1280px) {
              width: 400px;
              height: 54px;
              background: linear-gradient(to right, #0d28bb, #357eeb);
              border-radius: 10px;
            }

            @media only screen and (min-width: 320px) and (max-width: 1280px) {
              width: 27vw;
              height: 5vw;
              background: linear-gradient(to right, #0d28bb, #357eeb);
              border-radius: 10px;
            }

            h5 {
              @media only screen and (min-width: 1280px) {
                font-weight: 500;
                font-size: 16px;
                line-height: 19.09px;
                color: #fffafa;
              }

              @media only screen and (min-width: 320px) and (max-width: 1280px) {
                font-weight: 500;
                font-size: 1.4vw;
                font-weight: 500;
                font-size: 1.4vw;
                align-items: center;
                display: flex;
                justify-content: center;
                color: #fffafa;
              }
            }
          }
        }

        &_block {
          display: flex;
          flex-direction: row;
          align-items: center;
          color: #324195;

          &_img {
            @media only screen and (min-width: 320px) and (max-width: 1240px) {
              width: 50vw;
            }
          }
          &_text {
            @media only screen and (min-width: 1240px) {
              width: 552px;
              margin-right: 2rem;
            }
            @media only screen and (min-width: 320px) and (max-width: 1240px) {
              width: 55vw;
              margin-right: 1rem;
            }

            h2 {
              @media only screen and (min-width: 1240px) {
                font-weight: 800;
                font-size: 36px;
                line-height: 42.96px;
                margin-bottom: 50px;
              }

              @media only screen and (min-width: 320px) and (max-width: 1240px) {
                font-weight: 800;
                font-size: 3vw;
                line-height: 3vw;
                margin-bottom: 3vw;
              }
            }

            p {
              @media only screen and (min-width: 1240px) {
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 20px;
                line-height: 23.87px;
                margin-bottom: 1rem;
              }

              @media only screen and (min-width: 320px) and (max-width: 1240px) {
                display: flex;
                align-items: center;
                font-weight: 600;
                font-size: 2vw;
                line-height: 2vw;
                margin-bottom: 1rem;
              }
            }

            img {
              margin-right: 15px;

              @media only screen and (min-width: 320px) and (max-width: 1240px) {
                margin-right: 5px;
                width: 2vw;
              }
            }
          }
        }
      }
    }

    &_mobile {
      @media only screen and (min-width: 1280px) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 1280px;
        height: 807px;
        justify-content: flex-end;
        margin-top: 110px;
      }

      @media only screen and (min-width: 490px) and (max-width: 1280px) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100vw;
        height: 100vw;
        justify-content: flex-end;
        margin-top: -50px;
      }

      @media only screen and (min-width: 320px) and (max-width: 490px) {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        width: 100vw;
        height: 467px;
        justify-content: flex-end;
        margin-top: -500px;
      }

      img {
        @media only screen and (min-width: 1240px) {
          position: absolute;
          left: -10%;
        }
        @media only screen and (min-width: 490px) and (max-width: 1240px) {
          position: absolute;
          width: 100vw;
          right: 40%;
        }

        @media only screen and (min-width: 320px) and (max-width: 490px) {
          position: absolute;
          right: 675px;
          width: 450px;
          margin-top: 160px;
        }
      }

      &_text {
        @media only screen and (min-width: 1240px) {
          width: 620px;
          color: #324195;
          z-index: 2;
          margin-top: 200px;
        }

        @media only screen and (min-width: 490px) and (max-width: 1240px) {
          width: 50vw;
          color: #324195;
          z-index: 2;
          margin-top: 100px;
        }

        @media only screen and (min-width: 320px) and (max-width: 490px) {
          width: 55vw;
          color: #324195;
          z-index: 2;
          margin-top: 200px;
        }

        h2 {
          @media only screen and (min-width: 1240px) {
            font-weight: 800;
            font-size: 36px;
            line-height: 42.96px;
            margin-bottom: 50px;
          }

          @media only screen and (min-width: 490px) and (max-width: 1240px) {
            font-weight: 800;
            font-size: 3vw;
            line-height: 3vw;
            margin-bottom: 50px;
          }

          @media only screen and (min-width: 320px) and (max-width: 490px) {
            font-weight: 800;
            font-size: 14px;
            line-height: 16.96px;
            margin-bottom: 20px;
          }
        }

        p {
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 20px;
          line-height: 23.87px;
          margin-bottom: 1rem;
          margin-bottom: 4rem;

          @media only screen and (min-width: 490px) and (max-width: 1240px) {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 2vw;
            line-height: 3vw;
            margin-bottom: 1rem;
            margin-bottom: 4rem;
          }

          @media only screen and (min-width: 320px) and (max-width: 490px) {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 10px;
            line-height: 15.87px;
            margin-bottom: 0.5rem;
            margin-bottom: 1rem;
          }
        }

        &_button {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 1rem;
        }
      }
    }

    &_record {
      @media only screen and (min-width: 1400px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(to right, #0d28bb, #357eeb);
        border-radius: 30px;
        width: 1280px;
        height: 423px;
        color: #ffffff;
      }

      @media only screen and (min-width: 320px) and (max-width: 1400px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(to right, #0d28bb, #357eeb);
        border-radius: 30px;
        width: 85vw;
        height: 40vw;
        color: #ffffff;
      }

      img {
        @media only screen and (min-width: 1400px) {
          width: 720px;
        }

        @media only screen and (min-width: 320px) and (max-width: 1400px) {
          width: 50vw;
        }
      }

      &_block {
        @media only screen and (min-width: 1400px) {
          width: 559px;
          margin: 70px 0 70px 50px;
        }

        @media only screen and (min-width: 320px) and (max-width: 1400px) {
          width: 50vw;
          margin: 1vw 0 1vw 3vw;
        }

        h2 {
          @media only screen and (min-width: 1400px) {
            font-weight: 800;
            font-size: 36px;
            line-height: 42.96px;
            margin-bottom: 50px;
          }

          @media only screen and (min-width: 320px) and (max-width: 1400px) {
            font-weight: 800;
            font-size: 2.5vw;
            line-height: 3vw;
            margin-bottom: 0.5vw;
          }
        }

        p {
          @media only screen and (min-width: 1400px) {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 20px;
            line-height: 23.87px;
            margin-bottom: 1rem;
          }

          @media only screen and (min-width: 320px) and (max-width: 1400px) {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 2vw;
            line-height: 2vw;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    &_feedback {
      margin: 110px auto;
      width: 100%;
      text-align: center;
      padding: 0 1rem;

      @media only screen and (min-width: 320px) and (max-width: 800px) {
        margin-top: 110px;
        margin-bottom: -25vw;
      }
    }

    &_free {
      @media only screen and (min-width: 1400px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #cfe2ff;
        border-radius: 30px;
        width: 1280px;
        height: 400px;
        color: #324195;
        overflow: hidden;
        margin-bottom: 100px;
      }

      @media only screen and (min-width: 490px) and (max-width: 1400px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #cfe2ff;
        border-radius: 30px;
        width: 85vw;
        height: 40vw;
        color: #324195;
        overflow: hidden;
        margin-bottom: 100px;
        margin-top: -10vw;
      }

      @media only screen and (min-width: 320px) and (max-width: 490px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: #cfe2ff;
        border-radius: 30px;
        width: 85vw;
        height: 40vw;
        color: #324195;
        overflow: hidden;
        margin-bottom: 100px;
        margin-top: -320px;
      }

      img {
        @media only screen and (min-width: 1400px) {
          filter: drop-shadow(0px 4px 10px #251a3180);
        }

        @media only screen and (min-width: 320px) and (max-width: 1400px) {
          filter: drop-shadow(0px 4px 10px #251a3180);
          width: 40vw;
        }
      }

      &_block {
        @media only screen and (min-width: 1400px) {
          width: 640px;
          margin: 70px 0 70px 50px;
        }

        @media only screen and (min-width: 320px) and (max-width: 1400px) {
          margin: 1vw;
          width: 60vw;
        }

        h2 {
          @media only screen and (min-width: 1400px) {
            font-weight: 800;
            font-size: 36px;
            line-height: 42.96px;
            margin-bottom: 50px;
          }

          @media only screen and (min-width: 320px) and (max-width: 1400px) {
            font-weight: 800;
            font-size: 3vw;
            line-height: 3vw;
            margin-bottom: 2vw;
          }
        }

        p {
          @media only screen and (min-width: 1400px) {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 20px;
            line-height: 23.87px;
            margin-bottom: 1rem;
          }

          @media only screen and (min-width: 320px) and (max-width: 1400px) {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 2vw;
            line-height: 3vw;
            margin-bottom: 1rem;
          }
        }
      }
    }

    &_system {
      display: flex;
      align-items: center;
      flex-direction: column;

      h2 {
        @media only screen and (min-width: 1150px) {
          font-weight: 800;
          font-size: 36px;
          line-height: 42.96px;
          background: -webkit-linear-gradient(#0d28bb, #357eeb);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        @media only screen and (min-width: 490px) and (max-width: 1150px) {
          font-weight: 800;
          font-size: 3vw;
          line-height: 42.96px;
          background: -webkit-linear-gradient(#0d28bb, #357eeb);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        @media only screen and (min-width: 320px) and (max-width: 490px) {
          font-weight: 800;
          font-size: 16px;
          line-height: 42.96px;
          background: -webkit-linear-gradient(#0d28bb, #357eeb);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }

      &_box {
        @media only screen and (min-width: 1280px) {
          display: grid;
          margin: 100px 0 100px 0;
          gap: 2rem;
          grid-template-areas:
            'zapier youtube kassa metrika'
            'zoom1 tilda vk google'
            'bitriks wordpress amocmr tbank';
        }
        @media only screen and (min-width: 490px) and (max-width: 1280px) {
          display: grid;
          margin: 100px 0 100px 0;
          gap: 2vw;
          grid-template-areas:
            'zapier youtube kassa metrika'
            'zoom1 tilda vk google'
            'bitriks wordpress amocmr tbank';
        }
        @media only screen and (min-width: 320px) and (max-width: 490px) {
          display: grid;
          margin: 30px 0 30px 0;
          gap: 0.7rem 0.5rem;
          grid-template-areas:
            'zapier youtube kassa metrika'
            'zoom1 tilda vk google'
            'bitriks wordpress amocmr tbank';
        }

        div {
          @media only screen and (min-width: 1280px) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 290px;
            height: 72px;
            border-radius: 20px;
            box-shadow: 0px 0px 20px #3241951a;
          }
          @media only screen and (min-width: 490px) and (max-width: 1280px) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20vw;
            height: 6vw;
            border-radius: 20px;
            box-shadow: 0px 0px 20px #3241951a;
          }
          @media only screen and (min-width: 320px) and (max-width: 490px) {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 85px;
            height: 32px;
            border-radius: 20px;
            box-shadow: 0px 0px 20px #3241951a;
          }
          img {
            @media only screen and (min-width: 490px) and (max-width: 1280px) {
              width: 10vw;
            }

            @media only screen and (min-width: 320px) and (max-width: 490px) {
              width: 65%;
              height: 50%;
            }
          }
        }

        &_zapier {
          grid-area: zapier;
        }
        &_youtube {
          grid-area: youtube;
        }
        &_kassa {
          grid-area: kassa;
        }
        &_metrika {
          grid-area: metrika;
        }
        &_zoom {
          grid-area: zoom1;
        }
        &_tilda {
          grid-area: tilda;
        }
        &_vk {
          grid-area: vk;
        }
        &_google {
          grid-area: google;
        }
        &_bitriks {
          grid-area: bitriks;
        }
        &_wordpress {
          grid-area: wordpress;
        }
        &_amocmr {
          grid-area: amocmr;
        }
        &_tbank {
          grid-area: tbank;
        }
      }
    }

    &_takeFree {
      @media only screen and (min-width: 1400px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(to right, #0d28bb, #357eeb);
        border-radius: 30px;
        width: 1280px;
        height: 500px;
        color: #ffffff;
        overflow: hidden;
        margin-bottom: 100px;
      }
      @media only screen and (min-width: 320px) and (max-width: 1400px) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: linear-gradient(to right, #0d28bb, #357eeb);
        border-radius: 30px;
        width: 85vw;
        height: 40vw;
        color: #ffffff;
        overflow: hidden;
        margin-bottom: 100px;
      }

      &_block {
        width: 472px;
        margin: 70px 0 70px 50px;

        @media only screen and (min-width: 320px) and (max-width: 1400px) {
          width: 50vw;
          margin: 2vw;
        }

        h2 {
          @media only screen and (min-width: 1400px) {
            font-weight: 800;
            font-size: 36px;
            line-height: 42.96px;
            margin-bottom: 50px;
          }
          @media only screen and (min-width: 320px) and (max-width: 1400px) {
            font-weight: 800;
            font-size: 2.5vw;
            line-height: 3vw;
            margin-bottom: 5vw;
          }
        }

        p {
          @media only screen and (min-width: 1400px) {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 20px;
            line-height: 23.87px;
            margin-bottom: 1rem;
          }
          @media only screen and (min-width: 320px) and (max-width: 1400px) {
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 2vw;
            line-height: 3vw;
            margin-bottom: 1rem;
          }
        }
        &_img {
          img {
            @media only screen and (min-width: 320px) and (max-width: 1400px) {
              width: 50vw;
            }
          }
        }
      }
    }
  }
}

.btn_block {
  display: flex;
  width: 100%;
  justify-content: space-around;

  &_logIn {
    text-decoration: none;
    font-size: 18px;
    font-weight: 700;
    color: #ba75ff;
    animation: account 0.2s ease-in-out;
  }
}

// .btn_block button:nth-child(1) {
//   margin-right: 25px;
// }

.header_block {
  @include display(flex);
  justify-content: center;
  align-items: center;
  gap: 18px;

  &_menu {
    @media only screen and (min-width: 1150px) {
      align-items: center;
      display: flex;
      overflow: hidden;
      width: 510px;
      // transition: width 1s;
      justify-content: space-around;
    }

    @media only screen and (min-width: 320px) and (max-width: 1150px) {
      align-items: center;
      display: flex;
      overflow: hidden;
      width: 40vw;
      // transition: width 1s;
      justify-content: space-around;
    }

    &_platforma {
      &_content {
        @media only screen and (min-width: 1150px) {
          display: none;
          position: absolute;
          background-color: #cfe2ff;
          width: 314px;
          z-index: 1;
          border-radius: 14px;
          padding: 16px;
          box-shadow: 0px 2px 4px 0px #357eeb;
        }

        @media only screen and (min-width: 320px) and (max-width: 1150px) {
          display: none;
          position: absolute;
          background-color: #cfe2ff;
          width: 20vw;
          z-index: 1;
          border-radius: 14px;
          padding: 6px;
          box-shadow: 0px 2px 4px 0px #357eeb;
        }

        a {
          padding: 12px 16px;
          text-decoration: none;
          display: block;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;

          @media only screen and (min-width: 320px) and (max-width: 1150px) {
            padding: 1vw;
            text-decoration: none;
            display: block;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1vw;
          }
          img {
            @media only screen and (min-width: 320px) and (max-width: 1150px) {
              width: 2vw;
            }
          }

          h5 {
            @media only screen and (min-width: 1150px) {
              color: #324195;
              line-height: 18.75px;
              font-size: 16px;
            }
            @media only screen and (min-width: 320px) and (max-width: 1150px) {
              color: #324195;
              line-height: 2vw;
              font-size: 1vw;
            }
          }
        }

        a:hover {
          background-color: #0d28bb;
          text-decoration: none;
          border-radius: 14px;

          h5 {
            color: #ffffff;
          }
        }
      }
    }
    &_platforma:hover &_platforma_content {
      display: block;
    }
  }

  // .header_block:hover &_menu{
  //   width: 510px;
  // }

  &_name {
    margin-right: 15em;
    p {
      text-align: center;
      font-size: 16px;
      font-style: italic;
      font-weight: 800;
      color: #ba75ff;
    }
  }

  &_logOut {
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #357eeb;

    &:hover {
      color: #ffffff;
      background: linear-gradient(#0d28bb, #357eeb);
      border-radius: 10px;
    }

    cursor: pointer;
    margin-right: 0px;
  }

  &_plus {
    width: 24px;
    height: 24px;

    @media only screen and (min-width: 320px) and (max-width: 1150px) {
      width: 2vw;
      height: 2vw;
    }
  }
}
