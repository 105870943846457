@import '../../../../scss/mixin.scss';

.commentForm {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  border-radius: 1.5rem;

  box-shadow: 0rem 0rem 0.58rem 0.25rem rgba(53, 126, 235, 0.45);
  /* Blue inactive gradient */
  background: linear-gradient(180deg, rgb(207, 226, 255), rgb(187, 206, 235) 100%);

  textarea {
    min-height: 62px;
    width: 100%;
    padding: 0.63rem 1.25rem 0.63rem 1.25rem;
    // margin-bottom: 10px;
    box-sizing: border-box;
    /* White */
    border: 0.06rem solid rgb(255, 255, 255);
    border-radius: 0.88rem;
    /* White */
    background: rgb(255, 255, 255);
    &::-webkit-scrollbar {
      display: none;
    }
  }

  button {
    transition: all 0.3s;
    font-size: 1.25rem;
    padding: 1rem 2.5rem 1rem 2.5rem;
    width: 11.13rem;
    height: 3.38rem;
  }
}

.hwTitle {
  color: rgb(51, 47, 54);
  font-family: 'SFPRORegular';
  font-size: 2.25rem;
  font-weight: 500;
  line-height: 2.69rem;
  letter-spacing: -1px;
  text-align: left;
}

.wrapper {
  width: 100%;
  margin-top: 13px;
  padding: 32px 40px 22px 40px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 20px 13px;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100vw;
    textarea {
      width: 90vw;
      margin-top: 1%;
    }
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 20px 13px;
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100vw;
    textarea {
      width: 89vw;
      margin-top: 1%;
    }
  }

  textarea {
    width: 100%;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    padding: 10px;
    --focus-ring: transparent;
  }

  &_title {
    @include font(500, 14px, 17px);
    margin-bottom: 18px;
    color: #4d5766;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      margin: 0 0 15px 2px;
      @include font(500, 10px, 12px);
    }
  }
  &_form {
    &_addFiles {
      @include display(flex, row, flex-start, center);
      width: 180px;
      height: 36px;
      border: none;
      text-align: center;
      background: #f3f4f6;
      font-size: 12px;
      color: #6b728f;
      border-radius: 6px;
      padding: 11px 0 11px 16px;
      margin-top: 16px 0 8px 0;

      input {
        @include position_t_l(absolute, 0, 0, -10);
      }
      svg {
        margin-right: 10px;
      }
    }
    &_help {
      display: block;
      margin-top: 8px;
      font-size: 12px;
      color: #9f9f9f;
    }
  }
}
