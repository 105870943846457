@import 'src/scss/variable';
@import '../../../scss/mixin.scss';

.container {
  position: relative;
  width: 78px;
  height: 50px;
  background: #f6f6f6 url('../../../assets/img/common/blackArrowDown.svg') no-repeat 82% 51%;
  border: 1px solid #e8e8e8;
  border-radius: 8px;
  margin-left: 8px;
  cursor: pointer;
  font-family: $font-family-inter;
}
.phone {
  @include position_t_l (absolute, 14px, 13px);
}
.email {
  @include position_t_l (absolute, 17px, 10px);
}
.popup {
  @include position_b_l (absolute, -70px, 0, 12);
  width: 152px;
  height: 66px;
  border-radius: 7px;
  background: #ffffff;
  font-size: 13px;
  color: #6b7484;
  box-shadow: 0 0 10px rgba(236, 236, 236, 0.93);
  @media (max-width: 480px) {
    left: -50px;
    bottom: -85px;
  }

  & div {
    margin-top: 12px;
    padding-left: 32px;
  }
}
.checked {
  background: url('../../../assets/img/common/done.svg') no-repeat 10px 3px;
}