@import '../../../../scss/mixin.scss';
@import '../../../../scss/variable.scss';

.wrapper {
  min-width: 700px;
  width: 100%;
  position: relative;

  @include display(flex, column, flex-start, center);
  gap: 2rem;

  @media screen and (max-width: 1024px) {
    min-width: 100%;
    min-height: 700px;
  }

  &_title {
    @include font(500, 11px, 13px);
    color: #ba75ff;
  }

  &_question {
    margin: 16px 0;
    @include font(600, 16px, 20px);
    color: #4d5766;
  }

  &_progressBar {
    width: 164px;
    height: 8px;
    background-color: #f6f6f6;
    border-radius: 7px;
    overflow: hidden;

    &_progress {
      height: 100%;
      background-color: #ba75ff;
    }

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      margin-bottom: 4%;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      margin-bottom: 4%;
    }
    @media only screen and (min-width: 480px) and (max-width: 1024px) {
      margin-bottom: 4%;
    }
  }

  &_button button {
    align-self: flex-start;
    margin-top: 32px;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      margin-top: 0;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      margin-top: 0;
    }
  }
  &_button button:disabled {
    opacity: 0.5;
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    min-width: 95%;
    width: 100%;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    min-width: 95%;
    width: 100%;
  }
}

.questionBlock {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  width: 100%;
  min-height: 700px;
  position: relative;

  &_question {
    position: relative;
    min-height: 40rem;
    width: 100%;
    border-radius: 4rem;
    box-shadow: 0rem 0rem 0.5rem 0rem rgba(50, 65, 149, 0.3), 0rem 0rem 0.5rem 0rem rgba(50, 65, 149, 0.3), 0rem 0rem 1.56rem 0rem rgb(53, 126, 235);
    /* White */
    background: rgb(255, 255, 255);
    z-index: 1;

    @media screen and (max-width: 800px) {
      height: 100%;
    }

    &_buttons {
      position: absolute;
      z-index: 4;
      transform: translateX(-50%);
      left: 50%;
      right: 50%;
      bottom: 2rem;
      width: 18.56rem;
      height: 3.38rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }

    &_timer {
      position: absolute;
      z-index: 3;
      transform: translateX(-50%);
      left: 50%;
      right: 50%;
      top: 2rem;
      width: 100%;
      height: 4.88rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 0.2rem;

      p {
        width: 100%;
        flex: none;
        color: rgb(51, 47, 54);
        font-family: 'SFPRORegular';
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.19rem;
        letter-spacing: 0%;
        text-align: center;
      }

      h2 {
        width: 100%;
        /* Desktop/Head/H2 */
        color: rgb(51, 47, 54);
        font-family: 'SFPROMedium';
        font-size: 2.25rem;
        font-weight: 500;
        line-height: 2.69rem;
        letter-spacing: 0%;
        text-align: center;
      }
    }

    &_questionBlock {
      position: relative;
      z-index: 4;
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 30rem;

      &_preview {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 9rem 2rem 9rem 5rem;

        @media screen and (max-width: 800px) {
          padding: 4rem 1rem;
          align-items: center;
          justify-content: center;
        }
      }

      &_results {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 40rem;
        padding: 2rem 4rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: 1024px) {
          padding: 2rem 1rem;
        }

        &_header {
          width: 100%;
          display: flex;
          justify-content: space-between;
          justify-self: flex-start;

          @media screen and (max-width: 1024px) {
            gap: 1rem;
            font-size: 16px;
          }

          &_time {
            display: flex;
            gap: 3rem;
            font-size: 24px;
            font-family: 'SFPROMedium';
            line-height: 28.64px;

            @media screen and (max-width: 1024px) {
              gap: 1rem;
              font-size: 0.7em;
            }

            span {
              color: #357eeb;
            }
          }

          &_correctAnswers {
            display: flex;
            gap: 3rem;
            font-size: 24px;
            font-family: 'SFPROMedium';
            line-height: 28.64px;

            @media screen and (max-width: 1024px) {
              gap: 1rem;
              font-size: 0.7em;
            }

            span {
              color: #357eeb;
            }
          }
        }

        &_success {
          position: absolute;
          width: 566px;
          height: 251px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 2rem;

          &_circle {
            width: 160px;
            height: 160px;
            border-radius: 100%;
            background-color: #357eeb;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          span {
            font-family: 'SFPROMedium';
            font-size: 2.25rem;
            letter-spacing: -1px;
            font-weight: 500;

            @media only screen and (min-width: 320px) and (max-width: 480px) {
              font-size: 2rem;
            }
          }
        }
      }

      @media only screen and (max-width: 1024px) {
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }

      &_images {
        padding: 9rem 2rem 9rem 0;
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, 280px);
        gap: 1rem;

        @media screen and (max-width: 1024px) {
          padding: 2rem 2rem 8rem 2rem;
          justify-content: center;
        }
      }

      &_content {
        padding: 9rem 2rem 9rem 5rem;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @media screen and (max-width: 800px) {
          padding: 9rem 1rem 0;
        }

        &_title {
          display: flex;
          align-items: center;
          gap: 1rem;
          width: 100%;

          &_textBlock {
            display: flex;
            flex-direction: column;
            gap: 0.2rem;

            &_questionTitle {
              /* Desktop/Head/H3 */
              color: rgb(51, 47, 54);
              font-family: 'SFPROMedium';
              font-size: 1.5rem;
              font-weight: 500;
              line-height: 1.81rem;
              letter-spacing: -1px;
              text-align: left;

              @media screen and (max-width: 800px) {
                font-size: 1rem;
              }
            }

            &_questionSubtitle {
              /* Desktop/Head/H5 */
              color: rgb(51, 47, 54);
              font-family: 'SFPRORegular';
              font-size: 1rem;
              font-weight: 500;
              line-height: 1.19rem;
              letter-spacing: -0.5px;
              text-align: left;
            }
          }

          &_number {
            /* Desktop/Head/H2 */
            color: rgb(255, 255, 255);
            font-family: 'SFPRORegular';
            font-size: 2em;
            font-weight: 500;
            line-height: 2.69rem;
            letter-spacing: 0%;
            text-align: center;

            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
            min-width: 4rem;
            min-height: 4rem;
            width: 4rem;
            height: 4rem;
            /* Button */
            background: rgb(53, 126, 235);

            @media screen and (max-width: 800px) {
              font-size: 1rem;
              min-width: 1.5rem;
              min-height: 1.5rem;
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }

        &_answers {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 2rem;
          padding-left: 5rem;
        }
      }
    }

    &_background {
      z-index: 2;
      position: absolute;
      height: 100%;
      min-height: 40rem;
      width: 100%;
      left: 0;
      top: 0;
      border-radius: 4rem;
      backdrop-filter: blur(5.13rem);
      background: rgba(255, 250, 250, 0.2);
      display: grid;
      grid-template-columns: 1fr 28rem;
      grid-template-rows: 1fr;
      overflow: hidden;

      @media only screen and (min-width: 320px) and (max-width: 1024px) {
        display: none;
      }

      &_leftSide {
        position: relative;

        &_circleBg {
          /* Ellipse 559 */
          position: absolute;
          width: 18.06rem;
          height: 18.06rem;
          left: -7.06rem;
          bottom: -10rem;
          filter: blur(25rem);
          background: #57b8ff;
        }
      }

      &_rightSide {
        position: relative;
        width: 100%;
        height: 100%;

        @media screen and (max-width: 800px) {
          display: none;
        }

        svg {
          z-index: 3;
        }

        &_circleBg {
          /* Ellipse 558 */
          z-index: 2;
          position: absolute;
          width: 41.56rem;
          height: 41.56rem;
          right: -25rem;
          top: 10%;
          filter: blur(25rem);
          background: rgb(87, 184, 255);
        }

        svg:nth-child(1) {
          /* Vector */
          position: absolute;
          width: 11.78rem;
          height: 50.17rem;
          left: 50%;
          right: 50%;
          top: -3.3rem;
          transform: translateX(-50%);
        }

        svg:nth-child(2) {
          /* ? */
          position: absolute;
          width: 7.88rem;
          height: 10.19rem;
          left: 60%;
          top: 30.69rem;
        }

        svg:nth-child(3) {
          /* ? */
          position: absolute;
          width: 3.25rem;
          height: 4.19rem;
          left: 64%;
          top: 22.88rem;
        }
        svg:nth-child(4) {
          /* ? */
          position: absolute;
          width: 6.63rem;
          height: 8.69rem;
          left: 59%;
          top: 4.06rem;
        }
        svg:nth-child(5) {
          /* ? */
          position: absolute;
          width: 6.38rem;
          height: 8.31rem;
          left: 15%;
          top: 25.88rem;
        }
        svg:nth-child(6) {
          /* ? */
          position: absolute;
          width: 6.5rem;
          height: 8.44rem;
          left: 0;
          top: 12.94rem;
        }
        svg:nth-child(7) {
          /* ? */
          position: absolute;
          width: 3.88rem;
          height: 5.06rem;
          left: 24%;
          top: 3.81rem;
        }
      }
    }
  }
}

.button {
  width: 8.63rem;
  height: 3.38rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.94rem 2.5rem 0.94rem 2.5rem;

  font-family: 'SFPRORegular';
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: center;
}

.imageBorder {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 9rem;
  border-radius: 1rem;
  position: relative;

  /* Blue Linear 2 */
  background: linear-gradient(90deg, rgb(49, 112, 231) 13.5%, rgb(122, 144, 247) 100%);

  &_icon {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 14px;
    right: 14px;
    background-color: #3170e7;
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: 'SFPROMedium';
    color: white;
  }

  &_image {
    width: 260px;
    height: 8.44rem;
    border-radius: 1rem;

    @media screen and (max-width: 1024px) {
      width: 95%;
    }
  }
}

.button_complete {
  width: 290px;
  height: 3.38rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.94rem 2.5rem 0.94rem 2.5rem;

  font-family: 'SFPRORegular';
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  letter-spacing: 0%;
  text-align: center;
}

.lessonHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;

  &_title {
    color: var(--Black, #332f36);
    text-align: center;

    /* Desktop/Head/H2 */
    font-family: 'SFPROMedium';
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1px;

    @media only screen and (min-width: 320px) and (max-width: 480px) {
      font-size: 2rem;
    }
  }

  &_nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    height: 3.375rem;

    button {
      font-size: 1.25rem !important;
      letter-spacing: -1px;

      @media screen and (min-width: 701px) and (max-width: 900px) {
        font-size: 1rem !important;
        padding: 0.5rem 1rem;
        gap: 0.225rem;
      }

      @media screen and (min-width: 501px) and (max-width: 700px) {
        font-size: 0.75rem !important;
        padding: 0.25rem 0.75rem;
        gap: 0.225rem;
      }

      @media only screen and (min-width: 320px) and (max-width: 500px) {
        font-size: 0.625rem !important;
        padding: 0.15rem 0.2rem;
        gap: 0.2rem;
      }
    }
  }
}
