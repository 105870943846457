.pagination-bar {
  display: flex;
  align-items: center;
  justify-content: center;
} 

.pagination-text {
  font-weight: 500;
  font-size: 12px;
  color: #424345;
  margin-right: 10px;
}

.pagination-total {
  font-weight: 600;
}

.pagination-container {
  display: flex;
  list-style-type: none;
  //background: #FEFEFE;
  //border: 1px solid #D1D5DB;
  //border-radius: 7px;

  .pagination-item {
    //padding: 0 14px;
    height: 24px;
    text-align: center;
    vertical-align: middle;
    //margin: 0 auto;
    margin-right: 5px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    //box-sizing: border-box;
    align-items: center;
    font-size: 16px;
    width: 24px;
    //border-right: 1px solid #D1D5DB;

    //&:last-child {
    //  border-right: none
    //}

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      //background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: #357dea;
      border-radius:4px;
      color: #ffffff;
      border-style:hidden;
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
        margin-right: 15px;
      }

      &.right {
        transform: rotate(45deg);
        margin-left: 15px;
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}
