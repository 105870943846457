@import '../../scss/variable';
@import '../../scss/mixin.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../assets/fonts/SFProText/SFProText-Regular.ttf) format('opentype');
}

.fit-content {
  width: fit-content;
}

.text {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.main {
  .links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  font-family: 'Inter', sans-serif;
  color: #4d5766;

  &_input {
    width: 100%;
    min-width: 100%;
  }

  & input {
    height: 36px;
    width: 100%;
    box-sizing: border-box;
  }

  &_title {
    font-size: 18px;
    color: rgb(51, 47, 54);
    font-weight: 500;
    line-height: 29px;
  }

  &_project {
    color: rgb(51, 47, 54);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.03em;
    margin-bottom: 11px;
  }

  &_description {
    @include font(400, 10px);
    margin-bottom: 10px;
    color: dodgerblue;
    padding: 15px;
    opacity: 0.6;
    font-weight: bold;
    font-size: 12px;
  }

  .btndomain {
    display: block;
    margin: auto;
    width: 140px;
  }

  .instructions {
    border-radius: 5px;
  }

  .instructionsTitle {
    font-weight: bold;
    margin-bottom: 5px;
    font-size: 16px;
  }

  .instructionsText {
    margin: 0 0 10px 0;
    font-size: 14px;
    line-height: 1.5;
  }

  .instructionsList {
    font-size: 14px;
    line-height: 1.5;
    padding-left: 20px;
  }

  &_input {
    width: 318px;
    padding: 9px 0 9px 16px;
    outline: none;
    border: #e5e7eb 1px solid;
    border-radius: 5px;
    font-size: 12px;
    margin-right: 52px;
  }

  &_btn {
    width: 90px;
    font-size: 10px;
    color: #ffffff;
    background-color: #b5b5b5;
    letter-spacing: -0.03em;
    text-align: center;
    padding: 11px 0;
    border: none;
    border-radius: 5px;
  }

  &_btn_settings {
    width: 90px;
    font-size: 10px;
    color: #ffffff;
    background-color: #032c52;
    letter-spacing: -0.03em;
    text-align: center;
    padding: 11px 0;
    border: none;
    border-radius: 5px;
  }

  &_create_banner_btn {
    background-color: #357eeb;
    border-radius: 10px;
    color: #fff;
    padding: 15px 40px;
    font-size: 20px;
    font-weight: 500;
    transition-duration: $trans-dur02 !important;
  }

  &_input {
    max-width: 320px;
    padding-bottom: 10px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      max-width: 100vw;
    }

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      max-width: 100vw;
    }
  }
}

.logs {
  font-family: $font-family-inter;
  color: #4d5766;
  margin-bottom: 85px;

  &_title {
    @include font(500, 16px);
    margin-bottom: 20px;
  }

  &_table {
    width: 100%;

    &_title {
      position: relative;
      @include display(flex, row, space-between);
      font-size: 11px;
      padding: 0 20px;

      & div {
        width: 300px;
      }
    }
  }
}

.wrapper_contentemployees {
  background-color: #ffffff;
  //padding: 26px 32px 24px 32px;
  box-shadow: 0 0 8px #d9d9d9;
  border-radius: 20px;
  overflow-x: auto;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 4% 3% 4% 3%;
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 4% 3% 4% 3%;
  }
}

.employees {
  &_header {
    @include display(flex, row, space-between);
    margin-bottom: 15px;
    padding-left: 10px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5%;
    }

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5%;
    }

    &_title {
      @include font(500, 20px);
      color: #4d5766;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        margin-bottom: 0;
      }

      @media only screen and (min-width: 375px) and (max-width: 480px) {
        margin-bottom: 0;
      }
    }

    &_btn {
      font-family: 'Manrope', sans-serif;
      @include font(800, 10px);
      border: none;
      outline: none;
      background: #357eeb;
      width: 180px;
      border-radius: 10px;
      color: #ffffff;
      text-align: center;
      align-items: center;
      padding: 10px 0;
      cursor: pointer;
    }
  }

  &_table {
    width: 100%;
    max-width: 100%;

    &_title {
      position: relative;
      @include display(flex, row, space-between);
      width: 100%;
      font-size: 16px;
      font-weight: 600;
      // margin-bottom: 13px;
      font-family: 'SFPRORegular', sans-serif;

      //padding: 0 20px;
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        margin: 2% 0 2% 0;
      }

      @media only screen and (min-width: 375px) and (max-width: 480px) {
        padding: 4% 0 4% 0;
      }

      & div:nth-child(1) {
        width: 60%;
        height: 45px;
        padding-left: 30px;
        padding-top: 10px;
        background-color: #e3ecff;
      }

      & div:nth-child(2) {
        width: 40%;
        height: 45px;
        padding-top: 10px;
        background-color: #e3ecff;
      }
    }
  }
}

.bonuses {
  &_header {
    @include display(flex, row, space-between, center);
    margin-bottom: 20px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5%;
    }

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5%;
    }

    &_title {
      @include font(500, 24px, 28.64px);
      font-family: 'SFPRORegular';
      color: $color-text;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        margin-bottom: 0;
      }

      @media only screen and (min-width: 375px) and (max-width: 480px) {
        margin-bottom: 0;
      }
    }

    &_btn {
      font-family: 'SFPRORegular';
      @include font(500, 16px, 19.09px);
      border: none;
      outline: none;
      background: $color-blue;
      border-radius: 10px;
      color: #ffffff;
      text-align: center;
      align-items: center;
      padding: 10px 40px;
      cursor: pointer;
    }
  }

  &_closed {
    @include position_t_r(absolute, 43px, 30px);
    text-align: center;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 0.3s;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      top: 0;
      right: 0;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      top: 0;
      right: 0;
    }

    svg:hover path {
      fill: $color-blue;
    }
  }

  &_table {
    min-width: fit-content;

    &_title {
      position: relative;
      @include display(flex, row, space-between);
      width: 100%;
      font-size: 11px;
      //font-weight: bolder;
      letter-spacing: 0.05rem;
      color: #959ea6;
      padding: 0 20px;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        margin: 2% 0 2% 0;
      }

      @media only screen and (min-width: 375px) and (max-width: 480px) {
        padding: 4% 0 4% 0;
      }

      & div:nth-child(1) {
        width: 5%;
      }

      & div:nth-child(2) {
        width: 20%;
      }

      & div:nth-child(3) {
        width: 40%;
      }

      & div:nth-child(4) {
        width: 15%;
      }

      & div:nth-child(5) {
        width: 20%;
      }
    }
  }
}

.warning {
  @include font(400, 12px);
  color: #808996;
}

.title_passport {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;

  &_header {
    font-weight: 500;
    font-size: 20px;
  }

  &_block {
    display: flex;
    gap: 40px;
  }
}

.decor {
  font-family: 'Inter', sans-serif;
  color: #4d5766;
  @include display(flex, column);

  &_title {
    @include font(bold, 20px);
    margin-bottom: 20px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;

  &_actions {
    width: 100%;
    background-color: #ffffff;
    padding: 26px 32px 24px 32px;
    box-shadow: 0px 0px 18px 0px rgba(50, 65, 149, 0.3);
    border-radius: 20px;
    // overflow-x: auto;
    margin-bottom: 50px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      padding: 4% 3% 4% 3%;
    }

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      padding: 4% 3% 4% 3%;
    }
  }
}

.error {
  color: red;
  font-size: 12px;
  margin-top: -0.5em;
  padding-bottom: 1em;
  padding-left: 0.2em;
  font-family: 'Inter', sans-serif;
}

.form {
  position: relative;
  max-width: 60%;
  width: 100%;
  margin: 0 26px 0 0;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    max-width: 100%;
    width: 100%;
    margin: 73px 0 20px 0;

    h3 {
      @include font(600, 12px, 14px);
    }
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    max-width: 100%;
    width: 100%;
    margin: 73px 0 20px 0;

    h3 {
      @include font(600, 12px, 14px);
    }
  }

  &_logoWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 92px;
    height: 100%;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      height: 60px;
    }

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      height: 60px;
    }

    &_logoBlock {
      position: relative;
      height: 92px;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        height: 60px;
      }

      @media only screen and (min-width: 375px) and (max-width: 480px) {
        height: 60px;
      }

      &_title {
        font-family: 'SFPRORegular';
        @include font(400, 16px, 19.09px);
        color: $color-grey;

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          display: none;
        }

        @media only screen and (min-width: 375px) and (max-width: 480px) {
          display: none;
        }
      }

      &_img {
        @include position_b_l(absolute, 0, 0, 1);
        width: 60px;
        height: 60px;
        border-radius: 7px;
        background: transparent;

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          @include position_t_l(absolute, 0, 0, 1);
          width: 49px;
          height: 49px;
          border-radius: 50%;
        }
      }

      &_input {
        width: 100%;
        text-overflow: ellipsis;
        text-align: left;

        div {
          div {
            flex-direction: row-reverse;
            border: 1px solid transparent;
            background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(90deg, #0d28bb 0%, #357eeb 100%) border-box;
            padding: 15px 16px;
            border-radius: 16px;
          }
        }

        input {
          font-size: 18.5px;

          @media only screen and (min-width: 320px) and (max-width: 375px) {
            font-size: 14px;
          }
        }

        input::placeholder {
          font-weight: 400;
          font-size: 16px;
          color: $color-grey;
        }
      }

      &_groups {
        margin-bottom: 0.875rem;
      }
    }

    &_error {
      font-size: 12px;
      color: rgb(224, 24, 24);
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }

  &_groups {
    cursor: pointer;
    border: 1px solid transparent;
    background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(90deg, #0d28bb 0%, #357eeb 100%) border-box;
    padding: 16px;
    border-radius: 16px;
    height: 56px;
    font-size: 16px;
    color: $color-grey;
    font-weight: 400;
    @include display(flex, row, space-between, center);
  }

  &_button {
    max-width: 288px;
    width: 100%;

    @media only screen and (min-width: 320px) and (max-width: 480px) {
      max-width: 150px;
    }

    button {
      padding: 15px 40px;
      width: 100%;

      @media only screen and (min-width: 320px) and (max-width: 480px) {
        font-size: 16px;
        padding: 11px 13px;
      }
    }
  }
}

.bonus {
  position: relative;
  @include display(flex);

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    @include display(flex, column, flex-start, center);
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    @include display(flex, column, flex-start, center);
  }

  &_block {
    margin-bottom: 15px;

    &_logoBlock {
      position: relative;
      height: 92px;

      &_logo {
        @include position_b_l(absolute, 0, 0, 1);
        width: 60px;
        height: 60px;
        border-radius: 7px;
        background: url('../../assets/img/common/prizeNoAvatar.svg') no-repeat center center;

        &_title {
          font-family: 'SFPRORegular';
          @include font(500, 24px, 28.64px);
          color: #000000;
          margin: 0 0 16px 66px;

          @media only screen and (min-width: 320px) and (max-width: 428px) {
            font-size: 16px;
            margin: 0 0 0 66px;
          }
          @media only screen and (min-width: 429px) and (max-width: 480px) {
            font-size: 16px;
            margin: 16px 0 16px 66px;
          }
          @media only screen and (min-width: 480px) and (max-width: 545px) {
            font-size: 16px;
          }
          @media only screen and (min-width: 545px) and (max-width: 800px) {
            font-size: 20px;
          }
        }
      }

      &_input {
        @include position_b_l(absolute, 0, 0, 2);
        height: 60px;
        width: 60px;
        cursor: pointer;
        opacity: 0;

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          @include position_b_l(absolute, 0, 0, 2);
          width: 49px;
          height: 49px;
          border-radius: 50%;
          @include font(400, 8px, 10px);
        }
      }
    }
  }
}

.save_button {
  width: 179px;
  height: 54px;
  border-radius: 10px;
  color: #ffffff;
  background: rgb(53, 126, 235);
}

.inputWrapper {
  position: relative;
  width: 100%;
}

.main_input {
  width: 100%;
  padding-right: 36px;
}

.penIcon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: 0px 0px 8px 0px #3241954d;

  &_header {
    background: #cfe2ff;

    &_item {
      padding: 20px 8px;
      @include font(500, 16px, 19.09px);
      font-family: 'SFPRORegular';
      text-align: left;
    }
  }

  &_body {
    background: #ffffff;

    &_row {
      border: 1px solid #ebebeb;
      background: #ffffff;
    }
  }
}
