@import '../../../scss/variable';
@import '../../../scss/mixin.scss';

@font-face {
  font-family: SFPROBold;
  src: url(../../../assets/fonts/SFProText/SFProText-Bold.ttf) format('opentype');
}

@font-face {
  font-family: SFPROMedium;
  src: url(../../../assets/fonts/SFProText/SFProText-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../../assets/fonts/SFProText/SFProText-Regular.ttf) format('opentype');
}

* {
  box-sizing: border-box;
}

.btn_default {
  color: #6b7280;
  background: #f3f4f6;
  width: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 11px 13px;
  @include font(800, 16px);
  font-family: 'Inter', sans-serif;
  border: none;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease-in;
  &:hover:not(:disabled) {
    color: #ffff;
    background: #ba75ff;
  }
}

.primary {
  color: #ffff;
  background: #ba75ff;
  @include font(600, 18px);
  font-family: 'Manrope', sans-serif;
  transition: 0.3s ease-in;
  &:hover:not(:disabled) {
    color: $font-color-rose;
    background: #e0dced;
  }
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    // margin: 0 auto;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    // margin: 0 auto;
  }
}
.secondary {
  color: $font-color-rose;
  background: #e0dced;
  padding: 13px;
  @include font(600, 18px);
  font-family: 'Inter', sans-serif;
  transition: 0.3s ease-in;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 10px;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 10px;
  }
  &:hover:not(:disabled) {
    color: #fff;
    background: #ba75ff;
  }
}
.disabled {
  color: #ffff;
  background-color: #ddbbff;
  cursor: not-allowed !important;
  font-family: 'Manrope', sans-serif;
}
.registrationDisabled {
  color: #ffff;
  background-color: #b5b5b5;
  width: 246px;
  padding: 13px;
  @include font(800, 14px);
  font-family: 'Manrope', sans-serif;
}
.withoutBack {
  color: #6b7280;
  background: #ffffff;
  padding: 13px;
  @include font(600, 18px);
  font-family: 'Manrope', sans-serif;
}
.delete {
  color: #ef4444;
  background: #fef2f2;
  padding: 13px 13px;
  @include font(600, 16px);
  font-family: 'Inter', sans-serif;
  &:hover {
    background-color: #ef4444 !important;
    color: #fff;
  }
}

.logIn {
  @include font(800, 16px, 19px);
  margin-right: 15px;
  padding: 11px 25px;
  background: transparent;
  color: #000;
}

.create {
  width: 241px;
  height: 52px;
  background: #ba75ff;
  @include font(500, 12px, 15px);
  border-radius: 7px;
  letter-spacing: 0.02em;
  color: #fff;

  &:hover {
    &:hover:not(:disabled) {
      color: #fff;
      background: #d8b0ff;
    }
  }
}

.newCreate {
  display: flex;
  flex-direction: row;
  width: 252px;
  height: 46px;
  background: linear-gradient(to right, #0d28bb 100%, #357eeb 100%);
  align-items: center;
  justify-content: space-evenly;
  font-family: 'SFPRORegular';
  @include font(700, 16px, 19.09px);
  border-radius: 10px;
  color: #ffffff;
  padding: 10px 25px 10px 25px;

  &:hover {
    &:hover:not(:disabled) {
      color: #ffffff;
      background: #357eeb;
    }
  }
}

.newCreateHeader {
  @media only screen and (min-width: 1150px) {
    display: flex;
    flex-direction: row;
    width: 252px;
    height: 46px;
    background: linear-gradient(to right, #0d28bb 100%, #357eeb 100%);
    align-items: center;
    justify-content: space-evenly;
    font-family: 'SFPRORegular';
    @include font(700, 16px, 19.09px);
    border-radius: 10px;
    color: #ffffff;
    padding: 10px 25px 10px 25px;

    &:hover {
      &:hover:not(:disabled) {
        color: #ffffff;
        background: #357eeb;
      }
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 1150px) {
    display: flex;
    flex-direction: row;
    width: 18vw;
    height: 5vw;
    background: linear-gradient(to right, #0d28bb 100%, #357eeb 100%);
    align-items: center;
    justify-content: space-evenly;
    font-family: 'SFPRORegular';
    @include font(700, 1.4vw, 2vw);
    border-radius: 10px;
    color: #ffffff;
    padding: 1vw;

    &:hover {
      &:hover:not(:disabled) {
        color: #ffffff;
        background: #357eeb;
      }
    }
  }
}

.newLogIn {
  height: 46px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  background: #cfe2ff;
  align-items: center;
  justify-content: space-evenly;
  font-family: 'SFPRORegular';
  @include font(700, 16px, 19.09px);
  border-radius: 10px;
  letter-spacing: 0.02em;
  color: #324195;
  padding: 10px 25px 10px 25px;

  &:hover {
    &:hover:not(:disabled) {
      color: #ffffff;
      background: linear-gradient(to right, #0d28bb 100%, #357eeb 100%);
    }
  }
}

.newLogInHeader {
  @media only screen and (min-width: 1150px) {
    height: 46px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #cfe2ff;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'SFPRORegular';
    @include font(700, 16px, 19.09px);
    border-radius: 10px;
    letter-spacing: 0.02em;
    color: #324195;
    padding: 10px 25px 10px 25px;

    &:hover {
      &:hover:not(:disabled) {
        color: #ffffff;
        background: linear-gradient(to right, #0d28bb 100%, #357eeb 100%);
      }
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 1150px) {
    height: 2vh;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    background: #cfe2ff;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'SFPRORegular';
    @include font(700, 1.8vw, 2vw);
    border-radius: 10px;
    letter-spacing: 0.02em;
    color: #324195;
    padding: 2vw;

    &:hover {
      &:hover:not(:disabled) {
        color: #ffffff;
        background: linear-gradient(to right, #0d28bb 100%, #357eeb 100%);
      }
    }
  }

  //   @media only screen and (min-width: 320px) and (max-width: 400px){
  //     height: 32px;
  //     width: 13px;
  //     display: flex;
  //     flex-direction: row;
  //     flex-wrap: nowrap;
  //     background: #cfe2ff;
  //     align-items: center;
  //     justify-content: space-evenly;
  //     font-family: 'SFPRORegular';
  //     @include font(700, 11px, 5.09px);
  //     border-radius: 10px;
  //     letter-spacing: 0.02em;
  //     color: #324195;
  //     padding: 0px 0px 0px 0px;
  //     margin-right: 0.5rem;
  //
  //
  //     &:hover {
  //     &:hover:not(:disabled) {
  //       color: #ffffff;
  //       background: linear-gradient(to right, #0d28bb 100%, #357eeb 100%);
  //       }
  //     }
  //   }
}

.leaveRequest {
  width: 444px;
  height: 80px;
  align-items: center;
  justify-content: space-evenly;
  font-family: 'SFPRORegular';
  font-weight: 700;
  font-size: 24px;
  padding: 11px 25px;
  background: transparent;
  background: #357eeb;
  border-radius: 20px;
  line-height: 28.64px;
  color: #ffffff;

  &:hover {
    &:hover:not(:disabled) {
      color: #ffffff;
      background: linear-gradient(to right, #0d28bb 100%, #357eeb 100%);
    }
  }
}

.newLeaveRequest {
  @media only screen and (min-width: 1150px) {
    width: 444px;
    height: 80px;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'SFPRORegular';
    font-weight: 700;
    font-size: 24px;
    padding: 11px 25px;
    background: transparent;
    background: #357eeb;
    border-radius: 20px;
    line-height: 28.64px;
    color: #ffffff;

    &:hover {
      &:hover:not(:disabled) {
        color: #ffffff;
        background: linear-gradient(to right, #0d28bb 100%, #357eeb 100%);
      }
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 1150px) {
    width: 30vw;
    height: 5vw;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'SFPRORegular';
    font-weight: 700;
    font-size: 1.6vw;
    padding: 2vw 2vw;
    background: transparent;
    background: #357eeb;
    border-radius: 20px;
    line-height: 1.5vw;
    color: #ffffff;

    &:hover {
      &:hover:not(:disabled) {
        color: #ffffff;
        background: linear-gradient(to right, #0d28bb 100%, #357eeb 100%);
      }
    }
  }
}

.newPrimary {
  font-family: 'SFPRORegular';
  color: #ffffff;
  background: #357eeb;
  @include font(700, 20px, 23.87px);
  transition: 0.3s ease-in;
  &:hover:not(:disabled) {
    color: #ffffff;
    background: linear-gradient(90deg, #0d28bb 0%, #357eeb 100%);
  }
}

.newSecondary {
  font-family: 'SFPRORegular';
  @include font(700, 16px, 19.09px);
  padding: 11px 25px;
  background: transparent;
  color: #357eeb;
  border: 1px solid #357eeb;

  &:hover {
    &:hover:not(:disabled) {
      color: #ffffff;
      background: #357eeb;
    }
  }
}

.newSecondaryHeader {
  @media only screen and (min-width: 1150px) {
    font-family: 'SFPRORegular';
    @include font(700, 16px, 19.09px);
    padding: 11px 25px;
    background: transparent;
    color: #332f36;

    &:hover {
      &:hover:not(:disabled) {
        color: #ffffff;
        background: #355eeb;
      }
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 1150px) {
    font-family: 'SFPRORegular';
    @include font(700, 1.6vw, 19.09px);
    padding: 1vw;
    background: transparent;
    color: #332f36;

    &:hover {
      &:hover:not(:disabled) {
        color: #ffffff;
        background: #355eeb;
      }
    }
  }

  //   @media only screen and (min-width: 320px) and (max-width: 400px){
  //     font-family: 'SFPRORegular';
  //     @include font(700, 10px, 19.09px);
  //     padding: 0px 0px 0px 0px;
  //     background: transparent;
  //     color: #332f36;
  //
  //
  //     &:hover {
  //     &:hover:not(:disabled) {
  //       color: #ffffff;
  //       background: #355eeb;
  //       }
  //     }
  //   }
}

.goPlatform {
  font-family: 'SFPRORegular';
  width: 400px;
  height: 70px;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 700;
  font-size: 24px;
  padding: 11px 25px;
  background: transparent;
  background: #357eeb;
  border-radius: 20px;
  line-height: 28.64px;
  color: #ffffff;

  &:hover {
    &:hover:not(:disabled) {
      color: #ffffff;
      background: linear-gradient(to right, #0d28bb, #357eeb);
      transform: scale(1.2);
    }
  }
}

.more {
  width: 212px;
  height: 50px;
  align-items: center;
  justify-content: space-evenly;
  font-family: 'SFPRORegular';
  font-weight: 500;
  font-size: 24px;
  padding: 11px 25px;
  background: transparent;
  background: linear-gradient(to right, #0d28bb 100%, #357eeb 100%);
  border-radius: 10px;
  line-height: 28.64px;
  color: #ffffff;
  border: 2px solid #ffffff;

  &:hover {
    &:hover:not(:disabled) {
      color: #ffffff;
      background: linear-gradient(#0d28bb, #357eeb);
    }
  }
}

.emptyInside {
  font-family: 'SFPROMedium';
  display: flex;
  padding: 0.75rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  border-radius: 0.625rem;
  border: 2px solid var(--Button, #357eeb);
  color: #357eeb;

  &:hover {
    background: #357eeb !important;
    color: white;

    svg {
      path {
        stroke: white;
      }
    }
  }
}

.tryForFree {
  font-family: 'SFPRORegular';
  width: 388px;
  height: 54px;
  align-items: center;
  justify-content: space-evenly;
  font-weight: 600;
  font-size: 20px;
  line-height: 23.87px;
  color: #0d28bb;
  background: transparent;
  background: #ffffff;
  border-radius: 12px;
  margin-bottom: 1rem;

  &:hover {
    &:hover:not(:disabled) {
      color: #ffffff;
      background: linear-gradient(to right, #0d28bb 100%, #357eeb 100%);
      border: 1px solid #ffffff;
    }
  }
}

.newTryForFree {
  transition: all ease-in-out 0.2s;

  &:hover:not(:disabled) {
    color: #fff;
    background-color: #357eeb;
    border: 1px solid #fff;
  }

  @media only screen and (min-width: 1150px) {
    font-family: 'SFPRORegular';
    width: 388px;
    height: 54px;
    align-items: center;
    justify-content: space-evenly;
    font-weight: 600;
    font-size: 20px;
    line-height: 23.87px;
    color: #0d28bb;
    background: transparent;
    background-color: #ffffff;
    border-radius: 12px;
    margin-bottom: 1rem;
  }

  @media only screen and (min-width: 320px) and (max-width: 1150px) {
    font-family: 'SFPRORegular';
    width: 30vw;
    height: 4vw;
    align-items: center;
    justify-content: center;
    display: flex;
    font-weight: 600;
    font-size: 1.5vw;
    line-height: 1vw;
    color: #0d28bb;
    background: transparent;
    background: #ffffff;
    border-radius: 12px;
    margin-bottom: 0.5rem;
  }
}

.mobile {
  width: 300px;
  height: 48px;
  align-items: center;
  justify-content: space-evenly;
  font-family: 'SFPRORegular';
  font-weight: 600;
  font-size: 20px;
  line-height: 23.87px;
  color: #ffffff;
  background: transparent;
  background: #808080;
  border-radius: 10px;
  margin-bottom: 1rem;
  background-image: url(../../../assets/img/common/android.png);
  background-repeat: no-repeat;
  background-position: top 10px left 30px;
  padding-left: 60px;

  &:hover {
    &:hover:not(:disabled) {
      color: #ffffff;
      background: #0d28bb;
      background-image: url(../../../assets/img/common/android.png);
      background-repeat: no-repeat;
      background-position: top 10px left 30px;
    }
  }
}

.newMobile {
  @media only screen and (min-width: 1150px) {
    width: 300px;
    height: 48px;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'SFPRORegular';
    font-weight: 600;
    font-size: 20px;
    line-height: 23.87px;
    color: #ffffff;
    background: transparent;
    background: #808080;
    border-radius: 10px;
    margin-bottom: 1rem;
    background-image: url(../../../assets/img/common/android.png);
    background-repeat: no-repeat;
    background-position: top 10px left 30px;
    padding-left: 60px;

    &:hover {
      &:hover:not(:disabled) {
        color: #ffffff;
        background: #0d28bb;
        background-image: url(../../../assets/img/common/android.png);
        background-repeat: no-repeat;
        background-position: top 10px left 30px;
      }
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 1150px) {
    width: 20vw;
    height: 4vw;
    align-items: center;
    justify-content: space-evenly;
    font-family: 'SFPRORegular';
    font-weight: 600;
    font-size: 1vw;
    line-height: 0.3vw;
    color: #ffffff;
    background: transparent;
    background: #808080;
    border-radius: 10px;
    margin-bottom: 1rem;
    background-image: url(../../../assets/img/common/android.png);
    background-repeat: no-repeat;
    background-size: 10% 50%;
    background-position: 10% 50%;
    padding-left: 3vw;

    &:hover {
      &:hover:not(:disabled) {
        color: #ffffff;
        background: #0d28bb;
        background-image: url(../../../assets/img/common/android.png);
        background-repeat: no-repeat;
        background-position: top 10px left 30px;
      }
    }
  }
}

.inActive {
  color: $color-grey;
  background: #cfe2ff;
  cursor: not-allowed !important;
  font-family: 'SFPRORegular';
  @include font(500, 20px, 23.87px);
}

.cancel {
  font-family: 'SFPRORegular';
  color: #357eeb;
  background: transparent;
  border: 2px solid #357eeb;
  @include font(600, 20px, 23.87px);
  &:hover:not(:disabled) {
    color: #ffffff;
    background: #357eeb;
  }
}

.newDelete {
  font-family: 'SFPRORegular';
  color: black;
  background: #ec8787;
  border: 2px solid #ec8787;
  @include font(600, 20px, 23.87px);
  &:hover:not(:disabled) {
    color: black;
    background: #e96d6d;
    border: 2px solid #e96d6d;
  }
}

.newDisabled {
  font-family: 'SFPRORegular';
  color: #747474;
  background: transparent;
  border: 1px solid #747474;
  @include font(600, 20px, 23.87px);
  cursor: not-allowed !important;
  &:hover:not(:disabled) {
    color: #747474;
    background: #7474742a;
    border: 1px solid #747474;
  }
}
