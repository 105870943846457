@import '../../../scss/mixin.scss';

.lesson {
  // margin-left: 50%;
  // transform: translateX(-50%);
  // -webkit-transform: translateX(-50%);
  // -moz-transform: translateX(-50%);
  // -ms-transform: translateX(-50%);
  // -o-transform: translateX(-50%);
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    min-width: 100%;
    max-width: 100%;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    min-width: 100%;
    max-width: 100%;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) {
    min-width: 98%;
    max-width: 98%;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  align-self: stretch;
  width: 100%;

  &_wrapper {
    display: flex;
    gap: 10px;
    width: 100%;
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-evenly;
    }
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-evenly;
    }
    @media only screen and (min-width: 481px) and (max-width: 1024px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-evenly;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    @include display(flex, column, flex-start, center);
    width: 280px;
    margin-top: 20px;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    @include display(flex, column, flex-start, center);
    margin-top: 20px;
  }

  &__codeWraper {
    margin: 5px 0;
    background: #f3f4f6;
    padding: 15px;
    border-radius: 7px;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      padding: 10px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
    }
  }

  &__block_name {
    display: block;
    margin-bottom: 7px;
    font-weight: 600;
    font-size: 15px;
  }

  &__code_text {
    font-weight: 600;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      font-size: 8px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
    }
  }

  &__navBack {
    display: flex;
    align-items: center;
    cursor: pointer;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      align-self: flex-start;
      margin-top: 5%;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      align-self: flex-start;
      margin-top: 5%;
    }
    @media only screen and (min-width: 481px) and (max-width: 1024px) {
      align-self: flex-start;
      margin-top: 5%;
    }
  }

  &__navBack_text {
    margin-left: 5px;
    @include font(400, 12px, 15px);
    letter-spacing: -0.03em;
    color: #4d5766;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include font(500, 15px, 15px);
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include font(500, 14px, 15px);
    }
  }

  &__name {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: -0.02em;
    color: #4d5766;
    margin: 20px 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: flex-start;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      padding: 0;
      figure {
        width: 100%;
      }
      span {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        width: 100vw;
      }

      pre,
      code {
        width: 100vw;
        display: block;
        white-space: initial;
      }
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      padding: 0;
      figure {
        width: 100%;
      }
      span {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        // width: 100vw;
      }

      pre,
      code {
        // width: 100vw;
        display: block;
        white-space: initial;
      }
    }
  }

  &__name_mini {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #4d5766;
    margin-bottom: 18px;
    text-align: center;
  }

  &__blocks {
    display: flex;
    width: 100%;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include display(flex, column, flex-start, center);
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include display(flex, column, flex-start, center);
    }
    @media only screen and (min-width: 481px) and (max-width: 1024px) {
      flex-direction: column;
      min-width: 98%;
      max-width: 98%;
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 2rem;
    //align-items: flex-end;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include display(flex, column, flex-start, center);
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include display(flex, column, flex-start, center);
    }
  }

  &__card {
    border-radius: 7px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 40px;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      min-width: 280px;
      width: 70%;
      iframe {
        height: 160px;
      }
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      min-width: 350px;
      width: 98vw;
      padding: 5% 2% 6% 2%;
      iframe {
        height: 180px;
      }
    }
  }

  &__btns {
    @include display(flex, row, flex-start, center);
    justify-content: flex-end;
    gap: 1.875rem;
    @media only screen and (min-width: 375px) and (max-width: 550px) {
      @include display(flex, row, flex-start, center);
      gap: 0.5rem;
    }
  }

  &__desc {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #4d5766;
    margin-bottom: 26px;
    display: inline-block;

    p {
      word-break: break-all;
    }
  }

  &__materials {
    color: rgb(51, 47, 54);
    font-family: 'SFPROMedium';
    font-size: 2.25rem;
    font-weight: 500;
    line-height: 2.69rem;
    letter-spacing: 0%;
    text-align: left;
    width: 100%;

    &_files {
      display: flex;
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 1rem;
    }
  }

  &__block {
    padding: 26px 14px 37px 12px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 7px;
    overflow-y: auto;
    flex-shrink: 0;
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      max-width: 100%;
    }
    @media only screen and (min-width: 481px) and (max-width: 1024px) {
      margin-top: 10px;
    }

    &::-webkit-scrollbar {
      width: 9px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e1dbe6;
      height: 128px;
      border: 3px solid #ffff;
      border-top: 8px solid #ffff;
      border-bottom: 8px solid #ffff;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      -ms-border-radius: 5px;
      -o-border-radius: 5px;
      border-radius: 5px;
    }

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      margin: 32px 0 0 0;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      margin: 32px 0 0 0;
    }

    &:hover {
      border: 3px solid #ba75ff;
    }
  }

  &__block_title {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.03em;
    color: #4d5766;
    text-align: center;
    margin-bottom: 18px;
  }

  &__item_name {
    margin-left: 8px;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 15px 13px;
    width: 100%;
    background: #fff;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.02em;
    color: #ba75ff;
    margin-bottom: 5px;
  }

  &__item_active {
    display: flex;
    align-items: center;
    padding: 15px 13px;
    width: 100%;
    background: #f9f2ff;
    border-radius: 7px;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.02em;
    color: #4d5766;
    margin-bottom: 5px;
  }

  &__item_disabled {
    color: gray !important;
    cursor: not-allowed !important;
  }
}

.lessonHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;

  &_title {
    color: var(--Black, #332f36);
    text-align: center;
    font-family: 'SFPROMedium';
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      font-size: 2rem;
    }
  }

  &_nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    height: 3.375rem;

    button {
      font-size: 1.25rem !important;
      letter-spacing: -1px;

      @media screen and (min-width: 701px) and (max-width: 900px) {
        font-size: 1rem !important;
        padding: 0.5rem 1rem;
        gap: 0.225rem;
      }

      @media screen and (min-width: 501px) and (max-width: 700px) {
        font-size: 0.75rem !important;
        padding: 0.25rem 0.75rem;
        gap: 0.225rem;
      }

      @media only screen and (min-width: 320px) and (max-width: 500px) {
        font-size: 0.625rem !important;
        padding: 0.15rem 0.2rem;
        gap: 0.2rem;
      }
    }
  }
}

.lessonHeaderBanner {
  position: relative;
  width: 100%;
  height: 26.25rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  background: #e0dced;
  border-radius: 1.5rem;
  overflow: hidden;
  margin-bottom: 25px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    background-color: rgba(51, 47, 54, 0.5);
    z-index: 1;

    @media screen and (max-width: 700px) {
      border-radius: 0.625rem;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    @include display(flex, row, flex-start, center);
    margin-bottom: 0;
    height: 11.25rem;
    border-radius: 0.625rem;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    @include display(flex, row, flex-start, center);
    margin-bottom: 0;
    height: 11.25rem;
    border-radius: 0.625rem;
  }

  &_infoBlock {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    height: 6.4375rem;
    padding: 1.5rem 4rem;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    background: linear-gradient(90deg, #332f36 -5.88%, rgba(51, 47, 54, 0) 100%);
    z-index: 3;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      display: inline-flex;
      padding: 0rem 5.8125rem 0rem 5.75rem;
      justify-content: center;
      align-items: center;
      height: 2.25rem;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      display: inline-flex;
      padding: 0rem 5.8125rem 0rem 5.75rem;
      justify-content: center;
      align-items: center;
      height: 2.25rem;
    }
    &_title {
      @include display(flex, column);
      &_description {
        @include font(500, 14px, 10px);
        color: #bdbdbd;
        font-size: 16px;
        margin-bottom: 2px;
        strong {
          font-weight: 600;
        }
      }

      &_name {
        color: #fff;
        text-align: center;
        font-family: 'SFPRORegular';
        font-size: 2.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 3.875rem; /* 134.783% */

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          font-size: 12px;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          font-size: 12px;
        }
      }
    }
  }
}
