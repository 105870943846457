@import 'src/scss/variable';
@import '../../scss/mixin.scss';

.form {
  position: relative;
  max-width: 66%;
  width: 100%;
  margin: 15px 20px 0 0;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    max-width: 100%;
    width: 100%;
    margin: 73px 0 20px 0;
    h3 {
      @include font(600, 12px, 14px);
    }
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    max-width: 100%;
    width: 100%;
    margin: 73px 0 20px 0;
    h3 {
      @include font(600, 12px, 14px);
    }
  }

  &_avatarWrapper {
    margin-bottom: 15px;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include position_t_l(absolute, -24px, calc(50% - 25px), 0);
      width: 49px;
      height: 49px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include position_t_l(absolute, -33px, calc(50% - 33px), 0);
      width: 65px;
      height: 65px;
    }

    &_avatarBlock {
      position: relative;
      height: 120px;
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        width: 49px;
        height: 49px;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        width: 65px;
        height: 65px;
      }

      &_title {
        font-size: 14px;
        font-weight: 400;
        color: $font-color;
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          display: none;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          display: none;
        }
      }


      &_img {
        @include position_t_l(absolute, 28px, 10px, 1);
        width: 84px;
        height: 84px;
        border-radius: 7px;
        background: #f7f5f7 url('../../assets/img/common/noAvatar.svg') no-repeat center center;
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          @include position_t_l(absolute, 0, 0, 1);
          width: 49px;
          height: 49px;
          border-radius: 50%;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          @include position_t_l(absolute, 0, 0, 1);
          width: 65px;
          height: 65px;
          border-radius: 50%;
        }
      }
    }
    &_error {
        font-size: 12px;
        color: rgb(224, 24, 24);
        margin-top: 5px;
        margin-bottom: 5px;
      }
  }
  &_btnSave {
    margin-top: 10px;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      button {
        width: 105px;
        height: 27px;
        border-radius: 5px;
        @include font(600, 10px, 0);
      }
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      button {
        width: 105px;
        height: 27px;
        border-radius: 5px;
        @include font(600, 10px, 0);
      }
    }
    &:disabled {
      background: #ddbbff;
    }
  }
}
