@import '../../scss/variable';
@import '../../scss/mixin.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  padding: 36px 100px;
  min-height: 100vh;
  max-width: 100vw;
  background-color: #fff;
  font-family: $font-family-inter;
  // padding: 0 1rem 5%;
  gap: 2rem;
  z-index: 1;
  overflow: hidden;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 1%;
    width: 100vw;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 1%;
    width: 100vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) {
    height: 100%;
    padding: 36px 16px;
  }
}

.container {
  position: relative;
  max-width: min(1360px, 70.84vw);
  margin: 0 auto;
  width: 100%;
  padding-top: calc(2rem + 80px);

  @media screen and (max-width: 1023px),
          screen and (max-aspect-ratio: 1/1) {
            max-width: 100%;
          }
}

.main {
  margin: 0 auto;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 0 0 60px 0;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    width: 95vw;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 0 0 60px 0;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    width: 95vw;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) {
    padding-bottom: 10%;
    width: 98%;
  }
}
.mobileHeader {
  height: 40px;
  width: 100%;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
}

.mobileFooter {
  height: 80px;
  width: 100%;
  background: #ffffff;
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 100;
}
