$box-shadow: 0 0 8px #d9d9d9;
$font-color: #646f74;
$font-title: #4d5766;
$backgroundCheckBox: #e0dced;
$font-family-inter: 'Inter', sans-serif;
$font-color-rose: #ba75ff;
$fs11: 11px;
$fs12: 12px;
$fs14: 14px;
$fs16: 16px;
$color-white: #ffffff;
$color-blue: #357EEB;
$bord-rad7: 7px;
$bord-rad10: 10px;
$bord-rad50: 50%;

$trans-dur05: 0.5s; 
$trans-dur02: 0.25s; 

$icon-size: 4.5vh;

$ai-window-ratio: calc(80vh/1000px);

$color-text: #332F36;
$color-grey: #808080;


