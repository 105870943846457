@import 'src/scss/variable';
@import '../../../../../../scss/mixin.scss';
@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

.wrapper {
  display: flex;

  @media only screen and (min-width : 320px) and (max-width : 375px) {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}





.redactorCourse {
  font-family: $font-family-inter;
  @include display(flex);
  @media only screen and (min-width : 320px) and (max-width : 375px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;

  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
  }

  &_textField {
    margin-bottom: 40px;

    &_btnEditText {
      @include display(flex);

      &_setting {
        &:hover {
          background-color: rgba(196, 156, 210, 0.4);
        }

        @include display(flex, row, flex-start, center);
        margin-top: 10px;
        width: 100px;
        height: 36px;
        border: none;
        text-align: center;
        background: #f3f4f6;
        font-size: 14px;
        color: #6b728f;
        border-radius: 6px;
        padding: 11px 0 11px 16px;
      }
    }

    &_description {
      &_text {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 16px;
        font-family: 'Lucida Grande', 'Lucida Sans Unicode', Verdana, Helvetica, Arial, sans-serif;
      }
    }
  }

  &_leftSide {
    width: 395px;
    height: 783px;
    top: 237px;
    left: 275px;
    gap: 15px;
    opacity: 0px;

      &_title {
        width: 395px;
        height: 61px;
        padding: 16px;
        gap: 10px;
        border-radius: 16px;
        opacity: 0px;
        position:relative;
        overflow: hidden;
        margin-bottom: 15px;
        display:inline-block;
        background: -moz-linear-gradient(90deg, #332F36 -5.88%, rgba(51, 47, 54, 0) 100%);      ; /* FF3.6+ */
        background: -webkit-gradient(90deg, #332F36 -5.88%, rgba(51, 47, 54, 0) 100%); /* Chrome,Safari4+ */
        background: -webkit-linear-gradient(90deg, #332F36 -5.88%, rgba(51, 47, 54, 0) 100%); /* Chrome10+,Safari5.1+ */
        background: -o-linear-gradient(90deg, #332F36 -5.88%, rgba(51, 47, 54, 0) 100%); /* Opera 11.10+ */
        background: -ms-linear-gradient(90deg, #332F36 -5.88%, rgba(51, 47, 54, 0) 100%); /* IE10+ */
        background: linear-gradient(90deg, #332F36 -5.88%, rgba(51, 47, 54, 0) 100%); /* W3C */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */


        img{
          max-width: 500%;
          max-height: 500%;
          position:absolute;
          top:50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          z-index:-1;
        }

        h2{
          position: absolute;
          height: 100%;
          gap: 0px;
          opacity: 0px;
          font-family: SF Pro Display;
          font-size: 24px;
          font-weight: 500;
          line-height: 28.64px;
          text-align: left;
          justify-content: center;
          display: flex;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: rgba(255, 255, 255, 1);
        }
      }


    &_desc {
      overflow-y: scroll;
      width: 395px;
      height: 783px;
      gap: 10px;
      margin-left: 8px;
      opacity: 0px;

      &_headerText {
        //position: relative;

        &_title {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          @include font(400, 14px);
          background-color: transparent;
          color: $font-title;
          height: 30px;
          margin-bottom: 15px;

          
        }

        &_inputWrapper {
          position: absolute;
          top: -7px;
          //margin-bottom: 10px;
          left: 0;
          @include display(flex, row, space-between, center);
          height: 30px;
          width: 100%;
          //border: 1px blue solid;
          @media only screen and (min-width : 320px) and (max-width : 375px) {
            display: none;
            
          }
          @media only screen and (min-width : 375px) and (max-width : 480px) {
            display: none;
          }
          

          &:hover {
            z-index: 0;

            &_btn_delete {
              z-index: 10;
            }
          }

          &_input {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            background-color: transparent;
            color: $font-title;
            font-size: 14px;
            border: 1px solid lightgrey;
            border-radius: 6px;
            text-align: center;
            width: 85%;
            height: 100%;
            outline: none;
          }

          &_btn_delete {
            @include position_t_r(absolute, 0, 0, 8);
            //@include display(flex, row, center, center);
            border: none;
            background-color: transparent;
            width: 30px;
            height: 30px;
            cursor: pointer;

            &:hover {
              z-index: 10;
            }
          }
        }
      }

      &_lessonWrapper {
        width: 379px;
        height: 41px;
        padding: 10px 26px 10px 26px;
        gap: 84px;
        border-radius: 10px;
        opacity: 0px;
        background-color: rgba(207, 226, 255, 1);
        box-shadow: 0px 0px 4px 0px rgba(50, 65, 149, 0.3);
        margin-bottom: 10px;
        cursor: pointer;
        border: none;
        position: relative;

        &:hover {
          border-radius: 10px;
          border: 1px solid rgba(53, 126, 235, 1);
        }

        &:focus {
		      background-color: rgba(53, 126, 235, 1);
		      box-shadow: 0px 0px 4px 0px rgba(50, 65, 149, 0.3);
	      }

        &:focus & {
          &_lesson, &_counter {
            color: rgba(255, 255, 255, 1);
          }
        }

        &:focus & {
          &_lesson img {
            background: url('./images/upWhite.png') no-repeat center center;
          }
        }

        &:hover & {
          &_lesson, &_counter {
          -webkit-animation: fadeInFromNone 1s ease-out;
          -moz-animation: fadeInFromNone 1s ease-out;
          -o-animation: fadeInFromNone 1s ease-out;
          animation: fadeInFromNone 1s ease-out;
          }
        @-webkit-keyframes fadeInFromNone {
          0% {
            opacity: 0;
          }
          1% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @-moz-keyframes fadeInFromNone {
          0% {
            opacity: 0;
          }
          1% {
            opacity: 0;
          }
          100% {
            opacity: 1;
            }
        }
        @-o-keyframes fadeInFromNone {
          0% {
            opacity: 0;
          }
          1% {
            opacity: 0;
          }
          100% {
            opacity: 1;
            }
        }
        @keyframes fadeInFromNone {
          0% {
            opacity: 0;
          }
          1% {
            opacity: 0;
          }
          100% {
            opacity: 1;
        }
      }
    }
        &_wrapTable {
          position: relative;
          animation-name: slidein;
          animation-duration: 0.3s;

          &_line {
            list-style-type: none;

              &_content {
                width: 379px;
                height: 41px;
                padding: 10px 26px 10px 26px;
                gap: 84px;
                border-radius: 10px;
                opacity: 0px;
                background-color: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 4px 0px rgba(50, 65, 149, 0.3);
                margin-bottom: 10px;
                cursor: pointer;
                border: none;
                font-family: SF Pro Display;
                font-size: 16px;
                font-weight: 400;
                line-height: 19.09px;
                letter-spacing: 0.01em;
                text-align: center;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                color: rgba(51, 47, 54, 1);

                &:hover {
                  border: 1px solid rgba(53, 126, 235, 1);
                }

                &:focus {
                  background-color: rgba(53, 126, 235, 1);
                  box-shadow: 0px 0px 4px 0px rgba(50, 65, 149, 0.3);
                  color: rgba(255, 255, 255, 1);
                }

                &:focus & {
                  &_counter {
                    color: rgba(255, 255, 255, 1);
                  }
                }



                &_counter{
                  width: 19px;
                  height: 19px;
                  color: rgba(53, 126, 235, 1);
                  font-family: SF Pro Display;
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 19.09px;
                  text-align: center;
                  text-underline-position: from-font;
                  text-decoration-skip-ink: none;
                  float: right;
                  margin-right: 8px;
                  margin-top: 2px;
                }
              }
            }
          }

            @keyframes slidein {
              from {
                margin-top: -50px;
                opacity: 0;
              }

              25% {
                margin-top: -40px;
                }

              45% {
                margin-top: -30px;
                opacity: 0.33;
                }

              70% {
                margin-top: -20px;
                }

              to {
                margin-top: -10px;
                opacity: 1;
              }
            }

        &_stateWrapTable{
          position: relative;
          display: block;
          }

        &_noWrapTable{
          position: relative;
          animation-name: slideout;
          animation-duration: 0.3s;
          display: none;
          }

          @keyframes slideout {
              from {
                display:block;
                margin-top: -10px;
                opacity: 1;
              }

              25% {
                display:block;
                margin-top: -20px;
                }

              45% {
                display:block;
                margin-top: -30px;
                opacity: 0.33;
                }

              70% {
                display:block;
                margin-top: -40px;
                }

              to {
                display:block;
                margin-top: -50px;
                opacity: 0;
              }
            }

        &_lesson {
          font-family: SF Pro Display;
          font-size: 18px;
          font-weight: 500;
          line-height: 21.48px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: rgba(51, 47, 54, 1);
          float: left;

           img {
            background: url('./images/up.png') no-repeat center center;
            width: 14px;
            height: 100%;
            padding-top: 10px;
            left: 11.5px;
            gap: 0px;
            opacity: 0px;
            angle: 180 deg;
            margin-right: 10px;
          }
        }

        &_counter{
          width: 19px;
          height: 19px;
          color: rgba(53, 126, 235, 1);
          font-family: SF Pro Display;
          font-size: 16px;
          font-weight: 400;
          line-height: 19.09px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          float: right;
          margin-right: 8px;
          margin-top: 2px;
        }

        &_btn_deleteLesson {
          position: relative;
          z-index: -10;
          @include display(flex, row, center, center);
          border: none;
          background-color: transparent;
          width: 30px;
          height: 30px;
          cursor: pointer;

          & svg {
            & path {
              fill: #c1c1c1;
            }
          }
        }

        &_btn_drag_and_drop {
          position: relative;
          flex: 0 0 auto;
          white-space: nowrap;
          z-index: -10;
          background-color: transparent;
          width: 30px;
          height: 30px;

          & svg {
            & path {
              fill: #c1c1c1;
            }
          }
        }
      }
    }

    &_modul {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_classes {
      width: 236px;

      &_settings {
        position: relative;
        @include display(flex, row, right, center);
        margin: 25px 0;
        font-size: 14px;
        color: $font-title;
        text-align: center;
        cursor: pointer;

        &_drag {
          @include position_t_l(absolute, 0, -15px);

          svg {
            margin-right: 8px;
          }
        }

        &_panel {
          & svg {
            margin-left: 10px;
          }
        }
      }
    }
  }

  &_rightSideWrapper {
    width: 95%;
    right: 10px;
    min-width: 0;

    @media only screen and (min-width : 320px) and (max-width : 375px) {
      max-width: 100vw;
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
      max-width: 100vw;
    }

    &_rightSide {
      width: 939px;
      height: 129px;
      padding: 20px 49px 20px 49px;
      margin-left: 37px;
      border-radius: 20px;
      opacity: 0px;
      box-shadow: 0px 0px 18px 0px rgba(50, 65, 149, 0.3);
      background: rgba(255, 255, 255, 1);


      &_nameBlock {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        @media only screen and (min-width : 320px) and (max-width : 375px) {
          flex-direction: column;
          padding-right: 0;
        }
        @media only screen and (min-width : 375px) and (max-width : 480px) {
          flex-direction: column;
          padding-right: 0;
        }
      }

      &_btnBlock {
        width: Hug (322px)px;
        height: Hug (45px)px;
        display: flex;
        gap: 10px;
        opacity: 0px;

        }

      &_review {
        display: flex;
        align-items: center;
      }

      &_header {
        @include display(flex, row, space-between, center);
        text-align: center;

        &_btnBlock {
          @include display(flex);
          width: 100%;

          &_setting {
            &:hover {
              background-color: rgba(190, 190, 190, 0.4);
            }

            @include display(flex, row, flex-start, center);
            width: 150px;
            height: 36px;
            border: none;
            text-align: center;
            background: #f3f4f6;
            font-size: 13px;
            color: #6b728f;
            border-radius: 6px;
            padding: 11px 0 11px 16px;

            & svg {
              margin-right: 5px;
            }

            input {
              @include position_t_l(absolute, 0, 0, -20);
            }
          }

          &_edit {
            &:hover {
              background-color: rgba(190, 190, 190, 0.4);
              border: solid 2px #BA75FF;
            }

            @include display(flex, row, flex-start, center);
            width: 210px;
            height: 36px;
            border: none;
            text-align: center;
            background: #f3f4f6;
            font-size: 12px;
            color: #6b728f;
            border-radius: 6px;
            padding: 11px 0 11px 12px;

            & svg {
              margin-right: 5px;
            }

            input {
              @include position_t_l(absolute, 0, 0, -20);
            }
          }

          &_save {
            width: 159px;
            height: 45px;
            padding: 0px 40px 0px 40px;
            gap: 10px;
            border-radius: 10px;
            opacity: 0px;
            background-color: rgba(53, 126, 235, 1);
            border: none;
            font-family: SF Pro Display;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.09px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: rgba(255, 255, 255, 1);
            }

          &_cancel{
            width: 153px;
            height: 45px;
            padding: 0px 40px 0px 40px;
            gap: 10px;
            border-radius: 10px;
            border: 2px;
            opacity: 0px;
            border: 2px solid rgba(53, 126, 235, 1);
            background-color: rgba(255, 255, 255, 1);
            text-align: center;
            font-family: SF Pro Display;
            font-size: 16px;
            font-weight: 500;
            line-height: 19.09px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: rgba(53, 126, 235, 1);
            }

          &_delete {
            &:hover {
              background-color: rgba(190, 190, 190, 0.4);
              border: solid 2px #BA75FF;
            }

            @include display(flex, row, flex-start, center);
            margin-left: 15px;
            // width: 200px;
            height: 36px;
            border: none;
            text-align: center;
            background: #f3f4f6;
            font-size: 13px;
            color: #6b728f;
            border-radius: 6px;
            padding: 5px 5px 5px 5px;

            // & svg {
            //   margin-right: 5px;
            // }

            input {
              @include position_t_l(absolute, 0, 0, -20);
            }
          }
        }
      }

      &_title {
        width: 841px;
        height: 24px;
        gap: 0px;
        opacity: 0px;
        font-family: SF Pro Display;
        font-size: 20px;
        font-weight: 500;
        line-height: 23.87px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: rgba(0, 0, 0, 1);
      }

      &_blockAll {
        display: flex;
        align-items: center;
        padding-right: 45px;

        @media only screen and (min-width : 320px) and (max-width : 375px) {
          flex-direction: column;
          padding: 0 0 5% 0;
        }
        @media only screen and (min-width : 375px) and (max-width : 480px) {
          flex-direction: column;
          padding: 0 0 5% 0;
        }

      }
      
      &_block {
        display: flex;
        width: auto;
        padding-top: 1%;
        padding-bottom: 1%;
        flex-wrap: nowrap;

        &_nameSettings {
          color: rgba(53, 126, 235, 1);
          font-family: SF Pro Display;
          font-size: 28px;
          font-weight: 700;
          line-height: 33.41px;
          letter-spacing: 0.01em;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
     }

      &_functional {
        width: Fill 841px;
        height: 89px;
        gap: 20px;
        opacity: 0px;

        &_container {
          display: flex;
          flex-direction: column;
          gap: 40px;
          padding-inline: 3em;

          @media only screen and (min-width : 320px) and (max-width : 375px) {
            padding-inline: 0;
          }
          @media only screen and (min-width : 375px) and (max-width : 480px) {
            padding-inline: 0;
          }
        }

        &_content {
          @include display(flex, row, space-between);
          margin-bottom: 50px;
          margin-top: 50px;
          padding-right: 45px;

          @media only screen and (min-width : 320px) and (max-width : 375px) {
            padding: 0 0 10% 0;
            flex-direction: column;
            gap: 15px
          }
          @media only screen and (min-width : 375px) and (max-width : 480px) {
            padding: 0 0 10% 0;
            flex-direction: column;
            gap: 15px;
          }

          &_preview {
            color: $font-color-rose;
            font-size: 16px;
            margin-right: 11px;
          }
        }

        &_form {
          &_title {
            @include font(500, 16px, 19px);
            margin-bottom: 0;
            color: #4d5766;
            margin-left: 45px;
          }
        }
      }

      &_desc {
        display: block;
        margin: 8px 0 15px;
        font-size: 12px;
        color: #9f9f9f;
        margin-left: 45px;
        padding-bottom: 10px;
      }
      &_error {
        display: block;
        font-size: 12px;
        color: rgba(224, 24, 24, 0.67);
        margin-left: 45px;
        padding-bottom: 10px;
      }
    }
  }
}



.redactorCourse_leftSide_desc::-webkit-scrollbar {
  width: 10px;
}

.redactorCourse_leftSide_desc::-webkit-scrollbar-thumb {
  background: rgba(53, 126, 235, 1);
  width: 5px;
  height: 85px;
  gap: 0px;
  border-radius: 30px;
  opacity: 0px;
}

.newLesson {
  background-color: #dcdcdc; // цвет фона для подсвеченного урока
  border-radius: 8px; // скругление углов
}

.hl {
  width: 256px;
  border-bottom: 1px solid #d9d9d9;
  margin: 24px 0 18px -10px;
}

.btn {
  margin-bottom: 43px;
  width: 236px;
  background: #e0dced;
  font-size: 12px;
  padding: 11px 0;
  border: none;
  border-radius: 7px;
  color: $font-color-rose;

  &:hover {
    background: $font-color-rose;
    color: #e0dced;
  }
}

.btnModule {
  background: #e0dced;
  color: $font-color-rose;

  &:hover {
    color: #e0dced;
  }
}

hr {
  margin-bottom: 18px;
}

.coursePreviewHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &_text_block {
    margin: 20px 0 0 30px;

    &_published {
      @include display(flex, row, flex-end);
      justify-content: space-around;
      font-style: italic;
      font-weight: bold;
      color: #acce6e;

      svg {
        margin-right: 2px;
      }
    }

    &_notPublished {
      @include display(flex, row, flex-end);
      justify-content: space-around;
      font-style: italic;
      font-weight: bold;
      color: #a8444c;

      svg {
        margin-right: 2px;
      }
    }
  }
}

.coursePreviewHeaderRedactor {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media only screen and (min-width : 320px) and (max-width : 375px) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 5px;
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 5px;
  }

  &_text_block {
    margin: 20px 0 0 30px;
    @include display(flex, flex-end);
    color: #e0dced;
    font-style: normal;
    @include font(500, 11px, 13px);

    svg {
      margin-right: 10px;
    }
  }
}


.check_autotest {
  display: flex;
  margin-left: 40px;
  & span {
    margin-top: 2px;
    color: $font-color-rose;
    @include font(400, 14px, 100%);
    letter-spacing: 0.04em;
    margin-left: 10px;
  }
}

.autogeneration {
  min-width: 600px;
  width: 70%;
  border-radius: 5px;
  //margin-top: 1rem;
  margin: 30px 150px 30px 150px;
  padding-bottom: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);

  &_num {
    margin-left: 20px;
    padding-top: 20px;

    & input {
      line-height: normal;
      padding: 11px 0 12px 20px;
      border: 1px solid #e5e7eb;
      border-radius: 5px;
      text-overflow: ellipsis;
      width: 70px;
      margin-left: 20px;
    }
    & label {
     color: #9ca3af;
    }
  }

  &_select {
    margin-left: 20px;
    padding-top: 20px;
    color: #9ca3af;
  }

  &_error {
    margin-left: 20px;
    padding-top: 20px;
    color: #f50723;
    font-size: 13px;
  }

  &_test {
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: 7px;
    background: #f5f5f5;

    &_name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
      margin-left: 0.75rem;
      text-align: left;
      @include font(500, 12px, 150%);
      color: #9ca3af;
      letter-spacing: -0.05em;
    }
  }

  &_tests_block {
    display: flex;
    flex-direction: column;
    margin: 20px 20px 20px 20px;
    //margin-top: 0.75rem;
    //margin-left: 20px;
    //margin-right: 20px;
    gap: 0.625rem;
  }

  &_item {
    margin-top: 1.25rem;

    &:first-child {
      margin-top: 0;
    }

    &_name {
      @include font(600, 12px, 150%);
      color: $font-title;
      text-align: left;
    }
  }
}

.no_autogeneration {
  margin-left: 40px;
  margin-top: 30px;
  color: #9ca3af;
  font-size: 14px;
  letter-spacing: 0.06em;
}


.publickButton {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (min-width : 320px) and (max-width : 375px) {
    margin-left: 50%;
    gap: 10px;
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    margin-left: 50%;
    gap: 10px;
  }
}


.commentContainer {
  width: 939px;
  height: 712px;
  margin-top: 30px;
  margin-left: -50px;
  display: flex;
  gap: 0px;
  opacity: 0px;

  p{
    margin-left: 50px;
    }
}

.commentBox {
  margin-top: 10px;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.checked {
  width: 15px;
  height: 15px;
  color: rgb(195, 63, 195);
}

.publicCheckbox {
  width: 15px;
  height: 15px;
  vertical-align: middle;
}

.publicLabel {
  display: flex;
  align-items: center;

}

.publicLabel p {
  margin: 0;
  font-weight: 450;
}

.error {
  color: red;
  margin-left: 45px;
  margin-top: 10px;
}

.commentTable {
  border-collapse: collapse;
  width: 939px;

  &_title{
    width: 939px;
    height: 56px;
    padding-right: 16px 0px 16px 42px;
    box-shadow: 0px 0px 18px 0px rgba(50, 65, 149, 0.35);
    opacity: 0px;
    background-color: rgba(207, 226, 255, 1);
    font-family: SF Pro Display;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.87px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(0, 0, 0, 1);

    th:first-child {
      border-radius: 20px 0 0 0;
      padding-left: 37px;
    }
    th:last-child {
      border-radius: 0 20px 0 0;
      padding-left: 17px;
    }
  }
}

.commentTable tr {
  width: 939px;
  height: 84px;
  max-height: 400px;
  word-wrap: break-word;
  padding: 0px 20px 10px 20px;
  gap: 64px;
  border: 0px 0px 1px 0px;
  opacity: 0px;
  font-family: SF Pro Display;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.09px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: rgba(0, 0, 0, 1);
  }

.commentTable tr:nth-child(even) {
    background-color: rgba(255, 255, 255, 1);
  }

.commentTable td {
  max-height: 400px;
  height: 84px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
}


.centeredContent {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 59px;
  height: 24px;
  gap: 0px;
  opacity: 0px;
}

.centeredCheckbox {
  margin: 0;

}

.commentTable .publicCheckbox input[type="checkbox"]:checked {
  background-color: rgba(53, 126, 235, 1);
}

.paginationTable{
  width: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
}

.fillColorWhite path {
  fill: $color-white;
}

.lessonTypeIcon {
  margin-top: 3px;
  margin-left: 13px;
}


