@import '../../../scss/mixin.scss';

.filter_item_content {
  @include display(flex, row, space-between, center);
  @include font(500, 16px, 13px);
  font-family: SF Pro Display;
  font-size: 16px;
  font-style: normal;
  text-transform: initial;
  color: #332f36;
  white-space: nowrap;
  width: 100%;
  margin: 0 auto;
}

.filter_item_container {
  padding: 0 12px;
  cursor: pointer;
  height: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  border-style: hidden;
  outline: none;
  top: auto;
  left: auto;
  align-self: flex-start;

  &::after {
    justify-content: center;
    align-items: center;
    content: "";
    position: absolute;
    width: 90%;
    bottom: -8px;
    left: 5%;
    right: 5%;
    height: 0.25px;
    background-color: rgb(128, 128, 128);
    transform: translateY(50%);
  }

  &:last-child::after {
    content: none;
  }
}
