@import '../../scss/variable.scss';

.wrapper {
  width: 100%;
  max-width: 100%;
  overflow-x: auto;

  @media only screen and (min-width : 320px) and (max-width : 375px) {
    overflow-x: scroll;
  }

  @media only screen and (min-width : 375px) and (max-width : 480px) {
    overflow-x: scroll;
  }

  @media screen and (min-width : 481px) and  (max-width: 1024px) {
    overflow-x: scroll;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: #ffffffc5;
}

.table {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
  border-radius: 7px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  font-family: SFPRORegular, sanserif;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
;
  // table-layout: fixed;

  @media only screen and (min-width : 320px) and (max-width : 375px) {
    margin-top: 0rem;
    font-size: 12px;
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    margin-top: 1rem;
    font-size: 12px;
  }
  @media screen and (min-width : 481px) and  (max-width: 1024px) {
    overflow-x: scroll;
  }

  &_user {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  &_thead {
    position: relative;
    background-color: #CFE2FF;
    font-weight: 600;
    &_td {
      white-space: nowrap;
      text-align: left;
      vertical-align: middle;
      padding: 1.25rem;
      @media only screen and (min-width : 320px) and (max-width : 375px) {
        padding: 0.6rem;
      }
      @media only screen and (min-width : 375px) and (max-width : 480px) {
        padding: 1.0rem;
      }
      @media screen and (min-width : 481px) and  (max-width: 1024px) {
        padding: 1.25rem;
      }

      &:first-child {
        padding-left: 2.5rem;
      }

      &:last-of-type {
        padding-right: 3.5rem;
      }
    }

    tr {
      th {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .svgSettings {
        position: absolute;
        right: 5rem;
        top: 1.85em;
        margin-left: 1rem;
        margin-right: -1rem;
        border-radius: 0.375rem;
        padding-top: 0.175rem;
        padding-bottom: 0.375rem;
        padding-left: 0.55rem;
        padding-right: 0.75rem;
        font-size: 0.875rem;
        line-height: 1.25rem;
        background-color: #e0dced;
        border: none;
        color: #6b7280;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        vertical-align: middle;
        transition: background-color 0.4s;
          @media only screen and (min-width : 320px) and (max-width : 375px) {
            margin-right: -1.5rem;
          }
          @media only screen and (min-width : 375px) and (max-width : 480px) {
            margin-right: -1.5rem;
          }

        &:hover {
          color: $font-title;
          background-color: rgba(160, 160, 160, 0.4)
        }

        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
      }
      .tableSortButton {
        position: relative;
        top: 0.5rem;
        border: none;
        color: #6b7280;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        vertical-align: middle;
        transition: background-color 0.4s;
          // @media only screen and (min-width : 320px) and (max-width : 375px) {
          //   margin-right: -1.5rem;
          // }
          // @media only screen and (min-width : 375px) and (max-width : 480px) {
          //   margin-right: -1.5rem;
          // }

        &:hover {
          color: $font-title;
          background-color: rgba(160, 160, 160, 0.4)
        }

        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
      }
    }
  }
  &_tbody {
    background-color: #fff;

    tr {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      height: 56px;
      cursor: pointer;
      &:hover {
        background: rgba(249, 250, 251, 0.8);
        transition: 0.1s background-color;
      }

      & td {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;
        white-space: nowrap;
        @media only screen and (min-width : 320px) and (max-width : 375px) {
          padding-top: 0.3rem;
          padding-bottom: 0.3rem;
          padding-left: 0.6rem;
          padding-right: 0.6rem;
        }
        @media only screen and (min-width : 375px) and (max-width : 480px) {
          padding-top: 0.4rem;
          padding-bottom: 0.4rem;
          padding-left: 1.0rem;
          padding-right: 1.0rem;
        }
        & p {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 15rem;
        }

        &:first-child {
          padding-left: 20px;
          @media only screen and (min-width : 320px) and (max-width : 375px) {
            margin-top: 2rem;
            font-size: 12px;
            padding-left: 5px;
          }
          @media only screen and (min-width : 375px) and (max-width : 480px) {
            margin-top: 2rem;
            font-size: 12px;
            padding-left: 10px;
          }
        }
      }
    }
    .chat_button {
        position: relative;
        padding-left: 0.65rem;
        padding-right: 0.65rem;
        font-size: 0.575rem;
      }

  }
  /* &_tbody tr:nth-child(even) {
    background-color: #EDF1FA;
  } */
}