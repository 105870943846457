@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

.main {
  width: 100%;
  top: 701px;
  left: 280px;
  gap: 20px;

  &_payment {
    width: 100%;
    font-family: 'SFPRORegular';
    font-weight: 500;
    font-size: 24px;
    line-height: 28.64px;
    color: #332f36;
  }
}
.wrapper {
  width: 100%;
  border-radius: 20px;
  background-color: #ffffff;
  padding: 26px 34px 24px 34px;
  box-shadow: 0 0 18px #3241954d;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 4% 3% 4% 3%;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 4% 3% 4% 3%;
  }
}
.actions {
  width: 100%;
  gap: 24px;
}
.block {
  width: 704.83px;
  height: 54px;
  gap: 24px;
  display: flex;
}

.menuButton {
  border: 2px solid #357eeb;
  border-radius: 14px;
  padding: 16px;
  display: flex;
  width: 290px;
  height: 54px;
  font-size: 15px;
  position: relative;
  justify-content: space-between;
  color: #357eeb;
  font-family: 'SF Pro Display';
  font-size: 20px;
  line-height: 23.87px;
  font-weight: 500;
  background-color: white;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    margin-top: 3%;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    margin-top: 3%;
  }

  img {
    margin-left: 140px;
    margin-top: 9px;
  }
}

.dropdown {
  position: inline-block;
}

/* Выпадающее содержимое (скрыто по умолчанию) */
.dropdownContentMenu {
  display: none;
  position: absolute;
  width: 193px;
  height: 192px;
  margin-left: 142px;
  box-shadow: 0px 0px 8px 0px #3241954d;
  border-radius: 24px;
  margin-top: 3px;
  padding: 24px 34px 24px 34px;
  gap: 36px;
  background-color: white;
}

.dropdownContentMenu button {
  width: 105px;
  display: flex;
  margin-bottom: 36px;
  margin-left: 15px;
  font-family: 'SF Pro Display';
  font-weight: 500;
  font-size: 20px;
  line-height: 23.87px;
  color: #808080;
  background-color: white;
  border: none;
}

.generateLinkButton {
  width: 290px;
  height: 54px;
  border-radius: 10px;
  padding: 16px 40px 16px 40px;
  gap: 10px;
  background-color: #357eeb;
  color: #ffffff;
  font-family: 'SF Pro Display';
  font-size: 20px;
  line-height: 23.87px;
  font-weight: 500;
  position: relative;
  justify: space-between;
  border: none;

  &:hover {
    background: linear-gradient(90deg, #0d28bb 0%, #357eeb 100%);
  }
}

.notificationContainer {
  width: 220px;
  height: 40px;
  background-color: #ba75ff;
  color: white;
  position: fixed;
  padding: 11px;
  border-radius: 5px;
  z-index: 9;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

.btn {
  align-self: flex-end;
  width: 260px;
  height: 43px;
  background-color: #ddbbff;
  color: white;
  font: 500, 14px;
  padding: 7px;
  margin-top: 10px;
  margin-left: 10px;
  text-align: center;
  border: none;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    margin-top: 0;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    margin-top: 0;
  }

  &:hover {
    color: #ba75ff;
    background-color: #ffffff;
  }

  &:disabled {
    color: #f9f5fd;
    background-color: #e8e6e6;
  }

  &_admin {
    align-self: flex-end;
    width: 194px;
    height: 33px;
    background-color: #ddbbff;
    color: white;
    font: 500, 14px;
    padding: 7px;
    margin-top: 10px;
    text-align: center;
    border: none;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      // margin-top: 0;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      // margin-top: 0;
    }

    &:hover {
      color: #ba75ff;
      background-color: #ffffff;
    }
  }
}

.paymentTable {
  width: 100%;
  border-collapse: collapse;
}

.paymentTable th,
.paymentTable td {
  border-bottom: 1px solid black;
  padding: 8px;
  text-align: center;
}

.paymentTable th:first-child,
.paymentTable td:first-child {
  border-left: none;
}

.paymentTable th:last-child,
.paymentTable td:last-child {
  border-right: none;
}

.detailButtonCell {
  text-align: right;
}

.detailButton {
  background-color: #fff;
  color: #545151;
  border: 1px solid #545151;
  border-radius: 7px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, color 0.3s;
}

.noLinksMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75%;
  color: #332f36;
  width: 230px;
  font-family: 'SF Pro Display';
  font-weight: 500;
  font-size: 16px;
  line-height: 19.09px;
  align: center;
}
