@import '../../scss/variable';
@import '../../scss/mixin.scss';

th,
td {
  padding: 0 10px;
}

.wrapper {
  @media screen and (max-width: 1024px) {
    overflow-x: scroll;
    width: 100vw;
  }
}

.table {
  filter:drop-shadow(0px 0px 8px rgba(0.19607843458652496,0.2549019753932953,0.5843137502670288,0.30000001192092896));
  border-radius:16px;
  position: relative;
  overflow: hidden;
  width: 100%;
  border-spacing: 0;
  //box-shadow: 0 0 5px rgb(0 0 0 / 30%);
  margin-top: 25px;
  min-height: 295px;
  @media only screen and (min-width : 320px) and (max-width : 375px) {
    width: 98%;
    font-size: 14px;
    line-height: normal;
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    width: 99%;
    font-size: 14px;
    line-height: normal;
  }

  &_head {
    background-color:#cfe2ff;
    height:56px;
    border-radius:16px 16px 0px 0px;
    padding-left:4px;
    padding-right:4px;
    color: #000000;
    font-size: 20px;
    //font-weight: 100;
    tr {
      th {
        border: none;
        text-align: left;
      }
    }
    @media only screen and (min-width : 320px) and (max-width : 375px) {
      height: 20px;
      font-size: 14px;
      text-align: center;
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
     height: 20px;
     font-size: 14px;
     text-align: center;
    }
  }
  &_body {
    color: #000000;
    font-size: 16px;
    tr {
      height: 84px;
      cursor: pointer;
      &:nth-child(1n) {
        background-color: #ffffff;
      }
      &:nth-child(2n) {
        background-color: #edf1fa;
      }
      &:hover {
        background: linear-gradient(#CFE2FF, #BBCEEB);
        transition: 0.9s background-color;
      }
      @media only screen and (min-width : 320px) and (max-width : 375px) {
        height: 30px;
      }
      @media only screen and (min-width : 375px) and (max-width : 480px) {
       height: 30px;
      }

      td {
        margin: 0 0 0 27px;
        @media only screen and (min-width : 320px) and (max-width : 375px) {
          margin-left: 2%;
        }
        @media only screen and (min-width : 375px) and (max-width : 480px) {
          margin-left: 2%;
        }
        &:nth-child(1) {
          height: 64px;
          display: flex;
          flex-direction: row;
          align-self: flex-start;
          align-items: center;
          margin-top: 10px;
          margin-left: 4px;
          gap: 8px;
        }
      }
      //&:nth-child(1) .table_body_avatar_div {
      //  background: #d3f2f8;
      //  color: #72b0bc;
      //}
      //
      //&:nth-child(2) .table_body_avatar_div {
      //  background: #e2f6f3;
      //  color: #72c5b7;
      //}
      //
      //&:nth-child(3) .table_body_avatar_div {
      //  background: #f9dcdc;
      //  color: #d96b7d;
      //}
      //
      //&:nth-child(4) .table_body_avatar_div {
      //  background: #f1e3f4;
      //  color: #c97fcf;
      //}
    }
    &_avatar_div {
      width: 64px;
      height: 64px;
      border-radius: 14px;
    }
    &_status {
      height: 27px;
      border-radius: 8px;
      display: flex;
      flex-direction: row;
      align-self: flex-start;
      justify-content: center;
      align-items: center;
      gap: 4px;
    }
    &_mark {
      height: 84px;
      display: flex;
      flex-direction: row;
      align-self: flex-start;
      align-items: center;
      gap: 10px;
      margin-left: 7px;
    }
  }
}

.table_no_results {
  text-align: center;
  @include font(500, 20px);
  color: #6b7484;
}

.loader_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: 0.3s background-color;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

