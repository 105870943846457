@import '../../scss/mixin.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.newCoursePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  &_btnBack {
    width: 50px;
    height: 50px;
    border-radius: 10px;
    position: absolute;
    left: 30px;
    top: 93px;
    background: #CFE2FF;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 32px;
    }
  }

  &_logoWrapper {
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      display: none;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      display: none;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: fadeIn 2s ease-in-out forwards;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 0.9;
      }
    }

    p {
      color: #5873E2;
      font-size: 35px;
      font-weight: 800;
      text-shadow: 2px 2px 4px rgba(63, 63, 63, 0.5);
    }

    svg {
      filter: drop-shadow(2px 2px 4px rgba(63, 63, 63, 0.5));
    }

    &_container {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
      width: 100%;
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        background-color: #F3F4F6;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        background-color: #F3F4F6;
      }


      &_title {
        margin-top: 13px;
        font-weight: 800;
        font-size: 35px;
        line-height: 42px;
        color: #ffffff;
        
      }
    }
  }

  &_formWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    //background-color: #F3F4F6;
    opacity: 0;
    animation: slideIn 1.5s ease-in-out forwards;

    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateY(100%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &_form {
      margin-top: 30px;
      padding: 12px;
      width: 424px;
      height: 100%;
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        margin-top: 5%;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        margin-top: 5%;
      }

      &_title {
        font-weight: 700;
        font-size: 28px;
        line-height: 30px;
        letter-spacing: 0.02em;
        color: #252762;
        text-align: center;
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          margin-bottom: 10%;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          margin-bottom: 10%;
        }

        
        &_comment {
          margin-top: 1rem;
          text-align: center;
          color: #252762;
          font-size: 14px;
        }
      }

      &_help {
        margin-top: 13px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: #D1D5DB;
      }

      &_eMailWrapper {


        &_title {
          margin-top: 20px;
          margin-bottom: 8px;
          font-weight: 400;
          font-size: 16px;
          line-height: 13px;
          letter-spacing: 0.02em;
          color: #292e34;
        }

      }

      &_passwordWrapper {


        &_title {
          margin-top: 16px;
          margin-bottom: 8px;
          font-weight: 400;
          font-size: 16px;
          line-height: 13px;
          letter-spacing: 0.02em;
          color: #292e34;
        }
      }

      &_helpPassword {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;

        a {
          font-weight: 400;
          font-size: 10px;
          line-height: 12px;
          letter-spacing: 0.02em;
          text-decoration-line: underline;
          color: #252762;
        }
      }

      &_btnCreateWrapper {
        margin-top: 27px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &_or {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 2rem 0 1rem 0;

          p {
            font-weight: 500;
            font-size: 18px;
            line-height: 21.6px;
            color: #252762;
            margin: 0 10px 0 10px;
          }

          &_lineLeft {
            width: 170px;
            border-top: 1px solid #3C41A4;
          }

          &_lineRight {
            width: 170px;
            border-top: 1px solid #3C41A4;
          }
        }
        
        &_return {
          display: none;
          font-size: 10px;
          color: #6F6F6F;
          margin-left: 70%;
          margin-top: 5%;

          &:hover {
            color: #4d5766;
            transition: color 0.3s;
          }
          @media only screen and (min-width: 320px) and (max-width: 375px) {
            display: block;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            display: block;
            
          }
  
        }

      
        &_help {
          @media only screen and (min-width: 320px) and (max-width: 375px) {
            display: none;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            display: none;
          }
          display: flex;
          justify-content: center;
          margin-top: 8px;
          font-weight: 500;
          font-size: 14px;
          line-height: 13px;
          letter-spacing: 0.02em;
          color: #909193;

          a {
            margin-left: 3px;
            font-weight: 400;
            font-size: 14px;
            line-height: 12px;
            letter-spacing: 0.02em;
            text-decoration-line: underline;
            color: #252762;
          }
        }
      }
    }
  }
}

.input_container {
  width: 100%;
  height: 40px;
  border: 0.1rem solid #e8e8e8;
  border-radius: 8px;
  background-color: #f6f6f6;
  font-family: 'Inter', sans-serif;
  justify-content: center;

  & input {
    width: 90%;
    outline: none;
    border: none;
    -webkit-box-shadow: inset 0 0 0 50px #f6f6f6;
    //-webkit-text-fill-color: #000;
    margin: 11px 0 11px 11px;
    @include font(500, 14px);

    &::placeholder {
      @include font(500, 14px, 17px);
      color: #929292;
    }
  }

  &_input {
    position: relative;
    z-index: 10;
  }

  &_image {
    @include position_t_r(absolute, 10px, 10px);
    width: 26px;
    height: 22px;
    cursor: pointer;
  }
}

.bg {
  z-index: -1;

  &_wrap1 {
    position: absolute;
    z-index: -1;
    width: 50px;
    height: 50px;
    background: #97C1FF;
    border-radius: 50%;
    scale: 10;
    filter: blur(25px);
    animation: bgAnimate1 10s linear infinite;
  }

  &_wrap2 {
    z-index: -1;
    position: absolute;
    width: 50px;
    height: 50px;
    left: 100%;
    top: 100%;
    scale: 10;
    background: #FFFFFFE5;
    border-radius: 50%;
    filter: blur(25px);
    animation: bgAnimate2 10s linear infinite;
  }

  &_wrap3 {
    z-index: -1;
    position: absolute;
    width: 50px;
    height: 50px;
    left: 100%;
    top: 0%;
    scale: 10;
    background: #97C1FF;
    border-radius: 50%;
    filter: blur(25px);
    animation: bgAnimate3 10s linear infinite;
  }

  &_wrap4 {
    z-index: -1;
    position: absolute;
    width: 50px;
    height: 50px;
    scale: 10;
    left: 0%;
    top: 100%;
    background: #FFFFFFE5;
    border-radius: 50%;
    filter: blur(25px);
    animation: bgAnimate4 10s linear infinite;
  }
}

