@import '../../scss/mixin.scss';

.previous {
  position: relative;
  width: 100%;
  height: 26.25rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  background: #e0dced;
  border-radius: 1.5rem;
  overflow: hidden;
  margin-bottom: 25px;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    background-color: rgba(51, 47, 54, 0.1);
    //z-index: 1;

    @media screen and (max-width: 700px) {
      border-radius: 0.625rem;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    margin-bottom: 3%;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    margin-bottom: 3%;
  }

  &_avatar {
    @include position_t_l(absolute, 10px, 10px);
    // border: 1px solid #ffffff;
    background: transparent;
    max-width: 100px;
    width: 100%;
    object-fit: contain;
    border-radius: 7px;
    box-sizing: border-box;
    margin-right: 16px;
    z-index: 3;
  }

  &_loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.649);
    z-index: 9999;
  }
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    @include display(flex, row, flex-start, center);
    margin-bottom: 0;
    height: 11.25rem;
    border-radius: 0.625rem;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    @include display(flex, row, flex-start, center);
    margin-bottom: 0;
    height: 11.25rem;
    border-radius: 0.625rem;
  }
  &_bcgrShadow {
    @include position_t_l(absolute, 0, 0);
    opacity: 0.5;
    width: 100%;
    height: 100%;
    background-color: #000;
    z-index: 3;
  }
  .background_image_course {
    width: 100%;
    height: 100%;
    z-index: 3;
  }

  .background_image_profile {
    width: 100%;
    height: 100%;
    object-fit:cover;
    z-index: 3;
  }

  img {
    display: inline-block;
    z-index: 3;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      margin: 0 12px 0 8px;
      width: 40px;
      height: 40px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      margin: 0 12px 0 8px;
      width: 40px;
      height: 40px;
    }
  }
  &_infoBlock {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    height: 6.4375rem;
    padding: 1.5rem 4rem;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    background: linear-gradient(90deg, #332f36 -5.88%, rgba(51, 47, 54, 0) 100%);
    z-index: 3;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      display: inline-flex;
      padding: 0rem 5.8125rem 0rem 5.75rem;
      justify-content: center;
      align-items: center;
      height: 2.25rem;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      display: inline-flex;
      padding: 0rem 5.8125rem 0rem 5.75rem;
      justify-content: center;
      align-items: center;
      height: 2.25rem;
    }
    &_title {
      @include display(flex, column);
      &_description {
        @include font(500, 14px, 10px);
        color: #bdbdbd;
        font-size: 16px;
        margin-bottom: 2px;
        strong {
          font-weight: 600;
        }
      }


      &_name {
        color: #fff;
        text-align: center;
        font-family: 'SFPRORegular';
        font-size: 2rem;
        font-style: normal;
        font-weight: 800;
        //margin-bottom: 5px;
        line-height: 0.5rem; /* 134.783% */

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          font-size: 12px;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          font-size: 12px;
        }
      }


      &_about {
        @include font(700, 25px, 5px);
        color: #ffffff;
      }
    }
  }
  &_onlineCourses {
    @include position_t_l(absolute, 125px, 44px);
    @include font(500, 14px, 17px);
    color: #ba75ff;
  }
  &_btn {
    @include position_t_r(absolute, 1rem, 1rem);
    width: 24px;
    height: 24px;
    z-index: 3;
    cursor: pointer;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      display: none;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      display: none;
    }
    & button {
      padding: 10px 14px;
    }
  }
  &_btn_rate {
    @include position_t_r(absolute, 44px, 37px);
    //@media only screen and (min-width: 320px) and (max-width: 375px) {
    //  display: none;
    //}
    //@media only screen and (min-width: 375px) and (max-width: 480px) {
    //  display: none;
    //}
    //& button {
    //  padding: 10px 14px;
    //}
  }
  &_error {
    font-size: 12px;
    color: rgb(224, 24, 24);
    margin-bottom: 10px;
  }
}

.title_profile {
  text-align: center;
  font-size: 2rem;
  font-family: SFPRORegular;
  font-weight: 800;
}

.input_change {
  border: none;
  outline: none;
  background: #e0dced;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}
.hide_input {
  @include position_t_l(absolute, 0, 0);
  width: 66px;
  height: 66px;
  opacity: 0;
}
.input_background_image {
  opacity: 0;
}
.label_input_background_image {
  @include position_t_l(absolute, 0, 50%);
  transform: translateX(-50%);
  border-radius: 0 0 15px 15px;
  padding-top: 15px;
  width: 30%;
  display: flex;
  flex-direction: column;
  z-index: 3;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
}
.previous_title_name {
  @include position_t_l(absolute, 147px, 43px);
  @include font(500, 20px, 24px);
  color: #fff;
}
.back_all_course {
  @include position_t_l(absolute, 40px, 44px);
  @include display(flex, row, flex-start, center);
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  span {
    @include font(500, 12px, 15px);
    margin-left: 13px;
  }
}





.previousHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;

  &_title {
    color: var(--Black, #332f36);
    text-align: center;

    /* Desktop/Head/H2 */
    font-family: 'SFPROMedium';
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1px;
  }

  &_nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    height: 6rem;

    button {
      font-size: 1.25rem !important;
      letter-spacing: -1px;

      @media screen and (max-width: 700px) {
        font-size: 0.75rem !important;
        padding: 0.25rem 0.75rem;
        gap: 0.225rem;
      }
    }
  }
}