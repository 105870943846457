
@import '../../scss/mixin.scss';
@import '../../scss/variable.scss';


.helpPage {
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 124px;
  background: var(--main-bg);
  overflow: hidden;
  padding: 30px 15px 0;
}


.bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;

  &_wrap1 {
    position: absolute;
    width: 500px;
    height: 500px;
    left: 5%;
    top: 10%;
    background: rgba(53, 126, 235, 0.6);
    filter: blur(120px);
    animation: bgAnimate1 15s linear infinite alternate;
  }

  &_wrap2 {
    position: absolute;
    width: 600px;
    height: 600px;
    right: 10%;
    top: 20%;
    background: rgba(87, 184, 255, 0.5);
    filter: blur(110px);
    animation: bgAnimate2 12s linear infinite alternate;
  }

  &_wrap3 {
    position: absolute;
    width: 450px;
    height: 450px;
    left: 20%;
    bottom: 15%;
    background: rgba(45, 156, 219, 0.4);
    filter: blur(100px);
    animation: bgAnimate3 18s linear infinite alternate;
  }

  &_wrap4 {
    position: absolute;
    width: 500px;
    height: 500px;
    right: 15%;
    bottom: 10%;
    background: rgba(40, 120, 200, 0.5);
    filter: blur(105px);
    animation: bgAnimate4 14s linear infinite alternate;
  }
}


@keyframes bgAnimate1 {
  from { transform: translate3d(0, 0, 0); opacity: 0.8; }
  50% { transform: translate3d(50%, 100%, 0); opacity: 1; }
  to { transform: translate3d(0, 0, 0); opacity: 0.8; }
}

@keyframes bgAnimate2 {
  from { transform: translate3d(0%, 0%, 0); opacity: 0.7; }
  50% { transform: translate3d(-100%, -100%, 0); opacity: 1; }
  to { transform: translate3d(0%, 0%, 0); opacity: 0.7; }
}

@keyframes bgAnimate3 {
  from { transform: translate3d(0, 0, 0); opacity: 0.6; }
  50% { transform: translate3d(-300%, 100%, 0); opacity: 1; }
  to { transform: translate3d(0, 0, 0); opacity: 0.6; }
}

@keyframes bgAnimate4 {
  from { transform: translate3d(0, 0, 0); opacity: 0.5; }
  50% { transform: translate3d(350%, -80%, 0); opacity: 1; }
  to { transform: translate3d(0, 0, 0); opacity: 0.5; }
}

.helpBlock {
  width: 1280px;
  height: 600px;
  border-radius: 30px;
  display: grid;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-top: 120px;
}




.sections {
  width: 1280px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  justify-content: center;
  align-items: center;
  margin-top: -200px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-align: center;
  font-family: "SF Pro Display", sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 28.64px;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  height: 350px;
  position: relative;
}

.section p {
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center;
  margin: 0;
}


.section_default {
  @media only screen and (min-width: 1280px) {
    width: 400px;
    height: 300px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #CFE2FF;
    color: #0d28bb;
    transition: 0.3s ease;
  }

  &:hover {
    @media only screen and (min-width: 1280px) {
      background: #0D28BB;
      color: #FFFFFF;
    }
    @media only screen and (min-width: 320px) and (max-width: 1280px) {
      background: #0D28BB;
      color: #FFFFFF;
    }
  }
}

.section_variant2 {
  @media only screen and (min-width: 1280px) {
    width: 400px;
    height: 300px;
    border-radius: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(90deg, #0d28bb 0%, #357eeb 100%);
    color: white;
    transition: 0.3s ease;
  }

  &:hover {
    @media only screen and (min-width: 1280px) {
      background: linear-gradient(90deg, #357eeb 0%, #0d28bb 100%);
      color: #FFFFFF;
    }
    @media only screen and (min-width: 320px) and (max-width: 1280px) {
      background: linear-gradient(90deg, #357eeb 0%, #0d28bb 100%);
      color: #FFFFFF;
    }
  }
}


.sectionImage {
  @media only screen and (min-width: 1280px) {
    width: 200px;
    height: 200px;
    transition: transform 0.3s ease-in-out;
    position: absolute;
    top: 20px;
  }
}

.imageHover {
  @media only screen and (min-width: 1280px) {
    width: 230px;
    height: 230px;
  }
}
.ctaBlock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 36px 10px;
  gap: 48px;
  width: 1920px;
  height: 472px;
  background: linear-gradient(90deg, #0D28BB 0%, #357EEB 100%);
  border-radius: 0px;
}

.ctaText {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  width: 552px;
  height: 294px;
  color: #ffffff;
  text-align: left;
}

.ctaText h2 {
  font-family: 'SF Pro Display', sans-serif;
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
}

.ctaText p {
  font-family: 'SF Pro Display', sans-serif;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
}

.ctaImage {
  width: 660px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #324195;
  border-radius: 30px;
}

.ctaImage img {
  width: 578px;
  height: 376px;
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
  border-radius: 20px;
}

@media (max-width: 1200px) {
  .ctaBlock {
    flex-direction: column;
    height: auto;
    padding: 20px;
  }

  .ctaImage {
    width: 100%;
    height: auto;
  }

  .ctaImage img {
    width: 100%;
    height: auto;
  }
}


.helpBlock, .faqBlock {
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
}

.helpBlock img, .faqBlock img {
    max-width: 100%;
    height: auto;
}


.helpBlock {
    position: relative;
    top: 0;
}

/* Адаптация под мобильные устройства (до 760px) */
@media (max-width: 760px) {
  .section_default {
    background: #CFE2FF;
    color: #0d28bb;
  }

  .section_variant2 {
    background: linear-gradient(90deg, #0d28bb 0%, #357eeb 100%);
    color: white;
  }
}
@media (max-width: 760px) {
  .section_default:hover {
    background: #0D28BB;
    color: #FFFFFF;
  }

  .section_variant2:hover {
    background: linear-gradient(90deg, #357eeb 0%, #0d28bb 100%);
    color: #FFFFFF;
  }
}
@media (max-width: 760px) {
  .sections {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
    margin-bottom: -60px;
    margin-top: -140px
  }

  .sections .section {
    width: 90%;
    height: auto;
    padding: 15px;
    font-size: 14px;
  }

  .sectionImage {
    width: 100px;
    height: 100px;
    top: 10px;
  }

  .section p {
    bottom: 5px;
    transition: bottom 0.3s ease-in-out;
  }

  .section:hover p {
    bottom: -5px;
  }
}

@media (max-width: 760px) {
  .sectionImage {
    width: 100px;
    height: 100px;
    top: 10px;
    transition: transform 0.3s ease-in-out;
  }

  .imageHover {
    width: 120px;
    height: 120px;
  }
}
@media (max-width: 760px) {
  .section:hover .sectionImage {
    transform: scale(1.2);
  }
}

@media (max-width: 760px) {
  .ctaBlock {
    flex-direction: row;
    height: auto;
    padding: 20px;
    gap: 20px;
    width: 100%;
    transform: scale(1);
    margin-bottom: -60px;
  }

  .ctaText {
    width: 100%;
    height: auto;
    gap: 5px;

  }

  .ctaText h2 {
    font-size: 14px;
    line-height: 14px;
  }

  .ctaText p {
    font-size: 8px;
    line-height: 10px;
  }

  .ctaImage {
    width: 100%;
    height: auto;
    border-radius: 20px;
  }

  .ctaImage img {
    width: 80%;
    height: auto;
    border-radius: 15px;
  }
}

@media (max-width: 760px) {
  .bg_wrap1, .bg_wrap2, .bg_wrap3, .bg_wrap4 {
    width: 200px;
    height: 200px;
    filter: blur(60px);
  }
}

@media (max-width: 760px) {
  .helpBlock {
    width: 90%;
    height: auto;
    margin-top: 60px;
    margin-bottom: 10px;
  }

  .helpBlock img {
    width: 100%;
    height: auto;
  }
}

/* Адаптация под планшеты (768px - 1024px) */

@media (min-width: 768px) and (max-width: 1024px) {
  .helpBlock {
    width: 85%;
    height: auto;
    margin-top: 100px;
    margin-bottom: 40px;
  }

  .helpBlock img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .helpBlock {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .sections {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 90%;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: -60px;
  }

  .section {
    width: 100%;
    height: 200px;
    padding: 15px;
    font-size: 16px;
  }

  .sectionImage {
    width: 120px;
    height: 120px;
    top: 15px;
    transition: transform 0.3s ease-in-out;
  }

  .imageHover {
    width: 140px;
    height: 140px;
  }

  .section:hover .sectionImage {
    transform: scale(1.2);
  }

  .section p {
    bottom: 10px;
    transition: bottom 0.3s ease-in-out;
  }

}

@media (min-width: 768px) and (max-width: 1024px) {
  .section_default {
    background: #CFE2FF;
    color: #0d28bb;
  }

  .section_variant2 {
    background: linear-gradient(90deg, #0d28bb 0%, #357eeb 100%);
    color: white;
  }

  .section_default:hover {
    background: #0D28BB;
    color: #FFFFFF;
  }

  .section_variant2:hover {
    background: linear-gradient(90deg, #357eeb 0%, #0d28bb 100%);
    color: #FFFFFF;
  }

  .section:hover .sectionImage {
    transform: scale(1.2);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ctaBlock {
    flex-direction: row;
    height: auto;
    padding: 20px;
    gap: 20px;
    width: 100%;
    margin-bottom: -140px;
  }

  .ctaText {
    width: 70%;
    height: auto;
    gap: 10px;
  }

  .ctaText h2 {
    font-size: 20px;
    line-height: 25px;
  }

  .ctaText p {
    font-size: 12px;
    line-height: 15px;
  }

  .ctaImage {
    width: 100%;
    height: auto;
  }

  .ctaImage img {
    width: 90%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .faqBlock {
    width: 90%;
    height: auto;
    padding: 30px;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  .faqBlock img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .faqBlock {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


/* Адаптация под ноутбуки (1024px - 1440px) */
@media (min-width: 1024px) and (max-width: 1440px) {
  .helpBlock {
    width: 85%;
    height: auto;
    margin-top: 100px;
    margin-bottom: 40px;
  }

  .helpBlock img {
    width: 100%;
    height: auto;
  }
}
@media (min-width: 1024px) and (max-width: 1440px) {
  .helpBlock {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 1024px) and (max-width: 1440px) {
  .sections {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 90%;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: -60px;
  }

  .section {
    width: 100%;
    height: 230px;
    padding: 20px;
    font-size: 18px;
  }

  .sectionImage {
    width: 150px;
    height: 150px;
    top: 20px;
  }

  .section p {
    bottom: 20px;
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .section_default {
    background: #CFE2FF;
    color: #0d28bb;
  }

  .section_variant2 {
    background: linear-gradient(90deg, #0d28bb 0%, #357eeb 100%);
    color: white;
  }

  .section_default:hover {
    background: #0D28BB;
    color: #FFFFFF;
  }

  .section_variant2:hover {
    background: linear-gradient(90deg, #357eeb 0%, #0d28bb 100%);
    color: #FFFFFF;
  }

  .section:hover .sectionImage {
    transform: scale(1.2);
  }
}

@media (min-width: 1024px) and (max-width: 1400px) {
  .ctaBlock {
    flex-direction: row;
    height: auto;
    padding: 30px;
    gap: 30px;
    width: 100%;
  }

  .ctaText {
    width: 50%;
    height: auto;
    gap: 20px;
  }

  .ctaText h2 {
    font-size: 28px;
    line-height: 34px;
  }

  .ctaText p {
    font-size: 18px;
    line-height: 22px;
  }

  .ctaImage {
    width: 50%;
    height: auto;
  }

  .ctaImage img {
    width: 100%;
    height: auto;
  }
}