@import '../../../scss/variable.scss';
@import '../../../scss/mixin.scss';

.wrapper {
    background-color: #fff;
    margin-top: 20px;
    padding: 30px 61px 30px 61px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: $box-shadow;

    &_optionsContent {
        label {
            @include display (flex, row, flex-start, center);
            @include font (500, 14px, 17px);
            color: #A8ABAD;
            cursor: pointer;
        }
    }    
}