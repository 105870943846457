@import '../../../../scss/mixin.scss';
@import '../../../../scss/variable.scss';

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 7px;
  cursor: pointer;

  &_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;

    &_icon {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;

      color: #9ca3af;

      &_checked {
        color: #ba75ff;
      }
    }

    &_checked label {
      background-color: #e0d9fc !important;
      color: #ba75ff;
    }

    label {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 25px;
      border-radius: 7px;
      background: #f3f4f6;
      user-select: none;

      &::before {
        position: absolute;
        left: 25px;
      }
    }

    input:checked + label {
      background-color: #e0d9fc;
    }
  }
}
// input:not(:disabled):checked+label {
//     background-color: #b3d7ff;
//     border-color: #b3d7ff;
// }
