@import '../../../../scss/mixin.scss';

.logoBlock {
  &_title {
    font-size: 14px;
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
  }

  &_iconInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_desc {
    color: black;
    font-weight: 400;
    display: block;
    //@include font (400, 10px);
    letter-spacing: 0.01em;
    margin-bottom: 16px;
  }

  &_information {
    position: relative;
    @include display (flex);
    margin-top: 20px;
    margin-bottom: 26px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      padding-bottom: 1rem;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      padding-bottom: 1rem;
    }
    
    &_label {
      @include display (flex, row, center, center);
      margin: auto;
      width: 200px;
      height: 100px;
      cursor: pointer;
      background-color: #ffffff;
      //margin-right: 24px;
      border-radius: 10px;

      &:hover {
        background-color: #ffffff;
      }

      &_fileInput {
        @include position_t_l (absolute, 0, 0, -2);
      }

      &_img {
        //@include position_t_l(absolute, 0, 0, 0);
        width: 100px;
        border-radius: 7px;
        object-fit: contain;
        
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          //@include position_t_l(absolute, 0, 0, 0);
          width: 108px;
          height: 54px;
          border-radius: 50%;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          //@include position_t_l(absolute, 0, 0, 0);
          width: 144px;
          height: 72px;
          border-radius: 50%;
        }
      }
    }

    &_requirements {
      font-size: 10px;
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        padding-left: 1%;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
       padding-left: 1%;
      }

      &_title {
        font-size: 14px;
        margin-bottom: 15px;
      }

      & p {
        color: black;
        font-weight: 400;
        font-size: 16px;
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }
}