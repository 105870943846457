@import '../../../scss/variable';

.switch {
  width: 58px;
  height: 25px;
  display: flex;
  justify-content: flex-start;
  border-radius: 36px;
  background: rgb(237, 238, 242);
  position: relative;
  cursor: pointer;
  border: 1px solid rgb(53, 126, 235);
}

.switch[data-isOn='true'] {
  justify-content: flex-end;
  border: 1px solid transparent;
  background: linear-gradient(90deg, rgb(13, 40, 187), rgb(53, 126, 235) 100%);
  .handle {
    background-color: #ffffff;
    border-color: rgb(53, 126, 235);
  }
}

.handle {
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border-color: rgb(53, 126, 235);
  border-radius: 50%;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    background-color: #ffffff;
    border: 1px solid rgb(53, 126, 235);
    border-radius: 50%;
  }
}
