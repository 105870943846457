@import 'scss/mixin.scss';
@import 'scss/variable.scss';

.previous {
  position: relative;
  width: 1040px;
  height: auto;
  min-height: 200px;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  background: #e0dced;
  border-radius: 7px;

  @media (max-width: 600px) {
    width: 100%;
    height: 300px;
  }

  &_buttonAccept {
    button {
      width: 203px;
      height: 36px;
      font-size: 16px;
      font-weight: 700;
      padding: 0;
    }

    @include position_b_r(absolute, 50px, 44px);

    @media screen and (min-width: 375px) and (max-width: 480px) {
      @include position_b_r(absolute, 55px, 16px);
    }
  }

  &_bcgrShadow {
    @include position_t_l(absolute, 0, 0);
    opacity: 0.5;
    width: 100%;
    height: 100%;
    background-color: #000000;
    border-radius: 7px;
  }

  .background_image_course {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    border-radius: 7px;
    background-color: rgba(248, 229, 255, 0.68);
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: inline-block;

    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 7px;
    }

    &_error {
      z-index: 5;
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 20px;
      padding: 10px;
      border-radius: 10px;
      color: #ffff;
      background: rgba(224, 24, 24, 0.3);
      margin-top: 5px;
      text-align: center;
    }
  }

  &_onlineCourses {
    position: relative;
    padding-top: 80px;
    padding-left: 44px;
    width: 700px;

    &_name {
      width: 100%;
      padding: 5px;
      color: #ba75ff;
      background: none;
      border: none;
      @include font(700, 22px, 24px);
      text-transform: uppercase;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        @include position_t_l(absolute, 107px, 16px);
        @include font(500, 16px, 17px);
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        @include position_t_l(absolute, 107px, 16px);
        @include font(500, 16px, 17px);
      }
    }
  }
}
.previous_title_name {
  width: 700px;
  position: relative;
  padding-top: 20px;
  padding-left: 44px;
  padding-bottom: 30px;

  &_description {
    @include font(500, 22px, 28px);
    width: 100%;
    background: none;
    color: #fff;
    padding: 5px 5px;
    text-align: left;
    border: none;
    font-family: 'Inter', sans-serif;
    resize: none;
    font-size: 17px;
  }

  textarea:hover {
    background: rgba(248, 229, 255, 0.1);
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    @include position_t_l(absolute, 140px, 16px);
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    @include position_t_l(absolute, 140px, 16px);
  }
}
