@font-face {
  font-family: 'SFPRORegular';
  src: url(../../assets/fonts/SFProText/SFProText-Regular.ttf) format('opentype');
}

.text_container {
  @media only screen and (min-width: 1150px) {
    font-size: 2rem;
    text-align: center;
  }

  @media only screen and (min-width: 490px) and (max-width: 1150px) {
    font-size: 2vw;
    text-align: center;
  }

  @media only screen and (min-width: 390px) and (max-width: 435px) {
    font-size: 0.6rem;
    text-align: center;
  }
}
.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1rem;
  height: 125px;

  div {
    font-size: 46px;
    text-align: start;
  }

  @media only screen and (min-width: 390px) and (max-width: 435px) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 1rem;
    height: 45px;
  }

  p {
    @media only screen and (min-width: 1150px) {
      font-family: 'SFPRORegular';
      font-weight: 500;
      font-size: 46px;
      line-height: 54.89px;
      color: #332f36;
    }
    @media only screen and (min-width: 490px) and (max-width: 1150px) {
      font-family: 'SFPRORegular';
      font-weight: 500;
      font-size: 3vw;
      line-height: 54.89px;
      color: #332f36;
    }
    @media only screen and (min-width: 390px) and (max-width: 435px) {
      font-family: 'SFPRORegular';
      font-weight: 500;
      font-size: 12px;
      line-height: 10.89px;
      color: #332f36;
    }
  }

  &_worlds {
    @media only screen and (min-width: 1150px) {
      font-family: 'SFPRORegular';
      color: #357eeb;
      font-weight: 500;
      font-size: 46px;
      // line-height: 62px;
    }

    @media only screen and (min-width: 490px) and (max-width: 1150px) {
      font-family: 'SFPRORegular';
      color: #357eeb;
      font-weight: 500;
      font-size: 3vw;
      line-height: 52px;
    }

    @media only screen and (min-width: 390px) and (max-width: 435px) {
      font-family: 'SFPRORegular';
      color: #357eeb;
      font-weight: 500;
      font-size: 16px;
      line-height: 12px;
    }
  }
}
