@import '../../../scss/variable';
@import '../../../scss/mixin.scss';

.container {
  @include position_t_l(absolute, 50%, 50%, 10);
  @include display(flex, column);
  align-items: center;
  padding: 54px 34px 40px 34px;
  background: #ffffff;
  text-align: center;
  margin: 0 auto;
  border-radius: 24px;
  box-shadow: 0px 0px 8px 0px #3241954d;
  // overflow: hidden;
  transform: translate(-50%, -50%);
  font-family: $font-family-inter;
  max-width: 667px;
  width: 90%;
  max-height: 90dvh;
  overflow-y: scroll;
  height: auto;
  ::-webkit-scrollbar {
    display: none;
    width: 0;
  }

  // position: relative;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 100%;
    padding: 2rem 1rem 0 1rem;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    width: 100%;
    padding: 2rem 1rem 0 1rem;
  }

  &_decoration_shadow {
    width: 385px;
    height: 385px;
    border-radius: 385px;
    background: #57b8ff;
    filter: blur(200px);
    width: 385px;
    height: 385px;
    position: absolute;
    top: -322px;
    z-index: 1;
  }

  &_closed {
    @include position_t_r(absolute, 20px, 30px);
    text-align: center;
    cursor: pointer;

    & svg:hover {
      path {
        fill: rgb(172, 172, 172);
      }
    }
  }

  &_header {
    @include display(flex, column, flex-start, center);
    gap: 36px;
    justify-content: center;
    position: relative;

    &_title {
      color: var(--Black, #332f36);

      font-family: 'SF Pro Text', sans-serif;

      @include font(700, 32px);

      &_btn {
        height: max-content;
        width: 100%;
        margin: 0 auto;
        // margin-bottom: 20px;

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          width: 100%;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          width: 100%;
        }

        &_add {
          width: 100%;
          @media only screen and (min-width: 320px) and (max-width: 375px) {
            width: 100%;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            width: 100%;
          }
        }

        &_send {
          width: 100%;
          font-size: 16px;
          @media only screen and (min-width: 320px) and (max-width: 375px) {
            width: 100%;
            padding-bottom: 2%;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            width: 100%;
            margin-bottom: 2%;
          }
        }
      }
    }
  }

  &_navBlock {
    @include font(500, 18px);
    margin-bottom: 15px;

    & :not(:last-child) {
      margin-right: 15px;
    }

    &_btn {
      padding: 9px 15px;
      color: #b3b8c2;
      cursor: pointer;
      text-align: center;
    }

    &_active {
      border-radius: 7px;
      color: $font-title;
      border: none;
      background-color: #e5e7eb;
    }
  }
}

// .container:hover {
//     scrollbar-color: #ba75ff #e0dced80;
// }

.addStudent {
  color: $font-title;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  &_select {
    margin: 0 auto;
    width: 474px;

    & :nth-child(1) {
      padding-bottom: 7px;
    }
  }

  &_student {
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      width: 100%;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      width: 100%;
    }
    @include display(flex, column);
    text-align: left;
    width: 100%;
    margin: 0 0 1rem 0;

    &_title {
      @include display(flex, row, space-between);
      font-size: 18px;
      margin-bottom: 8px;
    }

    &_btn_remove {
      color: #bb0e0e;
      background: none;
      border: none;
    }

    &_btn {
      margin: 8px 5px;
      text-align: left;
      color: $font-color-rose;
      font-size: 16px;
      border: none;
      background-color: #ffffff;

      & :nth-child(1) {
        margin-right: 24px;
        cursor: pointer;
      }

      & :nth-child(2) {
        cursor: pointer;
      }
    }
  }

  &_btnBlock {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}

.addGroup {
  width: 100%;

  &_input {
    margin: 0 auto;
    width: 95%;
    text-align: left;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      padding-bottom: 5%;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      padding-bottom: 5%;
    }

    & :nth-child(2) {
      margin-top: 10px;
      // @media only screen and (min-width: 320px) and (max-width: 375px) {
      //   width: 100%;

      // }
      // @media only screen and (min-width: 375px) and (max-width: 480px) {
      //   width: 100%;

      // }
    }

    & span {
      font-size: $fs14;
      color: #a2a9b4;
    }

    &_check {
      display: flex;
      margin-top: 15px;
      margin-bottom: 15px;

      &_span {
        margin-left: 10px;
      }
    }
  }

  &_description {
    font-size: $fs12;
    color: #53565b;
  }

  // style={{marginLeft: 10, marginTop: "1%"}}
  &_btn {
    width: 100%;
    margin-top: 22px;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      padding-bottom: 5%;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      padding-bottom: 5%;
    }

    & button {
      width: 96%;
      margin: 0 auto;
    }
  }
}

.groupSetting {
  &_input {
    text-align: left;
    height: 104px;
    margin: 32px 0 10px 0;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      width: 100%;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      width: 100%;
    }
  }
  & input {
    height: 43px;
    font-size: 24px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0;
    text-align: left;
  }

  &__delete_btn {
    color: #ffffff;
    height: 54px;
    border-radius: 10px;
    background: rgb(127, 127, 127);

    &:hover:not(:disabled) {
      color: #ffff;
      background: rgb(127, 127, 127);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }

  &__save_btn {
    color: #ffffff;
    height: 54px;
    border-radius: 10px;
    background: rgb(53, 126, 235);

    &:hover:not(:disabled) {
      color: #ffff;
      background: rgb(53, 126, 235);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
  }

  &_description {
    font-size: $fs12;
    color: #6e7073;
    text-align: left;
    letter-spacing: 0.04em;
  }

  &_checkboxBlock {
    overflow-y: auto;
    height: 409px;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      margin-top: 24px;
      background: rgb(255, 255, 255);
      border-radius: 4px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      background: rgb(207, 226, 255);
      border-radius: 4px;
      border: 2px solid rgb(255, 255, 255);
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgb(53, 126, 235);
    }
    scrollbar-width: thin;
    scrollbar-color: rgb(207, 226, 255) rgb(255, 255, 255);
    & :not(:last-child) {
      margin-top: 10px;
      margin-bottom: 19px;
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        margin: 2%;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        margin: 2%;
      }
    }

    &_checkbox {
      @include display(flex, row, flex-start);
      text-align: left;
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        margin: 2%;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        margin: 2%;
      }

      &_desc {
        @include display(flex, column);
        font-weight: 500;

        & :nth-child(1) {
          color: $font-title;
          font-size: $fs16;
          margin-bottom: 4px;
        }

        & :nth-child(2) {
          color: #a8afb9;
          font-size: $fs16;
        }
      }
    }
  }

  &_duration {
    margin-top: 20px;
    margin-left: 35px;
    text-align: left;

    &_limit {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;

      & span {
        font-size: $fs16;
        color: #a2a9b4;
      }

      & input {
        width: 100px;
        height: 25px;
        border-radius: 8px;
        background-color: #e7d7e5;
        margin-left: 30px;
        text-align: center;
      }
    }
  }

  &_courseAccess_header {
    width: 100%;
    margin-top: 40px;
    margin-bottom: 20px;
    display: inline-flex;
    justify-content: space-between;
  }

  &_courseAccess_content {
    margin-bottom: 30px;

    &_desc {
      font-size: $fs14;
      color: #4d5766;
    }

    &_course {
      text-align: left;
      margin-top: 15px;

      &_name {
        @include font(600, 12px, 150%);
        color: #5a555b;
        margin-bottom: 8px;
      }
    }
  }

  &_selectBlock {
    margin-top: 20px;

    &_select {
      text-align: left;

      & :nth-child(2) {
        margin-top: 4px;
      }

      & span {
        @include font(500, $fs16);
        color: $font-title;
      }
    }
  }

  &_btn {
    margin-top: 40px;
    width: 100%;
    height: 54px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    & button {
      width: 100%;
    }
  }

  &_access {
    text-align: right;
    font-weight: 500;

    &_about {
      @include display(flex, row, right, center);
      margin: 30px 0 40px 0;

      & :nth-child(1) {
        font-size: 16px;
        color: $font-title;
        margin-right: 24px;
      }

      &_setClassesDate {
        @include display(flex, row, flex-start, center);
        cursor: pointer;

        & :hover {
          font-size: 16.1px;
          color: #575b65;
        }

        & :nth-child(1) {
          color: #787e8b;
          margin-right: 22px;
        }

        & svg {
          margin-top: -2px;
          margin-right: -8px;
        }
      }
    }
  }
}

.textarea__field {
  border: 1px solid #d5d3d3;
  margin-top: 2px;
  border-radius: 7px;
  outline: none;
}

.textField {
  font-family: $font-family-inter;
  font-size: 0.95em;
  opacity: 0.9;
  color: $font-title;
  margin-bottom: 7px;
  text-align: left;
  margin-left: 1%;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    margin: 2%;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    margin: 2%;
  }
}

.courseSelect {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 1px;
  margin-bottom: 15px;
  margin-top: 15px;

  label {
    display: block;
  }

  select {
    width: 100%;
    padding: 8px;
    color: #666;
    border: none;
    border-radius: 8px;
    background-color: transparent;
    outline: none;
  }
}

.selectGroup {
  div > button {
    height: 56px;
  }
}

.selectInput > div.css-13cymwt-control,
.selectInput > div.css-t3ipsp-control {
  height: 56px;
  border: 2px solid #357eeb;
  border-radius: 14px;
}