* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.PWA {
    display: flex;
    flex-direction: column;

    table, td {
        border: 1px solid;
      }

    &_Preamble {
        text-align: left;
        align-self: self-end;
    }
    &_Header {
        text-transform: uppercase;
        color: #4d5766;
    }
    &_Content {
        text-align: justify;
        text-indent: 10px;
        line-height: 1.4;
    }
}