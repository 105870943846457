@import '../../../scss/variable';

.switch {
  display: flex;
  justify-content: flex-start;
  border-radius: 48px;
  background: linear-gradient(180deg, #CFE2FF 0%, #BBCEEB 100%);
  position: relative;
  cursor: default;

  @media only screen and (min-width: 1800px) {
    margin-top: 30px;
    width: 565px;
    height: 64px;
  }

  @media only screen and (min-width: 480px) and (max-width: 1800px) {
    margin-top: 2vw;
    width: 31vw;
    height: 3.6vw;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 282.5px;
    height: 32px;
    margin-top: 15px;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 63%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border-radius: 64px;
    content: 'Годовая';
    font-family: SF Pro Display;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: center;
    vertical-align: middle;
    color: rgba(51, 47, 54, 1);

    @media only screen and (min-width: 1800px) {
      font-size: 20px;
    }

    @media only screen and (min-width: 480px) and (max-width: 1800px) {
      font-size: 1.1vw;
    }

    @media only screen and (min-width: 320px) and (max-width: 480px) {
      font-size: 10px;
    }
  }

  &::after {
      position: absolute;
      top: 50%;
      left: 83.5%;
      transform: translate(-50%, -50%);
      background-color: rgba(53, 126, 235, 1);
      border-radius: 16px;
      content: 'Экономия 20%';
      font-family: SF Pro Display;
      font-weight: 500;
      letter-spacing: 0%;
      text-align: center;
      vertical-align: middle;
      color: rgba(255, 250, 250, 1);

      @media only screen and (min-width: 1800px) {
        width: 127px;
        padding: 4px 8px 4px 8px;
        line-height: 19px;
        font-size: 16px;
        border: 1px solid rgba(255, 255, 255, 1);
      }

      @media only screen and (min-width: 480px) and (max-width: 1800px) {
        width: 7vw;
        padding: 0.2vw 0.4vw 0.2vw 0.4vw;
        line-height: 1vw;
        font-size: 0.8vw;
        border: 1px solid rgba(255, 255, 255, 1);
      }

      @media only screen and (min-width: 320px) and (max-width: 480px) {
        width: 65px;
        gap: 5px;
        border-radius: 8px;
        padding-top: 2px;
        padding-right: 4px;
        padding-bottom: 2px;
        padding-left: 4px;
        border: 0.5px solid rgba(255, 255, 255, 1);
        font-size: 8px;
    }
  }
}

.switch[data-isOn='true'] {
  justify-content: flex-end;
  background: linear-gradient(180deg, #CFE2FF 0%, #BBCEEB 100%);


  .handle {
    background-color: rgba(53, 126, 235, 1);

     &::before {
      position: absolute;
      top: 50%;
      left: 26%;
      transform: translate(-50%, -50%);
      background-color: transparent;
      border-radius: 64px;
      content: 'Годовая';
      font-family: SF Pro Display;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0%;
      text-align: center;
      vertical-align: middle;
      color: rgba(255, 250, 250, 1);

      @media only screen and (min-width: 1800px) {
        font-size: 20px;
      }

      @media only screen and (min-width: 480px) and (max-width: 1800px) {
        font-size: 1.1vw;
      }

      @media only screen and (min-width: 320px) and (max-width: 480px) {
        font-size: 10px;
    }
  }

    &::after {
      position: absolute;
      top: 50%;
      left: 67%;
      transform: translate(-50%, -50%);
      background-color: rgba(53, 126, 235, 1);
      border: 1px solid rgba(255, 255, 255, 1);
      border-radius: 16px;
      content: 'Экономия 20%';
      font-family: SF Pro Display;
      font-weight: 500;
      letter-spacing: 0%;
      text-align: center;
      vertical-align: middle;
      color: rgba(255, 250, 250, 1);

      @media only screen and (min-width: 1800px) {
        width: 127px;
        padding: 4px 8px 4px 8px;
        line-height: 19px;
        font-size: 16px;
      }

      @media only screen and (min-width: 480px) and (max-width: 1800px) {
        width: 7vw;
        padding: 0.2vw 0.4vw 0.2vw 0.4vw;
        line-height: 1vw;
        font-size: 0.8vw;
      }

      @media only screen and (min-width: 320px) and (max-width: 480px) {
        width: 65px;
        gap: 5px;
        border-radius: 8px;
        padding-top: 2px;
        padding-right: 4px;
        padding-bottom: 2px;
        padding-left: 4px;
        border: 0.5px solid rgba(255, 255, 255, 1);
        font-size: 8px;
      }
    }
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    border-radius: 64px;
    content: 'Ежемесячно';
    font-family: SF Pro Display;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: center;
    vertical-align: middle;
    color: rgba(51, 47, 54, 1);

    @media only screen and (min-width: 1800px) {
      font-size: 20px;
    }

    @media only screen and (min-width: 480px) and (max-width: 1800px) {
      font-size: 1.1vw;
    }

    @media only screen and (min-width: 320px) and (max-width: 480px) {
      font-size: 10px;
    }
  }
}

.handle {
  background-color: rgba(53, 126, 235, 1);
  border-color: rgb(53, 126, 235);
  border-radius: 64px;
  position: relative;

  @media only screen and (min-width: 1800px) {
    width: 282px;
    height: 64px;
  }

  @media only screen and (min-width: 480px) and (max-width: 1800px) {
    width: 15.5vw;
    height: 3.6vw;
  }

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    width: 141px;
    height: 32px;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(53, 126, 235, 1);
    border-radius: 64px;
    content: 'Ежемесячно';
    font-family: SF Pro Display;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0%;
    text-align: center;
    vertical-align: middle;
    color: rgba(255, 250, 250, 1);

    @media only screen and (min-width: 1800px) {
      font-size: 20px;
    }

    @media only screen and (min-width: 480px) and (max-width: 1800px) {
      font-size: 1.1vw;
    }

    @media only screen and (min-width: 320px) and (max-width: 480px) {
      font-size: 10px;
    }
  }
}