@import '../../../../scss/variable';
@import '../../../../scss/mixin.scss';

.accardion {
  // min-width: 600px;
  width: 100%;
  border-radius: 5px;
  margin-top: 1rem;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  max-height: 450px;
  overflow: overlay;
  scrollbar-color: rgb(207, 226, 255) rgb(255, 255, 255);

  //&_lesson {
  //  padding: 1rem;
  //  display: flex;
  //  align-items: center;
  //  border-radius: 7px;
  //  background: #f5f5f5;
  //
  //  &_name {
  //    overflow: hidden;
  //    text-overflow: ellipsis;
  //    white-space: nowrap;
  //    flex-grow: 1;
  //    @include font(500, 12px, 150%);
  //    margin-left: 0.75rem;
  //    letter-spacing: -0.05em;
  //    color: #9ca3af;
  //  }
  //}

  &_control_btn {
    flex-shrink: 0;
    margin-left: 1.5rem;
    margin-right: 1rem;
    color: $font-title;
    transform: rotate(0) translateY(-2px);
    -webkit-transform: rotate(0) translateY(-2px);
    -moz-transform: rotate(0) translateY(-2px);
    -ms-transform: rotate(0) translateY(-2px);
    -o-transform: rotate(0) translateY(-2px);
    transition: rotate 0.2s ease-in;
    -webkit-transition: rotate 0.2s ease-in;
    -moz-transition: rotate 0.2s ease-in;
    -ms-transition: rotate 0.2s ease-in;
    -o-transition: rotate 0.2s ease-in;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      margin-left: 1rem;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      margin-left: 1rem;
    }

    &.open {
      color: #9ca3af;
      transform: rotate(180deg) translateY(-2px);
      -webkit-transform: rotate(180deg) translateY(-2px);
      -moz-transform: rotate(180deg) translateY(-2px);
      -ms-transform: rotate(180deg) translateY(-2px);
      -o-transform: rotate(180deg) translateY(-2px);
      transition: rotate 0.2s ease-in;
      -webkit-transition: rotate 0.2s ease-in;
      -moz-transition: rotate 0.2s ease-in;
      -ms-transition: rotate 0.2s ease-in;
      -o-transition: rotate 0.2s ease-in;
    }
  }

  //&_lessons_block {
  //  display: flex;
  //  flex-direction: column;
  //  margin-top: 0.75rem;
  //  gap: 0.625rem;
  //}

  &_progress {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    margin-left: auto;
    gap: 12px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      gap: 10px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      gap: 8px;
    }

    &_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 2rem;
      gap: 5px;
      & span {
        @include font(500, 12px, 150%);
        color: $font-title;
      }

      @media screen and (max-width: 1024px) {
        margin-left: 0;
      }

    }
  }

  &_group {
    & span {
      @include font(400, 12px, 150%);
      letter-spacing: -0.03em;
      color: #717985;
    }
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &_course_name {
    @include font(600, 12px, 150%);
    color: $font-title;
  }

  &_course_avatar {
    height: 2.5rem;
    width: 2.5rem;
    flex-shrink: 0;
    border-radius: 5px;
    background: #c7bce9;
    margin-left: 0.5rem;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      height: 2rem;
      width: 2rem;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      height: 2rem;
      width: 2rem;
    }
  }
  &_course_img {
    height: 2.5rem;
    width: 2.5rem;
    object-fit: cover;
    max-width: 100%;
    flex-shrink: 0;
    border-radius: 5px;
  }

  &_info {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 0.5rem;
  }

  //&_item {
  //  margin-top: 1.25rem;
  //  &:first-child {
  //    margin-top: 0;
  //  }
  //  &_name {
  //    @include font(600, 12px, 150%);
  //    color: $font-title;
  //  }
  //  // overflow: hidden;
  //  // transition: height 0.3s ease;
  //}

  &_header {
    padding: 1.25rem 0;
    cursor: pointer;
    display: flex;
    align-items: center;


    @media only screen and (min-width: 320px) and (max-width: 375px) {
      gap: 10px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      gap: 10px;
      padding: 1.25rem 0.5rem 1.25rem 0.5rem;
    }

    // &:hover {
    //   background-color: #e0e0e0;
    // }
  }

  &_duration {
    margin-left: 20px;
    margin-right: 35px;

   
    &_label label {
        @include font(500, 12px, 150%);
        color: $font-title;
     }
    &_label span {
      margin-left: 10px;
        @include font(500, 12px, 150%);
        color: rgb(162, 112, 187);
     }

    &_limit {
      margin-top: 5px;
      width: 100%;
      //display: inline-flex;
      justify-content: space-between;
      text-align: left;

      &_check {
        display: flex;
        align-items: center;
      }

      & span {
       @include font(400, 12px, 150%);
       color: #717985;
     }
      & input {
        width: 90px;
        height: 25px;
        border-radius: 8px;
        background-color: #ecd9fa;
        margin-left: 30px;
        text-align: center;
     }
      &_btn {
        @include font(500, 12px, 150%);
        color: $font-title;
        height: 27px;
        width: 100px;
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        margin-top: 15px;
     }
    }
  }

  //&_content {
  //  padding-left: 1.25rem;
  //  padding-right: 1.25rem;
  //  padding-bottom: 1.25rem;
  //  margin-top: 1.75rem;
  //  // height: 0;
  //  // opacity: 0;
  //  transition: height 0.3s ease, opacity 0.3s ease;
  //
  //  &_check {
  //    display: flex;
  //    text-align: right;
  //    justify-content: flex-end;
  //
  //    &_span {
  //      margin-left: 10px;
  //      margin-top: 2px;
  //      @include font(600, 12px, 150%);
  //      letter-spacing: 0.04em;
  //      color: rgba(133, 86, 148, 0.78);
  //    }
  //
  //    &_btn {
  //      margin-left: auto;
  //      @include font(600, 12px, 150%);
  //      letter-spacing: 0.04em;
  //      color: rgba(28, 26, 26, 0.56);
  //    }
  //
  //    &_fake {
  //      margin-left: auto;
  //      min-height: 30px;
  //    }
  //  }
  //}
}
