@import '../../scss/mixin.scss';
@import '../../scss/variable.scss';

.header_block_text {
  font-style: normal;
  @include font(500, 16px, 19px);
  letter-spacing: -0.01em;
  color: #000000;
  margin: 30px 0 9px 0;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding-left: 1rem;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding-left: 1rem;
  }
}
.button_search_block_wButton {
  position: relative;
  align-items: center;
  height: auto;
  button {
    font-size: 18px;
    min-width: 250px;
    height: 62px;
  }
}

.button_search_block {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
  width: 100%;
  height: 36px;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 10%;
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-bottom: 10%;
  }
}

.arrow_add_file_block {
  @include display(flex, row, center, center);
  border: none;
  width: 60px;
  height: 62px;
  text-align: center;
  position: relative;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    margin-bottom: 1rem;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    margin-bottom: 1rem;
  }

  svg {
    z-index: 10;
    align-self: center;
    transition: all 1.2s ease-in-out;

    &:hover {
      transform: rotate(360deg);
    }
  }
}
.add_students_btn {
  @include display(flex, row, space-around, center);
  @include font(500, 12px);
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  border: none;
  border-radius: 7px;
  background-color: #357EEB;
  padding: 0 13px;
  height: 60%;
  margin-left: 1em;
  height: 35px;
  white-space: nowrap;
  &:hover:not(:disabled) {
      color: #fff;
      background: #357EEB
    }
}

.property_1 {
  height: 62px;
  width: 1360px;
  padding: 0px;

  .sorting {
    background-color: #ffffff;
    height: 62px;
    width: 1360px;
    filter: drop-shadow(0px 0px 8px rgba(0.19607843458652496, 0.2549019753932953, 0.5843137502670288, 0.30000001192092896));
    border-radius: 14px;
    padding-left: 48px;
    padding-right: 48px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .frame {
      height: 62px;
      width: 1264px;
      align-self: flex-start;
      left: 48px;
      top: 0px;
      position: absolute;
      padding-top: 16px;
      padding-bottom: 16px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;

      .search_icon {
        height: 30px;
        width: 30px;
        padding: 0px;
        border-style: hidden;
        outline: none;
      }

      .search_filter_icon {
        height: 62px;
        width: 64px;
        padding: 0px;
        border-style: hidden;
        outline: none;

        &.active {
          background-color: #cfe2ff;
          border-radius: 4px;
          padding: 5px;
        }
      }
    }
  }
}

.searchFieldBlock {
  position: relative;
  width: 100%;
}

.filter_button {
  z-index: 50;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

.searchBlock {
  @include display(flex, row, center, flex-start);
  gap: 1rem;
  width: 100%;
  flex-wrap: nowrap;
}
.svgSettingsWrapper {
  display: flex;
  align-items: center;
  height: 62px;
  justify-content: center;
  background-color: transparent;
  border: none;
  color: #000000;
  flex-shrink: 0;
  text-align: center;
  position: relative;
  flex-direction: row;
  &:hover {
    color: #000000;
    cursor: pointer;
    background-color: transparent
  }
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    margin-bottom: 1rem;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    margin-bottom: 1rem;
  }
  svg {
    margin: auto 0;
  }
}
