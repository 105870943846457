@import '../../../scss/mixin.scss';

.wrapper {
  width: 400px;
  max-width: 400px;
  height: 300px;
  position: relative;
  background: #357eeb;
  border-radius: 24px;
  margin-top: 2rem;
  transition: all ease-in 0.2s;

  @media screen and (max-width: 550px) {
    height: 98px;
    width: calc(92vw - 2rem);
  }

  &:hover {
    background: linear-gradient(90deg, #0d28bb 0%, #357eeb 100%);

    .wrapper_mainImage {
      width: 229px;
      height: 201px;
    }
    .wrapper_secondaryImage {
      display: block;

      @media screen and (max-width: 550px) {
        display: none;
      }
    }
  }

  &_mainImage {
    position: absolute;
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 211px;
    height: 185px;
    object-fit: contain;
    z-index: 2;

    @media screen and (max-width: 550px) {
      display: none;
    }
  }

  &_secondaryImage {
    position: absolute;
    top: 20px;
    left: 50%;
    width: 302px;
    height: 201px;
    transform: translateX(-50%);
    display: none;
    z-index: 1;
  }

  &_schoolBlock {
    position: absolute;
    width: 308px;
    height: 80px;
    left: 50%;
    bottom: 46px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      top: 50%;
      bottom: 0;
      transform: translate(-50%, -50%);
      width: calc(82vw - 1rem);
    }

    @media only screen and (min-width: 375px) and (max-width: 550px) {
      top: 50%;
      bottom: 0;
      transform: translate(-50%, -50%);
    }

    &_content {
      display: flex;
      gap: 1rem;

      &_logoSchool {
        width: 80px;
        height: 80px;
        border-radius: 24px;
        background-color: azure;
        object-fit: contain;
        padding: 5px;
        object-position: center;
      }

      &_text {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
        align-items: flex-start;
        color: white;
        max-width: 180px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &_role {
          font-family: 'SFPRORegular', sans-serif;
          @include font(600, 24px, 28.64px);
          letter-spacing: -1px;
          text-align: left;

          @media screen and (max-width: 550px) {
            @include font(600, 20px, 24px);
          }
        }

        &_name {
          font-family: 'SFPRORegular', sans-serif;
          @include font(600, 18px, 23.87px);
          text-align: left;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;

          @media screen and (max-width: 550px) {
            @include font(600, 16px, 16px);
          }
        }
      }
    }
  }
}
