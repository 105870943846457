@import '../../scss/variable';
@import '../../scss/mixin.scss';

.container {
  position: relative;
  width: 100%;
  height: 100dvh;
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.8)), url('../../assets/mobileImg/bg1.jpg') no-repeat center center;
  background-size: cover;
  font-family: 'SFPRORegular', sans-serif;
  padding: 0 20px;

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 0 0;
    width: 100vw;
    padding-top: 10px;
    overflow-y: auto;
  }

  @media only screen and (min-width: 481px) and (max-width: 1024px) {
    padding-top: 10px;
    width: 100vw;
    padding: 0 0;
    overflow-y: auto;
  }
}

.initPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  padding: min(250px, 25vh) 1rem;
  position: relative;

  &_logo {
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
    height: 175px;
    width: 300px;
    padding: 1rem;
    background-color: #357eeb16;
    border-radius: 20%;
    object-fit: contain;
    box-shadow: 0px 0px 25px 0px #357eeb;
    backdrop-filter: blur(35px);
  }

  & h1 {
    color: #ffffff;
    @include font(800, 1.5em);
    // margin: 112px 0 128px 0;
    // @media only screen and (min-width: 320px) and (max-width: 375px) {
    //   margin: 20px 0 0 0;
    //   padding: 0 25px 0 25px;
    //   font-size: 14px;
    //   display: flex;
    // }
    // @media only screen and (min-width: 375px) and (max-width: 480px) {
    //   margin: 20px 0 0 0;
    //   padding: 0 25px 0 25px;
    //   font-size: 16px;
    //   display: flex;
    // }
    // @media only screen and (min-width: 481px) and (max-width: 1024px) {
    //   font-size: 46px;
    //   margin: 20px 0 2% 0;
    //   font-size: 20px;
    // }
  }

  &_text {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 228px;
    gap: 30px;
    margin: 0 auto;
    text-align: center;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      width: 100%;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      width: 100%;
    }
    @media (min-width: 481px) and (max-width: 1024px) {
      //padding-top: 70px;
      width: 100%;
    }

    & p {
      @include font(600, 16px);
      color: #ffffff;
      margin: 0 0 80px 0;
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        margin: 1rem 0 2rem 0;
        font-size: 12px;
      }
      @media (min-width: 481px) and (max-width: 1024px) {
        font-size: 28px;
      }
    }

    &_rose {
      color: $font-color-rose;
    }

    & button {
      // margin-left: -20px;
      height: 65px;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        @include font(600, 16px, 15px);
        width: 100%;
        margin-left: 0;
        padding: 25px 13px;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        @include font(600, 19px, 15px);
        width: 100%;
        margin-left: 0;
        padding: 25px 13px;
        margin-bottom: 15px;
      }
      @media (min-width: 481px) and (max-width: 1024px) {
        margin-bottom: 5%;
      }
    }
  }
}

.help {
  font-size: 18px;
  color: #ffffff;
}
