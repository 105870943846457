@import 'src/scss/variable';
@import '../../../../scss/mixin.scss';

.inputDefault {
  @include display(flex, row, flex-start, center);
  width: 100%;
  border: 1px solid #332f36;
  gap: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  font-family: 'SFPRORegular', sans-serif;
  padding: 0.5rem 1rem;
  transition: all 0.2s;
  position: relative;
  overflow: hidden;
  // margin-bottom: 1rem;

  & input:focus {
    background-color: inherit;
  }

  &:has(input:focus) {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #357eeb;
  }

  &:hover {
    border-color: #357eeb;
    // background-color: #ebebeb;

    & input ::placeholder {
      color: #332f36;
    }
  }

  &:focus {
    border-color: #357eeb;
    // background-color: #ebebeb;
  }

  & input {
    width: 100%;
    color: #332f36;
    outline: none;
    border: none;
    background-color: transparent;
    @include font(400, 20px);
    z-index: 3;

    &:focus {
      color: #357eeb;
      &::placeholder {
        color: #332f36;
      }
    }
  }

  & input ::placeholder {
    color: #808080;
  }
}

.input_container {
  width: 100%;

  &_textFieldLabel {
    display: inline-block;
    margin-bottom: 8px;
    color: $font-color;
    font-weight: 400;
    font-family: 'SFPRORegular', sans-serif;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      width: 95%;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      width: 95%;
    }
  }
}

.error {
  border: 1px solid red !important;
}

.inputPhone {
  @include display(flex, row, flex-start, center);
  width: 100%;
  border: 1px solid #332f36;
  gap: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  font-family: 'SFPRORegular', sans-serif;
  padding: 0.5rem 1rem;
  transition: all 0.2s;
  position: relative;
  // margin-bottom: 1rem;
  z-index: 10;

  div {
    font-size: 20px;
    font-family: 'SFPRORegular', sans-serif;

    div {
      border: none !important;
      background-color: transparent !important;

      ul {
        z-index: 9999 !important;
        border-radius: 10px !important;
      }
    }
  }

  & input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: #357eeb;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-background-clip: text;
  }

  & input:focus {
    background-color: inherit;
  }

  &:has(input:focus) {
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid #357eeb;
  }

  &:hover {
    border-color: #357eeb;
    // background-color: #ebebeb;

    & input ::placeholder {
      color: #332f36;
    }
  }

  &:focus {
    border-color: #357eeb;
    // background-color: #ebebeb;
  }

  & input {
    width: 100%;
    color: #332f36;
    outline: none;
    border: none;
    background-color: transparent;
    @include font(400, 20px);

    &:focus {
      color: #357eeb;
      &::placeholder {
        color: #332f36;
      }
    }
  }

  & input ::placeholder {
    color: #808080;
  }
}
