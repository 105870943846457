.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999999;
  }
  
  .modal-content {
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    height: 40%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

    @media only screen and (min-width : 2400px) and (max-width : 3500px) {
      width: 40%;
      height: 29%;
    }
    @media only screen and (min-width : 2100px) and (max-width : 2400px) {
        width: 40%;
        height: 38%;
      }
    @media only screen and (min-width : 2000px) and (max-width : 2100px) {
        width: 40%;
        height: 43%;
      }
    @media only screen and (min-width : 1900px) and (max-width : 2000px) {
        width: 40%;
        height: 46%;
      }
    @media only screen and (min-width : 1600px) and (max-width : 1900px) {
        width: 40%;
        height: 51%;
      }
    @media only screen and (min-width : 1400px) and (max-width : 1600px) {
        width: 40%;
        height: 56%;
      }
      @media only screen and (min-width : 1300px) and (max-width : 1400px) {
        width: 40%;
        height: 62%;
      }
      @media only screen and (min-width : 100px) and (max-width : 1300px) {
        width: 40%;
        height: 70%;
      }
}

.closeButton {
  outline: none;
  border: none;
  color: #cd58eb;
  position: absolute;
  right: 4%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f1f1;
  border-radius: 5px;
  width: 15px;
  height: 15px;
  cursor: pointer;
  transition: 0.3s color;
  z-index: 999;
  background: none;
}

  .modal-content h3 {
    margin-bottom: 20px;
    text-align: center;
  }

  .label-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .modal-content input {
    padding: 7px;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .modal-content p {
    margin-bottom: 10px;
  }


