@import 'src/scss/variable';
@import '../../scss/mixin.scss';

.profile {
  position: relative;
  @include display(flex);
  justify-content: space-evenly;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    @include display(flex, column, flex-start, center);
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    @include display(flex, column, flex-start, center);
  }

  &_loader {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.649);
    z-index: 9999;
  }

  &_rating {
    //font-weight: bold;
    //font-size: 20px;
    //line-height: 19px;
    //letter-spacing: -0.02em;
    //margin-bottom: 20px;
    width: fit-content;
    color: #4d5766;
    font-size: 12px;
    letter-spacing: 0.01em;
    background-color: #e8d5ec;
    border-radius: 10px;
    float: right;
    padding: 14px 14px 14px 14px;
    box-shadow: $box-shadow;

    &_top {
      margin-bottom: 5px;
    }
  }

  &_title {
    font-weight: bold;
    font-size: 25px;
    line-height: 19px;
    letter-spacing: -0.02em;
    margin-bottom: 10px;
    color: #111111;
  }

  &_block_title {
    font-weight: 700;
    font-size: 16px;
    line-height: 17px;
    letter-spacing: -0.02em;
    color: #4d5766;
    margin-bottom: 20px;
  }

  &_block_title:last-child {
    margin-bottom: 16px;
  }
  &_block_btn {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    min-width: 90px;
    letter-spacing: 0.05em;
    color: #fdfcff;
    padding: 11px 14px;
    border-radius: 7px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
  }

  &_block {
    margin-bottom: 10px;

    &_select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      cursor: pointer;
      background: url(../../assets/img/common/profile_select_arr.svg) no-repeat;
      background-position: calc(100% - 13px) center !important;
      border: 1px solid #e5e7eb;
      border-radius: 5px;
      width: 100%;
      height: 35px;
      display: flex;
      align-items: center;
      padding-left: 14px;
      outline: none;
      &:required:invalid {
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.03em;
        color: #d2d7dc;
      }

      option[value=''][disabled] {
        display: none;
      }
      option {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding: 11px 0;
        cursor: pointer;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      label {
        @include font(400, 10px, 12px);
        margin-top: 16px;
      }
      input {
        @include font(400, 8px, 10px);
      }
    }

    &_avatarBlock {
      position: relative;
      height: 110px;

      &_title {
        font-weight: 400;
        color: $font-color;
        display: inline-block;
        margin-bottom: 10px;
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          @include font(400, 10px, 12px);
        }
      }

      &_avatar {
        @include position_t_l(absolute, 28px, 10px, 1);
        width: 84px;
        height: 84px;
        border-radius: 7px;
        background: #f7f5f7 url('../../assets/img/common/noAvatar.svg') no-repeat center center;
      }

      &_input {
        @include position_t_l(absolute, 28px, 10px, 2);
        height: 90px;
        width: 90px;
        cursor: pointer;
        opacity: 0;
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          @include position_t_l(absolute, 0, 0, 2);
          width: 49px;
          height: 49px;
          border-radius: 50%;
          @include font(400, 8px, 10px);
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          @include position_t_l(absolute, 0, 0, 2);
          width: 65px;
          height: 65px;
          border-radius: 50%;
        }
      }
    }

    &_textArea {
      width: 100%;
      display: block;
      outline: none;
      border: 1px solid #e5e7eb;
      border-radius: 5px;
      padding: 11px 43px 14px 14px;
      resize: none;
      margin-top: 10px;
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        width: 100%;
        @include font(500, 8px, 10px);
      }

      & ::placeholder {
        color: #d2d7dc;
      }
    }
  }
}

.modal {
  @include position_t_l(absolute, 50%, 50%, 10);
  @include display(flex, column);
  width: 558px;
  background: $color-white;
  font-family: 'Manrope', sans-serif;
  margin: 0 auto;
  border-radius: 7px;
  transform: translate(-50%, -50%);
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 100%;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    width: 100%;
  }

  & form {
    padding-top: 60px;
    margin-bottom: 0;
  }

  &_closed {
    @include position_t_r(absolute, 17px, 16px);
    background: #f1f1f1;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e0dced;

    &:hover {
      color: #4d5766;
      transition: color 0.3s;
    }
  }
}



.container_notification_toggle {
  max-width: 100%;
  padding: 10px 10px;
  background-color: #ffffff;
  border-radius: 10px;
  transition: background 2s;

}

.container_notification_toggle[data-isOn='true'] {
  justify-content: flex-end;
  background-color: #357EEB;
}




.container {
  max-width: 100%;
  padding: 15px 44px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: $box-shadow;
  margin-bottom: 15px;
  margin-top: 15px;
  //margin-left: 3px;
  //margin-right: 3px;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 100%;
    button {
      width: 105px;
      height: 27px;
      border-radius: 5px;
      @include font(600, 10px, 0);
    }
  }
  &_error {
    margin-top: 10px;
    color: red;
    font-size: 11px;
  }

  &_wrapper {
    margin-top: 16px;
    //margin-right: 48px;

  }
  & input::placeholder {
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.05em;
    color: #d2d7dc;
  }
}

.forms_wrapper {
  display: flex;
  flex-direction: column;
}

.notification {
  max-width: 100%;
  margin-top: 2px;
  padding: 26px 16px 0 36px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: $box-shadow;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 100%;
  }

  &_toggleWrapper {
    @include display(flex, column);

    &_toggleBlock {
      @include display(flex, row, space-between, flex-start);
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        margin: 20px 0 0 0;
      }

      &_text {
        max-width: 85%;
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          margin: 0 20px 0 0;
          width: 100%;
        }

        &_header {
          color: $font-color;
          display: inline-block;
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          @media only screen and (min-width: 320px) and (max-width: 375px) {
            @include font(500, 10px, 12px);
          }
        }

        &_desc {
          color: #a8abad;
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;
          display: inline-block;
          margin-bottom: 24px;
          @media only screen and (min-width: 320px) and (max-width: 375px) {
            @include font(500, 8px, 10px);
          }
        }
      }
      &_checkboxWrapper {
        &:first-child {
          @media only screen and (min-width: 320px) and (max-width: 375px) {
            div {
              margin: 10px;
            }
          }
        }
      }
    }
  }
}

h5 {
  font-size: 14px;
  font-weight: 500;
  color: $font-title;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    @include font(600, 12px, 14px);
    margin: 0 0 20px 0;
  }
}

.bg {
  z-index: -1;

  &_wrap1 {
    position: absolute;
    z-index: -1;
    width: 50px;
    height: 50px;
    background: #97C1FF;
    border-radius: 50%;
    scale: 10;
    filter: blur(25px);
    animation: bgAnimate1 10s linear infinite;
  }

  &_wrap2 {
    z-index: -1;
    position: absolute;
    width: 50px;
    height: 50px;
    left: 100%;
    top: 100%;
    scale: 8;
    background: #97C1FF;
    border-radius: 50%;
    filter: blur(25px);
    animation: bgAnimate2 10s linear infinite;
  }

  &_wrap3 {
    z-index: -1;
    position: absolute;
    width: 50px;
    height: 50px;
    left: 100%;
    top: 0%;
    scale: 8;
    background: #97C1FF;
    border-radius: 50%;
    filter: blur(25px);
    animation: bgAnimate3 10s linear infinite;
  }

  &_wrap4 {
    z-index: -1;
    position: absolute;
    width: 50px;
    height: 50px;
    scale: 6;
    left: 0%;
    top: 100%;
    background: #97C1FF;
    border-radius: 50%;
    filter: blur(25px);
    animation: bgAnimate4 10s linear infinite;
  }
}
