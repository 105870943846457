@import '../../../scss/mixin.scss';

.modal_btn_is_toggle {
  position: relative;
  display: inline-block;
  margin: 40px auto 0;
  max-width: max-content;
  background: transparent;
  border: none;
  color: #a1a6af;
  span {
    @include position_t_l(absolute, 0, -25px);
  }
}
.arrow_rotate {
  transform: rotate(180deg);
}
