@import 'src/scss/variable';
@import '../../scss/mixin.scss';

.redactorCourse {
  font-family: $font-family-inter;
  @include display(flex);

  &_wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    box-shadow: 0px 0px 8px 0px #3241954d;
    padding: 1rem;
    border-radius: 20px;
    margin: 0 3em;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      flex-direction: column;
      padding-bottom: 2rem;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      flex-direction: column;
      padding-bottom: 2rem;
    }
  }
  &_rightSide {
    background: #ffffff;
    padding: 36px 45px 56px 45px;
    border-radius: 10px;
    width: 100%;
    box-shadow: $box-shadow;

    &_functional {
      margin-top: 70px;

      &_addContent {
        position: relative;
        @include display(flex, column, center, center);
        // width: 50%;
        width: 100%;
        background: #ffffff;
        text-align: center;
        height: 100%;
        // border: 2px dashed #ebeef5;
        border-radius: 7px;
        margin: 1rem;

        & input {
          @include position_t_l(absolute, 0, 0, 2);
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }

        &_error {
          //display: block;
          font-size: 12px;
          color: rgba(224, 24, 24, 0.99);
          //margin-left: 45px;
        }

        &_navBlock {
          @include display(flex, column);

          &_div {
            @include display(flex, row, center, center);
            background: #f3f4f6;
            border: none;
            border-radius: 7px;
            width: 36px;
            height: 36px;
            text-align: center;
            margin-bottom: 8px;
            cursor: pointer;
          }

          &_delete {
            @include display(flex, row, flex-start, center);
            margin-left: 0.4em;
            margin-right: 0.3em;
            padding: 11px;
            border-radius: 0.5em;
            border: none;
            height: 36px;
            background: #fef2f2;
            text-align: center;
            opacity: 0.6;
            cursor: pointer;

            & svg {
              margin: 0 auto;
              height: 17px;
              width: 17px;
            }

            &:hover {
              background: #ffbdbd;
            }
          }
        }
        & span {
          margin-bottom: 15px;
          @include font(600, 16px);
          color: $font-title;
        }
        & button {
          margin-top: 15px;
          font-size: 16px;
          font-weight: 500;
          padding: 10px 40px;
        }
      }
    }
  }
}

.text {
  font-family: "SFPRORegular";
  font-size: 24px;
  font-weight: 500;
  line-height: 28.64px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
