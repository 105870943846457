@import '../../../scss/mixin.scss';

.accardionWrapper {
  &_component {
    @include display(flex, column, center);
    background-color: #fff;
    //box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    //border-radius: 7px;
    margin-bottom: 10px;
    transition: all 0.3s;
    background-color: transparent;
    @media only screen and (min-width: 320px) and (max-width: 700px) {
      margin-bottom: 10px;
    }

    &:hover {
      cursor: pointer;
    }
    &_header {
      @include display(flex, row, flex-start, center);
      width: 100%;
      height: 80px;
      border-radius: 24px;
      padding: 15px 20px;
      background-color: #cfe2ff;
      color: #332f36;

      @media only screen and (min-width: 320px) and (max-width: 1000px) {
        padding: 21px 11px;
      }

      &_active {
        @include display(flex, row, flex-start, center);
        background-color: #357dea;
        color: #ffffff;
        width: 100%;
        height: 80px;
        border-radius: 24px;
        padding: 15px 20px;

        @media only screen and (min-width: 320px) and (max-width: 1000px) {
          padding: 21px 11px;
        }

        svg {
          @media only screen and (min-width: 320px) and (max-width: 375px) {
            width: 20px;
            height: 20px;
          }
        }
      }

      &_showBtnWrapper_active > svg {
        // fill: '#C6C6C6';
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          width: 12px;
          height: 12px;
        }

        @media only screen and (min-width: 375px) and (max-width: 480px) {
          width: 16px;
          height: 16px;
        }
      }

      &_showBtnWrapper > svg {
        // fill: '#4D5766';
        transform: rotate(0);
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
      }
      svg {
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          width: 12px;
          height: 12px;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          width: 15px;
          height: 15px;
        }
      }

      &_number {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        font-weight: 500;
        font-size: 24px;
        margin-right: 20px;
        color: #ffffff;
        background-color: #357dea;
        border-radius: 50%;
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          width: 25px;
          height: 25px;
          font-size: 10px;
          margin-right: 10px;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          width: 30px;
          height: 30px;
          font-size: 10px;
          margin-right: 10px;
        }
        @media only screen and (min-width: 481px) and (max-width: 700px) {
          width: 35px;
          height: 35px;
          margin-right: 10px;
        }
        @media only screen and (min-width: 701px) and (max-width: 1000px) {
          width: 40px;
          height: 40px;
          margin-right: 10px;
        }

        &_active {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          font-weight: 500;
          font-size: 24px;
          background-color: #ffffff;
          color: #332f36;
          border-radius: 50%;
          margin-right: 20px;

          @media only screen and (min-width: 320px) and (max-width: 375px) {
            width: 25px;
            height: 25px;
            font-size: 10px;
            margin-right: 10px;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            width: 30px;
            height: 30px;
            font-size: 10px;
            margin-right: 10px;
          }
          @media only screen and (min-width: 481px) and (max-width: 700px) {
            width: 35px;
            height: 35px;
            margin-right: 10px;
          }
          @media only screen and (min-width: 701px) and (max-width: 1000px) {
            width: 40px;
            height: 40px;
            margin-right: 10px;
          }
        }

        svg {
          @include position_t_l(absolute, calc(50% - 8px), calc(50% - 7px));
        }
      }
      &_marks {
        @include display(flex, row, flex-start, center);
        gap: 4px;
        font-size: 16px;
        margin-right: 10px;

        @media only screen and (max-width: 700px) {
          display: none;
        }
      }

      &_container {
        @include display(flex, row, center, center);
        gap: 4px;
        width: 100%;

        @media only screen and (max-width: 1000px) {
          @include display(flex, column, center, center);
        }
      }

      &_lessonName {
        @include display(flex, row, flex-start, center);
        width: 100%;
        text-align: center;

        &_title {
          @include display(flex, row, flex-start, center);
          @include font(500, 24px, 16px);
          @media only screen and (min-width: 320px) and (max-width: 375px) {
            font-size: 10px;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            font-size: 11px;
          }
          @media only screen and (min-width: 481px) and (max-width: 700px) {
            font-size: 15px;
          }
          @media only screen and (min-width: 701px) and (max-width: 1000px) {
            font-size: 18px;
          }
          @media only screen and (min-width: 1001px) and (max-width: 1500px) {
            font-size: 20px;
          }

          //span {
          //  display: block;
          //  width: 20px;
          //  height: 2px;
          //  margin: 0 10px;
          //  background-color: #9ca3af;
          //}
        }
        &_exerciseSum {
          @include font(500, 14px, 16px);
          color: #9ca3af;
          @media only screen and (min-width: 320px) and (max-width: 375px) {
            font-size: 10px;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            font-size: 10px;
          }
          span {
            margin-left: 5px;
          }
        }
      }
      &_stats {
        @include display(flex, row, flex-start, center);
        gap: 8px;
        font-size: 16px;

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          font-size: 11px;
          gap: 5px;
          align-self: self-start;
        }

        @media only screen and (min-width: 375px) and (max-width: 480px) {
          font-size: 14px;
          gap: 6px;
          align-self: self-start;
        }
        @media only screen and (min-width: 481px) and (max-width: 1000px) {
          font-size: 15px;
          align-self: self-start;
        }

        &_lesson {
          @include display(flex, row, flex-start, center);
          gap: 4px;
        }
      }
      &_status {
        margin: 0 30px;

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          width: 20px;
          height: 20px;
          min-width: 20px;
          min-height: 20px;
          font-size: 10px;
          margin: 0 8px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          width: 20px;
          height: 20px;
          min-width: 20px;
          min-height: 20px;
          font-size: 10px;
          margin: 0 8px;

          svg {
            width: 100%;
            height: 100%;
          }
        }
        @media only screen and (min-width: 481px) and (max-width: 700px) {
          margin: 0 10px;
        }
        @media only screen and (min-width: 701px) and (max-width: 1000px) {
          margin: 0 15px;
        }
      }
    }

    &_exerciseWrapper {
      //padding-bottom: 16px;
      @include display(flex, column, center, center);
      padding: 16px 0;
      gap: 16px;
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        padding: 10px 0;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        padding: 10px 0;
      }
    }

    &_exerciseWrapperImage {
      //@include display(flex, row, flex-start, flex-start);
      margin-top: 15px;
      display: grid;
      grid-template-columns: repeat(auto-fit, 166px);
      grid-template-rows: repeat(auto-fit, 190px);
      gap: 32px;
      @media only screen and (min-width: 481px) and (max-width: 800px) {
        padding: 10px 10px;
        grid-template-columns: repeat(2, 1fr);
      }
      @media only screen and (min-width: 320px) and (max-width: 480px) {
        padding: 10px 10px;
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
