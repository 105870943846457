@import '../../../scss/variable.scss';
@import url('https://fonts.googleapis.com/css2?family=Bad+Script&family=Tourney:ital,wght@0,100..900;1,100..900&display=swap');

@font-face {
  font-family: 'SFPROBold';
  src: url(../../../assets/fonts/SFProText/SFProText-Bold.ttf) format("opentype");
}

@font-face {
  font-family: 'SFPROMedium';
  src: url(../../../assets/fonts/SFProText/SFProText-Medium.ttf) format("opentype");
}

.game {
  width: 472px;
  height: 150px;
  margin: 30px 0;
  border-bottom: 1px solid #332f36;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 1473px) and (max-width: 1890px) {
    margin: 60px 0;
  }

  &_player {
    width: 80px;
    height: 68px;
    position: absolute;
    bottom: 0;
    left: 10%;
    background-image: url(../../../assets/img/technicalWorks/player-run.png);
    background-size: contain;
    background-position: bottom;
    cursor: pointer;
  }

  &_obstacle {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 70%;
    background-size: contain;
    background-image: url(../../../assets/img/technicalWorks/obstacle.png);
    visibility: hidden;
  }

  &_score {
    color: #332f36;
    font-family: 'SFPROMedium';
    font-size: 20px;
    align-self: flex-end;

    @media only screen and (min-width: 1500px) and (max-width: 1890px) {
      font-size: 25px;
    }

    span {
      margin-right: 20px;
    }
  }

  &_restart {
    visibility: hidden;
    position: absolute;
    background-color: $color-white;
    opacity: 0.4;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    z-index: 2;

    &_content {
      position: relative;
      display: flex;
      align-items: center;
      z-index: 5;
    }
  }

  &_start {
    padding: 5px;
    margin: 0 auto;
    background: rgba(255, 255, 255, 0.6);
    border: 1px solid $color-white;
    border-radius: 5px;

    &:hover {
      background: transparent;
      color: $color-white;
    }

    @media only screen and (min-width: 1500px) and (max-width: 1890px) {
      font-size: 22px;
    }
  }
}

@keyframes jump {
  0% {
    bottom: 0;
  }

  25% {
    bottom: 80px;
  }
  40% {
    bottom: 80px;
  }

  100% {
    bottom: 0;
  }
}

.game_player.jump {
  animation: jump 1.5s infinite;
  background-image: url(../../../assets/img/technicalWorks/player-jump.png);
}

.game_obstacle.move {
  visibility: visible;
  animation: move 3s linear infinite;
}

.game_restart.show {
  visibility: visible;
}

.stop.game,
.stop .game_obstacle {
  animation-play-state: paused;
}

.stop .game_player {
  background-image: url(../../../assets/img/technicalWorks/player-run.png);
}

@keyframes move {
  0% {
    left: 100%;
  }
  100% {
    left: -100px;
  }
}
