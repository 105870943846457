@import 'src/scss/variable';
@import '../../../../../../scss/mixin.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../../../../../assets/fonts/SFProText/SFProText-Regular.ttf) format('opentype');
}

.showBtn {
  color: rgb(173, 173, 173);

  &:hover {
    color: #ba75ff;
    opacity: 0.7;
  }
}

.redactorCourse {
  font-family: $font-family-inter;
  @include display(flex);
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
  }
  @media only screen and (min-width: 375px) and (max-width: 1100px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &_textField {
    margin-bottom: 40px;

    &_btnEditText {
      @include display(flex);

      &_setting {
        &:hover {
          background-color: rgba(196, 156, 210, 0.4);
        }

        @include display(flex, row, flex-start, center);
        margin-top: 10px;
        width: 100px;
        height: 36px;
        border: none;
        text-align: center;
        background: #f3f4f6;
        font-size: 14px;
        color: #6b728f;
        border-radius: 6px;
        padding: 11px 0 11px 16px;
      }
    }

    &_description {
      &_text {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 16px;
        font-family: 'Lucida Grande', 'Lucida Sans Unicode', Verdana, Helvetica, Arial, sans-serif;
      }
    }
  }

  &_leftSide {
    @include display(flex, column);
    padding: 0 45px 20px 0;
    border-radius: 8px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    align-items: center;
    background-color: $color-white;
    text-align: center;
    margin-bottom: 30px;
    max-width: 460px;
    width: 100%;
    background: transparent;

    @media screen and (max-width: 1919px) {
      max-width: 360px;
    }

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      text-align: center;
      padding: 0;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      text-align: center;
      margin: 0;
      padding: 0;
    }

    &_title {
      border-radius: 16px;
      padding: 11px;
      width: 100%;
      background-image: linear-gradient(90deg, #332f36 -5.88%, rgba(51, 47, 54, 0) 100%),
        url(../../../../../../assets/img/createCourse/course-background.jpeg);
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;
      z-index: 2;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 16px;
        background: rgba(51, 47, 54, 0.5);
        z-index: 3;
      }
      @media only screen and (min-width: 320px) and (max-width: 375px) {
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        padding: 5% 0 5% 0;
      }

      &_name {
        font-family: 'SFPRORegular';
        line-height: 28.64px;
        font-weight: 500;
        color: $color-white;
        font-size: 24px;
        text-align: start;
        position: relative;
        z-index: 4;
        padding: 5px;
      }
    }

    &_desc {
      @include display(flex, column);
      margin-top: 10px;
      width: 100%;
      height: 554px;
      padding: 0 3px 0 3px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 4px !important;
        display: block;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #357eeb;
        border-radius: 5px;
      }

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        height: 100%;
        overflow-y: hidden;
        margin-top: 10px;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        height: 100%;
        overflow-y: hidden;
        margin-top: 10px;
      }

      &_wrapper {
        @include display(flex, row, flex-start, center);
      }

      &_headerText {
        &_title {
          position: relative;
          @include display(flex, row, space-between, center);
          @include font(400, 14px);
          background-color: #cfe2ff;
          box-shadow: 0px 0px 4px 0px rgba(50, 65, 149, 0.3);
          padding: 2px 26px 2px 5px;
          border-radius: 10px;
          transition: 400ms ease-out;
          &:hover {
            cursor: pointer;
          }
        }

        &_inputWrapper {
          position: absolute;
          top: -7px;
          left: 30px;
          @include display(flex, row, space-between, center);
          height: 30px;
          width: 100%;
          @media only screen and (min-width: 320px) and (max-width: 375px) {
            display: none;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            display: none;
          }

          &:hover {
            z-index: 0;

            &_btn_delete {
              z-index: 10;
            }
          }

          &_input {
            background-color: transparent;
            color: $color-text;
            font-family: 'SFPRORegular';
            line-height: 21.48px;
            font-weight: 500;
            font-size: 18px;
            border: none;
            text-align: start;
            outline: none;
            width: 100%;
          }

          &_btn_delete {
            position: relative;
            z-index: -10;
            @include display(flex, row, center, center);
            border: none;
            background-color: transparent;
            cursor: pointer;
            transition: 400ms ease-out;
          }
        }
      }

      &_lessonWrapper {
        position: relative;
        z-index: 8;
        @include display(flex, row, space-between, center);
        list-style-type: none;
        margin-bottom: 10px;
        margin-left: 0px;
        padding: 4px 26px 4px 5px;
        cursor: pointer;
        border-radius: 10px;
        background-color: $color-white;
        border: none;
        box-shadow: 0px 0px 8px 0px rgba(50, 65, 149, 0.3);
        transition: 400ms ease-out;

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          margin: 2% 0 2% 0;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          margin: 2% 0 2% 0;
        }

        &:hover + .btn {
          box-shadow: 0px 0px 8px 0px rgba(50, 65, 149, 0.3);
          border: 1px solid #357eeb;
        }

        &_lesson {
          @include display(flex, row, flex-start, center);
          font-family: 'SFPRORegular';
          line-height: 19.09px;
          font-weight: 400;
          font-size: 15px;
          color: $color-text;
          text-align: center;

          svg {
            margin-right: 5px;
          }
        }

        &_btn_deleteLesson {
          position: relative;
          z-index: -10;
          @include display(flex, row, center, center);
          border: none;
          background-color: transparent;
          cursor: pointer;
          transition: 400ms ease-out;
        }

        &_btn_drag_and_drop {
          position: relative;
          flex: 0 0 auto;
          white-space: nowrap;
          z-index: -10;
          background-color: transparent;
        }

        &_btn_drag_and_drop_module {
          position: relative;
          flex: 0 0 auto;
          white-space: nowrap;
          background-color: transparent;
        }
      }
    }

    &_modul {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_classes {
      width: 236px;

      &_settings {
        position: relative;
        @include display(flex, row, right, center);
        margin: 25px 0;
        font-size: 14px;
        color: $font-title;
        text-align: center;
        cursor: pointer;

        &_drag {
          @include position_t_l(absolute, 0, -15px);

          svg {
            margin-right: 8px;
          }
        }

        &_panel {
          & svg {
            margin-left: 10px;
          }
        }
      }
    }
  }

  &_rightSideWrapper {
    width: 95%;
    min-width: 0;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      padding-top: 2rem;
      max-width: 100vw;
      width: 100%;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      padding-top: 2rem;
      max-width: 100vw;
      width: 100%;
    }

    &_rightSide {
      background: #ffffff;
      padding: 20px 49px;
      height: fit-content;
      border-radius: 20px;
      width: 100%;
      box-shadow: 0px 0px 18px 0px #3241954d;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        margin: 0 auto;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        box-shadow: none;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        margin: 0 auto;
        padding: 1rem 0;
        display: flex;
        flex-direction: column;
        box-shadow: none;
      }
      @media only screen and (min-width: 481px) and (max-width: 1400px) {
        padding: 20px;
      }

      &_nameBlock {
        display: flex;
        justify-content: space-between;
        margin: 5px 0 20px 0;

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          padding-right: 0;
          margin: 5px 0 0 0;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          padding-right: 0;
          margin: 5px 0 0 0;
        }
        @media only screen and (max-width: 1500px) {
          flex-direction: column-reverse;
          gap: 16px;
        }

        button {
          @include font(500, 16px, 19.09px);
          padding: 13px 40px;

          @media only screen and (min-width: 320px) and (max-width: 375px) {
            @include font(500, 12px, 14.32px);
            padding: 13px 15px;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            @include font(500, 12px, 14.32px);
            padding: 13px 15px;
          }
        }
      }

      &_review {
        display: flex;
        align-items: center;
      }

      &_header {
        @include display(flex, row, space-between, center);
        text-align: center;

        &_btnBlock {
          @include display(flex);
          width: 100%;

          &_setting {
            &:hover {
              background-color: rgba(190, 190, 190, 0.4);
            }

            @include display(flex, row, flex-start, center);
            width: 150px;
            height: 36px;
            border: none;
            text-align: center;
            background: #f3f4f6;
            font-size: 13px;
            color: #6b728f;
            border-radius: 6px;
            padding: 11px 0 11px 16px;

            & svg {
              margin-right: 5px;
            }

            input {
              @include position_t_l(absolute, 0, 0, -20);
            }
          }

          &_edit {
            &:hover {
              background-color: rgba(190, 190, 190, 0.4);
              border: solid 2px #ba75ff;
            }

            @include display(flex, row, flex-start, center);
            width: 210px;
            height: 36px;
            border: none;
            text-align: center;
            background: #f3f4f6;
            font-size: 12px;
            color: #6b728f;
            border-radius: 6px;
            padding: 11px 0 11px 12px;

            & svg {
              margin-right: 5px;
            }

            input {
              @include position_t_l(absolute, 0, 0, -20);
            }
          }

          &_save {
            &:hover {
              background-color: rgba(190, 190, 190, 0.4);
              border: solid 2px #ba75ff;
            }

            @include display(flex, row, flex-start, center);
            margin-left: 15px;
            width: 200px;
            height: 36px;
            border: none;
            text-align: center;
            background: #f3f4f6;
            font-size: 12px;
            color: #6b728f;
            border-radius: 6px;
            padding: 11px 0 11px 16px;

            & svg {
              margin-right: 5px;
            }

            input {
              @include position_t_l(absolute, 0, 0, -20);
            }
          }

          &_delete {
            &:hover {
              background-color: rgba(190, 190, 190, 0.4);
              border: solid 2px #ba75ff;
            }

            @include display(flex, row, flex-start, center);
            margin-left: 15px;
            // width: 200px;
            height: 36px;
            border: none;
            text-align: center;
            background: #f3f4f6;
            font-size: 13px;
            color: #6b728f;
            border-radius: 6px;
            padding: 5px 5px 5px 5px;

            // & svg {
            //   margin-right: 5px;
            // }

            input {
              @include position_t_l(absolute, 0, 0, -20);
            }
          }
        }
      }

      &_title {
        @include font(500, 20px, 23.87px);
        color: #000000;
        font-family: 'SFPRORegular';

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          margin-left: 0;
          @include font(500, 16px, 19.08px);
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          margin-left: 0;
          @include font(500, 16px, 19.08px);
        }
      }

      &_blockAll {
        display: flex;
        align-items: center;

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          flex-direction: column;
          padding: 0 0 5% 0;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          flex-direction: column;
          padding: 0 0 5% 0;
        }
        @media only screen and (max-width: 1500px) {
          flex-direction: column-reverse;
          gap: 16px;
        }
      }

      &_block {
        display: flex;
        width: auto;
        flex-wrap: nowrap;
        align-items: center;
        position: relative;

        @media only screen and (max-width: 1500px) {
          align-self: center;
        }

        &_edit {
          @include font(500, 12px, 14.32px);
          color: $color-text;
          font-family: 'SFPRORegular';
          position: absolute;
          top: 0;
          left: 100%;
          cursor: pointer;

          @media only screen and (min-width: 320px) and (max-width: 375px) {
            @include font(500, 9px, 11.32px);
            left: 101%;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            @include font(500, 9px, 11.32px);
            left: 101%;
          }

          &:hover {
            text-decoration: underline;
          }
        }

        // @media only screen and (min-width: 320px) and (max-width: 375px) {
        //   margin-bottom: 10%;
        //   padding-left: 15px;
        //   padding-right: 15px;
        // }
        // @media only screen and (min-width: 375px) and (max-width: 480px) {
        //   margin-bottom: 10%;
        //   padding-left: 15px;
        //   padding-right: 15px;
        // }

        &_nameSettings {
          @include font(700, 28px, 33.41px);
          color: $color-blue;
          font-family: 'SFPRORegular';
          letter-spacing: 0.01em;

          @media only screen and (min-width: 320px) and (max-width: 375px) {
            margin-right: 0;
            @include font(700, 16px, 19.08px);
            color: $color-text;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            margin-right: 0;
            @include font(700, 16px, 19.08px);
            color: $color-text;
          }
        }
      }

      &_functional {
        &_container {
          display: flex;
          flex-direction: column;
          gap: 40px;
          // padding-inline: 3em;

          @media only screen and (min-width: 320px) and (max-width: 375px) {
            padding-inline: 0;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            padding-inline: 0;
          }
        }

        &_content {
          @include display(flex, row, space-between);
          margin-bottom: 37px;
          margin-top: 20px;

          @media only screen and (min-width: 320px) and (max-width: 375px) {
            padding: 0 0 10% 0;
            flex-direction: column;
            gap: 15px;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            padding: 0 0 10% 0;
            flex-direction: column;
            gap: 15px;
          }

          &_preview {
            color: $font-color-rose;
            font-size: 16px;
            margin-right: 11px;
          }
        }

        &_form {
          &_title {
            @include font(500, 16px, 19px);
            margin-bottom: 0;
            color: #4d5766;
            margin-left: 45px;
          }
        }
      }

      &_desc {
        display: block;
        margin: 8px 0 15px;
        font-size: 12px;
        color: #9f9f9f;
        margin-left: 45px;
        padding-bottom: 10px;
      }
      &_error {
        display: block;
        font-size: 12px;
        color: rgba(224, 24, 24, 0.67);
        margin-left: 45px;
        padding-bottom: 10px;
      }
    }
  }
}

.newLesson {
  background-color: #dcdcdc; // цвет фона для подсвеченного урока
  border-radius: 8px; // скругление углов
}

.hl {
  width: 256px;
  border-bottom: 1px solid #d9d9d9;
  margin: 24px 0 18px -10px;
}

.btn {
  margin: 10px auto;
  background: #357eeb;
  padding: 10px 15px;
  border: none;
  border-radius: 40px;
  color: $color-white;
  font-family: 'SFPRORegular';
  line-height: 19.09px;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  z-index: 10;

  &:hover {
    background: #357eeb !important;
  }
}

.btnModule {
  background: #e0dced;
  color: $font-color-rose;

  &:hover {
    color: #e0dced;
  }
}

hr {
  margin-bottom: 18px;
}

.coursePreviewHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &_text_block {
    margin: 20px 0 0 30px;

    &_published {
      @include display(flex, row, flex-end);
      justify-content: space-around;
      font-style: italic;
      font-weight: bold;
      color: #acce6e;

      svg {
        margin-right: 2px;
      }
    }

    &_notPublished {
      @include display(flex, row, flex-end);
      justify-content: space-around;
      font-style: italic;
      font-weight: bold;
      color: #a8444c;

      svg {
        margin-right: 2px;
      }
    }
  }
}

.coursePreviewHeaderRedactor {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 5px;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 5px;
  }

  &_text_block {
    margin: 20px 0 0 30px;
    @include display(flex, flex-end);
    color: #e0dced;
    font-style: normal;
    @include font(500, 11px, 13px);

    svg {
      margin-right: 10px;
    }
  }
}

.selectedLesson {
  background-color: #357eeb;
  border-radius: 8px;
  color: $color-white;
}

.check_autotest {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 5px;

  &_title {
    color: $color-blue;
    @include font(400, 14px, 100%);
    letter-spacing: 0.04em;
    margin-left: 10px;
    font-family: 'SFPRORegular';
  }

  span {
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include font(500, 14px, 16.8px);
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include font(500, 14px, 16.8px);
    }
  }
}

.timeLimit {
  width: 100%;
  border-radius: 5px;
  margin: 52px 0 30px 0;
  display: flex;
  align-items: center;
  background: transparent;
  max-width: 453px;
  width: 100%;
  justify-content: space-between;

  label {
    font-family: 'SFPRORegular';
    @include font(500, 16px, 19.09px);
    color: $color-text;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include font(500, 14px, 16.8px);
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include font(500, 14px, 16.8px);
    }
  }

  &_input {
    line-height: normal;
    padding: 6px 10px;
    border: 1px solid $color-grey;
    color: $color-blue;
    background: transparent;
    border-radius: 10px;
    text-overflow: ellipsis;
    max-width: 65px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      border-radius: 5px;
      padding: 0 10px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      border-radius: 5px;
      padding: 0 10px;
    }

    & input {
      border: none;
      width: 100%;
      background: transparent;
      text-align: center;
      color: $color-blue;
      font-family: 'SFPRORegular';
      @include font(700, 16px, 19.09px);

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        @include font(500, 14px, 16.8px);
        height: 27px;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        @include font(500, 14px, 16.8px);
        height: 27px;
      }
    }
  }
}

.autogeneration {
  min-width: 300px;
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 8px 0px #3241954d;
  background: linear-gradient(
    140deg,
    #c2e0ff 0%,
    #c2e0ff 11%,
    #d6eaff 16%,
    #e5f2ff 25%,
    #fafcff 57%,
    #fafcff 82%,
    #e5f2ff 89%,
    #d6eaff 93%,
    #c2e0ff 100%
  );
  margin: 20px auto;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    background: #ffffff;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    background: #ffffff;
  }

  &_num {
    @include display(flex, row, space-between, center);

    & input {
      line-height: normal;
      padding: 6px 10px;
      border: 1px solid $color-grey;
      color: $color-blue;
      background: transparent;
      border-radius: 10px;
      text-overflow: ellipsis;
      width: 50px;
    }
    & label {
      color: $color-text;
      font-family: 'SFPRORegular';
      @include font(500, 16px, 19.09px);

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        @include font(500, 14px, 16.8px);
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        @include font(500, 14px, 16.8px);
      }
    }
  }

  &_select {
    padding: 10px 0;
    color: $color-text;
    font-family: 'SFPRORegular';
    @include font(500, 16px, 19.09px);

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include font(500, 14px, 16.8px);
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include font(500, 14px, 16.8px);
    }
  }

  &_error {
    margin-left: 20px;
    padding-top: 20px;
    color: #f50723;
    font-size: 13px;
  }

  &_test {
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: 7px;
    background: #f5f5f5;

    &_name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
      margin-left: 0.75rem;
      text-align: left;
      @include font(500, 12px, 150%);
      color: $color-text;
      letter-spacing: -0.05em;
    }

    input:checked ~ span {
      border: none;
    }
  }

  &_tests_block {
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
  }

  &_item {
    margin-top: 1.25rem;

    &:first-child {
      margin-top: 0;
    }

    &_name {
      @include font(600, 12px, 150%);
      color: $font-title;
      text-align: left;
    }
  }
}

.no_autogeneration {
  margin-left: 40px;
  margin-top: 30px;
  color: #9ca3af;
  font-size: 14px;
  letter-spacing: 0.06em;
}

.publickButton {
  @include display(flex, row, space-between, center);

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    @include display(flex, column, space-between, center);
    margin-left: 0;
    gap: 2px;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    @include display(flex, column, space-between, center);
    margin-left: 0;
    gap: 2px;
  }
}

.showLessons {
  background-color: #357eeb;
  box-shadow: 0px 0px 4px 0px rgba(50, 65, 149, 0.3);
  color: #ffffff;
}

.showLessons:hover {
  border: 1px solid #324195;
}

.showLessonsInput {
  color: #ffffff;
}

.lesson_buttons {
  display: flex;
  gap: 8px;
}

.openEye {
  background-color: #c6c6c6;
  color: #808080;
  cursor: pointer;
}

.lesson_buttons_visible {
  z-index: 10;
}

.lessonTypeIcon {
  margin-top: 3px;
  margin-left: 13px;
}

.fillColorWhite path {
  fill: $color-white;
}

.fillColorGrey path {
  fill: #808080;
}

.strokeColorGrey path {
  stroke: #808080;
}

.strokeColorWhite path {
  stroke: $color-white;
}

.doBlockIcon {
  cursor: grab;
  margin-top: 4px;
  opacity: 1;
}

.unvisible {
  position: relative;
  z-index: -10;
}

.heightTransition {
  margin-bottom: 10px;
  max-height: 42px;
  transition: max-height 400ms ease-out;
  &:hover {
    max-height: 103px;
    transition: max-height 400ms ease-out;
  }
}

.publicBlockEdit {
  display: flex;
  align-items: center;
  gap: 20px;

  @media only screen and (max-width: 1500px) {
    align-self: flex-end;
  }

  button {
    @include font(500, 16px, 19.09px);
    padding: 13px 40px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include font(500, 12px, 14.32px);
      padding: 13px 15px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include font(500, 12px, 14.32px);
      padding: 13px 15px;
    }
  }

  &_mark {
    div {
      div {
        background-color: transparent;
        color: $color-blue;
        @include font(500, 16px, 19.09px);
        font-family: 'SFPRORegular';

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          @include font(500, 12px, 14.32px);
          height: 14px;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          @include font(500, 12px, 14.32px);
          height: 14px;
        }

        span {
          padding-right: 5px;
          padding-left: 0;
        }
      }
    }
  }

  &_publish_switch {
    div {
      width: 40px;
      height: 17px;
      background: #e3e3e3;
      border: none;

      &[data-isOn='true'] {
        background: transparent;
        border: 1px solid rgba(53, 126, 235, 1);

        div {
          &::before {
            background: rgba(53, 126, 235, 1);
            border: none;
          }
        }
      }

      div {
        width: 1rem;
        height: 1rem;

        &::before {
          width: 1rem;
          height: 1rem;
          background: #bebebe;
          border: none;
        }
      }
    }
  }
}

.unpublished {
  div {
    div {
      color: #bebebe;
    }
  }
}

.wrapper_preview {
  @include display(flex, column);
  background-color: #cfe2ff;
  padding: 20px;
  border-radius: 40px;
  max-width: 881px;
  width: 100%;
  margin: 0 auto 20px;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    border-radius: 30px;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    border-radius: 30px;
  }

  &_test {
    background: url(../../../../../../assets/img/common/questions.png),
      linear-gradient(
        140deg, 
        #c2e0ff 0%,
        #c2e0ff 11%,
        #d6eaff 16%,
        #e5f2ff 25%,
        #fafcff 57%,
        #fafcff 82%,
        #e5f2ff 89%,
        #d6eaff 93%,
        #c2e0ff 100%
      );
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    box-shadow: 0px 0px 8px 0px #3241954d;
    border-radius: 20px;
    min-height: 409px;
    max-width: 841px;
    width: 100%;
    padding: 21px;
    display: flex;
    gap: 10px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      border-radius: 24px;
      background: url(../../../../../../assets/img/common/questions-mobile.png);
      background-repeat: no-repeat;
      background-position: right;
      background-size: contain;
      background-color: #ffffff;
      padding: 30px 19px 14px 19px;
      min-height: 200px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      border-radius: 24px;
      background: url(../../../../../../assets/img/common/questions-mobile.png);
      background-repeat: no-repeat;
      background-position: right;
      background-size: contain;
      background-color: #ffffff;
      padding: 30px 19px 14px 19px;
      min-height: 200px;
    }

    &_leftSide {
      max-width: 453px;
      width: 100%;
    }

    &_inputWrapper {
      input {
        background: transparent;
      }
    }
  }

  &_title {
    @include font(500, 26px, 31.03px);
    font-family: 'SFPRORegular';
    text-align: center;
    color: $color-text;
    margin-bottom: 15px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include font(700, 16px, 16px);
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include font(700, 16px, 16px);
    }
  }

  button {
    font-size: 16px;
    align-self: flex-end;
    padding: 6px 21px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include font(500, 12px, 14.32px);
      padding: 5px 15px;
      border-radius: 5px;
      margin-bottom: 10px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include font(500, 12px, 14.32px);
      padding: 5px 15px;
      border-radius: 5px;
      margin-bottom: 10px;
    }
  }
}

.admin_preview {
  @include display(flex, column, flex-start, center);
  overflow: hidden;
  user-select: none;
  position: relative;
  margin: 37px 0 20px 0;
  padding: 20px 20px 30px 21px;
  background: url(../../../../../../assets/img/common/questions.png),
    linear-gradient(
      140deg, 
      #c2e0ff 0%,
      #c2e0ff 11%,
      #d6eaff 16%,
      #e5f2ff 25%,
      #fafcff 57%,
      #fafcff 82%,
      #e5f2ff 89%,
      #d6eaff 93%,
      #c2e0ff 100%
    );
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  box-shadow: 0px 0px 8px 0px #3241954d;
  overflow: hidden;
  min-height: 409px;
  border-radius: 64px;
  max-width: 841px;
  width: 100%;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    border-radius: 24px;
    background: url(../../../../../../assets/img/common/questions-mobile.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    background-color: #ffffff;
    min-height: 185px;
    margin: 20px 0 !important;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    border-radius: 24px;
    background: url(../../../../../../assets/img/common/questions-mobile.png);
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    background-color: #ffffff;
    min-height: 185px;
    margin: 20px 0 !important;
  }

  // @media only screen and (max-width: 1400px) {
  //   background: url(../../../../../../assets/img/common/questions-mobile.png);
  //   background-repeat: no-repeat;
  //   background-position: right;
  //   background-size: contain;
  //   background-color: #ffffff;
  // }

  &_container {
    align-self: flex-start;
    max-width: 353px;
    width: 100%;
    margin-top: 88px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      margin-top: 0;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      margin-top: 0;
    }

    span {
      font-family: 'SFPRORegular';

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        @include font(500, 14px, 16.8px);
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        @include font(500, 14px, 16.8px);
      }
    }

    &_subtitle {
      display: flex;
      justify-content: center;
      @include font(500, 16px, 19.09px);
    }

    &_title {
      @include font(700, 22px, 26.25px);
      font-family: 'SFPRORegular';
      color: $color-text;
      margin: 10px 0 52px 0;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        text-align: center;
        @include font(700, 20px, 24px);
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        text-align: center;
        @include font(700, 20px, 24px);
      }
    }

    &_wrapper {
      @include display(flex, row, space-between, center);
      @include font(500, 16px, 19.09px);
      color: $color-text;

      &_value {
        color: $color-blue;
        @include font(700, 16px, 19.09px);
      }
    }
  }
}
