@import 'scss/mixin.scss';
@import 'scss/variable.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 20px;
}

.text {
  font-size: 18px;
  font-weight: bold;
}