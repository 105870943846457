@import '../../scss/mixin.scss';
@import '../../scss/variable.scss';

.videoPlayer {
  width: 100%;
  height: 38.125rem;
  position: relative;
  text-align: center;
  justify-items: center;

  @media screen and (max-width: 700px) {
    height: auto;
  }

  &_btnDelete {
    &:hover {
      background-color: #fec4c4;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px;
    border-radius: 6px;
    border: none;
    width: auto;
    height: auto;
    background: #fef2f2;
    text-align: center;
    margin-top: 1em;
    margin-left: 1em;
    transition: ease-in-out 0.3s;
  }

  &_btnDownload {
    &:hover {
      background-color: #7ef9a9;
    }

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 11px;
    border-radius: 6px;
    border: none;
    width: auto;
    height: auto;
    background: #daffe7;
    text-align: center;
    margin-top: 1em;
    transition: ease-in-out 0.3s;
  }

  & svg {
    margin: 0 auto;
    width: 7.75rem;
    height: 7.75rem;

    @media screen and (max-width: 700px) {
      width: 3.5rem;
      height: 3.5rem;
    }
  }
}

.videoPlayer video {
  width: 100%;
  height: 100%;
  border-radius: 1.5rem;
}

.videoPlayer image {
  width: 50%;
  height: 50%;
}

.functionalBtns {
  width: 100%;
  min-height: 36px;
  @include display(flex, row, space-between, center);

  &_downloadBtn {
    background: #f3f4f6;
    border: none;
    border-radius: 7px;
    width: 36px;
    height: 36px;
    margin-bottom: 8px;
    text-align: center;
    @include display(flex, row, center, center);
    cursor: pointer;
    display: flex;
    justify-content: center;
  }

  &_grabBtn {
    display: flex;
    cursor: grab;
  }

  &_delete {
    padding: 10px 25px;
    @include font(500, 16px, 19.09px);
  }
}

.playerWrapper {
  height: 38.125rem;
  width: 100%;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 100%;
    height: auto;
    height: 11.25rem;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    width: 100%;
    height: auto;
    height: 11.25rem;
  }
  div {
    border-radius: 1.5rem;

    video {
      border-radius: 1.5rem;
    }

    &.react-player__preview {
      border-radius: 1.5rem;
    }
  }
}
