@import '../../../../../../../../../scss/variable.scss';
@import '../../../../../../../../../scss/mixin.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../../../../../../../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.avatar_rounded {
  background-color: #ffffff !important;
  border: 1px solid #324195;
  width: 26px !important;
  height: 26px !important;
}

.avatar_square {
  background-color: #ffffff !important;
  border: 1px solid $color-text;
  width: 24px !important;
  height: 24px !important;
  border-radius: 4px !important;
}

.answer_body {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16.71px !important;
  color: $color-text;
  font-family: 'SFPRORegular'!important;
}

.grid_container {
  align-items: center !important;
  padding-top: 0 !important;
}

.answerOptionsBlock {

  &_title {
    @include font(500, 14px, 17px);
    color: #4d5766;
  }

  &_inputWrapperWithPicture {
    @include display(flex, column-reverse, space-between, center);
    width: 100%;
    gap: 4px;
    
    @media only screen and (min-width : 320px) and (max-width : 375px) {
      height: 100%;
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
      height: 100%;
    }

    label {
      margin: 0 18px 0 0;
      width: 60%;
      height: 100%;

      input {
        padding: 0 10px;

        &::placeholder {
          @include font(500, 14px, 21px);
          color: #d4d7dd;
        }
      }
    }

    &_correctAnswerWrapper {
      @include display(flex);
      height: 100%;
      border: 1px solid #e5e7eb;
      border-radius: 5px;
      margin: 0 9px 0 0;
      cursor: pointer;

      label {
        @include display(flex);
        margin: 0;
        padding: 9px;
        width: fit-content;
        @include font(500, 12px, 18px);
        color: #babcbf;

        input {
          cursor: pointer;
        }

        span {
          width: 15px;
          height: 15px;
        }
      }
    }

    &_comment {
      cursor: pointer;

      &:hover {
        svg {
          path {
            stroke: $color-blue;
          }

          line {
            stroke: $color-blue;
          }
        }
      }
    }

    &_delete {
      cursor: pointer;

      &:hover {
        svg {
          path {
            fill: #ef4444;
          }
        }
      }
    }

    &_grab {
      cursor: grab;
    }
  }

  &_inputWrapperWithoutPicture {
    @include display(flex, row, space-between, center);
    width: 100%;
    margin-top: 10px;
    
    @media only screen and (min-width : 320px) and (max-width : 375px) {
      height: 100%;
      margin-top: 10px;
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
      height: 100%;
      margin-top: 10px;
    }

    label {
      margin: 0 18px 0 0;
      width: 60%;
      height: 100%;

      input {
        padding: 0 10px;

        &::placeholder {
          @include font(500, 14px, 21px);
          color: #d4d7dd;
        }
      }
    }

    &_correctAnswerWrapper {
      @include display(flex);
      height: 100%;
      border: 1px solid #e5e7eb;
      border-radius: 5px;
      margin: 0 9px 0 0;
      cursor: pointer;

      label {
        @include display(flex);
        margin: 0;
        padding: 9px;
        width: fit-content;
        @include font(500, 12px, 18px);
        color: #BABCBF;

        input {
          cursor: pointer;
        }

        span {
          width: 15px;
          height: 15px;
        }
      }
    }

    &_comment {
      cursor: pointer;

      &:hover {
        svg {
          path {
            stroke: $color-blue;;
          }

          line {
            stroke: $color-blue;
          }
        }
      }
    }

    &_delete {
      cursor: pointer;

      &:hover {
        svg {
          path {
            fill: #ef4444;
          }
        }
      }
    }

    &_grab {
      cursor: grab;
    }
  }

  .answerImage {
    border-top: 3px solid #3170e7;
    border-right: 3px solid #3170e7;
    border-left: 3px solid #3170e7;
    border-radius: 16px;
    max-width: 150px;
    height: auto;
    float: right;
  }
  
}