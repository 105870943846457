@import '../../scss/mixin.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.editor {
  border: 1px solid #DCDCDC;
  width: calc(100% - 10px);
  border-radius: 10px;
  margin-top: 10px;
  padding: 10px;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 100%;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    width: 100%;
  }

  &_panel {
    position: relative;
    display: flex;
    text-align: center;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #DCDCDC;
    flex-wrap: wrap;
    max-width: 100%;

    &_button {
      border: none;
      background: transparent;
      cursor: pointer;
      width: auto;
      padding: 0 2px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background: #f6f5f5;
        border-radius: 7px;
      }
    }
  }

  &_table {
    max-width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    word-wrap: break-word;
    max-height: 200px;
    min-height: 100px;
    padding: 10px 0;

    div {
      height: 100%;
      min-height: 25px;

      div {
        @include font(500, 16px, 19.09px);
        font-family: 'SFPRORegular';
      }
    }
  }
}

.media {
  min-width: 70px;
  max-width: 150px;
  max-height: 100px;
}

.textField {
  margin-bottom: 40px;

  &_btn {
    @include font(500, 14px, 16.71px);
    padding: 10px 20px;
  }
}

.align_center {
  div {
    text-align: center;
  }
}

.align_right {
  div {
    text-align: right;
  }
}

.align_left {
  div {
    text-align: left;
  }
}

.editor_select {
  fieldset {
    border: none;

    &::after {
      content: none;
    }
  }

  div {
    div {
      padding: 0;
    }
  }
}

.active {
  background: #d3e7f9;
  border-radius: 7px;
}

.url_container {
  display: flex;
  max-width: 200px;
  position: absolute;
  top: 60px;
  left: 250px;
  gap: 5px;
  align-items: center;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    left: 100px;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    left: 100px;
  }

  &_input {
    width: 100%;
    margin: 0;
  
    div {
      div {
        padding: 5px;
        font-size: 16px;
        border: 1px solid transparent;
        background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(90deg, #0D28BB 0%, #357EEB 100%) border-box;
      }
    }

    input {
      font-size: 16px;

      &::placeholder {
        @include font(400, 16px, 23.87px);
        color: #808080;
    
        @media only screen and (min-width: 1400px) and (max-width: 1890px){
          @include font(400, 20px, 23.87px);
        }
      }
    }
  }
}