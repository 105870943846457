@import '../../../../scss/mixin.scss';
@import '../../../../scss/variable.scss';

.wrapper {
  min-width: 700px;
  overflow: hidden;
  width: 100%;

  @media screen and (max-width: 1024px) {
    min-width: 100%;
  }

  &_title {
    margin: 26px 0 20px 0;
    @include font(500, 24px, 29px);
    color: #4d5766;
  }
  &_testWrapper {
    @include display(flex, row, center, center);
    width: 100%;
    padding: 2rem;

    @media screen and (max-width: 1024px) {
      padding: 2rem 1rem;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    min-width: 95%;
    width: 100%;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    min-width: 95%;
    width: 100%;
  }
}

.commentContainer {
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 10px;
  margin-top: 10px;
  width: 100%;
}

.commentBox {
  margin-top: 10px;
  background-color: white;
  border-radius: 10px;
  padding: 15px;
}

.commentForm {
  display: flex;
  flex-direction: column;

  textarea {
    height: 70px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    resize: none;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  button {
    padding: 8px 16px;
    background-color: #ba75ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: #8a49b5;
    }
  }
}

.lessonHeader {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;

  &_title {
    color: var(--Black, #332f36);
    text-align: center;

    /* Desktop/Head/H2 */
    font-family: 'SFPROMedium';
    font-size: 2.25rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1px;
  }

  &_nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    height: 3.375rem;

    button {
      font-size: 1.25rem !important;
      letter-spacing: -1px;

      @media screen and (max-width: 700px) {
        font-size: 0.75rem !important;
        padding: 0.25rem 0.75rem;
        gap: 0.225rem;
      }
    }
  }
}

.lessonHeaderBanner {
  position: relative;
  width: 100%;
  height: 26.25rem;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  background: #e0dced;
  border-radius: 1.5rem;
  overflow: hidden;
  margin-bottom: 25px;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1.5rem;
    background-color: rgba(51, 47, 54, 0.5);
    z-index: 1;

    @media screen and (max-width: 700px) {
      border-radius: 0.625rem;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    @include display(flex, row, flex-start, center);
    margin-bottom: 0;
    height: 11.25rem;
    border-radius: 0.625rem;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    @include display(flex, row, flex-start, center);
    margin-bottom: 0;
    height: 11.25rem;
    border-radius: 0.625rem;
  }

  &_infoBlock {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    height: 6.4375rem;
    padding: 1.5rem 4rem;
    align-items: center;
    gap: 0.625rem;
    flex-shrink: 0;
    background: linear-gradient(90deg, #332f36 -5.88%, rgba(51, 47, 54, 0) 100%);
    z-index: 3;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      display: inline-flex;
      padding: 0rem 5.8125rem 0rem 5.75rem;
      justify-content: center;
      align-items: center;
      height: 2.25rem;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      display: inline-flex;
      padding: 0rem 5.8125rem 0rem 5.75rem;
      justify-content: center;
      align-items: center;
      height: 2.25rem;
    }
    &_title {
      @include display(flex, column);
      &_description {
        @include font(500, 14px, 10px);
        color: #bdbdbd;
        font-size: 16px;
        margin-bottom: 2px;
        strong {
          font-weight: 600;
        }
      }

      &_name {
        color: #fff;
        text-align: center;
        font-family: 'SFPRORegular';
        font-size: 2.875rem;
        font-style: normal;
        font-weight: 500;
        line-height: 3.875rem; /* 134.783% */

        @media only screen and (min-width: 320px) and (max-width: 375px) {
          font-size: 12px;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          font-size: 12px;
        }
      }
    }
  }
}
