@mixin display($display, $flex-direction: row, $justify-content: flex-start, $align-item: stretch) {
  display: $display;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-item;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    margin: 0;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    margin: 0;
  }
}
@mixin position_t_l($position, $top, $left, $z-index: 0) {
  position: $position;
  top: $top;
  left: $left;
  z-index: $z-index;
}
@mixin position_b_l($position, $bottom, $left, $z-index: 0) {
  position: $position;
  bottom: $bottom;
  left: $left;
  z-index: $z-index;
}
@mixin position_t_r($position, $top, $right, $z-index: 0) {
  position: $position;
  top: $top;
  right: $right;
  z-index: $z-index;
}
@mixin position_b_r($position, $bottom, $right, $z-index: 0) {
  position: $position;
  bottom: $bottom;
  right: $right;
  z-index: $z-index;
}
@mixin border($width, $style, $color) {
  border: $width $style $color;
}
@mixin font($weight, $size, $line-height: normal) {
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
}

:root {
  --Blue-inactive-gradient: linear-gradient(180deg, #cfe2ff 0%, #bbceeb 100%);
  --Blue-Linear-2: linear-gradient(90deg, #3170e7 13.5%, #7a90f7 100%);
  --Button: #357eeb;
}

@mixin fontBold($weight, $size, $line-height: normal) {
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
  font-family: 'SFPROBold';
}

@mixin fontMedium($weight, $size, $line-height: normal) {
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
  font-family: 'SFPROMedium';
}

@mixin fontReg($weight, $size, $line-height: normal) {
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
  font-family: 'SFPRORegular';
}
