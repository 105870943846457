@import '../../../scss/variable.scss';
@import '../../../scss/mixin.scss';

@font-face {
    font-family: 'SFPRORegular';
    src: url(../../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
  }
  
  .wrapper {
    @include display(flex, column, space-between, center);
    gap: 5px;
    max-width: 205px;
    width: 100%;
    border-radius: 16px;
    padding: 17px 0;
    border: transparent;
    background-color: $color-blue;
    cursor: pointer;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      max-width: 480px;
      gap: 15px;
      @include display(flex, row, center, center);
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      max-width: 480px;
      gap: 15px;
      @include display(flex, row, center, center);
    }

    &:hover {
      transform: scale(1.1);
      transition: transform 0.15s ease-in-out;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        transform: scale(1.04);
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        transform: scale(1.04);
      }
    }

    &_title {
      @include font(500, 14px, 16.71px);
      color: $color-white;
      text-align: center;
      font-family: 'SFPRORegular';
    }
  }