@import '../../../../scss/variable';
@import '../../../../scss/mixin.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.table {
  &_body {
    background: #ffffff;

    &_row {
      border-bottom: 1px solid #ebebeb;
      background: #ffffff;

      &_icon {
        width: 100%;
        height: 100%;
        @include display(flex, row, flex-start, center);
        padding: 22px 0;
        img {
          width: 37px;
          height: 40px;
        }

        &_svg {
          background: transparent;
          border: none;
          svg {
            width: 32px;
            height: 32px;
          }

          svg path {
            fill: $color-grey;
          }
        }
      }

      &_expires {
        width: 100%;
        height: 100%;
        @include display(flex, row, flex-start, center);
        padding: 16px 0;

        p {
          @include font(400, 16px, 19.09px);
          color: #357eeb;
          text-align: left;
          font-family: 'SFPRORegular';
        }
      }

        &_button {
          @include display(flex, row, center, center);
          gap: 8px;
          background: #357eeb;
          width: 100%;
          min-width: 192px;
          height: 27px;
          padding: 4px;
          border-radius: 8px;

          &_active {
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background: #4cc700;
          }

          &_inactive {
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background: #c70000;
          }

          p {
            @include font(400, 16px, 19.09px);
            color: #ffffff;
            text-align: left;
            font-family: 'SFPRORegular';
          }
      }
    }
  }
}
