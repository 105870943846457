@import '../../../scss//mixin.scss';
@import '../../../scss/variable.scss';

.wrapper {
  @include display(flex, column, space-between, flex-start);
  width: 100%;
  min-height: 170px;
  padding: 1.8% 2.5%;
  transition: all ease-in 0.2s;
  box-shadow: 0px 0px 18px 0px #3241954d;
  border-radius: 20px;
  margin-top: 24px;
  position: relative;

  .content_section {
    @include display(flex, row, flex-start, flex-start);
    width: 100%;
    gap: 24px;
  }

  .banner_input_container {
    @include display(flex, row, flex-start, flex-start);
    width: 100%;
    position: relative;

    .penIcon {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  span {
    color: #000;
    font-weight: 500;
    font-size: 20px;
  }

  .banner_checkbox_status {
    @include display(flex, row, center, center);
    font-size: 16px;
    color: #357eeb;
  }

  .banner_link_container {
    @include display(flex, column, center, flex-start);
    width: 100%;
    gap: 10px;
  }

  .banner_groups_btn_cont {
    @include display(flex, row, flex-start, flex-start);
    position: relative;
    width: 100%;

    svg {
      position: absolute;
      right: 15px;
      top: 22px;
    }
  }

  .banner_groups_btn {
    background-color: transparent;
    flex-grow: 1;
    border: 1px solid #332f36;
    border-radius: 10px;
    text-align: start;
    height: 54px;
    padding: 0.5rem 1rem;
    font-size: 20px;
    color: var(--grey, #808080);
    transition-duration: $trans-dur02;

    &:hover {
      border: 1px solid #357eeb;
    }
  }

  .banner_go_link_btn {
    background-color: #357eeb;
    border-radius: 10px;
    color: #fff;
    padding: 15px 40px;
    font-size: 20px;
    font-weight: 500;
    transition-duration: $trans-dur02 !important;
  }

  &_content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 25px;
    width: 65%;
    height: 100%;
    padding: 1rem 0;

    &_groups {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;

      &_item {
        display: flex;
        gap: 0.2rem;
        align-items: center;
        flex-wrap: wrap;
      }
    }

    &_title {
      font-family: 'Inter', sans-serif;
      color: #4d5766;
      font-size: 20px;
      font-weight: 600;
      cursor: default;
    }

    &_description {
      font-family: 'Inter', sans-serif;
      color: #4d5766;
      font-size: 14px;
      cursor: default;
    }

    &_announcement {
      box-shadow: 0px 0px 8px 0px #3241954d;
      border-radius: 20px;
      padding: 20px;

      &_header {
        font-size: 14px !important;
      }
    }
  }

  &_buttons {
    padding: 1rem 0;
    display: flex;
    gap: 20px;
    align-items: center;

    @media only screen and (min-width: 320px) and (max-width: 480px) {
      button {
        border: 2px solid #357eeb;
        padding: 8px 16px !important;
        font-size: 12px;
      }
    }
    @media only screen and (min-width: 481px) and (max-width: 1024px) {
      button {
        border: 2px solid #357eeb;
        padding: 10px 20px !important;
        font-size: 14px;
      }
    }

    &_delete {
      border: 2px solid #357eeb;
      padding: 11px;
      border-radius: 10px;
      height: 54px;
      width: 288px;
      color: $color-blue;
      font-weight: 500;
      font-size: 20px;
      background: transparent;
      @include display(flex, row, center, center);
      text-align: center;
      cursor: pointer;
      transition: all ease-in 0.2s;

      &:hover {
        background: $color-blue;
        color: $color-white;
      }

      & svg {
        margin: 0 auto;
        height: 17px;
        width: 17px;
      }
    }

    &_confirm {
      padding: 11px;
      border-radius: 10px;
      border: none;
      height: 54px;
      width: 288px;
      font-weight: 500;
      font-size: 20px;
      background: $color-blue;
      color: $color-white;
      @include display(flex, row, center, center);
      text-align: center;
      cursor: pointer;
      transition: all ease-in 0.2s;

      &:hover {
        filter: brightness(0.85);
      }

      & svg {
        margin: 0 auto;
        height: 17px;
        width: 17px;
      }
    }

    &_edit {
      position: absolute;
      right: 20px;
      top: 20px;
      padding: 11px;
      border-radius: 6px;
      border: none;
      height: 36px;
      background: #ececec;
      @include display(flex, row, flex-start, center);
      text-align: center;
      cursor: pointer;
      transition: all ease-in 0.2s;

      &:hover {
        background: #f7f7f7;
      }

      & svg {
        margin: 0 auto;
        height: 17px;
        width: 17px;
      }
    }
  }

  &:hover {
    img {
      scale: 1.05;
    }
  }
}

.image {
  transition: all ease-in 0.2s;
  width: 20%;
}

.textarea {
  // max-width: 34vh;
  width: 100%;
  min-height: 150px;
  max-height: 300px;
  border: 1px solid #ba75ff;
  border-radius: 4px;
  resize: none;
  padding: 0.375rem;
  overflow: auto !important;
  border: 0.1rem solid #c6c3c3 !important;
  transition: all ease-in 0.1s;

  &:focus {
    border: 0.1rem solid #c6c3c3 !important;
    outline: none !important;
  }

  &:hover {
    outline: none !important;
    border: 0.1rem solid #ba75ff !important;
    // box-shadow: 0 0 10px #ba75ff;
  }

  @include font(400, 14px);
}

.statistics_container {
  width: 90%;
  margin: 5% 5%;
}
