@import '../../../../scss/mixin.scss';
@import '../../../../scss/variable.scss';

.wrapperBox:last-child:nth-child(odd):not(:first-child):not(:only-child) {
  grid-column: span 2;
}

.wrapperBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 150px;
  color: #fff;
  gap: 5px;

  &_imageBox {
    display: flex;
    width: 100%;
    height: 70px;
    position: relative;
    background: transparent;

    &_image {
      background: transparent;
      object-fit: contain;
      height: 100%;
      width: 100%;
    }

    &_free {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 40px;
      align-self: flex-end;
    }

    &_price {
      position: absolute;
      top: 0;
      right: 0;
      font-family: 'SFPRORegular';
      font-size: 14px;
      font-weight: 600;
      line-height: 19.09px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: $color-white;

      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 5px;
    }
  }

  &_text {
    font-family: 'SFPRORegular';
    font-size: 18px;
    font-weight: 600;
    line-height: 28.64px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: $color-white;
    letter-spacing: -1px;
  }
}

.smallPrizeIcon {
  width: 38px;
  height: 38px;
  position: relative;
  background: center / cover no-repeat url('./assets/single.png');

  &_count {
    position: absolute;
    top: 50%;
    left: 60%;
    width: 21px;
    height: 20px;
    background: center / cover no-repeat url('./assets/cloud.png');
    z-index: 99999;

    font-family: 'SFPRORegular';
    font-size: 12px;
    font-weight: 500;
    line-height: 14.32px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.prizeBuy {
  width: min-content;
  font-size: 14px;
  border-radius: $bord-rad10;
  margin: 0;
  height: 43px;
  padding: 0 2rem;
}
