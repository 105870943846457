@import '../../scss/variable.scss';
@import '../../scss/mixin.scss';

.wrapper {
  background-color: transparent;

  &_addQuestionsWrapper {
    background-color: #fff;
    margin-top: 32px;
    padding: 25px 61px 35px 61px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: $box-shadow;

    &_title {
      @include font(500, 14px, 17px);
      color: #4d5766;
      text-align: center;
    }

    &_btnWrapper {
      @include display(flex, row, center, center);
      margin-top: 15px;
      gap: 15px;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        @include display(flex, column, center, center);
        margin: 20px 0 0 !important;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        @include display(flex, column, center, center);
        margin: 20px 0 0 !important;
      }
      @media only screen and (min-width: 1126px) and (max-width: 1236px) {
        height: 96.5px;
      }
    }
  }
}

.nameSettings {
  color: $font-title;
  font-size: 25px;
  font-weight: bold;
}

.settings_list {
  @include display(flex, column, center, center);
  text-align: center;
  gap: 20px;
}
