@import '../../../scss/mixin.scss';

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.HelpCenterPage {
    h1 {
        margin: 23px;
        color: #4D5766;
        font-size: 35px;
    }

    &_FAQ {
        flex-direction: column;
        display: flex;
        max-width: 900px;
        margin: 0 auto;
        h1 {
//             text-align: center;
            margin: 42px 0 32px 0;
        }
        h2 {
            color: #BA75FF;
            font-size: 23px;
            font-family: 'Manrope', sans-serif;
        }
        p {
            margin-top: 20px;
            margin-bottom: 45px;
            font-size: 20px;
            line-height: 1.5;
//             font-size: var(--text-md);
//             line-height: 24px;
            text-align: justify;
            font-family: 'Manrope', sans-serif;
            font-weight: 400;
        }
         &_images {
            position: relative;
            max-width: 100%;
            width: 100%;
            display: inline-block;
//             display: grid;
            grid-template-columns: 1fr 1fr;
           &_Chat {
               display: block;
               margin-bottom: 30px;
                        width: 80%;
                        height: auto;
               margin-left: 2px;
               margin-right: 2px;
               border-radius: 6px !important;
               box-shadow: 0 0 8px 0px #bdbdbd;
               cursor: pointer;
               max-width: calc(100% - 4px);
               overflow-clip-margin: content-box;
               overflow: clip;
               z-index: 1;
               position: relative;

            }
        }
    }

    &_banner {
        margin-top: 35px;
        width: 100%;
//         background:  #934AFF;
        background: #934aff57;
//         background: #BA75FF;
        height: 349px;
        display: inline-flex;

        &_createProject {
            padding-top: 80px;
            margin-left: 170px;
            h1 {
                margin: 0;
//                 color: #eef2ff;
                font-size: 32px;
            }
            p {
                margin-top: 13px;
                color:#4D5766;
                font-size: 14px;
                width: 80%;
            }
            button {
                margin: 46px 0 0 27px;
                width: 241px;
                height: 52px;
                font-size: 12px;
            }
        }
        &_images {
            position: relative;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            &_firstStep {
                width: 285px;
                height: 210px;
                margin-top: 83px;
                margin-left: 200px;
                z-index: 1;
                position: absolute;
            }
            &_secondStep {
                width: 285px;
                height: 215px;
                margin-left: 410px;
                position: absolute;
                margin-top: 36px;
            }
        }
        @media (min-width: 320px) and (max-width: 1024px) {
            &_images {
                display: none;
            }
            height: 100%;
            &_createProject {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                margin: 20px;
                padding-top: 50px;
                h1 {
                    font-size: 32px;
                }
            }
        }
        @media (min-width: 320px) and (max-width: 481px) {
            h1 {
                font-size: 23px;
            }
            button {
                width: 150px;
                margin: 30px auto 0 auto;
            }
        }

    }

    &_footer {
        margin: 74px auto 0 auto;
        display: flex;
        flex-direction: column;
        width: fit-content;
        align-items: center;
        &_links {
            align-items: center;
            width: 400px;
            display: flex;
            justify-content: space-between;
            a {
                color: #6B7280;
                font-size: 10px;
            }
        }
        h1 {
            color: #D7D7D7;
            font-weight: 800;
            font-size: 33px;
            margin: 34px 0 9px 0;
        }
        h5 {
            color: #D7D7D7;
            font-size: 14px;
        }
        @media (min-width: 320px) and (max-width: 560px) {
            &_links {
                width: 100%;
                flex-direction: column;
                a {
                    padding: 5px;
                }
                h1 {
                    font-size: 23px;
                }
                p {
                    font-size: 10px;
                }
            }
        }
    }
}
.init {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 24px 0;
  width: 100vw;
  min-height: 100vh;
  height: 100%;
  font-family: 'Manrope', sans-serif;


  &_header {
    width: 100%;
    padding: 30px 70px 30px 90px;
    width: 100%;
    @include display(flex, row, space-between);

    &_logo {
      width: 340px;
      display: flex;
      background-color: inherit;
      transition: all ease-in 0.4s;

      &:hover {
        background-color: #dab3ff;
        p {
           background: linear-gradient(to right, #61a3ed, #5048d8, #9228ff, #ec40ff);
           background-clip: text;
           -webkit-background-clip: text;
           color: transparent;
        }
      }

      p {
        padding-left: 20px;
//         color: #5873e2;
        font-size: 35px;
        font-weight: 700;
        display: flex;
        text-shadow: 2px 2px 4px rgba(63, 63, 63, 0.5);
      }
    }
  }
}

.header_block {
  @include display(flex);
  justify-content: center;
  align-items: center;
  margin-right: 30px;

  &_logIn {
    margin-right: 15px;
    text-decoration: none;
    font-size: 18px;
    font-weight: 700;
    color: #ba75ff;
    animation: account 0.2s ease-in-out;
  }

  &_name {
    margin-right: 15em;
    p {
      text-align: center;
      font-size: 20px;
      font-style: italic;
      font-weight: 800;
      color: #ba75ff;
    }
  }
}