@import '../../../scss/mixin.scss';
@import '../../../scss/variable.scss';

.wrapper {
    width: 100%;
    // padding: 34px 42px 40px 42px;
    background-color: #fff;
    @media only screen and (min-width : 375px) and (max-width : 480px) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 24px 5px 30px 5px;
      }
      @media only screen and (min-width : 320px) and (max-width : 375px) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;
      }
}