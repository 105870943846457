@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

.modal-wrapper {
    width: 815px;
    height: 291px;
    margin-inline: auto;
    inset-inline: 0;
    inset-block-start: 50%;
    transform: translateY(-50%);
    background-color: transparent;
    border-radius: 24px;
  }
  
  .modal-content {
    width: 815px;
    height: 291px;
    border-radius: 24px;
    background-color: white;
    padding: 24px 34px 24px 34px;
    box-shadow: 0px 0px 8px 0px rgba(50, 65, 149, 0.3);
    border: 1px solid;
    border-image-source: linear-gradient(0deg, #FFFAFA 0%, #808080 100%);
    backdrop-filter: blur(27.799999237060547px);

//     @media only screen and (min-width : 2400px) and (max-width : 3500px) {
//       width: 40%;
//       height: 29%;
//     }
//     @media only screen and (min-width : 2100px) and (max-width : 2400px) {
//         width: 40%;
//         height: 38%;
//       }
//     @media only screen and (min-width : 2000px) and (max-width : 2100px) {
//         width: 40%;
//         height: 43%;
//       }
//     @media only screen and (min-width : 1900px) and (max-width : 2000px) {
//         width: 40%;
//         height: 46%;
//       }
//     @media only screen and (min-width : 1600px) and (max-width : 1900px) {
//         width: 40%;
//         height: 51%;
//       }
//     @media only screen and (min-width : 1400px) and (max-width : 1600px) {
//         width: 40%;
//         height: 56%;
//       }
//       @media only screen and (min-width : 1300px) and (max-width : 1400px) {
//         width: 40%;
//         height: 62%;
//       }
//       @media only screen and (min-width : 100px) and (max-width : 1300px) {
//         width: 90vw;
//         height: 70vh;
//         left: 50%;
//       }
}
  
.modal-content h2 {
    width: 330px;
    height: 29px;
    gap: 0px;
    opacity: 0px;
    font-family: SF Pro Display;
    font-size: 24px;
    font-weight: 500;
    line-height: 28.64px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(0, 0, 0, 1);
}

.label-container {
  margin-top: 10px;
  margin-bottom: 10px;
  width: 145px;
  height: 24px;
  gap: 0px;
  opacity: 0px;
  font-family: SF Pro Display;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.87px;
  text-align: left;
  margin-top: 36px;

  label{
    color: var(--grey, rgba(128, 128, 128, 1));
  }
}

  .modal-content input {
    padding: 7px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  
  .modal-content p {
    color: red;
    margin-bottom: 10px;
  }


  .currencyContainer {
    width: 747px;
    height: 56px;
    padding: 16px;
    border-radius: 14px;
    opacity: 0px;
    border: 2px solid rgba(53, 126, 235, 1);
    font-family: SF Pro Display;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.87px;
    justify-content: center;
    text-align: center;
  
    select {
      width: 100%;
      color: var(--grey, rgba(128, 128, 128, 1));
      border: none;
      border-radius: 8px;
      background-color: transparent;
      outline: none;
    }
  }

  .closeButton {
    outline: none;
    border: none;
    color: #cd58eb;
    position: absolute;
    right: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f1f1;
    border-radius: 5px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    transition: 0.3s color;
    z-index: 999;
    background: none;
  }
  
  .input-container {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px;
  }
  
  .input-container input {
    border: none;
    outline: none;
    width: 100%;
    height: 20px;
  }

  .btn {
    border: none;
    width: 300px;
    height: 54px;
    padding: 16px 80px 16px 80px;
    gap: 10px;
    border-radius: 10px;
    opacity: 0px;
    background: rgba(53, 126, 235, 1);
    font-family: SF Pro Display;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.87px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: rgba(255, 255, 255, 1);
    margin-top: 36px;

    @media only screen and (min-width : 320px) and (max-width : 375px) {
      margin-top: 0;
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
      margin-top: 0;
    }
  
    &:hover{
      background: linear-gradient(90deg, #0D28BB 0%, #357EEB 100%);
    }

}