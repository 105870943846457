@import 'scss/mixin.scss';
@import 'scss/variable.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../../../../../../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.wrapper {
  position: relative;
  width: 1040px;
  font-family: 'SFPRORegular';

  h2 {
    text-align: center;
    padding: 2rem 0 1rem;
    color: $color-text;
    @include font(700, 28px, 33.41px);
  }

  &_description {
    display: flex;
    justify-content: center;

    textarea {
      border: none;
      background: none;
      outline: none;
      font-size: 20px;
      padding: 10px;
      border-radius: 5px;
      width: 500px;
      resize: none;
      overflow-wrap: break-word;
      text-align: center;
    }

    textarea:hover {
      background: rgba(207, 226, 255, 0.12);
    }
  }

  &_cardContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 50px;
    margin-top: 40px;

    &_addButton {
        width: 40px;
        height: 40px;
        display: flex;
        font-size: 20px;
        justify-content: center;
        align-items: center;
        border: none;
        background: none;
        color: $color-blue;
        visibility: hidden; // По умолчанию кнопки невидимы
        opacity: 0;
        transition: visibility 0s, opacity 0.3s ease-in-out;
      }

    &_chip {
      position: relative;
      width: 100%;
      min-height: 150px;
      margin-bottom: 40px;

      &_addButtonBefor,
      &_addButtonAfter {
        position: absolute;
        left: 520px;
        width: 40px;
        height: 40px;
        display: flex;
        font-size: 20px;
        justify-content: center;
        align-items: center;
        border: none;
        background: none;
        color: $color-blue;
        visibility: hidden; // По умолчанию кнопки невидимы
        opacity: 0;
        transition: visibility 0s, opacity 0.3s ease-in-out;
      }

      &_addButtonBefor {
        top: -40px;
      }

      &_addButtonAfter {
        bottom: -40px;
      }

      &_addButtonBefor:hover,
      &_addButtonAfter:hover {
        color: #1e4b8e;
        border-radius: 50%;
        background: rgba(1, 1, 1, 0.15);
      }
    }

    &_addButton {
      width: 40px;
      height: 40px;
      display: flex;
      font-size: 20px;
      justify-content: center;
      align-items: center;
      border: none;
      background: none;
      color: $color-blue;
    }

    &_addButton:hover {
      color: #1e4b8e;
      border-radius: 50%;
      background: rgba(1, 1, 1, 0.15);
    }
  }

  &:hover {
    .wrapper_cardContainer_addButton,
    .wrapper_cardContainer_chip_addButtonBefor,
    .wrapper_cardContainer_chip_addButtonAfter {
      visibility: visible; // При наведении контейнер становится видимым
      opacity: 1;
    }
  }
}
