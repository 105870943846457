@import '../../scss/mixin.scss';
@import '../../scss/variable.scss';

.wrapper {
  position: fixed;
  z-index: 9999999999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: center / cover no-repeat url('./assets/42e4f94c0f1f41739229dd27db2cd920.jpeg');

  &_lights {
    position: absolute;
    width: 100%;
    height: 100%;
    background: center / cover no-repeat url('./assets/6ee131fef24842205c3a8e509bce5611.jpeg');
    opacity: 0;
    animation: initialBlight 6s linear 0.5s;
  }

  &_openingLights {
    position: absolute;
    width: 100%;
    height: 100%;
    background: center / cover no-repeat url('./assets/6ee131fef24842205c3a8e509bce5611.jpeg');
    opacity: 0;
    animation: openingBlight 2s linear;
  }

  &_body {
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100%;
    display: flex;
    padding: 2rem 0;
    justify-content: space-between;
    align-items: center;
    position: relative;
    transition: all 0.5s;
    z-index: 2;

    &_main {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 508px;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 2rem 0;
      align-items: center;
      justify-content: space-between;
      max-height: 920px;

      &_bottom {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
        color: $color-white;
        font-family: 'SFPRORegular';
        letter-spacing: -1px;

        &_lasts {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          font-size: 24px;
          font-weight: 500;
          line-height: 28.64px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }

        &_buttons {
          width: 100%;
          height: 54px;
          display: grid;
          grid-template-columns: 1fr 1fr 2fr 2fr;
          gap: 0.5rem;
        }

        &_garant {
          display: flex;
          align-items: center;
          font-size: 24px;
          font-weight: 500;
          line-height: 28.64px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
      }

      &_winners {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;

        &_tipsButton {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          cursor: pointer;
          position: relative;

          span {
            display: flex;
            border: 2px solid #ffffff;
            align-items: center;
            justify-content: center;
            width: 32px;
            height: 32px;
            color: #fff;
            font-size: large;
            border-radius: 10px;
          }

          &_tips {
            position: absolute;
            top: 120%;
            right: 0;
            display: flex;
            flex-direction: column;
            z-index: 4;
            width: 100%;
            gap: 1rem;

            &_tip {
              border-radius: 24px;
              backdrop-filter: blur(29.100000381469727px);
              padding: 1rem 2rem;
              width: 100%;
              background: #02337c66;
              display: flex;
              align-items: center;
              gap: 1rem;
              color: #fff;

              img {
                width: 100px;
                height: 100px;
                object-fit: cover;
              }

              p {
                font-size: 16px;
                font-weight: 400;
                line-height: 19.09px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
              }
            }
          }
        }

        &_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          h2 {
            font-family: 'SFPRORegular';
            font-size: 36px;
            line-height: 42.19px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #fff;
          }

          span {
            cursor: pointer;
            font-family: 'SFPRORegular';
            font-size: 16px;
            font-weight: 500;
            line-height: 19.09px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #fff;
          }
        }

        &_list {
          display: flex;
          width: 100%;
          flex-direction: column;
          gap: 5px;
        }
      }

      &_box {
        margin-top: -50px;
        width: 400px;
        height: 400px;
        object-fit: cover;
      }
    }

    &_deposit {
      border: 1px solid #fff;
      border-width: 1px 0 1px 1px;
      height: auto;
      min-height: 766px;
      border-radius: 34px 0 0 34px;
      background: linear-gradient(90deg, rgba(13, 40, 187, 0.4) 0%, rgba(53, 126, 235, 0.4) 100%),
        linear-gradient(0deg, rgba(51, 47, 54, 0.1), rgba(51, 47, 54, 0.1));
      width: 100%;
      max-width: 527px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 32px;
      padding: 2rem 1rem;
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.5s;
      overflow: hidden;

      &_header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        p {
          font-family: 'SFPRORegular';
          font-size: 36px;
          font-weight: 700;
          line-height: 42.96px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #fff;
        }

        div svg path {
          fill: #fff;
          stroke: #fff;
        }
      }

      &_slider {
        margin: 0 auto;
        width: 100%;
      }

      &_menu {
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-end: span 3;
        gap: 2rem;
        justify-items: center;
      }
    }

    &_prizes {
      border: 1px solid #fff;
      border-width: 1px 1px 1px 0;
      height: auto;
      min-height: 676px;
      border-radius: 0 34px 34px 0;
      background: linear-gradient(90deg, rgba(13, 40, 187, 0.4) 0%, rgba(53, 126, 235, 0.4) 100%),
        linear-gradient(0deg, rgba(51, 47, 54, 0.1), rgba(51, 47, 54, 0.1));
      width: 100%;
      max-width: 28vw;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      gap: 32px;
      padding: 2rem 1rem;

      &_title {
        font-family: 'SFPRORegular';
        font-size: 36px;
        font-weight: 700;
        line-height: 42.96px;
        text-align: center;
        width: 100%;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #fff;
      }

      &_icons {
        display: flex;
        gap: 1rem;
        padding-left: 1.5rem;
        flex-wrap: wrap;
        img {
          width: 6.75vw;
          height: 6.75vw;
          cursor: pointer;
          transition: all 0.4s;

          &:hover {
            transform: scale(0.95);
          }
        }
      }
    }
  }
}

@keyframes initialBlight {
  0% {
    opacity: 0.1;
  }
  25% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.3;
  }
  50% {
    opacity: 0.4;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

@keyframes openingBlight {
  0% {
    opacity: 0.3;
  }
  25% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.6;
  }
  75% {
    opacity: 0.4;
  }
  100% {
    opacity: 0;
  }
}

.hide {
  transform: translateX(150%);
}

.showBtnWrapper {
  height: 400px;
  border: 1px solid $color-white;
  border-width: 1px 0 1px 1px;
  border-radius: 32px 0 0 32px;
  background: linear-gradient(90deg, rgba(13, 40, 187, 0.4) 0%, rgba(53, 126, 235, 0.4) 100%),
    linear-gradient(0deg, rgba(51, 47, 54, 0.1), rgba(51, 47, 54, 0.1));
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 5px;

  svg path {
    stroke: #fff;
  }
}

.wrapper:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.273);
  z-index: 1;
}

.bubbleI {
  margin: 0 10px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-self: center;
  width: 30px;
  height: 30px;
  border: 2px solid $color-white;
  border-radius: $bord-rad50;
  font-size: large;
}

.prizeWinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 366px;
  height: 167px;
  padding: 24px 34px 24px 34px;
  gap: 18px;
  border-radius: 34px;
  background: #ffffff4d;

  &_text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 162px;
    height: 100%;
    color: #fffafa;

    h3 {
      //styleName: Desktop/Head/H1;
      font-family: 'SFPRORegular';
      font-size: 46px;
      font-weight: 500;
      line-height: 62px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      letter-spacing: -1px;
    }

    p {
      font-family: 'SFPRORegular';
      font-size: 20px;
      font-weight: 500;
      line-height: 23.87px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      letter-spacing: -1px;
    }
  }

  img {
    height: 100%;
    object-fit: contain;
  }
}

.paymentModal {
  @include display(flex, column, flex-start, center);
  padding: 0 2rem;
  gap: 2rem;
  min-width: 600px;

  &_title {
    font-size: 36px;
    font-weight: 500;
    line-height: 42.96px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #357eeb;
  }

  &_paymentInfo {
    margin-top: -1rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    color: #0d28bb;

    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      font-size: 20px;
      font-weight: 500;
      line-height: 23.87px;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #3e3e3e;

      li {
        font-size: 16px;
        font-weight: 600;
        line-height: 19.09px;

      }
    }

    p {
      width: 100%;
      font-size: 16px;
      font-weight: 700;
      line-height: 19.09px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }

  a {
    background: #357eeb;
    padding: 12px 20px;
    border-radius: 14px;
    color: white;
    text-decoration: none;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.87px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    &:hover {
      background: linear-gradient(90deg, #0d28bb 0%, #357eeb 100%);
    }
  }
}
