@import '../../../scss/mixin.scss';

.radioContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_radio {
  opacity: 0;
  z-index: 99999;
  width: 10px;
  height: 10px;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
label {
  color: #4d5766;
  cursor: pointer;
}
.custom_radio + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.custom_radio + label::before {
  content: '';
  display: inline-flex;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #111111;
  border-radius: 50%;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  //background-size: 50% 50%;
}
/* стили при наведении курсора на радио */
.custom_radio:not(:disabled):not(:checked) + label:hover::before {
   border-color: #357EEB;


}
/* стили для активной радиокнопки (при нажатии на неё) */
.custom_radio:not(:disabled):active + label::before {
  background-color: #b3d7ff;
  border-color: #b3d7ff;
}
/* стили для радиокнопки, находящейся в фокусе */
.custom_radio:focus + label::before {
  //box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}
/* стили для радиокнопки, находящейся в фокусе и не находящейся в состоянии checked */
.custom_radio:focus:not(:checked) + label::before {
  border-color: #80bdff;
}
/* стили для радиокнопки, находящейся в состоянии checked */
.custom_radio:checked + label::before {
  background-color: #ffffff;
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='dodgerblue'/%3e%3c/svg%3e");
}
/* стили для радиокнопки, находящейся в состоянии disabled */
.custom_radio:disabled + label::before {
  background-color: #e9ecef;
}