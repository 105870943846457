.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  background: #d3f2f8;
  color: #72b0bc;
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  font-size: inherit;
  font-weight: inherit;
  line-height: normal;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }
}
