.label {
    input {
        padding: 10px;
        width: 100%;
        height: 100%;
        border: 0.1rem solid #808080;
        border-radius: 10px;
        outline: none;

        &:focus {
            border: 1px solid #357eeb;
        }
    }
}