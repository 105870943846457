@import '../../../../scss/variable';
@import '../../../../scss/mixin.scss';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 0;
  width: 100%;
  min-height: 400px;
  margin: auto;
  flex-shrink: 0;
  flex-grow: 0;
  overflow: visible;
  &_decoration_shadow {
    width: 385px;
    height: 385px;
    border-radius: 385px;
    background: #57b8ff;
    filter: blur(200px);
    width: 385px;
    height: 385px;
    position: absolute;
    top: -322px;
    z-index: 1;
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
   padding: 2rem 0 2rem 0;    
  }
}

.student {
  &_progress {
    display: flex;
    gap: 40px;

    &_title {
      @include font(500, 12px, 150%);
      letter-spacing: -0.02em;
      color: #a3a9b5;
    }

    &_info {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 7px;
      margin-top: 10px;
    }
  }
  &_block {
    display: flex;
    align-items: center;
    flex-direction: column;

    &_avatar {
      flex-shrink: 0;
      width: 85px;
      height: 85px;
      text-transform: uppercase;
      @include font(500, 24px, 29px);
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #daebff;
      color: #8aabd1;
      letter-spacing: 0.02em;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
    }

    &_name {
      @include font(500, 20px, 24px);
      letter-spacing: 0.02em;
      color: $font-title;
      margin-bottom: 5px;
      text-transform: capitalize;
    }

    &_email {
      @include font(500, 10px, 150%);
      letter-spacing: -0.02em;
      color: #a3a9b5;
    }

    &_activity {
      @include font(500, 12px, 150%);
      letter-spacing: -0.02em;
      color: #357EEB;
      margin: 12px 0 17px;
    }
  }
}

.close_btn {
  @include position_t_r(absolute, 20px, 30px);
  text-align: center;
  cursor: pointer;

  & svg:hover {
    path {
      fill: rgb(172, 172, 172);
    }
  }
}

.datepicker {
  width: 100%;
  margin-left: 10px;

  &_open {
    min-height: 300px;
  }

  & input {
    border-radius: 5px;
    color: $font-color-rose;
    height: 25px;
    text-align: center;
    margin-left: 20px;
  }
}

.student_button {
    color: #ffffff;
    height: 54px;
    width: 100%;
    border-radius: 10px;
    background: rgb(53, 126, 235);

    &:hover:not(:disabled) {
      color: #ffff;
      background: rgb(53, 126, 235);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed !important;
    }
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      font-size: 12px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      font-size: 14px;
    }
  }

.delete_button {
  color: #ffffff;
  height: 54px;
  width: 100%;
  border-radius: 10px;
  background: rgb(127, 127, 127);

  &:hover:not(:disabled) {
    color: #ffff;
    background: rgb(127, 127, 127);
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
  }
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    font-size: 12px;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    font-size: 14px;
  }
}


.accardions {
  width: 100%;
  margin-bottom: 1rem;
}

.button_container{
    margin-top: 40px;
    width: 100%;
    height: 54px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
}