@import '../../../scss/mixin.scss';

.accardionWrapper_component_exerciseWrapper {
  //@include display(flex, column, center, center);
  //padding: 0 89px 0 94px;

  &_exercise {
    @include display(flex, row, flex-start, center);
    width: 100%;
    height: 58px;
    padding: 0 10px 0 8px;
    //margin-bottom: 16px;
    border-radius: 16px;
    //background: #f5f5f5;
    transition: background-color 0.3s ease, transform 0.3s ease;
    transform-origin: top;
    background-color: #ffffff;
    filter: drop-shadow(0px 0px 8px rgba(0.19607843458652496, 0.2549019753932953, 0.5843137502670288, 0.30000001192092896));
    &:hover {
      background-color: #b4cffc;
      transform: scale(1.03);
    }

    &_nameWrapper {
      @include display(flex, row, space-between, center);
      margin-left: 10px;
      width: 100%;

      &_title {
        @include font(500, 20px, 15px);
        color: #000000;
        padding: 0 5px;
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          @include font(500, 14px, 14px);
          margin: 0;
        }
        @media only screen and (min-width: 376px) and (max-width: 480px) {
          @include font(500, 16px, 15px);
          margin: 0;
        }
        @media only screen and (min-width: 481px) and (max-width: 700px) {
          @include font(500, 16px, 15px);
        }
        @media only screen and (min-width: 701px) and (max-width: 1000px) {
          @include font(500, 18px, 16.8px);
        }
      }

      &_status {
        //@include font(400, 8px, 10px);
        //color: #ba75ff;
      }

      &_status_neg {
        @include font(400, 8px, 10px);
        color: #ada9b1;
      }
    }
  }
}

.accardionWrapper_component_exerciseWrapperImage {
  &_exerciseimage {
    @include display(flex, column, flex-start, center);
    padding: 0;
    gap: 4px;
    //&:hover {
    //  background-color: #b4cffc;
    //  transform: scale(1.05);
    //}
    &_imageDiv {
      //@include display(flex, row, space-between, center);
      height: 160px;
      width: 100%;
      border-radius: 24px;
      padding: 0;
      position: relative;
      background-color: #d2d8e0;
      &_image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        background-color: #93bffa;
        border-radius: 24px;
      }

      &_status {
        position: absolute;
        right: 12px;
        top: 15px;
        width: 21px;
        height: 21px;
      }
    }
    &_nameWrapper {
      width: 166px;
      height: 20px;

      &_title {
        @include font(500, 14px, 14.6px);
        color: #000000;
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          margin: 0;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          margin: 0;
        }
        // text-align: left;
        // vertical-align: middle;
        overflow: hidden;
        white-space: nowrap;
        display: inline-block;
        width: 166px;
        height: 100%;
        text-overflow: ellipsis;
      }
    }
  }
}

.inactive {
  opacity: 0.5;
  pointer-events: none;
}

.inactive:hover {
  cursor: default;
}
