@import '../../..//scss/variable';

.chipsContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 8px;
  justify-content: flex-start;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    margin-left: 1rem;
    margin-top: 0;
    padding: 1rem 0 1rem 0;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    margin-left: 1rem;
    margin-top: 0;
    padding: 1rem 0 1rem 0;
  }
}

.removeChips {
  color: $font-title;
  border: none;
  outline: none;
  align-self: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.removeChips:hover {
  color: $font-color-rose;
  background: $backgroundCheckBox;
  transition: color, background-color 0.3s;
  -webkit-transition: color, background-color 0.3s;
  -moz-transition: color, background-color 0.3s;
  -ms-transition: color, background-color 0.3s;
  -o-transition: color, background-color 0.3s;
}

.chips_filter {
  color: $font-title;
  font-weight: 600;
}

.chips_filter::first-letter {
  text-transform: capitalize;
}

.chip {
  display: flex;
  align-items: center;
  background-color: #eeeeee;
  color: $font-title;
  border-radius: 9999px;
  font-size: 14px;
  padding-bottom: 0.625rem;
  padding-top: 0.625rem;
  padding-left: 1rem;
  padding-right: 1rem;
  -webkit-border-radius: 9999px;
  -moz-border-radius: 9999px;
  -ms-border-radius: 9999px;
  -o-border-radius: 9999px;
}

.chip span {
  margin-right: 4px;
}

.removeButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 1.25rem;
  width: 1.25rem;
  border: none;
  background-color: transparent;
  color: #999;
  font-size: 14px;
  margin-left: 5px;
  cursor: pointer;
  flex-shrink: 0;
  background: #e5e7eb;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.removeButton:hover {
  color: $font-color-rose;
  background: $backgroundCheckBox;
  transition: color, background-color 0.3s;
  -webkit-transition: color, background-color 0.3s;
  -moz-transition: color, background-color 0.3s;
  -ms-transition: color, background-color 0.3s;
  -o-transition: color, background-color 0.3s;
}

.searchIcon {
  path {
    fill: url(#gradient);
  }
}
