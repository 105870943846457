@import '../../scss/mixin.scss';

.wrapper {
  position: relative;
  // margin-right: 12px;
}

.header_dropdown_menu, .header_groups_down {
  @include display(flex, row, space-between, center);
  @include font(500, 12px);
  cursor: pointer;
  max-width: 167px;
  width: 167px;
  max-height: 40px;
  padding: 10px 9px;
  border-radius: 7px;
  box-sizing: border-box;
  color: #6b7484;
  @media only screen and (min-width : 320px) and (max-width : 375px) {
    margin-left: 2%;
    margin-right: 2%;
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    margin-left: 2%;
    margin-right: 2%;
  }

  span {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 50%;

    svg {
      transform: translate(4px, 7px);
      -webkit-transform: translate(4px, 7px);
      -moz-transform: translate(4px, 7px);
      -ms-transform: translate(4px, 7px);
      -o-transform: translate(4px, 7px);
    }
  }
}

.header_groups_down {
  max-width: 470px !important;
  width: 470px !important;
}

.drop_down_item_container {
  @include position_t_l(absolute, 55px, 75px);
  color: #6b7484;
  padding-top: 10px;
  min-width: 191px;
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 7px;
  z-index: 1000;
  p {
    @include font(600, 10px, 12px);
    color: #d2d6dc;
    text-align: center;
    margin-bottom: 15px;
  }
}
.drop_down_item_content {
  cursor: pointer;
  &:hover {
    background: #e7ebf1;
  }
}
.drop_down_item {
  @include display(flex, row, flex-start, center);
  padding: 10px 20px;
  span {
    @include font(500, 14px, 17px);
    margin-left: 15px;
  }
}
.rotate_arrow {
  transform: rotate(180deg);
}
.triangle {
  @include position_t_l(absolute, 5px, 0);
  transform: translateY(-50%);
}
.bg_color_hamburger {
  background: #ededed;
}
.bg_color_accepted {
  background-color: #c5ece5;
}
.bg_color_auto_check {
  background-color: #ededed;
}
.bg_color_under {
  background-color: #fff1da;
}
.bg_color_rejected {
  background-color: #fef2f2;
}
.bg_color_waiting {
  background: #f3f4f6;
}

.arrow_humburger,
.arrow_autocheck {
  background: #e5e7eb;
}

.arrow_accepted {
  background: #a2e0d6;
}

.arrow_under_revision {
  background: #ffe8c2;
}

.arrow_rejected {
  background: #fee2e2;
}

.arrow_waiting {
  background: #e5e7eb;
}
