@import '../../../../scss/variable';
@import '../../../../scss/mixin.scss';

.paginationBox {
  margin: 2rem auto 0;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.skeleton {
  position: absolute;
  left: 132px;
  top: 215px;
}
.course_admin {
  @media screen and (max-width: 1919px) {
    grid-template-columns: repeat(auto-fit, minmax(420px, 1fr));
  }
  margin-top: 20px;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, 420px);
  gap: 50px;
  position: relative;
  z-index: 0;
  @media only screen and (min-width: 320px) and (max-width: 500px) {
    gap: 10px;
    grid-template-columns: 1fr;
  }
}
.course {
  grid-auto-flow: row;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(660px, 1fr));
  grid-template-rows: 1fr;
  gap: 40px;
  padding-bottom: 50px;
  justify-content: center;
  // margin-bottom: 250px;
  position: relative;
  z-index: 0;

  @media screen and (max-width: 1919px) {
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  }

  @media only screen and (min-width: 320px) and (max-width: 450px) {
    grid-template-columns: 1fr;
  }

  &_addCourse {
    width: 100%;
    height: 390.8px;
    background: url('../../../../assets/img/createCourse/addCourse.svg') center center no-repeat;
    display: inline-block;
    text-align: center;
    position: relative;
    cursor: pointer;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      min-height: 385px;
      border-radius: 10px;
      border: none;

      &:hover {
        border: 3px #ba75ff solid;
      }
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      min-height: 385px;
      border-radius: 10px;
      border: none;

      // &:hover {
      //   border: 3px #ba75ff solid;

      // }
    }

    @media only screen and (min-width: 481px) and (max-width: 1024px) {
      min-height: 385px;
      border-radius: 10px;
      border: none;

      &:hover {
        border: 3px #ba75ff solid;
      }
    }

    & span {
      @include position_b_l(absolute, 132px, 17%);
      color: #e0dced;
      font-size: 17px;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        @include position_b_l(absolute, 0px, 0%);
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        @include position_b_l(absolute, 140px, 35%);
      }

      @media only screen and (min-width: 481px) and (max-width: 1024px) {
        @include position_b_l(absolute, 140px, 27%);
      }
    }
  }

  &_all {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_button_add {
    background: #357eeb;
    padding: 15px 35px;
    border-radius: 10px;
    border: none;
    font-size: 20px;
    font-weight: 500;
    color: #ffffff;
    text-align: center;
  }
  &_card {
    border-radius: 10px;
    width: auto;
    max-width: 500px;
    height: 400px;
    // height: fit-content;
    //max-height: fit-content;
    box-shadow: $box-shadow;
    background: #ffffff;
    border: 3px solid #f5f9ff;
    // transition: 0.4s ease-in-out;
    // transform: scale(0.9);
    padding-bottom: 10px;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      width: 100%;
      margin-bottom: 5%;
      padding-bottom: 2%;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      width: 100%;
      margin-bottom: 5%;
      padding-bottom: 2%;
    }

    &:hover {
      border: 3px #ba75ff solid;
    }

    &_img {
      border-radius: 10px 10px 0 0;
      width: 100%;
      height: 146px;
      background: #e0dced;

      img {
        object-fit: cover;
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          object-fit: contain;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          object-fit: contain;
        }
      }
    }

    &_progressBar {
      width: 100%;
      height: 27px;
      background-color: #ffffff;

      &_line {
        width: 13%;
        height: 100%;
        background-color: #ba75ff;
      }
    }

    &_about {
      position: absolute;
      top: -30px;

      &_desc {
        display: block;
        height: 70px;
        box-sizing: border-box;
        font-size: 11px;
        color: #b4a2a2;

        &_admin {
          display: block;
          height: 55px;
          box-sizing: border-box;
          font-size: 11px;
          color: #a2a9b4;
        }
      }

      &_progressWrapper {
        @include display(flex, row, flex-start, center);

        img {
          width: 15px;
          height: 15px;
          margin-right: 9px;
        }

        &_title {
          font-size: 11px;
          color: #ba75ff;
        }
      }
    }

    &_duration {
      width: 374px;
      height: 24px;
      display: flex;
      align-items: center;

      font-family: 'SFPRORegular';
      font-size: 20px;
      font-weight: 500;
      line-height: 23.87px;
      letter-spacing: -1px;
      color: white;

      span {
        font-family: 'SFPRORegular', sans-serif;
        font-size: 20px;
        font-weight: 500;
        line-height: 23.87px;
        color: #357eeb;
      }

      &_limit {
        color: white;
      }
      &_remaining {
        color: white;

        &_expired {
          color: white;
        }
      }
    }

    &_status {
      &_show {
        @include display(flex, row, flex-start, center);
        text-align: center;

        &_public {
          color: #ba75ff;
          @include font(500, 11px);
          margin-left: 8.5px;
        }

        &_hide {
          color: #e0dced;
          @include font(500, 11px);
          margin-left: 8.5px;
        }
      }
    }

    & h5 {
      margin: 16px 0;
      color: $font-title;
      font-size: 12px;
    }
  }
}

.btn {
  align-self: flex-end;
  width: 194px;
  min-height: 33px;
  height: auto;
  background-color: #ddbbff;
  color: white;
  @include font(500, 14px);
  padding: 7px;
  margin-top: 10px;
  text-align: center;
  border: none;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    margin-top: 0;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    margin-top: 0;
  }

  &:hover {
    color: #ba75ff;
    background-color: #ffffff;
  }

  &:disabled {
    color: #f9f5fd;
    background-color: #e8e6e6;
  }

  &_admin {
    align-self: flex-end;
    width: 194px;
    height: 33px;
    background-color: #ddbbff;
    color: white;
    @include font(500, 14px);
    padding: 7px;
    margin-top: 10px;
    text-align: center;
    border: none;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      // margin-top: 0;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      // margin-top: 0;
    }

    &:hover {
      color: #ba75ff;
      background-color: #ffffff;
    }
  }

  &_student {
    align-self: flex-end;
    width: 194px;
    min-height: 33px;
    height: auto;
    background-color: #ddbbff;
    color: white;
    @include font(500, 14px);
    padding: 7px;
    text-align: center;
    border: none;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      // margin-top: 0;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      // margin-top: 0;
    }

    &:hover {
      color: #ba75ff;
      background-color: #ffffff;
    }
  }

  &_teacher {
    align-self: flex-end;
    width: 194px;
    height: 33px;
    background-color: #ddbbff;
    color: white;
    @include font(500, 14px);
    padding: 7px;
    margin-top: 80px;
    text-align: center;
    border: none;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      // margin-top: 0;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      // margin-top: 0;
    }

    &:hover {
      color: #ba75ff;
      background-color: #ffffff;
    }
  }
}

.no_image_found {
  position: absolute;
  top: 0;
  bottom: 0;

  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px 0 0;
  span {
    color: rgb(0, 0, 0);
    font-size: 16px;
    opacity: 0.7;
    font-weight: bold;
  }
}

.search {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 24px;
  width: 100%;
  padding-top: 2rem;
  margin-bottom: 2rem;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 1rem 1rem 0 1rem;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 1rem 1rem 0 1rem;
  }
}

@media (max-width: 768px) {
  .course {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.searchIcon {
  path {
    fill: url(#gradient);
  }
}

.input {
  div {
    div {
      box-shadow: 0px 0px 8px 0px #3241954d;
      border: none;
      border-radius: 14px;
      padding: 10px;

      @media only screen and (min-width: 320px) and (max-width: 575px) {
        height: 40px;
      }
    }
  }

  input {
    @media only screen and (min-width: 320px) and (max-width: 575px) {
      font-size: 14px;
    }
  }

  input::placeholder {
    @media only screen and (min-width: 320px) and (max-width: 575px) {
      font-size: 14px;
    }
  }
}
