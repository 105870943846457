.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999999;
  }
  
  .modal-content {
    position: absolute;
    top: 50%;
    left: 52%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    height: 40%;
    max-height: 90%;
    overflow-y: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

    @media only screen and (min-width : 2400px) and (max-width : 3500px) {
      width: 40%;
      height: 33%;
    }
    @media only screen and (min-width : 2100px) and (max-width : 2400px) {
        width: 40%;
        height: 42%;
      }
    @media only screen and (min-width : 2000px) and (max-width : 2100px) {
        width: 40%;
        height: 47%;
      }
    @media only screen and (min-width : 1900px) and (max-width : 2000px) {
        width: 40%;
        height: 50%;
      }
    @media only screen and (min-width : 1600px) and (max-width : 1900px) {
        width: 40%;
        height: 57%;
      }
    @media only screen and (min-width : 1400px) and (max-width : 1600px) {
        width: 40%;
        height: 63%;
      }
      @media only screen and (min-width : 1300px) and (max-width : 1400px) {
        width: 40%;
        height: 71%;
      }
      @media only screen and (min-width : 100px) and (max-width : 1300px) {
        width: 40%;
        height: 81%;
        left: 50%;
      }
}
  
  .modal-content h2 {
    margin-bottom: 20px;
    text-align: center;
  }

  .label-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .modal-content input {
    padding: 7px;
    margin-bottom: 5px;
    margin-top: 5px;
  }
  
  .modal-content p {
    color: red;
    margin-bottom: 10px;
  }

  .textareaFullWidth {
    width: 100%;
    box-sizing: border-box;
    resize: none;
    min-height: 100px;
}

  .currencyContainer {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 1px;
    margin-bottom: 15px;
  
    label {
      display: block;
    }
  
    select {
      width: 100%;
      padding: 8px;
      color: #666;
      border: none;
      border-radius: 8px;
      background-color: transparent;
      outline: none;
    }
  }

  .closeButton {
    outline: none;
    border: none;
    color: #cd58eb;
    position: absolute;
    right: 4%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f1f1f1;
    border-radius: 5px;
    width: 15px;
    height: 15px;
    cursor: pointer;
    transition: 0.3s color;
    z-index: 999;
    background: none;
  }
  
  .input-container {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 5px;
  }
  
  .input-container input {
    border: none;
    outline: none;
    width: 100%;
    height: 20px;
  }

  .input-container textarea {
    border: none;
    outline: none;
    width: 100%;
    height: 40px;
    resize: none;
  }

  .btn {
    align-self: flex-end;
    width: 150px;
    height: 40px;
    background-color: #ddbbff;
    color: white;
    font: 500, 14px;
    padding: 7px;
    margin-top: 10px;
    text-align: center;
    border: none;
    @media only screen and (min-width : 320px) and (max-width : 375px) {
      margin-top: 0;
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
      margin-top: 0;
    }
  
    &:hover {
        color: #ba75ff;
        background-color: #ffffff;
    }
  
    &:disabled {
        color: #f9f5fd;
        background-color: #e8e6e6;
    }
}

.custom-checkbox {
  margin-top: 10px;
}

.checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

