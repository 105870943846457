@import 'scss/mixin.scss';
@import 'scss/variable.scss';

.wrapper {
  padding: 1rem;
  width: 100vw;
  height: auto;
  background: #e0dced;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 0;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 0;
  }

  &_banner {
    // background: #e0dced;
    width: 1040px;
    padding: 2rem 0 2rem 0;
    border-radius: 7px;
    margin-top: -20px;

    @media (max-width: 600px) {
      width: 100%;
      padding: 2rem 20px;
    }

    h2 {
      font-weight: 700;
      font-size: 22px;
    }

    &_content {
      display: inline-flex;

      &_createProject {
        @include display(flex, column);
        gap: 1rem;
        padding-top: 60px;
        // margin-left: 120px;
        h1 {
          margin: 0;
        }

        p {
          color: #4d5766;
          font-size: 14px;
          width: 80%;
        }
        button {
          width: 241px;
          height: 52px;
          font-size: 12px;
        }
      }
      &_images {
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;

        &_firstStep {
          width: 280px;
          height: 209px;
          margin-top: 133px;
          z-index: 1;
          position: absolute;
        }
        &_secondStep {
          width: 308px;
          height: 231px;
          margin-left: 200px;
          position: absolute;
          margin-top: 36px;
        }
      }
      @media (min-width: 320px) and (max-width: 1024px) {
        &_images {
          display: none;
        }
        height: auto;
        &_createProject {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin: 20px;
          height: auto;
          h1 {
            font-size: 32px;
          }
        }
      }
      @media (min-width: 320px) and (max-width: 481px) {
        h1 {
          font-size: 23px;
        }
        button {
          width: 150px;
          margin: 30px 0;
        }
      }
    }
  }

  &_courseStats {
    position: relative;
    width: 831px;
    height: 125px;
    // margin: -20px auto 0;
    margin-top: -20px;
    z-index: 10;
    border-radius: 30px;
    box-shadow: 0 2px 8px rgb(147, 147, 147);
    background-color: #ffffff;
    @include display(flex, row, center, center);
    gap: 5rem;
    padding: 2rem;

    @media screen and (min-width: 375px) and (max-width: 480px) {
      flex-direction: column;
      height: auto;
      margin: -20px auto 0;
      width: 90%;
      gap: 20px;
      padding: 20px;
    }

    &_stat {
      @include display(flex, column, center, center);
      @include font(700, 22px);
      color: #ba75ff;

      &_all {
        @include display(flex, row, center, center);
        @include font(700, 22px);
        color: #ba75ff;

        &_text {
          @include display(flex, column, center, center);
          @include font(700, 22px);
          color: #ba75ff;
          padding-left: 10px;
        }
      }

      span {
        @include display(flex, row, center, center);
      }
    }
  }
}

.previous {
  position: relative;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  background: #e0dced;
  border-radius: 7px;
  z-index: 20;
  height: auto;
  height: auto;

  @media (max-width: 600px) {
    width: 100%;
    height: auto;
  }

  &_buttonAccept {
    button {
      width: 203px;
      height: 36px;
      font-size: 16px;
      font-weight: 700;
      padding: 0;
    }

    @include position_b_r(absolute, 50px, 44px);

    @media screen and (min-width: 375px) and (max-width: 480px) {
      @include position_b_r(absolute, 55px, 16px);
    }
  }

  &_bcgrShadow {
    @include position_t_l(absolute, 0, 0);
    opacity: 0.5;
    width: 100%;
    height: 100%;
    background-color: #000000;
    border-radius: 7px;
  }

  .background_image_course {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border-radius: 7px;
    background-color: rgba(248, 229, 255, 0.68);

    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 7px;
    }
  }

  &_onlineCourses {
    @include position_t_l(absolute, 107px, 44px);
    @include font(700, 22px, 24px);
    color: #ba75ff;
    text-transform: uppercase;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include position_t_l(absolute, 107px, 16px);
      @include font(500, 16px, 17px);
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include position_t_l(absolute, 107px, 16px);
      @include font(500, 16px, 17px);
    }
  }
}
.input_change {
  border: none;
  outline: none;
  background: #e0dced;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}
.hide_input {
  @include position_t_l(absolute, 0, 0);
  width: 66px;
  height: 66px;
  opacity: 0;
}
.input_background_image {
  opacity: 0;
}
.label_input_background_image {
  @include position_t_l(absolute, 0, 50%);
  transform: translateX(-50%);
  border-radius: 0 0 15px 15px;
  padding-top: 15px;
  width: 30%;
  display: block;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.3);
  text-align: center;
}
.previous_title_name {
  @include position_t_l(absolute, 140px, 43px);
  @include font(500, 22px, 28px);
  color: #fff;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    @include position_t_l(absolute, 140px, 16px);
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    @include position_t_l(absolute, 140px, 16px);
  }
}
.back_all_course {
  @include position_t_l(absolute, 40px, 44px);
  @include display(flex, row, flex-start, center);
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    @include position_t_l(absolute, 22px, 16px, 10);
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    @include position_t_l(absolute, 25px, 16px, 10);
  }

  span {
    @include font(500, 12px, 15px);
    margin-left: 13px;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      @include font(500, 10px, 12px);
      color: #ffffff;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      @include font(500, 13px, 12px);
      color: #ffffff;
    }
  }
}

.accardionWrapper {
  &_component {
    @include display(flex, column, center);
    background-color: #fff;
    box-shadow: 0px 0px 8px 0px #3241954D;
    border-radius: 20px;
    margin-bottom: 16px;
    transition: all 0.3s;
    width: 100%;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      margin: 5px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      margin: 5px;
    }
    &:hover {
      cursor: pointer;
    }
    &_header {
      @include display(flex, row, space-between, center);
      width: 100%;
      height: 89px;
      padding: 11px 40px;
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        padding: 21px 11px;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        padding: 21px 11px;
      }

      &_showBtnWrapper_active > svg {
        // fill: '#C6C6C6';
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        -o-transform: rotate(180deg);
      }

      &_showBtnWrapper > svg {
        // fill: '#4D5766';
        transform: rotate(0);
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
      }
      svg {
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          width: 15px;
          height: 15px;
          margin-left: 10px;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          width: 15px;
          height: 15px;
          margin-left: 10px;
        }
      }

      &_completedIcon {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 57px;
        height: 57px;
        background: rgba(207, 226, 255, 0.12);
        font-weight: 700;
        font-size: 22px;
        line-height: 18px;
        color: $color-blue;
        //background-color: #ba75ff;
        border-radius: 50%;
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          width: 30px;
          height: 25px;
          font-size: 10px;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          width: 30px;
          height: 25px;
          font-size: 10px;
        }

        svg {
          @include position_t_l(absolute, calc(50% - 8px), calc(50% - 7px));
        }
      }
      &_lessonName {
        @include display(flex, row, flex-start, center);
        width: 90%;
        margin-left: 32px;
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          margin-left: 9px;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          margin-left: 9px;
        }

        &_title {
          @include display(flex, row, flex-start, center);
          @include font(700, 22px, 16px);
          color: $color-blue;
          @media only screen and (min-width: 320px) and (max-width: 375px) {
            font-size: 10px;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            font-size: 10px;
          }

          span {
            display: block;
            width: 20px;
            height: 2px;
            margin: 0 10px;
            background-color: #9ca3af;
          }
        }
        &_exerciseSum {
          @include font(600, 22px, 16px);
          color: $color-grey;
          @media only screen and (min-width: 320px) and (max-width: 375px) {
            font-size: 10px;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            font-size: 10px;
          }
          span {
            margin-left: 5px;
          }
        }
      }
    }

    &_exerciseWrapper {
      @include display(flex, column, center, center);
      padding: 10px 89px 20px 94px;
      gap: 5px;
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        padding: 10px 10px;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        padding: 10px 10px;
      }

      &_exercise {
        height: 50px;
        margin-bottom: 16px;
        border-radius: 7px;
        background-color: #f1e3ff;
      }
    }
  }
}

.phoneInput {
  width: 100% !important;
  line-height: 1.4375em !important;
  padding: 28px 48px;
  font-size: 16px !important;

  &:hover {
    border: 1px solid black;
  }

  &:focus {
    border: 2px solid #ba75ff;
  }
}
