@import '../../../scss/mixin.scss';


.filterButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.filterButton {
  padding: 10px 10px;
  cursor: pointer;
  //margin: 0 0;
  font-size: 16px;
  //magrin-bottom: 16px;
  top:0px;
  width: 90%;

  &:hover {
    background: #e7ebf1;
  }
}
