@import '../../scss/mixin.scss';
@import '../../scss/variable.scss';

@font-face {
  font-family: 'SFPROBold';
  src: url(../../assets/fonts/SFProText/SFProText-Bold.ttf) format('opentype');
}

@font-face {
  font-family: 'SFPROMedium';
  src: url(../../assets/fonts/SFProText/SFProText-Medium.ttf) format('opentype');
}

@font-face {
  font-family: 'SFPROHeavy';
  src: url(../../assets/fonts/SFProText/SFProText-Heavy.ttf) format('opentype');
}

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../assets/fonts/SFProText/SFProText-Regular.ttf) format('opentype');
}

.blurLoad {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 30;
  backdrop-filter: blur(10px);
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

.active {
  width: 28.375rem !important;
  height: 9.9375rem !important;
}

.wrapper {
  position: relative;
  display: flex;
  width: 100%;
  height: 8.3125rem;
  padding: 1rem;
  border-radius: 1rem;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 2px 25px 0px rgba(53, 126, 235, 0.5);
  transition: all ease-in 0.15s;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    background-color: rgba(51, 47, 54, 0.5);
    z-index: 1;
  }

  &:hover {
    box-shadow: 0px 2px 25px 0px #0d28bbcc;

    .wrapper_shadow {
      background: linear-gradient(90deg, rgba(51, 47, 54, 0.3) -5.88%, #3e80e2 100%);
    }
  }

  &_shadow {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    transition: all 0.5s;
    z-index: 10;
    border-radius: 1rem;
  }

  &_settings {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    z-index: 20;
    cursor: pointer;
  }

  &_text {
    display: flex;
    z-index: 20;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    transition: all ease-in 0.2s;
    cursor: pointer;
    overflow: hidden;
    width: 100%;

    &_title {
      width: 100%;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-family: 'SFPROBold';
      color: var(--White, #fff);
      transition: all ease-in 0.2s;
      text-wrap: nowrap;
      text-overflow: ellipsis;
    }

    &_description {
      color: var(--White, #fff);
      text-align: center;
      font-family: 'SFPRORegular';
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
      gap: 0.62rem;
    }
  }
}
