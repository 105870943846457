.accardionWrapper {
  width: 100%;
  margin: 0 auto;
  background: transparent;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 0;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 0;
  }
}
