@import '../../scss/mixin.scss';
@import '../../scss/variable.scss';

.wrapper {
  width: 100%;
  padding: 10px;
  @include display(flex, column, center, flex-start);
  gap: 20px;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
}

.text {
  font-weight: bold;
  font-size: 16px;
  color: #332f36;
  font-family: 'SFPRORegular', sans-serif;
  text-align: left;
}

.preview {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.buttonsBlock {
  button {
    padding: 10px 25px;
    font-family: 'SFPRORegular', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.71px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
