@import '../../scss/mixin.scss';
@import 'src/scss/variable';

.wrapper {
  position: relative;
  margin-right: 7px;
  width: fit-content;
  z-index: 20;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding-left: 1rem;
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding-left: 1rem;
  }
}

.container_btn {
  padding: 12px 30px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0;
  text-align: center;
}

.header_dropdown_menu {
  @include font(600, 11px, 10px);
  font-style: normal;
  letter-spacing: -0.01em;
  color: #d2d6dc;
  text-align: center;
  margin-bottom: 15px;
}

.drop_down_block {
  @include position_t_l(absolute, 40px, 70px);
  width: 310px;
  border-radius: 7px;
  display: inline-block;
  box-sizing: border-box;
  padding-top: 16px;
  margin-top: 10px;
  background-color: #ffffff;
  box-shadow: 1px 1px 3px 3px rgba(0, 0, 0, 0.1);
  z-index: 1000;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 70vw;
    left: -100px;
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    width: 70vw;
    left: -100px;
  }
}

.search_filter_icon {
  height: 62px;
  width: 64px;
  padding: 0px;
  border-style: hidden;
  outline: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #cfe2ff;
    border-radius: 10px;
  }
}

.icon_svg {
  pointer-events: auto;
  cursor: pointer;
  position: relative;
}

.search_filter_icon.active {
  background-color: #cfe2ff;
  border-radius: 10px;
}

.filter_container {
  border-radius: 32px;
  backdrop-filter: blur(14px);
  background: #cfe2ff;
  width: 240px;
  height: auto;
  display: flex;
  padding-bottom: 20px;
  padding-top: 20px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  position: absolute;
  left: calc(100% + 20px);
  top: -50%;
  z-index: 30;
}

.filterPointer {
  position: absolute;
  left: -9px;
  top: 25px;
  z-index: -1;
}

.resetButton {
  position: absolute;
  top: 50%;
  right: 45px;
  transform: translateY(-50%);
  border: none;
  background: #cfe2ff93;
  border-radius: 12px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
}