@import '../../../scss/mixin.scss';

.container {
  @include display(flex, column);
  background-color: #ffffff;
  min-width: 319px;
  border-radius: 10px;
  box-sizing: border-box;
  padding-bottom: 30px;
  & > .input {
    border: none;
    border-bottom: 1px solid #e8e8e8;
  }
  button {
    margin-top: 20px;
  }

  @media only screen and (min-width : 320px) and (max-width : 375px) {
    width: 70vw;
    margin-left: -50px;
    padding-left: 1rem; 
  }
  
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    width: 70vw;
    margin-left: -50px;
    padding-left: 1rem;
   
  }
}
.title {
  font-size: 10px;
  text-align: center;
  color: #d2d6dc;
  margin: 0 0 20px 0;
}
.wrapper {
  min-height: 200px;
  max-height: 200px;
  overflow: auto;
  margin: 10px 0;
  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e1dbe6;
    height: 128px;
    border: 3px solid #ffff;
    border-top: 8px solid #ffff;
    border-bottom: 8px solid #ffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
  }
}

.category_filter_item {
  padding: 12px 20px;
  @include font(500, 14px, 17px);
  color: #4d5766;
}
.category_content {
  cursor: pointer;
  &:hover {
    background: #e7ebf1;
  }
}
.element {
  @include font(500, 14px);
  color: #4d5766;
  padding: 13px 20px;
  cursor: pointer;
  &:hover {
    background: #e7ebf1;
  }
}
