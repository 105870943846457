@import '../../../scss/mixin.scss';
@import '../../../scss/variable.scss';

.main {
  display: flex;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;

  &_head {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &_title {
    font-family: 'SFPRORegular', sans-serif;
    font-size: 18px;
    color: rgb(51, 47, 54);
    font-weight: 600;
    line-height: 29px;
  }

  &_body {
    position: relative;
    overflow: visible;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }
}
