* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.HelpCenterPage {
    h1 {
        margin: 23px;
        color: #4D5766;
        font-size: 35px;
    }
    &_pageHeader{
        height: 308px;
        background: #E0DCED;
        border-radius: 7px;
        align-items: center;
        flex-direction: column;
        display: flex;
        p {
            padding: 75px 10px 0 10px;
            font-weight: 500;
            color: #BA75FF;
            font-size: 14px;
        }
        h1 {
            margin: 23px;
        }
        &_inputGroup {
            display: inline-flex;
            align-items: center;
            max-width: 584px;
            margin-bottom: 82px;
            &_input {
                height: 45px;
            }
            button {
                margin-left: 5px;
                height: 45px;
                width: 105px;
                font-size: 15px;
            }
        }
        @media (min-width: 320px) and (max-width: 650px) {
            padding: 10px;
            height: 100%;
            p {
                text-align: center;
            }
            h1 {
                font-size: 26px;
                text-align: center;
            }
            &_inputGroup {
                display: flex;
                flex-direction: column;
                button {
                    margin-top: 20px;
                }
            }

        }
    }
    &_quickStart {
        align-items: center;
        flex-direction: column;
        display: flex;
        h1 {
            margin-top: 90px;
        }
        &_userSelect {
            display: inline-flex;
            align-items: center;
            h5 {
                margin-right: 45px;
                font-size: 14px;
            }
            hr {
                width: 150px;
                margin-top: 6px;
            }
            &_authorEnabled {
                width: 220px;
                h5 {
                    float: right;
                    color: #BA75FF;
                }
                hr {
                    border: 1px solid #BA75FF;
                    float: right;
                }
            }
            &_studentDisabled {
                width: 220px;
                h5 {
                    color: #D6D6D6;
                    margin-left: 45px;
                }
                hr {
                    border: 1px solid #D6D6D6;
                }
            }
            &_authorDisabled {
                width: 220px;
                h5 {
                    float: right;
                    color: #D6D6D6;
                }
                hr {
                    border: 1px solid #D6D6D6;
                    float: right;
                }
            }
            &_studentEnabled {
                width: 220px;
                h5 {
                    color: #BA75FF;
                    margin-left: 45px;
                }
                hr {
                    border: 1px solid #BA75FF;
                }
            }


        }
        &_cardGroup {
            margin-top: 45px;
            display: inline-flex;
            align-items: center;
            width: 100%;
            justify-content: space-around;
            &_card {
                width: 313px;
                height: 189px;
                margin: 5px;
                background: #FFFFFF;
                border: 1px solid #BA75FF;
                border-radius: 20px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                &_text {
                    margin: 40px 20px 40px 40px;

                    h3 {
                        color: #4D5766;
                    }
                    p {
                        margin-top: 25px;
                        font-size: 15px;
                        color: #4D5766;
                    }
                }
            }
            @media (min-width: 767px) and (max-width: 1024px) {
                display: grid;
                grid-template-columns: 1fr 1fr;
                &_card {
                        margin: 10px;
                }
            }
            @media (min-width: 320px) and (max-width: 767px) {
                display: flex;
                flex-direction: column;
                &_card {
                    margin-bottom: 10px;
                }
            }
        }
        @media (min-width: 320px) and (max-width: 767px) {

                h5 {
                    font-size: 11px;
                }
        }
    }
    &_FAQ {
        flex-direction: column;
        display: flex;
        max-width: 725px;
        margin: 0 auto;
        h1 {
            text-align: center;
            margin: 106px 0 62px 0;
        }
        h2 {
            color: #BA75FF;
            font-size: 23px;
        }
        p {
            margin-top: 20px;
            margin-bottom: 45px;
            font-size: 15px;
        }
    }

    &_banner {
        margin-top: 65px;
        width: 100%;
        background: #E0DCED;
        height: 379px;
        display: inline-flex;
        &_createProject {
            padding-top: 80px;
            margin-left: 120px;
            h1 {
                margin: 0;
            }
            p {
                margin-top: 11px;
                color: #4D5766;
                font-size: 14px;
                width: 80%;
            }
            button {
                margin: 46px 0 0 27px;
                width: 241px;
                height: 52px;
                font-size: 12px;
            }
        }
        &_images {
            position: relative;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            &_firstStep {
                width: 280px;
                height: 209px;
                margin-top: 133px;
                z-index: 1;
                position: absolute;
            }
            &_secondStep {
                width: 308px;
                height: 231px;
                margin-left: 200px;
                position: absolute;
                margin-top: 36px;
            }
        }
        @media (min-width: 320px) and (max-width: 1024px) {
            &_images {
                display: none;
            }
            height: 100%;
            &_createProject {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                margin: 20px;
                padding-top: 50px;
                h1 {
                    font-size: 32px;
                }
            }
        }
        @media (min-width: 320px) and (max-width: 481px) {
            h1 {
                font-size: 23px;
            }
            button {
                width: 150px;
                margin: 30px auto 0 auto;
            }
        }

    }

    &_footer {
        margin: 74px auto 0 auto;
        display: flex;
        flex-direction: column;
        width: fit-content;
        align-items: center;
        &_links {
            align-items: center;
            width: 400px;
            display: flex;
            justify-content: space-between;
            a {
                color: #6B7280;
                font-size: 10px;
            }
        }
        h1 {
            color: #D7D7D7;
            font-weight: 800;
            font-size: 33px;
            margin: 34px 0 9px 0;
        }
        h5 {
            color: #D7D7D7;
            font-size: 14px;
        }
        @media (min-width: 320px) and (max-width: 560px) {
            &_links {
                width: 100%;
                flex-direction: column;
                a {
                    padding: 5px;
                }
                h1 {
                    font-size: 23px;
                }
                p {
                    font-size: 10px;
                }
            }
        }
    }
}