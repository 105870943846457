.bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  &_wrap1 {
    position: absolute;
    z-index: -1;
    width: 500px;
    height: 500px;
    background: rgba(87, 184, 255, 0.4);
    border-radius: 50%;
    animation: bgAnimate1 50s linear infinite;
  }

  &_wrap2 {
    z-index: -1;
    position: absolute;
    width: 500px;
    height: 500px;
    left: 100%;
    top: 100%;
    background: rgba(87, 184, 255, 0.4);
    border-radius: 50%;
    animation: bgAnimate2 50s linear infinite;
  }

  &_wrap3 {
    z-index: -1;
    position: absolute;
    width: 500px;
    height: 500px;
    left: 100%;
    top: 0%;
    background: rgba(87, 184, 255, 0.4);
    border-radius: 50%;
    animation: bgAnimate3 50s linear infinite;
  }

  &_wrap4 {
    z-index: -1;
    position: absolute;
    width: 500px;
    height: 500px;
    left: 0%;
    top: 100%;
    background: rgba(87, 184, 255, 0.4);
    border-radius: 50%;
    animation: bgAnimate4 50s linear infinite;
  }
}

@keyframes bgAnimate1 {
  from {
    transform: translate3d(0, 0, 0);
  }

  20% {
    transform: translate3d(50%, 100%, 0);
  }

  30% {
    transform: translate3d(150%, 150%, 0);
  }

  50% {
    transform: translate3d(400%, 200%, 0);
  }

  70% {
    transform: translate3d(300%, 70%, 0);
  }

  90% {
    transform: translate3d(100%, 30%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bgAnimate2 {
  from {
    transform: translate3d(0%, 0%, 0);
  }
  20% {
    transform: translate3d(-100%, -100%, 0);
  }
  30% {
    transform: translate3d(-200%, -150%, 0);
  }
  50% {
    transform: translate3d(-400%, -200%, 0);
  }
  70% {
    transform: translate3d(-300%, -60%, 0);
  }
  90% {
    transform: translate3d(-100%, -10%, 0);
  }
  to {
    transform: translate3d(0%, 0%, 0);
  }
}

@keyframes bgAnimate3 {
  from {
    transform: translate3d(0, 0, 0);
  }

  20% {
    transform: translate3d(-150%, 10%, 0);
  }

  30% {
    transform: translate3d(-300%, 100%, 0);
  }

  50% {
    transform: translate3d(-400%, 200%, 0);
  }

  70% {
    transform: translate3d(-250%, 150%, 0);
  }

  90% {
    transform: translate3d(-50%, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bgAnimate4 {
  from {
    transform: translate3d(0, 0, 0);
  }

  20% {
    transform: translate3d(150%, -10%, 0);
  }

  30% {
    transform: translate3d(350%, -80%, 0);
  }

  50% {
    transform: translate3d(400%, -150%, 0);
  }

  70% {
    transform: translate3d(250%, -140%, 0);
  }

  90% {
    transform: translate3d(100%, -80%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}
