@import 'scss/mixin.scss';
@import 'scss/variable.scss';

.loaderBox {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.wrapper {
  padding: 1rem 0;
  width: 100%;
  max-width: 1360px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 0;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 0;
  }

  &_banner {
    // background: #e0dced;
    width: 1040px;
    padding: 2rem 0 2rem 0;
    border-radius: 7px;
    margin-top: -20px;

    @media (max-width: 600px) {
      width: 100%;
      padding: 2rem 20px;
    }

    h2 {
      font-weight: 700;
      font-size: 22px;
    }

    &_content {
      display: inline-flex;

      &_createProject {
        @include display(flex, column);
        gap: 1rem;
        padding-top: 60px;
        // margin-left: 120px;
        h1 {
          margin: 0;
        }

        p {
          color: #4d5766;
          font-size: 14px;
          width: 80%;
        }
        button {
          width: 241px;
          height: 52px;
          font-size: 12px;
        }
      }
      &_images {
        position: relative;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;

        &_firstStep {
          width: 280px;
          height: 209px;
          margin-top: 133px;
          z-index: 1;
          position: absolute;
        }
        &_secondStep {
          width: 308px;
          height: 231px;
          margin-left: 200px;
          position: absolute;
          margin-top: 36px;
        }
      }
      @media (min-width: 320px) and (max-width: 1024px) {
        &_images {
          display: none;
        }
        height: auto;
        &_createProject {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin: 20px;
          height: auto;
          h1 {
            font-size: 32px;
          }
        }
      }
      @media (min-width: 320px) and (max-width: 481px) {
        h1 {
          font-size: 23px;
        }
        button {
          width: 150px;
          margin: 30px 0;
        }
      }
    }
  }
}