@import '../../scss/mixin.scss';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.loginPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  height: 100%;

  &_btnBack {
    @media only screen and (min-width: 320px) and (max-width: 1000px) {
      left: 10px;
      top: 13vw;
      width: 40px;
      height: 40px;
      border-radius: 10px;
      position: absolute;
      background: #cfe2ff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    width: 50px;
    height: 50px;
    border-radius: 10px;
    position: absolute;
    left: 30px;
    top: 93px;
    background: #cfe2ff;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 20px;
      height: 32px;

      @media only screen and (min-width: 320px) and (max-width: 1000px) {
        width: 3vw;
        height: 3.5vw;
      }
    }
  }

  &_logoWrapper {
    img {
      @media only screen and (min-width: 320px) and (max-width: 1000px) {
        width: 50vw;
        margin-top: 100px;
      }
    }

    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    margin-top: 100px;
    animation: fadeIn 2s ease-in-out forwards;

    @keyframes fadeIn {
      from {
        opacity: 0;
      }
      to {
        opacity: 0.9;
      }
    }

    p {
      color: #252762;
      font-size: 35px;
      font-weight: 800;
      text-shadow: 2px 2px 4px rgba(63, 63, 63, 0.5);
    }

    &_container {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: auto;
      width: 100%;
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        background-color: #f3f4f6;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        background-color: #f3f4f6;
      }

      &_title {
        margin-top: 13px;
        font-weight: 800;
        font-size: 35px;
        line-height: 42px;
        color: #ffffff;
      }
    }
  }

  &_formWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0 1rem;
    flex-direction: column;
    align-items: center;
    //background-color: #F3F4F6;
    opacity: 0;
    animation: slideIn 1.5s ease-in-out forwards;

    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateY(100%);
      }
      to {
        opacity: 1;
        transform: translateY(0);
      }
    }

    &_form {
      margin-top: 40px;
      width: 400px;
      height: 650px;

      @media only screen and (min-width: 320px) and (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      &_title {
        font-weight: 700;
        font-size: 28px;
        line-height: 30px;
        letter-spacing: 0.02em;
        color: #252762;
        text-align: center;

        @media only screen and (min-width: 320px) and (max-width: 1000px) {
          font-weight: 700;
          font-size: 28px;
          line-height: 30px;
          letter-spacing: 0.02em;
          color: #252762;
          text-align: center;
        }

        &_comment {
          margin-top: 1rem;
          text-align: center;
          color: #252762;
          font-size: 14px;

          @media only screen and (min-width: 320px) and (max-width: 1000px) {
            margin-top: 1rem;
            text-align: center;
            color: #252762;
            font-size: 14px;
          }
        }
      }

      &_title_next {
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        letter-spacing: 0.02em;
        color: #4d5766;
        text-align: center;
      }

      &_help {
        margin-top: 13px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: #d1d5db;
      }

      &_eMailWrapper {
        @media only screen and (min-width: 320px) and (max-width: 1000px) {
          width: 100%;
        }

        &_title {
          margin-top: 20px;
          margin-bottom: 8px;
          font-weight: 400;
          font-size: 16px;
          line-height: 13px;
          letter-spacing: 0.02em;
          color: #292e34;
        }
      }

      &_passwordWrapper {
        @media only screen and (min-width: 320px) and (max-width: 1000px) {
          width: 100%;
          margin-top: 8px;
        }

        margin-top: 18px;
      }

      &_btnCreateWrapper {
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          margin-top: 10%;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          margin-top: 10%;
        }

        margin-top: 37px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        p {
          font-family: 'SFPRORegular', sans-serif;
          font-size: 14px;
          font-weight: 500;
          line-height: 16.8px;
          text-align: center;
          padding: 8px 0;
          color: #324195;

          @media only screen and (min-width: 320px) and (max-width: 1000px) {
            font-family: 'SFPRORegular', sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 16.8px;
            text-align: center;
          }
        }

        &_return {
          display: none;
          font-size: 10px;
          color: #6f6f6f;
          margin-left: 70%;
          margin-top: 5%;

          &:hover {
            color: #4d5766;
            transition: color 0.3s;
          }
          @media only screen and (min-width: 320px) and (max-width: 375px) {
            display: block;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            display: block;
          }
        }

        &_btn {
          padding: 8px 0;

          @media only screen and (min-width: 320px) and (max-width: 1000px) {
            right: 0;
            // margin-bottom: 10%;
            font-size: 10px;
          }

          &_a {
            font-family: 'SFPRORegular', sans-serif;
            font-size: 10px;
            font-weight: 500;
            line-height: 12px;
            text-align: center;
            text-decoration-line: underline;
            text-decoration-style: solid;
            color: var(--grey, #808080);
          }
        }

        &_or {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 2rem 0 1rem 0;

          p {
            font-weight: 500;
            font-size: 18px;
            line-height: 21.6px;
            color: #252762;
            margin: 0 10px 0 10px;
          }

          &_lineLeft {
            width: 170px;
            border-top: 1px solid #3c41a4;
          }

          &_lineRight {
            width: 170px;
            border-top: 1px solid #3c41a4;
          }
        }

        &_socialMedia {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 15px;
        }
      }
    }
  }
}

.btn {
  width: 100%;

  button {
    width: 400px;
    height: 54px;

    @media only screen and (min-width: 320px) and (max-width: 1000px) {
      width: 100%;
      height: 54px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.errors {
  color: #ff3c3c;
  font-size: 12px;
  margin-top: 2px;
  margin-left: 8px;

  &_forgot {
    color: #ff3c3c;
    font-size: 12px;
    margin-left: 8px;
    font: bold;
  }
}
