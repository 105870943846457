.nav_btn {
  display: inline-block;
  font-family: 'Inter', sans-serif;
  padding: 10px 12px;
  color: #888484;
  text-align: center;
  //margin: 5px 116px;
  background: none;
  text-decoration: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-sizing: border-box;
  border: 0.5px solid transparent;

  &:hover {
    border: 0.5px solid #dedede;
    padding: 10px 12px;
  }
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 2%;
    margin: 0;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 2%;
    margin: 0;
  }
}

.active {
  background: #357eeb;
  color: #ffffff;
}
