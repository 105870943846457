@import 'scss/mixin.scss';
@import 'scss/variable.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../../../../../../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.button {
  text-decoration: none;
  background: #ba75ff;
  padding: 10px 15px;
  border-radius: 10px;
  color: #f3f4f6;
  font-weight: 600;
  transition: ease-in 0.3s;
  word-wrap: break-word;
  max-width: 230px;
  overflow: hidden;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }
}

.createButton {
  font-weight: 600;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 3px 5px;
  border: 2px solid $color-blue;
  border-radius: 7px;
  width: fit-content;
  cursor: pointer;
  transition: ease-in 0.2s;

  &:hover {
    background: rgba(207, 226, 255, 0.12);
    color: rgb(94, 94, 94);
  }
}

.wrapper {
  display: flex;
  flex-direction: row;

  &_redactorField {
    width: 100%;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      width: 85%;
      margin-left: 0;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      width: 85%;
      margin-left: 0;
    }

    &_paper {
      padding: 30px 20px;
      max-width: 100%;
      height: 100%;
      @include display(flex, column, flex-start);
      gap: 10px;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        padding: 1rem 0 1rem 0;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        padding: 1rem 1rem 1rem 1rem;
      }

      &_title {
        font-weight: bold;
        font-size: 16px;
        font-family: 'SFPRORegular';
      }
    }
  }

  &_navBlock {
    @include display(flex, column);
    padding: 1em;
    justify-content: center;

    &_div {
      background: #f3f4f6;
      border: none;
      border-radius: 7px;
      width: 36px;
      height: 36px;
      text-align: center;
      @include display(flex, row, center, center);
      margin-bottom: 8px;
      cursor: pointer;
    }

    &_grabBtn {
      background: #f3f4f6;
      border: none;
      border-radius: 7px;
      width: 36px;
      height: 56px;
      text-align: center;
      @include display(flex, row, center, center);
      margin-bottom: 8px;
      cursor: grab;
    }

    &_delete {
      padding: 11px 0;
      border-radius: 6px;
      border: none;
      height: 36px;
      background: #fef2f2;
      @include display(flex, row, flex-start, center);
      text-align: center;
      cursor: pointer;

      & svg {
        margin: 0 auto;
        height: 17px;
        width: 17px;
      }
    }
  }
}
