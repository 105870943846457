@import '../../../scss/variable.scss';
@import '../../../scss/mixin.scss'; 

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.answerOptionsBlock {
  &_title {
    @include font (500, 14px,17px);
    color: #4d5766;
  }

  &_inputWrapper {
    @include display (flex, row, flex-start, center);
    width: 100%;
    height: 27px;
    margin-bottom: 10px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      margin-bottom: 10px !important;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      margin-bottom: 10px !important;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    label {
      display: flex;

      input {
        padding: 4px 9.4px;
        border-radius: 5px;
        max-width: 119px;
        width: 100%;
        height: 100%;
        transition: all 0.2s;
        @include font (500, 13px, 16.71px);
        color: $color-text;

        &::placeholder {
        @include font (500, 14px, 21px);
        color: $color-grey;
        }

        &:hover {
          border: 1px solid $color-blue;
        }
      }

      span {
        width: 24px;
        height: 24px;
        border: 1px solid $color-text;
        border-radius: 4px;
      }

      input:checked ~ span {
        border: none;
      }
    }

    &_correctAnswerWrapper {
      label {
        input {
          color: $color-blue;
          border: 1px solid $color-blue;
          }
        }
      }

      &_comment {
        cursor: pointer;
        &:hover {
          svg {
            path {
              stroke: $color-blue;
            }
            line {
              stroke: $color-blue;
            }
          }
        }
      }

      &_delete {
        margin-left: 4px;
        cursor: pointer;
        &:hover {
          svg {
            path {
              fill: #ef4444;
            }
          }
        }
      }

      &_grab {
        cursor: grab;
      }
    }
}

.children {
  flex-direction: column;
}
