@import '../../../../scss/mixin.scss';

.logs_user {
  position: relative;
  @include display (flex, row, space-between);
  font-size: 11px;
  margin-top: 28px;
  padding: 0 20px;

  & div {
    width: 300px;
  }
  & ::after {
    content: '';
    @include position_t_l (absolute, -13px, 1%);
    width: 98%;
    background: #e5e7eb;
    height: 1px;
  }
}