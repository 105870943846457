@import '../../scss/variable';
@import '../../scss/mixin.scss';

.help {

    &Page {
        margin: 0;
        padding-top: 24px;

    }

    &_title_container {
        font-family: 'SF Pro Display', sans-serif;
        @include display(flex, row, space-between, center);
        width: 100%;
        height: 100px;
        margin-top: 100px;

        p {
            color: #000000;
            font-weight: 700;
            font-size: 36px;
        }

        .back_btn {
            @include display(flex, row, center, center);
            width: 50px;
            height: 50px;
            background-color: #CFE2FF;
            border-radius: 14px;
            transition-duration: 0.25s;

            &:hover {
                filter: brightness(0.85);
                cursor: pointer;
            }
        }
    }
}


.sections {
    @include display(flex, column, flex-start, center);
    width: 84vw;
    max-width: 1340px;
    gap: 60px;

    .section {
        font-family: 'SF Pro Display', sans-serif;
        width: 100%;
        @include display(flex, row, space-between, flex-start);
        gap: 64px;
        padding: 16px;

        &_title {
            @include display(flex, row, flex-start, center);
            gap: 16px;

            &_text {
                font-weight: 700;
                font-size: 24px;
                color: #332F36;
            }
        }

        &_number {
            background-color: #357EEB;
            min-width: 64px;
            min-height: 64px;
            border-radius: 32px;
            @include display(flex, row, center, center);
            font-weight: 600;
            font-size: 32px;
            color: #fff;
        }

        .text_part {
            @include display(flex, column, flex-start, flex-start);
            gap: 16px;
            width: 49%;
        }

        &_text {
            // font-family: 'SF Pro Text', sans-serif;
            font-weight: 400;
            font-size: 20px;
            color: #332F36;
        }

        .img_part {
            @include display(flex, column, flex-start, center);
            width: 49%;
            padding: 24px 24px 0 24px;
            border-radius: 32px;
            background: linear-gradient(90deg, #3170E7 13.5%, #7A90F7 100%);


            img {
                width: 100%;
                border-radius: 20px;

            }

            &_t2 {
                @include display(flex, column, flex-start, center);
                width: 49%;
                padding: 0 24px 0 24px;
                border-radius: 32px;
                background: linear-gradient(90deg, #3170E7 13.5%, #7A90F7 100%);

                img {
                    width: 100%;
                    border-radius: 20px;
                }
            }
        }


    }
}

.text {
    &_bold {
        font-weight: 700;
    }

    &_list {
        @include display(flex, column, flex-start, flex-start);
        gap: 16px;

        li {
            margin-left: 24px;
        }
    }
}