@import '../../../scss/variable';

.wrapper {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), no-repeat center fixed;
  background-size: cover;
  position: fixed;
  display: flex;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 99;
  padding: 30px 0;

  @media (max-width: 460px) {
    background: rgba(0, 0, 0, 0.5);
  }
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 100%;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    width: 100%;
  }
  @media only screen and (min-width: 481px) and (max-width: 1024px) {
    width: 100%;
   }
  
}

.open_modal {
  flex-shrink: 0;
  flex-grow: 0;
  width: 100%;
  min-height: 100%;
  margin: auto;
  // display: flex;
  overflow: hidden;
  // flex-flow: column nowrap;
  // align-items: center;
  // justify-content: center;
}
