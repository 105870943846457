@import '../../../../scss/mixin.scss';
@import '../../../../scss/variable.scss';

.wrapper {
  width: 100%;
  border-radius: 24px;
  background: #fffafa33;
  backdrop-filter: blur(14px);
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 5px;

  &_avatar {
    height: 38px;
    width: 38px;
    border-radius: 38px;
    object-fit: cover;
  }

  &_text {
    font-family: 'SFPRORegular';
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
    text-align: left;
    text-underline-position: from-font;
    color: $color-white;
    text-decoration-skip-ink: none;
    letter-spacing: -1px;
  }
}
