@import 'scss/mixin.scss';
@import 'scss/variable.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../../../../../../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.wrapper {
  width: 100%;
  min-height: 150px;
  border-radius: 20px;
  box-shadow: 0px 0px 8px 0px #3241954D;
  background-color: #ffffff;
  display: grid;
  grid-template: auto auto / 150px auto;
  grid-gap: 5px;
  //@include display(flex, column, center, center);
  //gap: 5rem;
  //padding: 2rem;
  position: relative;

  button {
    padding: 3px;
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    color: #808080;
    visibility: hidden; // По умолчанию кнопки невидимы
    opacity: 0;
    transition: visibility 0s, opacity 0.3s ease-in-out;
  }

  &:hover button {
    visibility: visible; // При наведении контейнер становится видимым
    opacity: 1;
  }

  button:hover {
    color: #808080;
    background: rgba(207, 226, 255, 0.12);
    border-radius: 5px;
  }

  &_imageBox {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    grid-row: 1 / span 2;

    img {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      background: rgba(207, 226, 255, 0.12);
      border: none;
    }

    &_error {
      z-index: 5;
      white-space: nowrap;
      position: absolute;
      top: 0;
      left: 150px;
      font-size: 15px;
      padding: 5px;
      border-radius: 10px;
      color: rgb(224, 24, 24);
      background: rgba(224, 24, 24, 0.3);
      margin-top: 5px;
      text-align: center;
    }
  }

  &_title {
    width: 100%;
    min-height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    textarea {
      padding: 10px;
      width: 90%;
      color: $color-blue;
      font-weight: bolder;
      border: none;
      border-radius: 10px;
      outline: none;
      font-family: 'SFPRORegular';
      resize: none;
      font-size: 20px;
    }
    textarea:hover {
      background: rgba(207, 226, 255, 0.12);
    }
  }

  &_description {
    width: 100%;
    min-height: 70px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    textarea {
      padding: 10px;
      width: 90%;
      color: #34495e;
      border: none;
      border-radius: 10px;
      outline: none;
      font-family: 'SFPRORegular';
      resize: none;
      font-size: 17px;
    }
    textarea:hover {
      background: rgba(207, 226, 255, 0.12);
    }
  }
}