@import './src/scss/variable';
@import '../../../scss//mixin.scss';

.modalBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(111, 104, 104, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainer {
    position: relative;
    width: 35%;
    height: 30%;
    background-color: white;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 3100px) {
        height: 23%;
    }
    @media screen and (max-width: 2100px) {
        height: 25%;
    }
    @media screen and (max-width: 1800px) {
        height: 30%;
    }
    @media screen and (max-width: 1500px) {
        height: 35%;
    }
    @media screen and (max-width: 1300px) {
        height: 40%;
    }
    @media screen and (max-width: 1024px) {
        height: 55%;
    }
    @media screen and (max-width: 900px) {
        height: 60%;
    }
}
  
  .modalHeader h2 {
    margin-top: 0;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .modalContainer form {
    margin-bottom: 20px;
  }

  
  .modalContainer button {
    padding: 8px 20px;
    border-radius: 5px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
  }
  
  .modalContainer button + button {
    margin-left: 10px;
  }
  
  .modalContainer button[type="submit"] {
     background-color: rgba(53, 126, 235, 0.85);
  }
  
  .modalContainer button[type="submit"]:hover,
  .modalContainer button[type="button"]:hover {
    background-color: #357EEB;
  }
  
  .modalContainer button[type="submit"]:disabled {
    background-color: #6c757d;
    cursor: not-allowed;
  }
  
  .modalContainer button[type="submit"]:disabled:hover {
    background-color: #6c757d;
  }
  
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .closedModal {
    align-items: right;
    //background: #f1f1f1;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #e0dced;

    &:hover {
      color: #357EEB;
      transition: color 0.3s;
    }
  }

  .modalContainer label {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: black;
  }

  .modalContainer .submitButton {
    position: absolute;
    bottom: 20px;
    padding: 10px;
    width: calc(100% - 40px);
    border: none;
    background-color: #357EEB;
    color: white;
    border-radius: 10px;
    cursor: pointer;
  }
