@import 'src/scss/variable';
@import '../../scss/mixin.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.redactorCourse {
  font-family: $font-family-inter;
  @include display(flex);
  &_leftSide {
    @include display(flex, column);
    margin-right: 30px;
    margin-top: 50px;

    &_title {
      color: $font-title;
      font-size: 16px;
    }

    &_desc {
      margin-left: 16px;
      @include display(flex, column);
      &_title {
        color: $font-title;
        font-size: 14px;
        margin-bottom: 20px;
      }

      &_lesson {
        @include display(flex, row, flex-start, center);
        color: $font-color-rose;
        font-size: 12px;
        margin-bottom: 26px;

        & img {
          margin-right: 7.5px;
        }
      }
    }

    &_classes {
      width: 236px;

      &_settings {
        position: relative;
        @include display(flex, row, right, center);
        margin: 25px 0;
        font-size: 14px;
        color: $font-title;
        text-align: center;
        cursor: pointer;

        &_drag {
          @include position_t_l(absolute, 0, -15px);
          svg {
            margin-right: 8px;
          }
        }

        &_panel {
          & svg {
            margin-left: 10px;
          }
        }
      }
    }
  }

  &_rightSide {
    background: #ffffff;
    padding: 36px 45px 56px 45px;
    margin-bottom: 30px;
    border-radius: 10px;
    width: 100%;
    box-shadow: $box-shadow;

    &_header {
      @include display(flex, row, space-between, center);
      text-align: center;

      &_btnBlock {
        @include display(flex);

        &_setting {
          @include display(flex, row, flex-start, center);
          height: 36px;
          border: none;
          text-align: center;
          background: #f3f4f6;
          font-size: 12px;
          color: #6b728f;
          border-radius: 6px;
          padding: 0 7px;

          & svg {
            margin-right: 5px;
          }
        }

        &_delete {
          @include display(flex, row, flex-start, center);
          padding: 11px 0;
          border-radius: 6px;
          border: none;
          height: 36px;
          background: #fef2f2;
          text-align: center;
          cursor: pointer;

          & svg {
            margin: 0 auto;
            height: 17px;
            width: 17px;
          }
        }
      }
    }

    &_title {
      color: $font-title;
      font-size: 16px;
    }

    &_functional {
      margin-top: 70px;

      &_addContent {
        position: relative;
        @include display(flex, column, center, center);
        width: 99%;
        background: #ffffff;
        text-align: center;
        margin-bottom: 48px;
        height: 254px;
        border: 2px dashed #ebeef5;
        border-radius: 7px;

        & input {
          @include position_t_l(absolute, 0, 0, 2);
          width: 100%;
          height: 100%;
          opacity: 0;
          cursor: pointer;
        }

        &_navBlock {
          @include position_t_r(absolute, 0, -40px, 2);
          @include display(flex, column, flex-start);
          &_div {
            @include display(flex, row, center, center);
            background: #f3f4f6;
            border: none;
            border-radius: 7px;
            width: 36px;
            height: 36px;
            text-align: center;
            margin-bottom: 8px;
            cursor: pointer;
          }
        }
        & span {
          margin-bottom: 32px;
          @include font(600, 16px);
          color: $font-title;
        }
      }

      &_addDragContent {
        border: 2px dashed #939394;
      }

      &_blackBlock {
        position: relative;
        background: #1d262f;
        width: 100%;
        height: 138px;
        border-radius: 7px;
        margin-bottom: 48px;

        &_select {
          width: 176px;
          padding: 8px 0 0 36px;
        }
      }

      &_content {
        @include display(flex, row, space-between);
        margin-bottom: 66px;

        &_preview {
          color: $font-color-rose;
          font-size: 12px;
          margin-right: 11px;
        }
      }

      &_creating {
        max-width: 43.3rem;
        padding: 2.5rem 1.4rem;
        margin-bottom: 2rem !important;
        border-radius: 20px;
        border: 2px dashed rgba(53, 126, 235, 1);
        text-align: center;
        margin: 0 auto;
        @media only screen and (min-width: 320px) and (max-width: 375px) {
          width: 100%;
          height: auto;
          padding: 2.5rem 0.7rem;
        }
        @media only screen and (min-width: 375px) and (max-width: 480px) {
          width: 100%;
          height: auto;
          padding: 2.5rem 0.7rem;
        }

        &:hover {
          border: solid 2px #357eeb;
        }

        &_title {
          font-family: 'SFPRORegular';
          font-weight: 500;
          margin-bottom: 20px;
          color: #332f36;
          line-height: 31.03px;
          font-size: 26px;
        }

        &_function {
          display: flex;
          justify-content: space-evenly;
          flex-wrap: wrap;
          gap: 20px;
          
          & span {
            margin-top: 10px;
            color: #332f36;
            line-height: 19.09px;
            font-size: 1rem;
          }
          & div:not(:last-child) {
            margin-right: 16px;
          }
        }
      }
    }

    &_desc {
      font-size: 12px;
      color: #9f9f9f;
    }
  }
}
.hl {
  width: 256px;
  border-bottom: 1px solid #d9d9d9;
  margin: 24px 0 18px -10px;
}
.btn {
  width: 236px;
  background: #e0dced;
  font-size: 12px;
  padding: 11px 0;
  border: none;
  border-radius: 7px;
  color: $font-color-rose;
}
