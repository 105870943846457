@import '../../../scss/mixin.scss';

.scores_container {
  @include position_t_l(absolute, 0, 0);
  @include display(flex, column, space-between, center);
  max-width: 500px;
  width: 238px;
  height: 178px;
  padding: 19px 20px;
  border-radius: 32px;
  backdrop-filter: blur(14px);
  background: #cfe2ff;

  h5 {
    @include font(500, 14px, 19px);
    color: rgb(51, 47, 54);
    letter-spacing: 0;
    line-height: 19px;
    text-align: left;
  }

  button {
    width: 184px;
    height: 40px;
    color: rgb(53, 126, 235);
    box-sizing: border-box;
    background: #cfe2ff;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: SF Pro Display;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    border: 2px solid rgb(53, 126, 235);
    border-radius: 10px;
  }

  button:hover {
    outline: none;
    background: #cfe2ff;
    color: rgb(53, 126, 235);
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 70vw;
    left: -30px;
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    width: 70vw;
    left: -30px;
  }
}

.input_container {
  @include display(flex, row, flex-start, center);
  width: 206px;
  height: 39px;
  gap: 16px;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  padding: 0px;

  p {
    @include font(400, 12px, 14px);
    color: #6b7484;
    margin: 0px 16px;
  }

  p:last-of-type {
    margin: 0 14px;
  }

  input {
    max-width: 146px;
  }
}
