@import '../../scss/mixin.scss';
@import '../../scss/variable.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.textField {
  width: 100%;

  &_paper {
    padding: 20px; 
    max-width: 100%;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      padding: 1rem 0.5rem 1rem 0.5rem;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      padding: 1rem 0.5rem 1rem 0.5rem;
    }

  }

  &_btnSave {
    @include display(flex, row, center);
    margin-top: 10px;
    width: 120px;
    height: 36px;
    border: none;
    text-align: center;
    background: #6b728f;
    font-size: 14px;
    color: #ffffff;
    border-radius: 6px;
    padding: 11px 0 11px;
    cursor: pointer;
  }

  &_btnCancel {
    @include display(flex, row, center);
    margin-top: 10px;
    width: 120px;
    height: 36px;
    border: none;
    text-align: center;
    background: #f3f4f6;
    font-size: 14px;
    color: #6b728f;
    border-radius: 6px;
    padding: 11px 0 11px;
    cursor: pointer;
  }

  &_textarea {
    width: 100%;
    height: 200px;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }

  &_btnEditText {
    @include display(flex);
    font-family: 'SFPRORegular';

    &_setting {
      @include font(500, 14px, 16.71px);
      padding: 10px 20px;
      max-width: 104px;
    }
  }

  &_description {
    &_text {
      @include font(500, 14px, 16.71px);
      font-family: 'SFPRORegular';
      color: $color-text;
    }
  }
}

a {
  color: $color-blue;
}

.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;

  &_navBlock {
    @include display(flex, row, space-between, center);

    &_div {
      background: #f3f4f6;
      border: none;
      border-radius: 7px;
      width: 36px;
      height: 36px;
      text-align: center;
      @include display(flex, row, center, center);
      margin-bottom: 8px;
      cursor: pointer;
    }

    &_grabBtn {
      display: flex;
      cursor: grab;
    }

    &_delete {
      padding: 10px 25px;
      @include font(500, 16px, 19.09px);

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        @include font(500, 12px, 14.32px);
        padding: 5px 15px;
        border-radius: 5px;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        @include font(500, 12px, 14.32px);
        padding: 5px 15px;
        border-radius: 5px;
      }
    }
  }
}

