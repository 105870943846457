@import '../../../scss/mixin.scss';
@import '../../../scss/variable.scss';

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 50rem;
  height: 28.13rem;
  max-width: 90vw;
  max-height: 60vh;
  margin: 0 auto;
  align-self: center;
  padding: 2rem 0;
  font-family: 'SFPRORegular';

  border-radius: 2rem;
  box-shadow: 0rem 0.13rem 1.56rem 0rem rgba(53, 126, 235, 0.5);
  background: rgb(255, 255, 255);

  &_btnClose {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 30px;
    width: 30px;
    background: transparent;
    border: none;
  }

  h2 {
    /* Desktop/Head/H2 */
    color: rgb(0, 0, 0);
    font-family: 'SFPRORegular';
    letter-spacing: -1px;
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 2.69rem;
    letter-spacing: 0%;
    text-align: left;
  }

  h3 {
    color: rgb(0, 0, 0);
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 1.81rem;
    letter-spacing: 0%;
    text-align: left;
    display: flex;
    gap: 10px;

    p {
      /* Desktop/Head/H3 */
      color: rgb(76, 199, 0);
      font-size: 1.5rem;
      font-weight: 500;
      letter-spacing: -1px;
      line-height: 1.81rem;
      letter-spacing: 0%;
      text-align: center;
    }
  }

  &_image {
    width: 12.53rem;
    height: 11.12rem;
  }
}
