.fileWrapper {
  position: static;
  width: 9.75rem;
  height: 8.19rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 13;
  padding: 1rem;
  border-radius: 1.5rem;
  text-decoration: none;

  /* Blue inactive */
  background: rgb(207, 226, 255);

  &_body {
    position: static;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    padding: 0rem;

    &_icon {
      /* Component 134 (Component 133) */
      position: static;
      width: 3rem;
      height: 3rem;
    }

    &_text {
      /* Frame 1171280436 */
      position: static;
      width: 7.75rem;
      height: 2.94rem;
      /* Автолейаут */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4px;
      padding: 0rem;

      &_title {
        /* Материалы */
        width: 7.75rem;
        height: 1.19rem;
        /* Автолейаут */
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        padding: 0rem;
        p {
          color: rgb(51, 47, 54);
          font-family: 'SFPRORegular';
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.19rem;
          letter-spacing: 0%;
          text-align: left;
          text-decoration: none;
          width: 7.75rem;
          white-space: nowrap; // текст в одну строку
          overflow: hidden; // скрывает все что выходит за зону контейнера
          text-overflow: ellipsis; // заменяет скрытый текст на многоточие
        }
      }

      &_size {
        /* Frame 1171280330 */
        position: static;
        width: 5.19rem;
        height: 1.5rem;
        /* Автолейаут */
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 13px;
        padding: 0rem;

        p {
          /* Desktop/Text/Text small */
          color: rgb(51, 47, 54);
          font-family: 'SFPRORegular';
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.19rem;
          letter-spacing: 0%;
          text-align: left;
        }
      }
    }
  }
}

.hwFileWrapper {
  width: max-content;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 24;
  max-width: 100%;
  white-space: nowrap; // текст в одну строку
  overflow: hidden; // скрывает все что выходит за зону контейнера
  text-overflow: ellipsis; // заменяет скрытый текст на многоточие

  &_body {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    gap: 10px;

    &_arrowDown {
      border-radius: 3.75rem;
      background: rgb(207, 226, 255);
      width: 3.75rem;
      height: 3.75rem;
      min-width: 3.75rem;
      min-height: 3.75rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_text {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: space-between;
      white-space: nowrap; // текст в одну строку
      overflow: hidden; // скрывает все что выходит за зону контейнера
      text-overflow: ellipsis; // заменяет скрытый текст на многоточие

      &_title {
        color: rgb(51, 47, 54);
        font-family: 'SFPRORegular';
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.31rem;
        letter-spacing: 0%;
        text-align: left;
        white-space: nowrap; // текст в одну строку
        overflow: hidden; // скрывает все что выходит за зону контейнера
        text-overflow: ellipsis; // заменяет скрытый текст на многоточие
      }

      &_size {
        /* Desktop/Head/H4 */
        color: rgb(133, 141, 157);
        font-family: 'SFPRORegular';
        font-size: 0.75rem;
        font-weight: 500;
        line-height: 1.5rem;
        letter-spacing: 0%;
        text-align: left;
      }
    }
  }
}
