@import '../../../scss/variable.scss';
@import '../../../scss/mixin.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.header {
  @include display(flex, column, center);
  color: $color-text;
  font-family: 'SFPRORegular';
  text-align: left;
  max-width: 485px;
  width: 100%;

  &_container {
    display: flex;
    gap: 24px;
    margin-bottom: 10px;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      gap: 8px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      gap: 8px;
    }

    &_question {
      width: 100%;
      max-width: 427px;
    }
  }

  &_subtitle {
    @include font(500, 10px, 11.93px);
    font-family: 'SFPRORegular';
    letter-spacing: -0.01em;
    margin-top: 4px;
    color: $color-grey;
  }

  &_title {
    @include font(700, 18px, 21.48px);
  }

  &_testIconWrapper {
    @include display(flex, column, flex-start, center);
    &_iconRow {
      width: 50px;
      height: 9px;
      margin: 2px 0;
      padding: 2px 4px;
      border-radius: 22px;
      background-color: #6b7280;
      span {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #fff;
      }
    }
  }
  &_controlIconWrapper {
    @include display(flex, row, space-between, center);
    width: 250px;
    &_timer {
      position: relative;
      @include display(flex, row, flex-start, center);
      padding: 5px 8px;
      border-radius: 5px;
      background-color: #f3f4f6;
      input {
        position: relative;
        z-index: 100;
        margin: 0 0 0 7px;
        border: none;
        @include font(400, 12px, 18px);
        color: #929292;
        background-color: transparent;
        outline: none;
        &::-webkit-calendar-picker-indicator {
          width: 6px;
          opacity: 0;
          cursor: pointer;
        }
      }
      &_clearTimer {
        @include position_t_r(absolute, 4px, 10px, 5);
        cursor: pointer;
      }
      &_openList {
        @include position_t_r(absolute, 6px, 10px, 5);
        cursor: pointer;
      }
      svg {
        cursor: auto;
      }
    }
    &_togglerShowOption {
      cursor: pointer;
      transition: ease-in-out 0.2s;
      &:hover {
        svg {
          path {
            stroke: #357eeb;
          }
        }
      }
    }
    &_duplicate {
      cursor: pointer;
      &:hover {
        svg {
          path {
            fill: #357eeb;
          }
        }
      }
    }
    &_delete {
      cursor: pointer;
      &:hover {
        svg {
          path {
            fill: #ef4444;
          }
        }
      }
    }
    &_grab {
      cursor: grab;
    }
  }
}
.rotate_arrow {
  transform: rotate(180deg);
}

.avatar {
  background: linear-gradient(90deg, #3170E7 13.5%, #7A90F7 100%) !important;
  width: 34px !important;
  height: 34px !important;
}
