.social_button {
  text-decoration: none;
  color: inherit;
}

.telegram:hover path {
  fill: #59bfeb;
  transition: 0.3s linear;
}

.vk:hover path {
  fill: #357eeb;
  transition: 0.3s linear;
}

.x:hover path {
  fill: #332f36;
  transition: 0.3s linear;
}

.youtube:hover {
  path:nth-child(3) {
    transition: 0.3s linear;
    fill: #ff0000;
  }
}

.link:hover path {
  fill: url(#gradient_link);
  transition: 0.3s linear;
}

.instagram:hover path {
  stroke: url(#gradient_instagram);
  transition: 0.3s linear;
}
