@import '../../scss/variable';
@import '../../scss/mixin';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../assets/fonts/SFProText/SFProText-Regular.ttf) format('opentype');
}
h1 {
  font-family: 'SFPRORegular';
}

p {
  font-family: 'SFPRORegular';
}

.wrapper {
  @media only screen and (min-width: 1150px) {
    width: 100%;
    max-width: min(70.84vw, 1360px);
    height: max-content;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
  }

  @media screen and (max-width: 600px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding-bottom: 6rem;
    padding: 1rem;
  }

  @media only screen and (min-width: 800px) and (max-width: 1150px) {
    width: 100%;
    max-width: 800px;
    height: max-content;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    margin: 0 auto;
  }

  @media only screen and (min-width: 390px) and (max-width: 435px) {
    width: 100%;
    max-width: 387px;
    height: max-content;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;
    margin: 0 auto;
  }

  &_img {
    @media only screen and (min-width: 1150px) {
      width: 100%;
      max-width: 1260px;
      display: flex;
      padding-top: 11px;
      padding-bottom: 5px;
    }

    @media only screen and (min-width: 800px) and (max-width: 1150px) {
      width: 100%;
      max-width: 800px;
      display: flex;
      padding-top: 11px;
      padding-bottom: 5px;
    }

    @media only screen and (min-width: 390px) and (max-width: 435px) {
      width: 100%;
      max-width: 387px;
      display: flex;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    img {
      @media only screen and (min-width: 800px) and (max-width: 1150px) {
        width: 150px;
      }

      //       @media screen and (max-width: 600px) {
      //         margin: 0 auto;
      //       }

      @media only screen and (min-width: 390px) and (max-width: 435px) {
        width: 50px;
      }
    }
  }

  &_box {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    gap: 1rem;
    flex-wrap: wrap;

    @media only screen and (min-width: 800px) and (max-width: 1150px) {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      width: 800px;
      height: 100%;
      gap: 1rem;
      flex-wrap: wrap;
    }

    @media only screen and (min-width: 390px) and (max-width: 435px) {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      max-width: 387px;
      width: 100%;
      height: 240px;
      gap: 0.1rem;
      flex-wrap: wrap;
    }

    @media only screen and (min-width: 320px) and (max-width: 389px) {
      height: 100vh;
    }

    @media only screen and (min-width: 436px) and (max-width: 480px) {
      height: 100vh;
    }

    h1 {
      font-weight: 600;
      font-size: 20px;
      line-height: 23.87px;
      color: #324195;

      @media only screen and (min-width: 800px) and (max-width: 1150px) {
        font-weight: 600;
        font-size: 18px;
        line-height: 18.87px;
        color: #324195;
      }

      @media only screen and (min-width: 390px) and (max-width: 435px) {
        font-weight: 600;
        font-size: 8px;
        line-height: 8.87px;
        color: #324195;
      }
    }

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 19.09px;
      color: #357eeb;

      @media only screen and (min-width: 800px) and (max-width: 1150px) {
        font-weight: 600;
        font-size: 15px;
        line-height: 18.09px;
        color: #357eeb;
      }

      @media only screen and (min-width: 390px) and (max-width: 435px) {
        font-weight: 600;
        font-size: 7px;
        line-height: 7.09px;
        color: #357eeb;
      }
    }

    a {
      font-weight: 600;
      font-size: 16px;
      line-height: 19.09px;
      color: #357eeb;

      @media only screen and (min-width: 800px) and (max-width: 1150px) {
        font-weight: 600;
        font-size: 15px;
        line-height: 18.09px;
        color: #357eeb;
      }

      @media only screen and (min-width: 390px) and (max-width: 435px) {
        font-weight: 600;
        font-size: 7px;
        line-height: 7.09px;
        color: #357eeb;
      }
    }

    &_contact {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media only screen and (min-width: 390px) and (max-width: 435px) {
        display: flex;
        flex-direction: column;
        gap: 3px;
      }

      //       @media screen and (max-width: 600px) {
      //         width: 100%;
      //       }

      &_pack {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;

        @media only screen and (min-width: 390px) and (max-width: 435px) {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 3px;
        }

        img {
          @media only screen and (min-width: 390px) and (max-width: 435px) {
            width: 13px;
          }
        }
      }
    }

    &_directions {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media only screen and (min-width: 390px) and (max-width: 435px) {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }

      //       @media screen and (max-width: 600px) {
      //         width: 100%;
      //       }

      &_link {
        font-family: 'SFPROSemiBold';
        font-weight: 600;
        font-size: 16px;
        line-height: 19.09px;
        color: #357eeb;
      }
    }

    &_networks {
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media only screen and (min-width: 390px) and (max-width: 435px) {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }

      //       @media screen and (max-width: 600px) {
      //         width: 100%;
      //       }
    }

    &_users {
      width: 233px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      @media only screen and (min-width: 390px) and (max-width: 435px) {
        width: 75px;
        display: flex;
        flex-direction: column;
        gap: 6px;
      }

      //       @media screen and (max-width: 600px) {
      //         width: 100%;
      //       }
    }
  }
}
