.CourseCardsTS {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  &__persents_top {
    display: none;
  }
  &__student {
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    position: relative;
    border-radius: 24px;
    width: 100%;
    overflow: hidden;
    max-width: 660px;
    justify-content: space-between;
    height: 100%;
    // margin: auto;
  }

  &__bg_filter {
    background: #332f3680;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }

  &__title {
    position: relative;
    z-index: 2;
    background: linear-gradient(90deg, #332f36 -5.88%, rgba(51, 47, 54, 0) 100%);
    padding: 16px;
    width: 100%;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.19px;
    color: #fffafa;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__properties {
    position: relative;
    z-index: 2;
    background: #332f361a;
    margin: 200px 0 0 0;
    width: 100%;
    padding: 16px;
    border-radius: 24px;
    backdrop-filter: blur(14px);

    &::before {
      content: '';
      position: absolute;
      inset: calc(0px - var(--border-w));
      border-radius: 24px;
      padding: 1px;
      background: linear-gradient(180deg, #808080, #fffafa);
      -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
      -webkit-mask-composite: xor;
      mask-composite: exclude;
    }
  }
  &__property {
    display: flex;
    align-items: center;
    gap: 16px;

    &_wrapper {
      align-items: center;
      display: flex;
      width: 100%;
      justify-content: space-between;
      position: relative;
      z-index: 3;
      // gap: 10px;
      flex-wrap: wrap;
    }

    &_name {
      font-size: 14px;
      white-space: nowrap;
      color: #fff;
    }
  }
  &__line {
    height: 32px;
    width: 1px;
    background: #808080;
  }
  &__bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    position: relative;
    z-index: 3;
    align-items: center;
    text-decoration: none;
    color: #fff;
  }

  &__button {
    background: #357eeb;
    padding: 16px 40px;
    border-radius: 10px;
    display: block;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.87px;
    text-align: center;
    text-decoration: none;
    color: #ffffff;
    transition: 0.3s;

    &:hover {
      background-color: #0e29bb;
    }
  }

  &__percents {
    font-size: 24px;
    font-weight: 500;
    line-height: 28.64px;
    text-align: left;
    color: #ffffff;
    text-decoration: none;
  }

  &__admin {
    padding: 16px;
    border-radius: 30px;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 600px;
    // margin: auto;
    width: 100%;
    height: 100%;

    justify-content: space-between;

    &_top {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &_studentCount {
    }

    &_main {
      max-height: 230px;
      position: relative;
      border-radius: 24px;
      overflow: hidden;
      padding: 0 0 160px 0;
    }

    &_main_img {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      object-fit: cover;
    }

    &_title {
      position: relative;
      z-index: 2;
      background: linear-gradient(90deg, #332f36 -5.88%, rgba(51, 47, 54, 0) 100%);
      padding: 16px;
      width: 100%;
      font-size: 32px;
      line-height: 38.19px;
      color: #fffafa;
    }

    &_bg_filter {
      background: #332f3680;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }

    &_property_wrapper {
      align-items: center;
      display: flex;
      width: 100%;
      justify-content: space-between;
      gap: 5px;
      position: relative;
      flex-wrap: wrap;
      z-index: 3;
    }

    &_property {
      display: flex;
      align-items: center;
      gap: 5px;
      &_name {
        white-space: nowrap;
        font-size: 14px;
      }
    }

    &_buttons {
      display: grid;
      width: 100%;
    }

    &_button_students {
      background: #357eeb;
      padding: 16px;
      border-radius: 10px;
      text-decoration: none;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.09px;
      text-align: center;
      color: #ffffff;
      width: 100%;
      transition: 0.3s;
      white-space: nowrap;
      border: none;
    }

    &_button_edit {
      background: #f5f5f5;
      padding: 16px;
      border: none;
      border-radius: 10px;
      text-decoration: none;
      font-size: 16px;
      font-weight: 500;
      line-height: 19.09px;
      text-align: center;
      color: #357eeb;
      width: 100%;
      transition: 0.3s;
      white-space: nowrap;
    }

    &_student_count {
      font-size: 16px;
      font-weight: 600;
      line-height: 19.09px;
      text-align: left;
      color: #000000;
      transition: 0.3s;
    }
  }

  &__public {
    font-size: 16px;
    font-weight: 500;
    line-height: 19.09px;
    text-align: left;
    transition: 0.3s;
  }
}

.progress {
  font: 12px Arial, Tahoma, sans-serif;
  position: relative;
  overflow: hidden;

  &_value {
    color: #333;
    display: none;
    line-height: 21px;
    text-align: center;
  }

  &_bg {
    background: #e6e9ed;
    position: relative;
    height: 27px;
    border-radius: 30px;
    overflow: hidden;
    margin: 16px 0 32px 0;
  }

  &_bar {
    background: #ffffff;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    transition: width 2s linear;

    &:after {
      animation: progress_bar 5s linear infinite;
      background-image: linear-gradient(90deg, #0d28bb 0%, #357eeb 50%, #0d28bb 100%);
      background-size: 1000px 100px;
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

@keyframes progress_bar {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }

  100% {
    background-position: 0% 0;
  }
}

.wraper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.switchWrap {
  cursor: pointer;
  background: transparent;
  padding: 2px;
  width: 40px;
  height: calc(40px / 2 + 2px);
  border-radius: calc(40px / 2);
  transition: all 0.2s;
  position: relative;

  input {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }

  .switch-filter {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #808080;
    z-index: 5;
  }

  .switch {
    height: 100%;
    display: grid;
    grid-template-columns: 0fr 1fr 1fr;
    transition: 0.2s;
    position: relative;
    z-index: 10;

    &::after {
      content: '';
      border-radius: 50%;
      background: #5a5a5a;
      grid-column: 2;
      transition: 0.2s;
    }

    input:checked + & {
      grid-template-columns: 1fr 1fr 0fr;

      &::after {
        background-color: #357eeb;
      }
    }
  }
}

.svg-path {
  fill: #000000;
}

@media (max-width: 768px) {
  .CourseCardsTS {
    &__line {
      display: none;
    }
    &__student {
      width: 100%;
    }
  }
}
@media (max-width: 500px) {
  .CourseCardsTS {
    &__properties {
      margin: 100px 0 00 0;
      border-radius: 10px;
      padding: 10px;
    }
    &__admin {
      max-width: none;
      width: 100%;
      &_property_wrapper {
        img {
          width: 15px;
        }
      }
      &_button_edit {
        padding: 10px;
        font-size: 12px;
      }
      &_button_students {
        // padding: 10px !important;
        line-height: normal;
        font-size: 12px;
      }

      &_property_name {
        line-height: normal;
        font-size: 10px;
      }
      &_title {
        font-size: 20px;
        line-height: 24px;
        padding: 20px;
        font-family: 'SFPRORegular';
        border-radius: 15px;
        line-height: normal;
      }

      &_student_count {
        line-height: normal;
        font-size: 12px;
      }

      &_main {
        padding: 0;
      }
    }

    &__public {
      font-size: 14px;
    }
    &__student {
      width: 100% !important;
      border-radius: 10px;
    }
    &__title {
      font-size: 20px;
      line-height: normal;
      font-weight: 400;

      padding: 4px 20px;
    }
    &__property_name {
      font-size: 10px;
    }
    &__line {
      display: none;
    }
    &__property_wrapper {
      // flex-direction: column;
      gap: 5px;
    }
    &__button {
      font-size: 12px;
      padding: 10px 32px;
      max-width: 218px;
      width: 100%;
      border-radius: 10px;
      margin: 10px 0 0 0;
      line-height: 14px;
    }
    &__percents {
      font-size: 24px;
    }
    &__property {
      gap: 5px;
      &-img {
        width: 14px;
      }
    }
    &__persents_top {
      display: block;
      font-size: 16px;
      font-weight: 700;
      line-height: 16px;
      text-align: left;
    }
    &__bottom {
      justify-content: center;
    }
  }
  .progress {
    display: none;
  }
  .progress_value {
    display: none;
  }
  .progress_bg {
    margin: 20px 0;
  }
}

.wrapper_switch {
  display: flex;
  align-items: center;
  gap: 10px;

  div {
    cursor: default;
  }
}
