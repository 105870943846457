@import '../../scss/mixin.scss';
.wrapper_actions {
  background-color: #ffffff;
  padding: 26px 32px 24px 32px;
  box-shadow: 0 0 8px #d9d9d9;
  border-radius: 8px;
  overflow-x: auto;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 4% 3% 4% 3%;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 4% 3% 4% 3%;
  }}

.rating_param {
  margin-top: 20px;

  &_label {
    color: #ac6ed5;
    @include font(500, 14px);
  }

  &_radio {
    margin-top: 20px;
    & label {
      color: #a599ad;
      @include font(500, 12px);
    }
  }
}

.table {
  position: relative;
  overflow: hidden;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 0;
  box-shadow: 0 0 5px rgb(0 0 0 / 30%);
  background-color: #fff;
  border-radius: 7px;
  margin-top: 25px;
  min-height: 295px;
  @media only screen and (min-width : 320px) and (max-width : 375px) {
    width: 98%;
    font-size: 14px;
    line-height: normal;

  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    width: 99%;
    font-size: 14px;
    line-height: normal;
  }

  &_head {
    height: 55px;
    background: #f3f4f6;
    color: #adabb3;
    font-size: 14px;
    font-weight: 500;
    tr {
      th {
        border: none;
        text-align: left;
      }
    }
    @media only screen and (min-width : 320px) and (max-width : 375px) {
      height: 20px;
      font-size: 14px;
      text-align: center;
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
     height: 20px;
     font-size: 14px;
     text-align: center;
    }
  }
  &_body {
    tr {
      height: 35px;
      color: #9198a1;
      &:hover {
        background: rgba(249, 250, 251, 0.7);
        transition: 0.1s background-color;
      }
      @media only screen and (min-width : 320px) and (max-width : 375px) {
        height: 30px;

      }
      @media only screen and (min-width : 375px) and (max-width : 480px) {
       height: 30px;

      }

      td {
        margin: 0 0 0 27px;
        height: 35px;
        @media only screen and (min-width : 320px) and (max-width : 375px) {
          margin-left: 2%;
        }
        @media only screen and (min-width : 375px) and (max-width : 480px) {
          margin-left: 2%;
        }
      }
    }
  }
}

.table_no_results {
  text-align: center;
  @include font(500, 20px);
  color: #6b7484;
}

.loader_wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  transition: 0.3s background-color;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.meetingTable {
  width: 100%;
  border-collapse: collapse;
}

.meetingTable th,
.meetingTable td {
  border-bottom: 1px solid black;
  padding: 8px;
  text-align: center;
}

.meetingTable th:first-child,
.meetingTable td:first-child {
  border-left: none;
}

.meetingTable th:last-child,
.meetingTable td:last-child {
  border-right: none;
}

.detailButtonCell {
  text-align: right;
}

.generateMeetingButton{
  border: 1px solid slategrey;
  cursor: pointer;
  margin-left: 1%;
  margin-bottom: 1rem;
}
.table_no_results {
  text-align: center;
  @include font(500, 20px);
  color: #6b7484;
}

.reminders {
  padding: 5px;

  @include font(400, 19px);
}