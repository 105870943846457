@import '../../../scss/mixin.scss';
@import '../../../scss/variable.scss';

//chat

.chat {
  position: relative;
  background: $color-white;
  max-width: 1160px;
  max-height: 70vh;
  min-height: 70vh;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  border-radius: 32px;
  overflow: hidden;
  z-index: 9999;
  fill: #9698fc;

  @media only screen and (max-width: 1024px) {
    max-width: calc(100vw - 2rem);
    max-height: 85dvh;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 45%;
  }

  @media only screen and (min-width: 2200px) and (max-width: 4600px) {
    max-height: 70vh;
    max-width: 1160px;
    min-height: 50vh;
  }

  &_loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 1);
    z-index: 9999;
  }

  &_close {
    outline: none;
    border: none;
    color: $backgroundCheckBox;
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 5px;
    width: 35px;
    height: 35px;
    cursor: pointer;
    transition: 0.3s color;
    z-index: 999;
    &:hover {
      color: $font-title;
    }
  }
}

//chat privew

.chatPreviewWrapper {
  position: relative;
  width: 100%;
  overflow: visible;
  min-height: 80px;
  z-index: 99999;
  max-height: 100%;
}

.chatPreviewSupport {
  display: flex;
  justify-content: space-between;
  //align-items: center;
  min-height: 80px;
  box-shadow: 0px 0px 8px 0px #3241954d;
  background: linear-gradient(90deg, #3170e7 13.5%, #7a90f7 100%);
  border-radius: 16px;
  z-index: 999999;
  width: 100%;
  gap: 0.5rem;

  &_time {
    color: $color-white;
  }

  cursor: pointer;
  &:hover {
    background: linear-gradient(90deg, #5385e3 13.5%, #7a90f7 100%);
  }
}

.chatPreview {
  display: flex;
  justify-content: space-between;
  //align-items: center;
  min-height: 80px;
  box-shadow: 0px 0px 8px 0px #3241954d;
  background: #edf1fa;
  border-radius: 16px;
  z-index: 999999;
  width: 100%;
  gap: 0.5rem;
  cursor: pointer;

  &_selected {
    width: calc(100% + 30px);
    position: absolute;
  }

  &:hover {
    background: #f3f4f6;
  }

  &_avatarWrap {
    margin-right: auto;
    width: 5rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    &_avatar {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }

    // &:nth-child(2) {
    //   background: #e2f6f3;
    //   color: #72c5b7;
    // }

    // &:nth-child(3) {
    //   background: #f9dcdc;
    //   color: #d96b7d;
    // }

    // &:nth-child(4) {
    //   background: #f1e3f4;
    //   color: #c97fcf;
    // }
  }

  &_top {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.25rem;
  }

  &_info {
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    width: 100%;
    // gap: 0.4rem;
    color: #332f36;
    font-size: 14px;
    padding: 10px 0;

    p:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #424345;
    }
  }

  &_lastMessage {
    overflow: hidden;
    min-height: 1rem;
    max-height: 100%;
    width: 100%;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: initial;
    display: -webkit-box;
    font-size: 0.8em;
    color: #000000;
    line-height: 1rem;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;

    @media only screen and (min-width: 481px) and (max-width: 1024px) {
      width: 60vw;
    }

    @media only screen and (min-width: 2200px) and (max-width: 4600px) {
      width: 60vw;
    }
  }

  &_lastMessageSupport {
    overflow: hidden;
    min-height: 1rem;
    max-height: 100%;
    width: 100%;
    text-overflow: ellipsis;
    word-break: keep-all;
    white-space: initial;
    display: -webkit-box;
    font-size: 0.8em;
    color: #ffffff;
    line-height: 1rem;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
  }

  &_newMessage {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1rem 1rem 0;
    //padding-top: 1rem;
    //border: 1px solid navy;
  }
  &_time {
    align-self: flex-start;
    color: rgb(0, 0, 0);
    font-size: 12px;
  }
  &_badge {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 50%;
    background-color: #3170e7;
    color: #ffff;
    font-size: 16px;
    font-weight: 600;
  }
}

// chat list

.chatsList {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  width: 43.5vw;
  max-width: 500px;
  height: 80dvh;
  gap: 6px;
  overflow: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  padding: 8px 50px 25dvh 1rem;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    position: absolute;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #357eeb;
    border-radius: 5px;
    width: 0;
  }

  &::-moz-webkit-scrollbar-thumb {
    background: #357eeb;
    border-radius: 5px;
  }

  @media only screen and (max-width: 1024px) {
    margin-top: 0;
    height: 75vh;
    padding: 1rem;
    width: 100%;
  }
}

//chat panel

.chatPanel {
  position: absolute;
  left: 0;
  top: 0;
  flex: 0 1 0;
  background: #d7dfef;
  font-size: smaller;
  width: 40%;
  z-index: 10;
  // min-width: 468px;
  @media screen and (max-width: 1024px) {
    min-width: 100%;
    width: 100%;
    padding: 1.5rem 0;
  }

  &_top {
    padding: 1rem;
    font-family: 'Inter', sans-serif;
    font-size: smaller;
    @media screen and (max-width: 1024px) {
      padding: 0 1rem;
    }

    &_btn {
      width: 80px;
      height: 30px;
      padding: 0;
      margin-left: 1rem;
      margin-bottom: 1rem;
    }
  }
  &_user {
    padding: 1.5rem 1.5rem 0 1.5rem;
    display: flex;
    align-items: center;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      padding: 0 5% 0 5%;
      flex-direction: column;
      text-align: center;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      padding: 0 5% 0 5%;
      flex-direction: column;
      text-align: center;
    }
  }

  &_user_avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    flex-shrink: 0;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      width: 50px;
      height: 50px;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      width: 50px;
      height: 50px;

      &_user {
        text-align: center;
      }
    }

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 50%;
    }

    background: #d3f2f8;
    // color: #72b0bc;

    &_userName {
      @include display(flex, column);
      font-family: 'Inter', sans-serif;
      font-size: 5px;
      &_status {
        //styleName: Desktop/Head/H4;
        font-family: 'SFPRORegular';
        font-size: 1em;
        font-weight: 600;
        line-height: 23.87px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        overflow: hidden;
        max-height: 100%;
        width: 100%;
        text-overflow: ellipsis;
        word-break: keep-all;
        white-space: initial;
        display: -webkit-box;
        line-height: 1rem;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      &_statusSupport {
        color: #ffffff;
        //styleName: Desktop/Head/H4;
        font-family: 'SFPRORegular';
        font-size: 1em;
        font-weight: 600;
        line-height: 23.87px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        overflow: hidden;
        max-height: 100%;
        width: 100%;
        text-overflow: ellipsis;
        word-break: keep-all;
        white-space: initial;
        display: -webkit-box;
        line-height: 1rem;
        line-clamp: 1;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
      }

      &_name {
        color: #000000;
      }
    }
    // &:nth-child(2) {
    //   background: #e2f6f3;
    //   color: #72c5b7;
    // }

    // &:nth-child(3) {
    //   background: #f9dcdc;
    //   color: #d96b7d;
    // }

    // &:nth-child(4) {
    //   background: #f1e3f4;
    //   color: #c97fcf;
    // }
  }
}

// chat workspace

.chatWorkspace {
  flex: 1 1 100%;
  position: absolute;
  left: 40%;
  top: 0;
  right: 0;
  bottom: 0;
  width: 60%;
  display: flex;
  z-index: 3;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    left: 0;
    width: 100%;
  }

  &_preview {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background: linear-gradient(90deg, rgba(8, 79, 210, 0.4) 13.5%, rgba(151, 169, 253, 0.4) 100%),
      url('./assets/e77ebcdf563407e2f547281160fe3223.png'), no-repeat;
    background-blend-mode: hard-light;

    p {
      font-size: 20px;
      font-weight: 600;
      color: #424345;
    }
  }

  &_wrapper {
    flex: 1 1 auto;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;
    scroll-behavior: smooth;
    background: linear-gradient(90deg, rgba(8, 79, 210, 0.4) 13.5%, rgba(151, 169, 253, 0.4) 100%),
      url('./assets/e77ebcdf563407e2f547281160fe3223.png'), no-repeat;
    background-blend-mode: hard-light;
  }
  &_content {
    padding: 20px 0;
    display: flex;
    width: 100%;
    min-height: 100%;
    flex-flow: column nowrap;
  }
}

// chat input

.chatInput {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  display: flex;
  height: 70px;
  flex: none;
  align-items: center;

  &_input {
    padding: 9px 60px 9px 9px;
    width: 90%;
    max-height: 200px;
    font-size: 14px;
    line-height: 17px;
    overflow-y: auto;
    overflow-x: hidden;
    outline: none;
    margin-right: 10px;
    border: none;
  }

  &_send {
    display: flex;
    justify-content: center; /* Центрируем по горизонтали */
    align-items: center; /* Центрируем по вертикали */
    width: 10%;
    height: 30%; /* Устанавливаем высоту 100%, чтобы центрировать вертикально */
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
}

// chat user

.chatUser {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #357eeb;

  //border: 1px solid blue;

  &_info {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &_avatar {
    margin-right: 10px;
    //margin-left: 1rem;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background: #d3f2f8;
    color: #72b0bc;
  }
  &_nameWrapper {
    flex-grow: 1;
    //margin-left: auto;
    //margin-right: 40px;
    //padding: 0px;
    //border: 1px #f30e0e solid;

    span {
      color: #ffff;
      font-family: 'SFPRORegular';
      font-size: 16px;
      font-weight: 600;
      line-height: 23.87px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
  &_name {
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &_lastVisit {
    font-size: 12px;
    margin-top: 5px;
    color: #cfe2ff;
    cursor: pointer;
  }

  &_btn {
    width: 50px;
    height: 25px;
    background-color: #72c5b7;
  }
}

// chat message

.chatMessageLeft {
  justify-content: flex-start !important;
}

.chatMessage {
  padding: 10px 35px;
  display: flex;
  justify-content: flex-end;
  transition: background-color 0.05s ease;

  // &:nth-child(2) {
  //   background: #e2f6f3;
  //   color: #72c5b7;
  // }

  // &:nth-child(3) {
  //   background: #f9dcdc;
  //   color: #d96b7d;
  // }

  // &:nth-child(4) {
  //   background: #f1e3f4;
  //   color: #c97fcf;
  // }

  &_avatar {
    background: #d3f2f8;
    color: #72b0bc;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    & img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
    }
  }

  &_user_name {
    font-size: 14px;
    font-weight: 500;
  }

  &_right {
    align-self: flex-end;
    justify-content: flex-end;
    // flex-direction: row-reverse;
  }

  &_content_left {
    margin-right: 20px;
    display: flex;
    align-items: flex-end;
    align-self: flex-start;
  }

  &_content_right {
    margin-left: 20px;
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
  }

  &_content_right &_avatar {
    order: 2;
    margin-left: 15px;
    align-self: flex-end;
  }

  &_content_left &_avatar {
    margin-right: 15px;
  }

  &_mess_right {
    padding: 15px 20px 20px 20px;
    position: relative;
    color: $font-title;
    border-radius: 6px 6px 0 6px;
    max-width: 350px;
    background-color: #f3f3f5;

    // & > .chatMessage_textwrapper::after {
    //   content: '';
    //   border-style: solid;
    //   border-color: transparent transparent #f3f3f5 transparent;
    //   width: 0;
    //   height: 0;
    //   position: absolute;
    //   bottom: 0;
    //   border-width: 10px 0 0 10px;
    //   background-color: #f3f3f5;
    //   right: -10px;
    //   left: auto;
    // }
  }

  &_mess_left {
    padding: 15px 20px 20px 20px;
    position: relative;
    background-color: #f3f3f5;
    max-width: 350px;
    color: $font-title;
    border-radius: 6px 6px 6px 0;
  }

  &_mess_author {
    padding: 15px 20px 20px 20px;
    position: relative;
    //background-color: rgba(238, 226, 253, 0.8);
    background-color: rgb(255, 255, 255);
    max-width: 350px;
    color: $font-title;
    border-radius: 6px 6px 0 6px;
  }

  // & > .chatMessage_textwrapper::after {
  //   content: '';
  //   border-width: 0 0 10px 10px;
  //   border-style: solid;
  //   border-color: transparent transparent #f3f3f5 transparent;
  //   width: 0;
  //   height: 0;
  //   position: absolute;
  //   bottom: 0;
  //   left: -10px;
  // }
  //}

  &_textWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    p {
      margin-top: 5px;
      font-size: 14px;
      white-space: pre-wrap;
    }
  }
  &_text_time {
    padding-left: 10px;
    flex-grow: 1;
    position: relative;
    top: 6px;
    color: #4d576666;
    font-size: 12px;
    text-align: right;
  }
}

.chatMessageSeparator {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  color: #ffffff;
  font-size: 14px;
  background-color: #332f36;
  border-radius: 10px;
  width: fit-content;
  margin: 0 auto;
  padding: 0.2rem 1rem;
}

// chatgrouppreview
.chatGroup {
  position: relative;
}

.chatGroup_content {
  padding-bottom: 20px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 9px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e1dbe6;
    height: 128px;
    border: 3px solid #ffff;
    border-top: 8px solid #ffff;
    border-bottom: 8px solid #ffff;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border-radius: 5px;
  }

  &_user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 40px;
    cursor: pointer;

    &:hover {
      background-color: #f3f4f6;
    }

    &_avatar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;

      & img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
      }
    }

    &_avatar:nth-child(1) {
      background: #d3f2f8;
      color: #72b0bc;
    }

    &_avatar:nth-child(2) {
      background: #e2f6f3;
      color: #72c5b7;
    }

    &_avatar:nth-child(3) {
      background: #f9dcdc;
      color: #d96b7d;
    }

    &_avatar:nth-child(4) {
      background: #f1e3f4;
      color: #c97fcf;
    }

    &_info {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
}

.chatGroup_header {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 0.75rem;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  &_preview {
    & span:first-child {
      font-weight: 500;
      color: $font-title;
    }

    & span:last-child {
      color: #9198a1;
    }
  }

  &_info {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    color: #9198a1;
    cursor: pointer;

    &:hover {
      color: $font-color-rose;
      transition: color 0.3s;
      -webkit-transition: color 0.3s;
      -moz-transition: color 0.3s;
      -ms-transition: color 0.3s;
      -o-transition: color 0.3s;
    }
  }
}

.searchInput {
  padding: 8px 16px;
  border: none;
  box-shadow: #edf1fa;
  color: #edf1fa;

  input[type='search'] {
    font-family: 'SFPRORegular', sans-serif;
    font-weight: 500;
    font-size: 16px;
    ::placeholder {
      color: #808080;
    }
  }
}

.filePicker {
  cursor: pointer;
  background: transparent;
  padding: 5px 10px 5px 35px;
  border: none;
  transition: all 0.5s;
  position: relative;

  &:hover {
    svg path {
      fill: #0f62de;
    }
  }

  input[type='file'] {
    display: none;
  }
}
