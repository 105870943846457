@import '../../../../scss/variable';
@import '../../../../scss/mixin.scss';

.employee_user {
  
  position: relative;
  @include display (flex);
  width: 100%;
  font-size: 11px;
  margin-top: 13px;
  padding: 0 30px 15px;
  font-family: $font-family-inter;

  @media only screen and (min-width : 320px) and (max-width : 375px) {
    margin: 5% 1% 5% 0;
    padding: 0 2%;
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    margin: 5% 1% 5% 0;
    padding: 0 2%;
  }
 
 

  &_info {
    @include display (flex, row, flex-start, center);
    width: 75%;
    @media only screen and (min-width : 320px) and (max-width : 375px) {
      width: 65%;
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
      width: 65%;
    }
   

    &_avatar {
      width: 34px;
      height: 34px;
      border-radius: 50%;
      margin-right: 12px;
    }

    &_name {
      @include display (flex, column);

      & span:nth-child(1) {
        color: #111827;
        @include font (500, 11px);
        margin-bottom: 4px;
      }

      & span:nth-child(2) {
        color: #bdbdbd;
        font-size: 10px;
      }
    }

    &_btn {
      width: 140px;
    }
  }

  &_roleBtn {
    @include display (flex, row, space-between);
    width: 50%;
    @media only screen and (min-width : 320px) and (max-width : 375px) {
      width: 60%;
      align-items: center;
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
      width: 60%;
      align-items: center;
    }

    &_role {
      @include display (flex, row, flex-start, center);
      width: 150px;
      color: #798188;
    }

    &_btn {
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      color: #357EEB;
      margin-top: 10px;
      width: 100px;
      @media only screen and (min-width : 320px) and (max-width : 375px) {
        width: 100%;
      }
      @media only screen and (min-width : 375px) and (max-width : 480px) {
        width: 100%;
      }
    }
  }
}
.employee_user::after {
  content: '';
  @include position_t_l (absolute, -13px, 1%);
  width: 98%;
  background: #e5e7eb;
  height: 1px;

  
}

.changePseudonymBtn {
  margin-left: 3px;
  color: #357EEB;
  background-color: transparent;
  border: none;
}

.additionalRoles {
  margin-top: 4px;
  display: block;
}