.creatingCourse {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 0;
  flex-wrap: wrap;

  & a:not(:last-child) {
    margin-right: 10px;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      margin: 0;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      margin: 0;
    }
  }
  & a {
    &:hover {
      background-color: rgba(220, 220, 220, 0.4);
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1em;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    margin: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 1em;
  }

  &_backButton {
    display: inline-block;
    font-family: 'Inter', sans-serif;
    padding: 11px 13px;
    color: #888484;
    text-align: center;
    //margin: 5px 116px;
    background: none;
    text-decoration: none;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
    border: 0.5px solid transparent;
    &:hover {
      border: 0.5px solid #dedede;
      padding: 10px 12px;
      background: #ebe7e7;
    }
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      padding: 2%;
      margin: 0;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      padding: 2%;
      margin: 0;
    }
  }
}
