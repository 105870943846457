@import '../../scss/mixin.scss';
@import '../../scss/variable.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../assets/fonts/SFProText/SFProText-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'SFPROBold';
  src: url(../../assets/fonts/SFProText/SFProText-Bold.ttf) format('opentype');
}

:root {
  --background-gradient: linear-gradient(30deg, #f39c12 30%, #f1c40f);
  --gray: #34495e;
  --darkgray: #2c3e50;
}

.select2 {
  /* Reset Select */
  appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1;
  padding: 0 1em;
  color: #fff;
  background-color: var(--darkgray);
  background-image: none;
  cursor: pointer;
}

.con {
  min-height: 100vh;
}

.bg1 {
  // width: 100vw;
  // height: 100vh;
  display: flex;
  flex-direction: row;
  padding: 2% 0 2% 0;
}
.tit {
  font-family: 'SFPRORegular', sans-serif;
  font-size: 32px;
  font-weight: 700;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    @include font(700, 17px, 24px);
    text-align: left;
  }

  @media only screen and (min-width: 375px) and (max-width: 1000px) {
    @include font(700, 20px, 24px);
    text-align: left;
  }
}
.bg2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
  background-color: #8b00ff;

  &_citate {
    color: whitesmoke;
    font-size: 20px;
  }
  &_c {
    margin-top: 1em;
    color: white;
    font-size: 18px;
  }
  img {
    border-radius: 50%;
    width: 6em;
    height: 6em;
  }
}
.bg3 {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  //background-image: linear-gradient(to right top, #e4bae0, #ebd3ef, #ead4f9, #f4effe, #ebebeb);

  @media only screen and (min-width: 320px) and (max-width: 1000px) {
    width: 100vw;
  }
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  &_btnBack {
    @media only screen and (min-width: 320px) and (max-width: 1000px) {
      left: 2rem;
      top: 13vw;
      width: 34px;
      height: 34px;
      border-radius: 5px;
      position: absolute;
      background: #cfe2ff;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    width: 50px;
    height: 50px;
    border-radius: 10px;
    position: absolute;
    left: 10%;
    top: 6%;
    background: #cfe2ff;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      @media only screen and (min-width: 320px) and (max-width: 1000px) {
        width: 24px;
        height: 24px;
      }

      width: 32px;
      height: 32px;
      object-fit: contain;
      object-position: center;
    }
  }

  &_rolePic {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    margin-bottom: 2rem;

    @media only screen and (min-width: 320px) and (max-width: 1000px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      margin-bottom: 1rem;
    }

    &_box {
      display: flex;
      width: 400px;
      height: 401px;

      @media only screen and (min-width: 320px) and (max-width: 1400px) {
        display: flex;
        width: 30vw;
        height: 35vw;
      }

      &_role {
        display: inline-block;
        width: 400px;
        height: 300px;

        @media only screen and (min-width: 320px) and (max-width: 1400px) {
          display: inline-block;
          max-width: 30vw;
          height: 300px;
        }
      }

      img {
        @media only screen and (min-width: 320px) and (max-width: 1400px) {
          width: 30vw;
        }
      }
    }
  }

  &_logoWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 70px;

    @media screen and (max-width: 505px) {
      margin-bottom: 2rem;
    }

    img {
      @media only screen and (min-width: 320px) and (max-width: 1000px) {
        width: 50vw;
        margin-top: 7vw;
      }
    }
  }
}

.schoolBox {
  @media only screen and (min-width: 320px) and (max-width: 1300px) {
    width: 100vw;
    // height: 500px;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0;
  }

  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1295px;
  // height: 350px;
  // overflow-y: hidden;
  // overflow-x: auto;
  padding: 2rem 0;
  scrollbar-color: #357eeb #fff;
  margin: 0 auto;
}

.swiper {
  height: 100%;
  width: 100%;
  @media screen and (max-width: 550px) {
    min-height: 400px;
  }
}

.swiper-wrapper {
  width: calc(100vw - 2rem);
}

.slide {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  max-height: 350px;
  max-width: 450px;
  @media screen and (max-width: 550px) {
    max-height: 130px;
    max-width: calc(92vw - 2rem);
  }
}

.name {
  font-family: 'SFPRORegular';
  font-weight: 700;
  font-size: 30px;
  line-height: 35.8px;
  margin-bottom: 5px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #0d28bb;

  @media only screen and (min-width: 320px) and (max-width: 1000px) {
    font-family: 'SFPRORegular';
    font-weight: 700;
    font-size: 3.5vw;
    line-height: 4vw;
    margin-bottom: 1vw;
    width: 30vw;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #0d28bb;
  }
}

.role {
  font-family: 'SFPROBold';
  color: #332f36;
  font-weight: 600;
  font-size: 20px;
  line-height: 23.87px;

  @media only screen and (min-width: 320px) and (max-width: 1000px) {
    font-family: 'SFPROBold';
    color: #332f36;
    font-weight: 600;
    font-size: 2.4vw;
    line-height: 2vw;
  }
}

.search {
  margin: 1rem 0;
  position: relative;
  background-color: hsl(0, 0%, 100%);
  border-style: solid;
  border-color: hsl(273, 100%, 74%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  width: 343px;
  height: 60px;
  transition: ease-in-out background-color 0.3s;
  padding: 5px;

  form {
    width: 100%;
    height: 100%;

    input {
      margin: 0;
      line-height: 30px;
      width: 100%;
      height: 100%;
      line-height: 2rem;
      border: none;
      outline: none;
    }
  }
}

.bg {
  @media only screen and (min-width: 320px) and (max-width: 1000px) {
    margin-top: 1.5em;
    margin-left: 1rem;
    position: relative;
    background-color: #cfe2ff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 24px;
    width: 40vw;
    height: 17vw;
    transition: ease-in-out background-color 0.3s;
    padding: 20px 16px 20px 16px;
  }

  margin-top: 1.5em;
  margin-left: 1rem;
  position: relative;
  background-color: #cfe2ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 24px;
  width: 397px;
  height: 161px;
  transition: ease-in-out background-color 0.3s;
  padding: 20px 16px 20px 16px;

  &_container {
    display: flex;
    flex-direction: column;
  }

  span {
    font-size: 70px;
    margin-right: 9rem;
    color: #3686ff;
    transition: ease-in-out color 0.3s;
    height: 50%;
    @include display(flex, row, center, center);

    @media only screen and (min-width: 320px) and (max-width: 1000px) {
      align-items: center;
      font-size: 6vw;
      margin-right: 75%;
      color: #3686ff;
      transition: ease-in-out color 0.3s;
      height: 50%;
      @include display(flex, row, center, center);
    }
  }

  &:hover {
    background: linear-gradient(to right, #0d28bb, #357eeb);

    span {
      color: #fffafa;
    }
    .role {
      color: #fffafa;
    }

    .name {
      color: #fffafa;
    }
  }
}

.create {
  @media only screen and (min-width: 320px) and (max-width: 1000px) {
    display: flex;
    font-weight: 300;
    position: relative;
    justify-content: center;
    align-items: center;
    color: #0d28bb;
    height: 6vw;
    width: 33vw;
    border-radius: 10px;
    background-color: #cfe2ff;
    font-family: 'SFPRORegular';
    font-weight: 600;
    font-size: 2vw;
    line-height: 23.87px;
    transition: all ease-in 0.3s;

    &:hover {
      color: #ffffff;
      background: linear-gradient(to right, #0d28bb 100%, #357eeb 100%);
      border: 1px solid #ffffff;
    }
  }
  display: flex;
  font-weight: 300;
  position: relative;
  justify-content: center;
  align-items: center;
  color: #0d28bb;
  height: 57px;
  width: 343px;
  border-radius: 10px;
  background-color: #cfe2ff;
  font-family: 'SFPRORegular';
  font-weight: 600;
  font-size: 20px;
  line-height: 23.87px;
  transition: all ease-in 0.3s;

  &:hover {
    color: #ffffff;
    background: linear-gradient(to right, #0d28bb 100%, #357eeb 100%);
    border: 1px solid #ffffff;
  }
}

.main {
  background-color: #6b728f;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  & > button {
    margin-top: 50px;
  }
}
.title {
  font-size: 20px;
  text-align: center;
  margin-bottom: 100px;
}
.select2::-ms-expand {
  display: none;
}
.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  border-radius: 0.25em;
  overflow: hidden;
}
.select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #34495e;
  transition: 0.25s all ease;
  pointer-events: none;
}
.select:hover::after {
  color: #f39c12;
}

@keyframes bgAnimate1 {
  from {
    transform: translate3d(0, 0, 0);
  }

  20% {
    transform: translate3d(50%, 100%, 0);
  }

  30% {
    transform: translate3d(150%, 150%, 0);
  }

  50% {
    transform: translate3d(400%, 200%, 0);
  }

  70% {
    transform: translate3d(300%, 70%, 0);
  }

  90% {
    transform: translate3d(100%, 30%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bgAnimate2 {
  from {
    transform: translate3d(0%, 0%, 0);
  }
  20% {
    transform: translate3d(-100%, -100%, 0);
  }
  30% {
    transform: translate3d(-200%, -150%, 0);
  }
  50% {
    transform: translate3d(-400%, -200%, 0);
  }
  70% {
    transform: translate3d(-300%, -60%, 0);
  }
  90% {
    transform: translate3d(-100%, -10%, 0);
  }
  to {
    transform: translate3d(0%, 0%, 0);
  }
}

@keyframes bgAnimate3 {
  from {
    transform: translate3d(0, 0, 0);
  }

  20% {
    transform: translate3d(-150%, 10%, 0);
  }

  30% {
    transform: translate3d(-300%, 100%, 0);
  }

  50% {
    transform: translate3d(-400%, 200%, 0);
  }

  70% {
    transform: translate3d(-250%, 150%, 0);
  }

  90% {
    transform: translate3d(-50%, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes bgAnimate4 {
  from {
    transform: translate3d(0, 0, 0);
  }

  20% {
    transform: translate3d(150%, -10%, 0);
  }

  30% {
    transform: translate3d(350%, -80%, 0);
  }

  50% {
    transform: translate3d(400%, -150%, 0);
  }

  70% {
    transform: translate3d(250%, -140%, 0);
  }

  90% {
    transform: translate3d(100%, -80%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.bgf {
  z-index: -1;

  &_wrap1 {
    position: absolute;
    width: 50px;
    height: 50px;
    background: #3686ff;
    border-radius: 50%;
    scale: 10;
    filter: blur(25px);
    -webkit-filter: blur(25px);
    -moz-filter: blur(25px);
    -o-filter: blur(25px);
    -ms-filter: blur(25px);
    animation: bgAnimate1 50s linear infinite;
  }

  &_wrap2 {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 100%;
    top: 100%;
    scale: 10;
    background: #3686ff;
    border-radius: 50%;
    filter: blur(25px);
    -webkit-filter: blur(25px);
    -moz-filter: blur(25px);
    -o-filter: blur(25px);
    -ms-filter: blur(25px);
    animation: bgAnimate2 50s linear infinite;
  }

  &_wrap3 {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 100%;
    top: 0%;
    scale: 10;
    background: #3686ff;
    border-radius: 50%;
    filter: blur(25px);
    -webkit-filter: blur(25px);
    -moz-filter: blur(25px);
    -o-filter: blur(25px);
    -ms-filter: blur(25px);
    animation: bgAnimate3 50s linear infinite;
  }

  &_wrap4 {
    position: absolute;
    width: 50px;
    height: 50px;
    scale: 10;
    left: 0%;
    top: 100%;
    background: #3686ff;
    border-radius: 50%;
    filter: blur(25px);
    -webkit-filter: blur(25px);
    -moz-filter: blur(25px);
    -o-filter: blur(25px);
    -ms-filter: blur(25px);
    animation: bgAnimate4 50s linear infinite;
  }
}

.input {
  div {
    div {
      box-shadow: 0px 0px 8px 0px #3241954d;
      border: none;
      border-radius: 14px;
      padding: 10px;

      @media only screen and (min-width: 320px) and (max-width: 575px) {
        height: 40px;
      }
    }
  }

  input {
    @media only screen and (min-width: 320px) and (max-width: 575px) {
      font-size: 14px;
    }
  }

  input::placeholder {
    @media only screen and (min-width: 320px) and (max-width: 575px) {
      font-size: 14px;
    }
  }
}

.searchIcon {
  path {
    fill: $color-blue;
  }
}

.container {
  width: 100%;
  padding: 0 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 320px) and (max-width: 480px) {
    padding: 0 1rem;
  }
}
