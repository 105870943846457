@import '../../../../../../../../../scss/variable.scss';
@import '../../../../../../../../../scss/mixin.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../../../../../../../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.header {
  @include display(flex, column, center);
  color: $color-text;
  font-family: 'SFPRORegular';
  text-align: left;

  &_container {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-bottom: 10px;
  }

  &_title {
    @include font(700, 18px, 21.48px);

    @media only screen and (min-width : 320px) and (max-width : 375px) {
      @include font(700, 16px, 16px);
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
      @include font(700, 16px, 16px);
    }
  }

  &_subtitle {
    @include font(500, 14px, 16.71px);
    margin-top: 4px;

    @media only screen and (min-width : 320px) and (max-width : 375px) {
      @include font(500, 10px, 11.93px);
      margin-top: 8px;
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
      @include font(500, 10px, 11.93px);
      margin-top: 8px;
    }
  }

  &_testIconWrapper {
    @include display(flex, column, flex-start, center);
    &_iconRow {
      width: 50px;
      height: 9px;
      margin: 2px 0;
      padding: 2px 4px;
      border-radius: 22px;
      background-color: #6b7280;
      span {
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #ffffff;
      }
    }
  }

  @media only screen and (min-width : 320px) and (max-width : 375px) {
    @include display(flex, row, flex-start, center);
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    @include display(flex, row, flex-start, center);
  }


  &_controlIconWrapper {
    @include display(flex, row, space-between, center);
    width: 250px;

    @media only screen and (min-width : 320px) and (max-width : 375px) {
      width: auto;
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
      width: auto;
    }

    &_timer {
      position: relative;
      @include display(flex, row, flex-start, center);
      padding: 5px 8px;
      border-radius: 5px;
      background-color: #f3f4f6;
      input {
        position: relative;
        z-index: 100;
        margin: 0 0 0 7px;
        border: none;
        @include font(400, 12px, 18px);
        color: #929292;
        background-color: transparent;
        outline: none;
        &::-webkit-calendar-picker-indicator {
          width: 6px;
          opacity: 0;
          cursor: pointer;
        }
      }
      &_clearTimer {
        @include position_t_r(absolute, 4px, 10px, 5);
        cursor: pointer;
      }
      &_openList {
        @include position_t_r(absolute, 6px, 10px, 5);
        cursor: pointer;
      }
      svg {
        cursor: auto;
      }
    }
    &_togglerShowOption {
      cursor: pointer;
      svg {
        path {
          stroke: rgb(108, 104, 104);
        }
      }
      &:hover {
        svg {
          path {
            stroke: $color-blue;
          }
        }
      }
    }
    &_duplicate {
      cursor: pointer;
      &:hover {
        svg {
          path {
            fill: $color-blue;
          }
        }
      }
    }
    &_delete {
      cursor: pointer;
      &:hover {
        svg {
          path {
            fill: #ef4444;
          }
        }
      }
    }
    &_grab {
      cursor: grab;
    }
  }
}
.rotate_arrow {
  transform: rotate(180deg);
}

.avatar {
  background: linear-gradient(90deg, #3170e7 13.5%, #7a90f7 100%) !important;
  width: 34px !important;
  height: 34px !important;

  @media only screen and (min-width : 320px) and (max-width : 375px) {
    background: $color-blue !important;
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    background: $color-blue !important;
  }
}
