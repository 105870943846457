@import '../../../scss/mixin.scss';
@import '../../../scss/variable.scss';

.waterWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  position: relative;
  z-index: 1;
  width: 90%;

  @media screen and (min-width: 389px) and (max-width: 700px) {
    display: flex;
    width: 10.125rem;
    height: 11rem;
    padding: 0.39038rem 0.19519rem;
    justify-content: center;
    align-items: center;
    gap: 0.19519rem;
  }

  @media screen and (min-width: 320px)and (max-width: 388px) {
    display: flex;
    width: 9rem;
    height: 9.25rem;
    padding: 0.39038rem 0.19519rem;
    justify-content: center;
    align-items: center;
    gap: 0.19519rem;
  }

  &:nth-child(1) svg {
    width: 100%;
    height: auto;
    @media screen and (min-width: 389px) and (max-width: 700px) {
      width: 120px;
      height: 120px;
    }

    @media screen and (min-width: 320px)and (max-width: 388px) {
      width: 100px;
      height: 100px;
    }
  }

  &_percentageTextTo60 {
    position: absolute;
    z-index: 4;
    background: var(--Blue-Linear-2, #3170e7);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    align-self: center;
    font: 800 46px/1 'SFPROMedium', -apple-system, Roboto, Helvetica, sans-serif;

    @media screen and (max-width: 700px) {
      font-size: 16px;
    }
  }

  &_percentageTextAfter60 {
    position: absolute;
    z-index: 4;
    background: var(--Blue-inactive-gradient, linear-gradient(180deg, #cfe2ff 0%, #bbceeb 100%));
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    align-self: center;
    font: 800 46px/1 'SFPROMedium', -apple-system, Roboto, Helvetica, sans-serif;

    @media screen and (max-width: 900px) {
      font-size: 16px;
    }
  }

  &_waterPlayground {
    width: 10rem;
    height: 10rem;
    overflow: hidden;
    z-index: 2;
    position: absolute;
    border-radius: 16.25rem;
    border: 3px solid rgba(49, 112, 231, 0.8);
    background: var(--Blue-inactive-gradient, linear-gradient(180deg, #cfe2ff 0%, #bbceeb 100%));
    box-shadow: 0px 0px 25px 0px #357eeb;
    backdrop-filter: blur(17.5px);

    @media screen and (min-width: 701px) and (max-width: 900px) {
      width: 7rem;
      height: 7rem;
    }

    @media screen and (max-width: 700px) {
      width: 60px;
      height: 60px;
      flex-shrink: 0;
      border-radius: 9.00006rem;
      border: 1.688px solid var(--Blue-Linear-2, #3170e7);
      background: var(--Blue-inactive-gradient, linear-gradient(180deg, #cfe2ff 0%, #bbceeb 100%));
      box-shadow: 0px 0px 14.063px 0px #357eeb;
      backdrop-filter: blur(9.84375px);
    }

    svg {
      g {
        z-index: 0;
        mix-blend-mode: multiply;
      }
    }
  }
}

.waterWave > path {
  animation: move-forever 8s linear infinite;
  animation-timing-function: linear;
  animation-fill-mode: both;
  will-change: transform;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
