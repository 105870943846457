@import 'src/scss/variable';
@import '../../scss/mixin.scss';

.editorWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 300px;
  max-height: 300px;
  border-radius: 11px;
  background: #1e1e1e;
  width: 100%;

  &_wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    box-shadow: 0px 0px 8px 0px #3241954d;
    border-radius: 20px;
    padding: 1rem;
  }

  &_editor {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 7px;
    overflow: hidden;

    &_add {
      @include position_t_l(absolute, 90px, 0);
      overflow: hidden;
      border-radius: 7px;
      width: 100%;
      height: 100%;
    }
  }

  &_selectWrapper {
    @include position_t_l(absolute, -45px, 0);
    width: 176px;
  }

  &_navBlock {
    @include display(flex, row, space-between, center);
    width: 100%;
    padding-bottom: 1rem;

    &_div {
      background: #f3f4f6;
      border: none;
      border-radius: 7px;
      width: 36px;
      height: 36px;
      text-align: center;
      @include display(flex, row, center, center);
      margin-bottom: 8px;
      cursor: pointer;
    }

    &_grabBtn {
      display: flex;
      cursor: grab;
    }

    &_delete {
      padding: 10px 25px;
      @include font(500, 16px, 19.09px);
    }
  }
}

.btnSave {
  @include display(flex, row, center);
  margin-top: 10px;
  width: 120px;
  height: 36px;
  border: none;
  text-align: center;
  background: #6b728f;
  font-size: 14px;
  color: #ffffff;
  border-radius: 6px;
  padding: 11px 0 11px;
  cursor: pointer;
}

.btnCancel {
  @include display(flex, row, center);
  margin-top: 10px;
  width: 120px;
  height: 36px;
  border: none;
  text-align: center;
  background: #f3f4f6;
  font-size: 14px;
  color: #6b728f;
  border-radius: 6px;
  padding: 11px 0 11px;
  cursor: pointer;
}

.inputSelect {
  padding: 0;
  margin-bottom: 0;
  min-width: 200px;

  div {
    button {
      background: #357eeb;
      border: 0.1rem solid transparent;
      color: #fff;
      border-radius: 10px;
    }
    svg path {
      fill: #fff;
    }
  }
}
