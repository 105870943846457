@import '../../scss/variable.scss';
@import '../../scss/mixin.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.init {

    &_container {
        width: 600px;
        min-height: 420px;
        border-radius: 30px;
        background: linear-gradient(to right, #0D28BB, #357EEB);
        opacity: 1;
        transition: all 0.4s ease-out;

        @media only screen and (min-width: 320px) and (max-width: 1150px){
          max-width: 65vw;
          max-height: 49vw;
          border-radius: 30px;
          background: linear-gradient(to right, #0D28BB, #357EEB);
        }



        &_box {

            display: grid;
            padding: 24px 30px 24px 24px;
            grid-template-areas:
                "photo info"
                "rev rev";


            &_photo {
                grid-area: photo;

                img{
                    border-radius: 100px;
                    width: 140px;
                    height: 140px;
                }

            }

            &_info {
                width: 389px;
                height: 100%;
                grid-area: info;
                padding: 24.5px 73px 24.5px 25px;
                font-family: 'SFPRORegular';
                color: rgba(255, 255, 255, 1);
                @include font(500, 24px, 28.64px);

                @media only screen and (min-width: 320px) and (max-width: 1150px){
                  width: 40vw;
                  height: 10vw;
                  grid-area: info;
                  margin-top: 26px;
                  margin-left: 36px;
                  font-size: 2vw;
                }

                &_position {
                  @include font(500, 23px, 28.87px);
                  margin-bottom: 0;
                  color: rgba(255, 255, 255, 0.6);

                }
            }

            &_rev {
                border-radius: 30px;
                background: #FFFFFF;
                width: 100%;
                min-height: 220px;
                gap: 5px;
                grid-area: rev;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-top: 10px;
                padding: 48.5px 36px 28.5px 36px;
                font-family: 'SFPRORegular';
                color: rgba(25, 33, 79, 1);
                @include font(500, 16px, 19.09px);

                @media only screen and (min-width: 320px) and (max-width: 1150px){
                  border-radius: 30px;
                  background: #FFFFFF;
                  width: 65vw;
                  height: 30vw;
                  grid-area: rev;
                  text-align: 20px;
                  margin-left: 4vw;
                  padding: 35.78px 15vw 11.93px 11.93px;
                  font-size: 1.5vw;
                }

                &_block {
                  @include display(flex, row, space-between, center);

                  &_date {
                    color: rgba(110, 87, 136, 1);
                  }
                }
            }

            &_leave {
              font-family: 'SFPRORegular';
              @include display(flex, row, justify-content, center);
              gap: 12.5px;
              @include font(500, 24px, 28.64px);
              color: #324195;
              background: #CFE2FF;

                @media only screen and (min-width: 320px) and (max-width: 1150px){
                  color: #324195;
                  font-size: 3vw;
                  background: #CFE2FF;
                }
            }
        }
   }

    &_containerLeave {
        width: 600px;
        min-height: 420px;
        border-radius: 30px;
        background: #CFE2FF;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center;
        cursor: pointer;

        @media only screen and (min-width: 320px) and (max-width: 1150px){
          max-width: 65vw;
          max-height: 49vw;
          border-radius: 30px;
          background: #CFE2FF;
          display: flex;
          align-items: center;
          flex-direction: row;
          justify-content: center;
        }
    }

}

.swiper {
    padding-bottom: 30px;
}

.swiper-slide-prev {
    width: 100px;


    @media only screen and (min-width: 320px) and (max-width: 435px){
      width: 4px;
      height: 4px;
      background-color: #E1EBF9;
      left: 200px;
      color: #CFE2FF;


    }
}

.buttons_nav {
  @include display(flex, row, center, center);
  position: relative;
  gap: 20px;
  margin-top: 47px;

  &_btn {
    @include display(flex, row, center, center);
    width: 44px;
    height: 44px;
    border-radius: 14px;
    background: #CFE2FF;
    backdrop-filter: blur(20px);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color:#332F36;
    fill: #332F36;

    &:hover {
      background: #357EEB;
      color:#FFFFFF;
      fill: #FFFFFF;

  }
}
}

.pagination {
  @include display(flex, row, center, center);
  position: relative;
  gap: 5px;
}

.inactive {
  opacity: 0.5;
}
