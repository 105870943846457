@import '../../scss/mixin';
@import '../../scss/variable.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.lesson {
  @include display(flex, column, center, center);
  background: $color-white;
  width: 138px;
  height: 73px;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 0px 18px 0px #3241954D;
  padding: 10px 20px;
  cursor: pointer;
  font-family: 'SFPRORegular';
  font-weight: 500;
  & img {
    width: 24px;
    height: 24px;
  }
  &:hover {
    box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.1);
    transform: scale(1.05);
  }
}
