@import '../../scss/mixin.scss';
@import '../../scss/variable.scss';

.button {
  text-decoration: none;
  background: #ba75ff;
  padding: 10px 15px;
  border-radius: 10px;
  color: #f3f4f6;
  font-weight: 600;
  transition: ease-in 0.3s;
  word-wrap: break-word;
  max-width: 230px;
  overflow: hidden;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }
}

.createButton {
  font-weight: 500;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 10px 40px;
  width: fit-content;
}

.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0px 0px 8px 0px #3241954d;
  background: #ffffff;
  border-radius: 20px;
  padding: 1rem;

  &_redactorField {
    width: 100%;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      width: 85%;
      margin-left: 0;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      width: 85%;
      margin-left: 0;
    }

    &_paper {
      padding: 30px 20px;
      max-width: 100%;
      height: 100%;
      @include display(flex, column, flex-start);
      gap: 10px;
      box-shadow: none;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        padding: 1rem 0 1rem 0;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        padding: 1rem 1rem 1rem 1rem;
      }

      &_title {
        font-weight: bold;
        font-size: 16px;
        font-family: 'SFPRORegular', sans-serif;
      }
    }
  }

  &_navBlock {
    @include display(flex, row, space-between, center);
    min-width: 36px;
    width: 100%;
    min-height: 36px;
    // justify-content: center;

    &_div {
      @include display(flex, row, center, center);
      background: #f3f4f6;
      border: none;
      border-radius: 7px;
      width: 36px;
      height: 36px;
      text-align: center;
      cursor: pointer;
    }

    &_grabBtn {
      display: flex;
      cursor: grab;
    }

    &_delete {
      padding: 10px 25px;
      @include font(500, 16px, 19.09px);
    }
  }
}
