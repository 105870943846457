@import '../../../scss/variable';
@import '../../../scss/mixin.scss';

.wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &_tariffCard {
    display: flex;
    flex-direction: column;
    width: 40em;
    height: fit-content;
    padding: 2em;
    border-radius: 1em;
    box-shadow: 1em;
    background-color: rgb(253, 253, 253);

    &_info {
      align-items: center;
      flex-direction: column;
      display: flex;
      margin-top: 1em;

      &_cardGroup {
        margin-top: 45px;
        display: inline-flex;
        align-items: center;
        width: 90vw;
        justify-content: space-around;

        &_card:hover {
          background-color: #ebe8f5;
          button {
            color: #ba75ff;
            background-color: #ffffff;
          }
        }

        &_card {
          width: 253px;
          height: 310px;
          background: #fff;
          border-radius: 20px;
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          margin-bottom: 80px;

          &_text {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
            width: 100%;
            min-width: 100%;
            min-height: 100%;
            animation: fadeIn 1s ease-in-out forwards;

            @keyframes fadeIn {
              from {
                opacity: 0;
              }
              to {
                opacity: 1;
              }
            }

            h3 {
              color: #4d5766;
              font-weight: 600;
              font-size: 22px;
              text-align: center;
            }
            hr {
              border: 1px solid #ba75ff;
              width: 80%;
              height: 0;
              opacity: 0.5;
              text-align: center;
            }
            ul {
              list-style: none;

              li::before {
                content: '\2022';
                color: #ba75ff;
                font-weight: bold;
                display: inline-block;
                width: 1em;
                margin-left: -1em;
              }
              li {
                font-size: 16px;
                padding-bottom: 8px;

                span {
                  font-weight: 800;
                  margin-left: 0.3em;
                  color: #ba75ff;
                }
              }
            }
            button {
              width: 184px;
              height: 36px;
              font-size: 12px;
              position: relative;
            }
          }
        }
        @media (min-width: 767px) and (max-width: 1024px) {
          display: grid;
          grid-template-columns: 1fr 1fr;
          &_card {
            margin: 0 auto 80px auto;
          }
        }
        @media (min-width: 320px) and (max-width: 767px) {
          display: flex;
          flex-direction: column;
        }
      }
    }

    &_form {
      margin-top: 1em;
      height: max-content;
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      width: 100%;
    }

    &_info {
      display: flex;
    }

    &_body {
      display: flex;
      animation: fadeIn 1s ease-in-out forwards;

      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }

    &_header {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: fit-content;

      &_title {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: fit-content;
        padding: 0.5em;
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        font-size: 20px;
        color: $font-title;
        animation: fadeIn 0.7s ease-in-out forwards;

        @keyframes fadeIn {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }

      &_btnHeader {
        display: flex;
        width: 100%;
        height: fit-content;
        justify-content: flex-end;

        &_btnClose {
          outline: none;
          border: none;
          color: $backgroundCheckBox;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #f4f4f4;
          border-radius: 5px;
          width: 35px;
          height: 35px;
          cursor: pointer;
          transition: 0.3s color;
          @media only screen and (min-width: 320px) and (max-width: 375px) {
            top: 15px;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            top: 15px;
          }

          &:hover {
            color: $font-title;
          }
        }
      }
    }
  }
}

.paymentField {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;

  label {
    margin: 0.2em;
  }

  &_inputField {
    height: 2em;
    border: 1px solid #c6c3c3;
    border-radius: 4px;
    background-color: #ffffff;
    font-family: 'Inter', sans-serif;
    padding: 0 5px;
    transition: border-color 0.2s;
    width: 15em;
  }
}

.error {
  color: red;
  opacity: 0.8;
  font-size: 10px;
}

.btn {
  margin-top: 2em;
  background-color: #ccb4f9;
  width: 12em;
  height: 2.5em;
}

.paymentsMethods {
  display: flex;
  flex-direction: column;
  padding: 1em;

  img {
    margin: 0.4em;
  }
}

.acceptBtn {
  display: flex;
  margin-top: 1em;
  background-color: #ccb4f9;
  width: 12em;
  height: 2.5em;
  border-radius: 0.5em;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  color: #6b6b6b;
  transition: background-color 0.5s, color 0.5s;

  &:hover {
    cursor: pointer;
    background-color: #ba75ff;
    color: #e2e2e2;
  }
}

.secondPhaseWrapper {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.backwardBtn {
  display: flex;
  margin-top: 1em;
  background-color: #b8b8b8;
  width: 12em;
  height: 2.5em;
  border-radius: 0.5em;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  color: #1e1e1e;
  transition: background-color 0.5s, color 0.5s;

  &:hover {
    cursor: pointer;
    background-color: #d2d2d2;
    color: #000000;
  }
}

.btnBlock {
  display: flex;
  a {
    margin: 1em;
  }
}
