@import '../../../scss/variable.scss';
@import '../../../scss/mixin.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.wrapper {
  @include display(flex, column, flex-start, center);
  overflow: hidden;
  user-select: none;
  position: relative;
  margin-top: 10px;
  padding: 20px 20px 30px 36px;
  background: linear-gradient(140deg,#c2e0ff 0%, #c2e0ff 11%, #d6eaff 16%, #e5f2ff 25%, #fafcff 57%, #fafcff 82%, #e5f2ff 89%, #d6eaff 93%, #c2e0ff 100%);
  box-shadow: 0px 0px 8px 0px #3241954D;
  overflow: hidden;
  min-height: 409px;
  border-radius: 64px;
  max-width: 841px;
  width: 100%;
  animation: slideDown 0.2s ease-in forwards;

  &_question_count {
    @include font(700, 20px, 23.87px);
    color: $color-text;
    text-align: center;
    font-family: 'SFPRORegular';
    margin-bottom: 12px;
  }

  &_drop_down_menu {
    position: relative;
    margin-top: 20px;
    max-width: 490px;
    width: 100%;
    overflow: hidden;
    animation: slideDown 0.2s ease-in forwards;

    @media only screen and (min-width : 320px) and (max-width : 375px) {
      padding: 5%;
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
      padding: 5%;
    }

  }

  @keyframes slideDown {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
}

.settings_list {
  user-select: none;
  list-style-type: none;
  display: flex;
  gap: 10px 20px;
  flex-wrap: wrap;

  @media only screen and (min-width : 320px) and (max-width : 375px) {
    display: flex;
    flex-direction: column;
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    display: flex;
    flex-direction: column;
  }

}
