@import '../../../../../scss/mixin.scss';
@import '../../../../../scss/variable.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../../../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.container {
  @include display(flex, row);
  gap: 40px;

  @media only screen and (max-width : 1220px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    padding-top: 50px;
  }
}

.container_right {
  @include display(flex, column);
  width: 100%;
  gap: 20px;

  @media only screen and (min-width : 320px) and (max-width : 375px) {
    padding: 0 0.2rem;
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    padding: 0 0.2rem;
  }

  .availability_course_wrapper {
    padding: 45px 45px 30px;
    margin-top: 32px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px #d9d9d9;

    form {
      h4 {
        @include font(500, 16px, 19px);
        color: #4d5766;
      }

      legend {
        margin-top: 21px;
        @include font(400, 12px, 15px);
        color: #6c7889;
      }

      div {
        margin-top: 15px;
        @include font(400, 12px, 15px);
        letter-spacing: 0.03em;
        color: #6c7889;

        label {
          &::before {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .course_actions_wrapper {
    padding: 45px 45px 30px;
    margin-top: 32px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px #d9d9d9;

    div {
      h4 {
        @include font(500, 16px, 19px);
        color: #4d5766;
      }

      button {
        margin-top: 20px;
      }

      .button_delete:hover {
        background: #ef4444;
      }
    }
  }
}

.card_image_downloads {
  height: 262px;
  box-shadow: 0 0 10px #d9d9d9;
  border-radius: 7px;
  background-color: #fff;

  @media only screen and (min-width : 320px) and (max-width : 375px) {
    margin-top: 1rem;
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    margin-top: 1rem;
  }

  &_error {
    font-size: 12px;
    color: rgb(224, 24, 24);
    margin-top: 5px;
    text-align: center;
  }
}

.hide_input {
  width: 100%;
  height: 100%;
  opacity: 0;
  background-image: none;
  background-color: transparent;
}

.block_download_image {
  display: block;
  width: 300px;
  height: 162px;
  background-color: #d7d7d7;
  border-radius: 7px 7px 0 0;
  overflow: hidden;

  img {
    border-radius: 7px 7px 0 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    transition: opacity 0.6s, transform 0.6s;
  }

  &:hover {
    img {
      opacity: 0.4;
      transform: scale(1.1);
    }
    background-position: top;
    background-size: cover;
  }
}

.text_block {
  margin: 15px 0 0 30px;
  @include display(flex, row, flex-start, center);
  color: #e0dced;
  font-style: normal;
  @include font(500, 11px, 13px);

  svg {
    margin-right: 10px;
  }
}

.text_name {
  margin: 20px 0 0 25px;
  color: #4d5766;
  text-transform: capitalize;
  font-style: normal;
  @include font(500, 14px);
}

.basic_settings {
  background-color: $color-white;
  border-radius: 20px;
  box-shadow: 0px 0px 18px 0px rgba(50, 65, 149, 0.3);
  padding: 1.5rem 2.125rem;

  @media only screen and (min-width : 320px) and (max-width : 375px) {
    padding: 2rem 1rem 2rem 1rem;
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    padding: 2rem 1rem 2rem 1rem;
  }


  .publish_switch {
    @include display(flex, column);

    &_title {
      @include font(500, 20px, 23.87px);
      color: #000000;

      @media only screen and (min-width : 320px) and (max-width : 375px) {
        @include font(500, 17px, 21.87px);
      }
    }

    &_desc {
      @include font(500, 16px, 19.09px);
      color: $color-grey;
      margin: 16px 0 8px;

      @media only screen and (min-width : 320px) and (max-width : 375px) {
        @include font(500, 14px, 19.09px);
      }
    }

    &_wrapper_switch {

      div {
        width: 40px;
        height: 20px;
        background: rgba(128, 128, 128, 1);
        border: none;

        &[data-isOn='true'] {
          background: transparent;
          border: 1px solid rgba(53, 126, 235, 1);

          div {
            &::before {
              background: rgba(53, 126, 235, 1);
              border: none;
            }
          }
        }

        div {
          width: 1rem;
          height: 1rem;

          &::before {
            width: 1rem;
            height: 1rem;
            background: #ffffff;
            border: none;
          }
        }
      }
    }

    label {
      margin: 0 13px;
    }
  }

  .course_name_wrapper {

    .course_name_title {
      @include font(500, 20px, 23.87px);
      color: #000000;
      font-family: 'SFPRORegular';
      margin: 1rem 0;

      @media only screen and (min-width : 320px) and (max-width : 375px) {
        @include font(500, 17px, 21.87px);
      }
    }
  }

  .short_discription_wrapper {

    .short_discription_title {
      @include font(500, 20px, 23.87px);
      color: #000000;
      font-family: 'SFPRORegular';
      margin: 1.5rem 0;

      @media only screen and (min-width : 320px) and (max-width : 375px) {
        @include font(500, 17px, 21.87px);
      }
    }

    .penIcon {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }

    .short_discription_input {
      width: 100%;
      text-overflow: ellipsis;
      text-align: left;
      position: relative;

      div {
        div {
          border-radius: 16px;
          padding: 16px;
          border: 1px solid transparent;
          background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(90deg, #0d28bb 0%, #357eeb 100%) border-box;
        }
      }
    
      input::placeholder {
        font-weight: 400;
        font-size: 16px;
        color: #808080;
      }
    }
  }

  .course_link_wrapper {
    margin-top: 16px;

    .course_link_title {
      @include font(500, 12px, 15px);
      color: #6c7889;
    }

    div {
      margin-top: 10px;
    }
  }

  .link_switch_wrapper {
    @include display(flex, column);

    .link_switch_label {
      @include display(flex, row, flex-start, center);
      margin-top: 16px;

      &_title {
        @include font(500, 12px, 15px);
        letter-spacing: 0.03em;
        color: #6c7889;
      }

      &_help {
        margin: 6px 0 0 26px;
        @include font(500, 12px, 15px);
        color: #b9bac4;
      }
    }
  }

  .course_category_wrapper {
    margin-top: 18px;

    .course_category_title {
      @include font(500, 12px, 15px);
      color: #6c7889;
    }

    .selectInput_container {
      margin-top: 10px;
    }
  }
}

.header_basic_settings {
  @include display(flex, row, space-between);
  width: 100%;

  & p:first-of-type {
    @include font(500, 24px, 28.64px);
    font-family: 'SFPRORegular';
    color: $color-text;

    @media only screen and (min-width : 320px) and (max-width : 375px) {
      @include font(500, 20px, 23.87px);
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
      @include font(500, 20px, 23.87px);
    }
  }

  .right_content_header {
    @include display(flex, row, flex-start, center);
    @include font(500, 11px, 13px);
    letter-spacing: 0.02em;
    color: #6c7889;

    svg {
      fill: #6c7889;
      margin-right: 10px;
    }
  }
}

.no_image {
  width: 100%;
  height: 100%;
  background-color: #939393;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px 7px 0 0;

  span {
    color: white;
    font-size: 16px;
    opacity: 0.5;
    font-weight: bold;
  }

}

.btn {
  display: flex;
  gap: 37px;
  margin: 1.5rem 0 1.25rem;

  @media only screen and (min-width : 320px) and (max-width : 375px) {
    flex-direction: column;
    gap: 1rem;
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    flex-direction: column;
    gap: 1rem;
  }

  button {
    @media only screen and (min-width : 320px) and (max-width : 375px) {
      font-size: 15px;
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
      font-size: 19px;
    }
  }
}

.settings_input {
  width: 100%;
  text-overflow: ellipsis;
  text-align: left;

  div {
    div {
      border-radius: 16px;
      flex-direction: row-reverse;
      border: 1px solid transparent;
      background: linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(90deg, #0d28bb 0%, #357eeb 100%) border-box;
    }
  }

  input::placeholder {
    font-weight: 400;
    font-size: 16px;
    color: #808080;
  }

  input {
    @media only screen and (min-width : 320px) and (max-width : 375px) {
      @include font(500, 17px, 21.87px);
    }
  }
}

.folderslist {
  padding-top: 1.25rem;
  max-width: 430px; 
  display: flex; 
  flex-direction: column;
  gap: 1.25rem;

    div {
      margin-bottom: 0;

      button {
        border-radius: 14px;
        border: 2px solid #357eeb;
        padding: 16px;
        @include font(500, 20px, 23.87px);
        font-family: 'SFPRORegular';
        color: $color-blue;

        @media only screen and (min-width : 320px) and (max-width : 375px) {
          @include font(500, 17px, 19.09px);
        }
     }

     svg {
      right: 16px;
      top: 50%;
      transform: translateY(-50%);
      width: 8px;
      height: 5px;

      path {
        fill: $color-blue;
      }
     }
    }
}
