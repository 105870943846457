@import 'src/scss/variable';
@import '../../../../../../scss/mixin.scss';

.wrapper {
  display: flex;

  @media only screen and (min-width : 320px) and (max-width : 375px) {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

.redactorCourse {
  font-family: $font-family-inter;
  @include display(flex);
  @media only screen and (min-width : 320px) and (max-width : 375px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
    
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2%;
  }

  &_textField {
    margin-bottom: 40px;

    &_btnEditText {
      @include display(flex);

      &_setting {
        &:hover {
          background-color: rgba(196, 156, 210, 0.4);
        }

        @include display(flex, row, flex-start, center);
        margin-top: 10px;
        width: 100px;
        height: 36px;
        border: none;
        text-align: center;
        background: #f3f4f6;
        font-size: 14px;
        color: #6b728f;
        border-radius: 6px;
        padding: 11px 0 11px 16px;
      }
    }

    &_description {
      &_text {
        margin-bottom: 10px;
        font-weight: bold;
        font-size: 16px;
        font-family: 'Lucida Grande', 'Lucida Sans Unicode', Verdana, Helvetica, Arial, sans-serif;
      }
    }
  }

  &_leftSide {
    @include display(flex, column);
    width: 280px;
    margin-right: 30px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 8px;
    height: 550px;
    //margin-top: 50px;
    //border: 1px #0af50a solid;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    align-items: center;
    background-color: #FFFFFF;
    padding-top: 50px;
    padding-bottom: 20px;
    text-align: center;
    @media only screen and (min-width : 320px) and (max-width : 375px) {
      width: 95%;
      text-align: center;
      
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
     width: 95%;
     text-align: center;
     margin: 0;
    }
    

    &_title {
      color: $font-title;
      font-size: 16px;
      @media only screen and (min-width : 320px) and (max-width : 375px) {
       
        
      }
      @media only screen and (min-width : 375px) and (max-width : 480px) {
        padding: 5% 0 5% 0;
      }
    }

    &_desc {
      @include display(flex, column);
      margin-top: 23px;
      margin-left: 16px; // 16
      //border: 1px deeppink solid;

      @media only screen and (min-width : 320px) and (max-width : 375px) {
        height: 300px;
        overflow-y: scroll;
      }
      @media only screen and (min-width : 375px) and (max-width : 480px) {
        height: 400px;
        overflow-y: scroll;
      }

      &_headerText {
        //position: relative;
        

        &_title {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          @include font(400, 14px);
          background-color: transparent;
          color: $font-title;
          height: 30px;
          margin-bottom: 15px;

          
        }

        &_inputWrapper {
          position: absolute;
          top: -7px;
          //margin-bottom: 10px;
          left: 0;
          @include display(flex, row, space-between, center);
          height: 30px;
          width: 100%;
          //border: 1px blue solid;
          @media only screen and (min-width : 320px) and (max-width : 375px) {
            display: none;
            
          }
          @media only screen and (min-width : 375px) and (max-width : 480px) {
            display: none;
          }
          

          &:hover {
            z-index: 0;

            &_btn_delete {
              z-index: 10;
            }
          }

          &_input {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
            background-color: transparent;
            color: $font-title;
            font-size: 14px;
            border: 1px solid lightgrey;
            border-radius: 6px;
            text-align: center;
            width: 85%;
            height: 100%;
            outline: none;
          }

          &_btn_delete {
            @include position_t_r(absolute, 0, 0, 8);
            //@include display(flex, row, center, center);
            border: none;
            background-color: transparent;
            width: 30px;
            height: 30px;
            cursor: pointer;

            &:hover {
              z-index: 10;
            }
          }
        }
      }

      &_lessonWrapper {
        position: relative;
        @include display(flex, row, space-between, center);
        list-style-type: none;
        margin-bottom: 16px;
        margin-left: 0px;
        margin-right: 5px;
        cursor: pointer;
        //border: 1px solid red;
        //background-color: rgba(220, 220, 100, 1);
        @media only screen and (min-width : 320px) and (max-width : 375px) {
          margin: 2% 0 2% 0;
        }
        @media only screen and (min-width : 375px) and (max-width : 480px) {
          margin: 2% 0 2% 0;
        }

        &:hover {
          //background-color: rgba(220, 220, 220, 1);
          border-radius: 8px;
          border: 1px black solid;
        }

        &_lesson {
          @include display(flex, row, flex-start, center);
          color: $font-color-rose;
          font-size: 12px;
          padding: 5px;
          margin-right: 20px;
          margin-left: 12px;
          

           svg {
            margin-right: 5px;
          }
          //
          //& img {
          //  margin-right: 7.5px;
          //}
        }

        &_btn_deleteLesson {
          position: relative;
          z-index: -10;
          @include display(flex, row, center, center);
          border: none;
          background-color: transparent;
          width: 30px;
          height: 30px;
          cursor: pointer;

          & svg {
            & path {
              fill: #c1c1c1;
            }
          }
        }

        &_btn_drag_and_drop {
          position: relative;
          flex: 0 0 auto;
          white-space: nowrap;
          z-index: -10;
          background-color: transparent;
          width: 30px;
          height: 30px;

          & svg {
            & path {
              fill: #c1c1c1;
            }
          }
        }
      }
    }

    &_modul {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &_classes {
      width: 236px;

      &_settings {
        position: relative;
        @include display(flex, row, right, center);
        margin: 25px 0;
        font-size: 14px;
        color: $font-title;
        text-align: center;
        cursor: pointer;

        &_drag {
          @include position_t_l(absolute, 0, -15px);

          svg {
            margin-right: 8px;
          }
        }

        &_panel {
          & svg {
            margin-left: 10px;
          }
        }
      }
    }
  }

  &_rightSideWrapper {
    width: 95%;
    right: 10px;
    min-width: 0;
    
    @media only screen and (min-width : 320px) and (max-width : 375px) {
      max-width: 100vw;
    }
    @media only screen and (min-width : 375px) and (max-width : 480px) {
      max-width: 100vw;
    }

    &_rightSide {
      background: #ffffff;
      padding-top: 30px;
      height: fit-content;
      margin-bottom: 30px;
      border-radius: 10px;
      width: 100%;
      padding-bottom: 30px;

      &:hover {
        border: solid 3px #BA75FF ;
      }
      
      @media only screen and (min-width : 320px) and (max-width : 375px) {
        margin: 0 auto;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
      }
      @media only screen and (min-width : 375px) and (max-width : 480px) {
        margin: 0 auto;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
      }

      &_nameBlock {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;

        @media only screen and (min-width : 320px) and (max-width : 375px) {
          flex-direction: column;
          padding-right: 0;
        }
        @media only screen and (min-width : 375px) and (max-width : 480px) {
          flex-direction: column;
          padding-right: 0;
        }
      }

      &_review {
        display: flex;
        align-items: center;
      }

      &_header {
        @include display(flex, row, space-between, center);
        text-align: center;

        &_btnBlock {
          @include display(flex);
          width: 100%;

          &_setting {
            &:hover {
              background-color: rgba(190, 190, 190, 0.4);
            }

            @include display(flex, row, flex-start, center);
            width: 150px;
            height: 36px;
            border: none;
            text-align: center;
            background: #f3f4f6;
            font-size: 13px;
            color: #6b728f;
            border-radius: 6px;
            padding: 11px 0 11px 16px;

            & svg {
              margin-right: 5px;
            }

            input {
              @include position_t_l(absolute, 0, 0, -20);
            }
          }

          &_edit {
            &:hover {
              background-color: rgba(190, 190, 190, 0.4);
              border: solid 2px #BA75FF;
            }

            @include display(flex, row, flex-start, center);
            width: 210px;
            height: 36px;
            border: none;
            text-align: center;
            background: #f3f4f6;
            font-size: 12px;
            color: #6b728f;
            border-radius: 6px;
            padding: 11px 0 11px 12px;

            & svg {
              margin-right: 5px;
            }

            input {
              @include position_t_l(absolute, 0, 0, -20);
            }
          }

          &_save {
            &:hover {
              background-color: rgba(190, 190, 190, 0.4);
              border: solid 2px #BA75FF;
            }

            @include display(flex, row, flex-start, center);
            margin-left: 15px;
            width: 200px;
            height: 36px;
            border: none;
            text-align: center;
            background: #f3f4f6;
            font-size: 12px;
            color: #6b728f;
            border-radius: 6px;
            padding: 11px 0 11px 16px;

            & svg {
              margin-right: 5px;
            }

            input {
              @include position_t_l(absolute, 0, 0, -20);
            }
          }

          &_delete {
            &:hover {
              background-color: rgba(190, 190, 190, 0.4);
              border: solid 2px #BA75FF;
            }

            @include display(flex, row, flex-start, center);
            margin-left: 15px;
            // width: 200px;
            height: 36px;
            border: none;
            text-align: center;
            background: #f3f4f6;
            font-size: 13px;
            color: #6b728f;
            border-radius: 6px;
            padding: 5px 5px 5px 5px;

            // & svg {
            //   margin-right: 5px;
            // }

            input {
              @include position_t_l(absolute, 0, 0, -20);
            }
          }
        }
      }

      &_title {
        margin-top: 10px;
        margin-left: 45px;
        color: #BA75FF;
        font-size: 16px;
        @media only screen and (min-width : 320px) and (max-width : 375px) {
          margin-left: 0;
        }
        @media only screen and (min-width : 375px) and (max-width : 480px) {
          margin-left: 0;
        }
      }

      &_blockAll {
        display: flex;
        align-items: center;
        padding-right: 45px;

        @media only screen and (min-width : 320px) and (max-width : 375px) {
          flex-direction: column;
          padding: 0 0 5% 0;
        }
        @media only screen and (min-width : 375px) and (max-width : 480px) {
          flex-direction: column;
          padding: 0 0 5% 0;
        }

      }
      
      &_block {
        display: flex;
        background-color: #BA75FF;
        width: auto;
        padding-top: 1%;
        padding-bottom: 1%;
        padding-left: 45px;
        flex-wrap: nowrap;

        @media only screen and (min-width : 320px) and (max-width : 375px) {
          margin-bottom: 10%;
          padding-left: 15px;
          padding-right: 15px;
        }
        @media only screen and (min-width : 375px) and (max-width : 480px) {
          margin-bottom: 10%;
          padding-left: 15px;
          padding-right: 15px;
        }

        &_nameSettings {
          color: #FFFFFF;
          font-size: 20px;
          font-weight: 700;
          margin-right: 20px;

          @media only screen and (min-width : 320px) and (max-width : 375px) {
            margin-right: 0;;
          }
          @media only screen and (min-width : 375px) and (max-width : 480px) {
            margin-right: 0;
          }
        }

     }

      &_functional {
        margin-top: 40px;

        &_container {
          display: flex;
          flex-direction: column;
          gap: 40px;
          padding-inline: 3em;

          @media only screen and (min-width : 320px) and (max-width : 375px) {
            padding-inline: 0;
          }
          @media only screen and (min-width : 375px) and (max-width : 480px) {
            padding-inline: 0;
          }
        }

        &_content {
          @include display(flex, row, space-between);
          margin-bottom: 50px;
          margin-top: 50px;
          padding-right: 45px;

          @media only screen and (min-width : 320px) and (max-width : 375px) {
            padding: 0 0 10% 0;
            flex-direction: column;
            gap: 15px
          }
          @media only screen and (min-width : 375px) and (max-width : 480px) {
            padding: 0 0 10% 0;
            flex-direction: column;
            gap: 15px;
          }

          &_preview {
            color: $font-color-rose;
            font-size: 16px;
            margin-right: 11px;
          }
        }

        &_form {
          &_title {
            @include font(500, 16px, 19px);
            margin-bottom: 0;
            color: #4d5766;
            margin-left: 45px;
          }
        }
      }

      &_desc {
        display: block;
        margin: 8px 0 15px;
        font-size: 12px;
        color: #9f9f9f;
        margin-left: 45px;
        padding-bottom: 10px;
      }
      &_error {
        display: block;
        font-size: 12px;
        color: rgba(224, 24, 24, 0.67);
        margin-left: 45px;
        padding-bottom: 10px;
      }
    }
  }
}

.newLesson {
  background-color: #dcdcdc; // цвет фона для подсвеченного урока
  border-radius: 8px; // скругление углов
}

.hl {
  width: 256px;
  border-bottom: 1px solid #d9d9d9;
  margin: 24px 0 18px -10px;
}

.btn {
  margin-bottom: 43px;
  width: 236px;
  background: #e0dced;
  font-size: 12px;
  padding: 11px 0;
  border: none;
  border-radius: 7px;
  color: $font-color-rose;

  &:hover {
    background: $font-color-rose;
    color: #e0dced;
  }
}

.btnModule {
  background: #e0dced;
  color: $font-color-rose;

  &:hover {
    color: #e0dced;
  }
}

hr {
  margin-bottom: 18px;
}

.coursePreviewHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &_text_block {
    margin: 20px 0 0 30px;

    &_published {
      @include display(flex, row, flex-end);
      justify-content: space-around;
      font-style: italic;
      font-weight: bold;
      color: #acce6e;

      svg {
        margin-right: 2px;
      }
    }

    &_notPublished {
      @include display(flex, row, flex-end);
      justify-content: space-around;
      font-style: italic;
      font-weight: bold;
      color: #a8444c;

      svg {
        margin-right: 2px;
      }
    }
  }
}

.coursePreviewHeaderRedactor {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media only screen and (min-width : 320px) and (max-width : 375px) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 5px;
  }
  @media only screen and (min-width : 375px) and (max-width : 480px) {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: 5px;
  }

  &_text_block {
    margin: 20px 0 0 30px;
    @include display(flex, flex-end);
    color: #e0dced;
    font-style: normal;
    @include font(500, 11px, 13px);

    svg {
      margin-right: 10px;
    }
  }
}

.selectedLesson {
  background-color: #dcdcdc;
  border-radius: 8px;
}

.check_autotest {
  display: flex;
  margin-left: 40px;
  & span {
    margin-top: 2px;
    color: $font-color-rose;
    @include font(400, 14px, 100%);
    letter-spacing: 0.04em;
    margin-left: 10px;
  }
}

.autogeneration {
  min-width: 600px;
  width: 70%;
  border-radius: 5px;
  //margin-top: 1rem;
  margin: 30px 150px 30px 150px;
  padding-bottom: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);

  &_num {
    margin-left: 20px;
    padding-top: 20px;

    & input {
      line-height: normal;
      padding: 11px 0 12px 20px;
      border: 1px solid #e5e7eb;
      border-radius: 5px;
      text-overflow: ellipsis;
      width: 70px;
      margin-left: 20px;
    }
    & label {
     color: #9ca3af;
    }
  }

  &_select {
    margin-left: 20px;
    padding-top: 20px;
    color: #9ca3af;
  }

  &_error {
    margin-left: 20px;
    padding-top: 20px;
    color: #f50723;
    font-size: 13px;
  }

  &_test {
    padding: 1rem;
    display: flex;
    align-items: center;
    border-radius: 7px;
    background: #f5f5f5;

    &_name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
      margin-left: 0.75rem;
      text-align: left;
      @include font(500, 12px, 150%);
      color: #9ca3af;
      letter-spacing: -0.05em;
    }
  }

  &_tests_block {
    display: flex;
    flex-direction: column;
    margin: 20px 20px 20px 20px;
    //margin-top: 0.75rem;
    //margin-left: 20px;
    //margin-right: 20px;
    gap: 0.625rem;
  }

  &_item {
    margin-top: 1.25rem;

    &:first-child {
      margin-top: 0;
    }

    &_name {
      @include font(600, 12px, 150%);
      color: $font-title;
      text-align: left;
    }
  }
}

.scrollable {
  max-height: 500px;
  overflow-y: auto;
  width: 240px;
}
