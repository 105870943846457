@import '../../../scss/variable';
@import '../../../scss/mixin.scss';

.statistics {
  width: 100%;

  &_header {
    position: relative;
    @include display(flex, row, flex-start, center);
    width: 100%;
    height: 60px;
    background-color: #fff;
    border-radius: 7px;
    margin-top: 37px;
    box-shadow: $box-shadow;

    &_icon {
      align-self: flex-start;
      margin: 14px 7px 0 12px;
    }

    &_info_wrapper {
      @include display(flex, column, flex-start, flex-start);
      text-align: left;
      padding: 1em;

      span:first-of-type,
      span:last-of-type {
        @include font(500, 12px, 15px);
        letter-spacing: 0.01em;
        text-align: left;
      }

      span:first-of-type {
        color: $font-title;
      }

      span:last-of-type {
        color: #b9bac4;
      }
    }

    &_btn_collapse {
      @include position_t_r(absolute, calc(50% - 19px), 15px);
      @include display(flex, row, center, center);
      height: 38px;
      width: 87px;
      font-style: normal;
      @include font(500, 12px, 38px);
      letter-spacing: 0.01em;
      color: #6b7280;
      background: #f3f4f6;
      border-radius: 7px;
      text-align: center;
      cursor: pointer;
      border: none;
    }
  }

  &_new_student_wrapper {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr;
    grid-gap: 16px;

    &_new_students {
      position: relative;
      @include display(flex, column, flex-start, center);
      margin-top: 18px;
      border-radius: 7px;
      background-color: #fff;
      box-shadow: $box-shadow;

      &_title {
        font-style: normal;
        @include font(500, 12px, 15px);
        margin-top: 12px;
        color: #b9bac4;
      }

      &_amount {
        font-style: normal;
        @include font(500, 30px, 36px);
        letter-spacing: 0.05em;
        margin-top: 22px;
        color: #4d5766;
      }

      &_graph {
        margin-top: 21px;
      }

      &_info_wrapper {
        @include display(flex, row, space-around, center);
        width: 100%;
        margin-top: 23px;

        &_info {
          font-style: normal;
          @include font(400, 12px, 15px);
          letter-spacing: 0.02em;
          color: #646f74;

          &_amount_time {
            font-style: normal;
            @include font(500, 16px, 19px);
            color: #24b59e;
            margin-right: 10px;
          }
        }
      }

      &_info_btn {
        @include position_t_r(absolute, 11px, 11px);
        font-style: normal;
        @include font(500, 10px, 12px);
        text-align: center;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid #eaecef;
        color: #eaecef;
        cursor: pointer;
      }
    }
  }
}

.students {
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    margin-left: 0px;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    margin-top: 50px;
  }
}

.students_group {
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 2rem 1rem 2rem 1rem;
  }
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 2rem 1rem 2rem 1rem;
  }
  @include display(flex, column);
  width: 100%;

  &_header {
    @include display(flex, row, space-between);
    width: 100%;
    margin-top: 40px;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      align-items: center;
      flex-direction: column;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      align-items: center;
      flex-direction: column;
    }

    &_title {
      font-style: normal;
      @include font(500, 16px, 19px);
      color: #4d5766;
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        padding-bottom: 2%;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        padding-bottom: 2%;
      }
    }

    &_add_group_btn {
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        line-height: normal;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        line-height: normal;
      }
      &:hover {
        background-color: rgba(217, 197, 237, 0.5);
      }

      @include display(flex, row, flex-start, center);
      margin: 1em;
      height: 36px;
      padding: 10px;
      font-style: normal;
      @include font(500, 12px, 32px);
      letter-spacing: 0.02em;
      color: #ba75ff;
      background: #e5dfff;
      border-radius: 7px;
      cursor: pointer;

      & svg {
        margin-right: 8px;
      }
    }
    &_add_teacher_btn {
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        line-height: normal;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        line-height: normal;
      }
      &:hover {
        background-color: rgba(217, 197, 237, 0.5);
      }

      @include display(flex, row, flex-start, center);
      margin: 1em;
      height: 36px;
      padding: 10px;
      font-style: normal;
      @include font(500, 12px, 32px);
      letter-spacing: 0.02em;
      color: #ba75ff;
      background: #e5dfff;
      border-radius: 7px;
      cursor: pointer;
    }

    &_export_button {
      color: #FFFFFF;
      background-color: #357EEB;
      &:hover:not(:disabled){
        color: #fff;
        background: #357EEB
      }
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        line-height: normal;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        line-height: normal;
      }

      @include display(flex, row, flex-start, center);
      margin-top: 0.5%;
      padding: 5px 10px 5px 10px;
      height: 36px;
      padding: 10px;
      gap: 10px;
      font-style: normal;
      @include font(500, 12px, 32px);
      letter-spacing: 0.02em;
      border-radius: 7px;
      cursor: pointer;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        margin-left: 1rem;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        margin-left: 1rem;
      }

      & svg {
        margin-right: 8px;
      }
    }
  }

  &_content_wrapper {
    @include display(flex, column);
    min-width: 300px;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      padding: 3%;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      padding: 3%;
    }

    &_info {
      position: relative;
      @include display(flex, row, flex-start, center);
      width: 290px;
      max-width: 310px;
      @media only screen and (min-width: 320px) and (max-width: 375px) {
        width: 60%;
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        width: 60%;
      }
      margin-bottom: 10px;
      box-sizing: border-box;
      margin-top: 16px;
      padding: 17px;
      background-color: #fdfaff;
      border-radius: 7px;
      box-shadow: $box-shadow;
      transition: background-color 0.2s, transform 0.2s;
      min-height: 90px;
      align-items: stretch;

      &:hover {
        cursor: pointer;
        background-color: darken(#fdfaff, 3%);
        transform: scale(1.05);
      }

      &_students_icon {
        align-self: flex-start;
        margin-top: 4px;
        margin-right: 10px;
        transition: background-color 0.2s;

        &:hover {
          background-color: rgb(187, 147, 166);
        }
      }

      &_info_wrapper {
        display: block;
        &_name {
          font-style: normal;
          @include font(500, 14px, 17px);
          letter-spacing: -0.03em;
          color: #4d5766;

          @media only screen and (min-width: 320px) and (max-width: 375px) {
            display: flex;
            width: 90%;
          }
          @media only screen and (min-width: 375px) and (max-width: 480px) {
            display: flex;
            width: 90%;
          }
        }

        &_amount_wrapper span {
          margin-top: 8px;
          font-style: normal;
          @include font(500, 10px, 12px);
          color: #a5a9b4;

          & svg {
            margin-right: 4px;
            margin-bottom: 2px;
          }
        }

        &_auto {
          font-style: normal;
          @include font(400, 12px, 17px);
          letter-spacing: 0.02em;
          color: #929dad;
        }
      }

      &_setings_btn {
        @include position_t_r(absolute, 15px, 15px);
        @include display(flex, row, center, center);
        width: 42px;
        height: 42px;
        background: #f7f6fb;
        border-radius: 10px;
        cursor: pointer;
      }
    }

    &_show_all_groups_btn {
      @include display(flex, row, flex-start, center);
      align-self: center;
      margin-top: 34px;
      cursor: pointer;

      & span {
        font-style: normal;
        @include font(500, 12px, 15px);
        margin-left: 8px;
        color: #9ca3af;
      }
    }
  }
}

.all_students {
  margin-top: 50px;

  &_title {
    font-style: normal;
    @include font(500, 16px, 19px);
    letter-spacing: -0.01em;
    color: #4d5766;
  }

  &_control_panel {
    display: grid;
    grid-template-columns: 4fr 17fr 1fr 4fr;
    grid-column-gap: 10px;
    width: 100%;
    margin-top: 22px;

    &_filter_btn {
      @include display(flex, row, flex-start, center);
      height: 100%;
      font-style: normal;
      @include font(500, 12px, 15px);
      letter-spacing: 0.02em;
      background: #ededed;
      border-radius: 7px;
      padding-left: 14px;
      color: #6b7484;
      cursor: pointer;

      & svg {
        margin-right: 9px;
      }
    }

    &_search_panel {
      height: 100%;
      font-style: normal;
      @include font(400, 12px, 15px);
      letter-spacing: 0.02em;
      text-align: center;
      color: #d1d5db;
      border: 1px solid #e5e7eb;
      border-radius: 7px;
      background-image: url('../../../assets/img/studentPage/search-icon.svg');
      background-repeat: no-repeat;
      background-position: calc(50% - 80px) center;
      outline: none;
    }

    &_search_btn {
      @include display(flex, row, center, center);
      height: 100%;
      background: #e0dced;
      border-radius: 7px;
      padding: 0 16px;
      cursor: pointer;
    }

    &_add_students_btn {
      @include display(flex, row, flex-start, center);
      font-style: normal;
      @include font(500, 12px, 35px);
      letter-spacing: 0.02em;
      color: #ffffff;
      background: #ba75ff;
      border-radius: 7px;
      padding-left: 12px;
      cursor: pointer;

      & svg {
        margin-right: 6px;
      }
    }
  }
}

.student_info_table {
  width: 100%;
  margin-top: 23px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 7px;

  &_header {
    @include display(flex, row, flex-start, center);
    height: 55px;
    background: #f3f4f6;

    &_checkbox {
      width: 18px;
      height: 18px;
      border: 1px solid #dcdfe6;
      margin: 0 59px 0 37px;
    }

    &_name_wrapper {
      display: flex;
      margin-right: 101px;
      font-style: normal;
      @include font(500, 12px, 15px);
      letter-spacing: 0.05em;
      color: #6b7484;

      & svg {
        margin: 0 0 0 6px;
      }
    }

    &_email_wrapper {
      display: contents;
      font-style: normal;
      @include font(500, 12px, 35px);
      letter-spacing: 0.05em;
      color: #adabb3;

      &_btn_wrapper {
        @include display(flex, column);
        margin: 0 174px 0 2px;

        & svg {
          cursor: pointer;
        }
      }
    }

    &_score {
      margin-right: 76px;
      font-style: normal;
      @include font(500, 12px, 15px);
      letter-spacing: 0.05em;
      color: #adabb3;
    }

    &_progress {
      margin-right: 69px;
      font-style: normal;
      @include font(500, 12px, 15px);
      letter-spacing: 0.05em;
      color: #adabb3;
    }

    &_activity {
      margin-right: 70px;
      font-style: normal;
      @include font(500, 12px, 15px);
      letter-spacing: 0.05em;
      color: #adabb3;
    }

    &_comment {
      margin-right: 64px;
      font-style: normal;
      @include font(500, 12px, 15px);
      letter-spacing: 0.05em;
      color: #adabb3;
    }

    &_settings_btn {
      cursor: pointer;
    }
  }

  &_contents_wrapper {
    &_content {
      @include display(flex, row, flex-start, center);
      height: 51px;
      background-color: #fff;

      & input {
        width: 18px;
        height: 18px;
        border: 1px solid #dcdfe6;
        margin: 0 59px 0 37px;
      }

      &_student {
        @include display(flex, row, flex-start, center);
        margin-right: 82px;

        &_avatar {
          margin-right: 19px;
          text-align: center;
          width: 32px;
          height: 33px;
          border-radius: 50%;
          background-color: #d3f2f8;
          font-style: normal;
          @include font(500, 12px, 33px);
          color: #72b0bc;
        }

        &_name {
          font-style: normal;
          @include font(500, 12px, 15px);
          letter-spacing: 0.03em;
          color: #6b7484;
        }
      }

      &_student_email {
        margin-right: 119px;
        font-style: normal;
        @include font(500, 12px, 15px);
        letter-spacing: 0.03em;
        color: #6b7484;
      }

      &_student_score {
        @include display(flex, row, flex-start, center);
        margin-right: 122px;
        font-style: normal;
        @include font(500, 12px, 15px);
        letter-spacing: 0.03em;
        color: #6b7484;

        & svg {
          margin-right: 7px;
        }
      }

      &_student_progress {
        @include display(flex, row, flex-start, center);
        margin-right: 98px;
        font-style: normal;
        @include font(500, 12px, 15px);
        letter-spacing: 0.03em;
        color: #6b7484;

        & svg {
          margin-right: 11px;
        }
      }

      &_student_activity {
        @include display(flex, row, flex-start, center);
        font-style: normal;
        @include font(500, 12px, 15px);
        letter-spacing: 0.03em;
        margin-right: 100px;
        color: #6b7484;
      }
    }
  }
}

// Плашки с информацией о группах
.container {
  width: 100%;
  margin-top: 140px;
  box-sizing: border-box;
  h4 {
    margin-bottom: 40px;
  }
  &_groups {
    display: flex;
    align-items: baseline;

    &_dropdown {
      max-width: fit-content;
      margin: 0 auto;
      button {
        margin-top: 0;
      }
    }
  }
}

$max-columns: 3;
$gap: 10px;

.groups_card_block {
  padding: 1rem 0;
  width: 100%;
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
}