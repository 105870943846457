@import '../../../scss/mixin.scss';
@import 'src/scss/variable';
.wrapper {
  @include display(flex, row, center);
  padding: 2px 0 24px 0;
  margin-bottom: -13px;
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    height: auto;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    width: 100%;
  }
}
.container {
  width: 100%;
  position: relative;
  color: #646f74;
  @include font(500, 11px);
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    width: 99%;
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    display: flex;
  }

  &_btn {
    &:hover {
      border: 1px solid #357eeb;
    }
    position: relative;
    border: 1px solid #332f36;
    background: #ffffff;
    border-radius: 16px;
    width: 100%;
    padding: 8px 0 8px 9px;
    text-align: left;
    color: #646f74;
    min-width: 100px;
    font-size: 16px;
    @media only screen and (min-width: 320px) and (max-width: 375px) {
      width: 100%;
      padding: 11px 0 11px 1%;
    }
    @media only screen and (min-width: 375px) and (max-width: 480px) {
      width: 100%;
      padding: 11px 0 11px 1%;
    }
  }

  & li {
    cursor: pointer;
    padding: 11px;
  }

  & li:hover {
    background: #f6f5f5;
  }
}
button:hover {
  cursor: pointer;
}
.options {
  @include position_t_l(absolute, 100%, 0, 30);
  border: 1px solid #c9ccd0;
  border-radius: 7px;
  width: 100%;
  box-shadow: 0px 0px 8px 0px #3241954D;
  background: #ffffff;
  // display: none;
  list-style: none;
  margin-top: 10px;
  overflow: hidden;
  overflow-y: scroll;
  font-size: 16px;
  max-height: 300px;
  opacity: 0;
  transition-duration: $trans-dur02;
}
.show {
  // display: block;
  // overflow: auto;
  opacity: 1;
}

.hidden {
  display: none;
}

.svgIcon {
  @include position_t_r(absolute, 50%, 20px, 2);
  transform: translateY(-50%);

  &_open {
    @include position_t_r(absolute, 50%, 20px, 2);
    transform: translateY(-50%) rotate(180deg);
  }
}
