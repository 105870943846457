@import '../../scss/variable';
@import '../../scss/mixin.scss';

.navbar {
  position: fixed;
  z-index: 10;
  @include display(flex, column, flex-end, center);
  top: 2rem;
  left: calc(((100vw - min(70.83vw, 1360px) - 80px) / 2) - 80px);
  white-space: nowrap;
  overflow: hidden;
  max-height: min(889px, 90vh);
  height: 100%;
  max-width: 100px;
  padding: 20px 0;
  border-radius: 24px;
  background: linear-gradient(180deg, rgba(207, 226, 255, 0.7) 0%, rgba(177, 196, 225, 0.7) 100%);
  box-shadow: 0px 0px 25px 0px #357eeb;
  backdrop-filter: blur(35px);
  transition: $trans-dur05 ease-in-out;

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    @include position_b_l(fixed, 0, 0, 10);
    @include display(flex, row, center, center);
    width: 100%;
    height: 70px;
    background: $color-white;
    box-shadow: 0 0 5px #d9d9d9;
    padding: 35px 0;
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    @include position_b_l(fixed, 0, 0, 10);
    @include display(flex, row, center, center);
    width: 100%;
    height: 70px;
    background: $color-white;
    box-shadow: 0 0 5px #d9d9d9;
    padding: 35px 0;
  }

  // @media only screen and (min-width: 481px) and (max-width: 1024px) {
  //   @include position_b_l(fixed, 0, 0, 10);
  //   @include display(flex, row, center, center);
  //   width: 100%;
  //   height: 70px;
  //   background: $color-white;
  //   box-shadow: 0 0 5px #d9d9d9;
  //   padding: 35px 0;
  // }

  &_show_btn {
    @include display(flex, column, space-between, center);
    position: fixed;
    top: 20px;
    width: 60px;
    height: 60px;
    z-index: 10;

    &_round {
      @include display(flex, column, space-between, center);
      background-color: $color-white;
      width: 60px;
      height: 60px;
      border-radius: 30px;
      box-shadow: 0px 0px 4px 0px #357eeb inset;
      padding: 20px;
      transition-duration: $trans-dur05;

      &_line {
        border: 1px solid #324195;
        width: 30px;
        height: 0;
      }
    }

    &:hover {
      cursor: pointer;
    }

    & .isBtnToggled {
      padding: 27px;
    }
  }

  &_menu {
    @include display(flex, column, center, center);
    margin-top: auto;
    transition-duration: $trans-dur05;
    width: 100%;
    padding: 5px;
    border-radius: 10px;
    margin-bottom: 0.8vh;

    & div {
      @include display(flex, column, center, center);
      width: 100%;
      text-decoration: none;
      height: $icon-size;
      margin-bottom: 3px;

      svg {
        height: 100%;
        width: auto;
      }
    }

    & p {
      color: #332f36;
    }

    // cursor: pointer;
    // margin-top: -0.2em;
    // border-radius: 10px;
    // color: #d2cfdc;
    // width: max-content;
    // height: max-content;

    // &:hover {
    //   border-radius: 10px;
    //   background: #e0dced;
    //   color: #ba75ff;
    // }

    & span {
      text-decoration: none;
      cursor: pointer;
      font-size: $fs14;
    }
  }

  &_menu:hover {
    background-color: $color-blue;

    & p {
      color: $color-white;
    }

    & div svg path {
      fill: $color-white;
    }
  }

  &_settings {
    cursor: pointer;
  }

  &_setting_account {
    @include display(flex, column, space-between, center);
    width: 100%;
    // cursor: pointer;
    gap: 0.8vh;
    transition-duration: $trans-dur02;

    @media only screen and (min-width: 320px) and (max-width: 375px) {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 20px;
      align-items: center;
    }

    @media only screen and (min-width: 375px) and (max-width: 480px) {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }

    // @media only screen and (min-width: 481px) and (max-width: 1024px) {
    //   height: 100%;
    //   width: 100%;
    //   display: flex;
    //   flex-direction: row;
    //   justify-content: space-evenly;
    //   margin: 20px;
    //   align-items: center;
    // }

    &_icon_container,
    &_setting_account {
      @include display(flex, column, center, center);
      width: 100%;
      height: auto;
      border-radius: 10px;
      padding: 5% 10%;
      transition-duration: $trans-dur02;

      div {
        height: $icon-size;
        margin-bottom: 3px;
        svg {
          height: 100%;
          width: auto;
        }
      }

      &:hover {
        border-radius: 10px;
        color: $color-white;
        background-color: #357eeb;
        cursor: pointer;
        fill: $color-white;

        svg path {
          fill: $color-white;
          stroke: $color-white;
        }

        // Изменяем цвет текста только для текущего элемента
        p {
          color: $color-white; // Применяем цвет только к p внутри текущего элемента
        }
      }
    }

    & a {
      text-align: center;
      color: #332f36;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        margin: 0;
      }

      @media only screen and (min-width: 375px) and (max-width: 480px) {
        margin: 0;
      }
    }

    & p {
      width: 100%;
      font-size: $fs14;
      text-align: center;
      word-wrap: break-word;
      white-space: normal;
      color: #332f36;
      transition-duration: $trans-dur02;
    }
  }

  & .tg_container {
    @include display(flex, column, center, center);
    width: 100%;
    height: auto;
    border-radius: 10px;
    padding: 5px 0;
    transition-duration: $trans_dur02;

    span {
      height: $icon-size;
      margin-bottom: 3px;
      svg {
        height: 100%;
        width: auto;
      }
    }

    &:hover {
      background-color: $color-blue;

      span svg path {
        fill: $color-white;
        stroke: $color-white;
      }

      & p {
        color: $color-white;
      }
    }
  }

  &.isNavBarShow {
    max-height: 100px;
    height: 100px;

    .navbar_menu,
    .chatIcon_container,
    .navbar_setting_account {
      opacity: 0;
    }
  }
}

.chatIcon {
  color: #332f36;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 10px;
  margin-bottom: 3px;
  max-height: 4.5vh;

  span:nth-child(1) {
    height: 100%;
  }

  svg {
    height: 100%;
    width: auto;
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    margin: 0;
  }

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    margin: 0;
  }

  // &:hover {
  //   background: #357EEB;
  //   color: $color-white;
  // }

  &_container {
    @include display(flex, column, center, center);
    transition-duration: $trans-dur02;
    width: 100%;
    padding: 5px;
    border-radius: 10px;

    &:hover {
      border-radius: 10px;
      color: $color-white;
      background-color: #357eeb;
      cursor: pointer;
      fill: $color-white;

      svg path {
        fill: $color-white;
        stroke: $color-white;
      }

      p {
        color: $color-white;
      }
    }
  }
}

.chatIcon_active {
  background: $color-blue;
  color: $color-white;
}

.isActive {
  background: $color-blue;
  border-radius: 10px;
  text-align: center;
  color: $color-white !important;

  & a {
    & svg path {
      fill: $color-white;
    }
  }
}

.mobileIsActive {
  & svg {
    & path {
      stroke: $font-color-rose;
    }
  }
}
