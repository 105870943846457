@import '../../../../scss/mixin.scss';
.input_container {
  overflow: hidden;
  width: 100%;
  height: 54px;
  border: 1px solid #332f36;
  border-radius: 10px;
  background-color: #ffff;
  font-family: 'Inter', sans-serif;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 1rem;
  transition: 0.3s;

  @media only screen and (min-width: 320px) and (max-width: 1000px) {
    overflow: hidden;
    width: 100%;
    height: 54px;
    border: 1px solid #332f36;
    border-radius: 10px;
    background-color: #ffff;
    font-family: 'Inter', sans-serif;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    transition: 0.3s;
  }

  &:hover {
    border: 1px solid rgb(53, 126, 235);
  }

  & input:focus {
    background-color: inherit;
  }

  &:has(input:focus) {
    background-color: inherit;
    border: 1px solid #357eeb;
  }

  & input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: #357eeb;
    transition: background-color 5000s ease-in-out 0s;
    -webkit-background-clip: text;
  }

  & input {
    width: 100%;
    height: 100%;
    outline: none;
    transition: 0.3s;
    border: none;
    color: #357eeb;
    @include font(500, 20px);

    &::placeholder {
      @include font(400, 20px, 17px);
      color: #656465;
    }

    &:focus {
      color: #357eeb;
      &::placeholder {
        color: #357eeb;
      }
    }
    &:hover {
      &::placeholder {
        color: #357eeb;
      }
    }

    @media only screen and (min-width: 320px) and (max-width: 1000px) {
      width: 100%;
      height: 100%;
      outline: none;
      transition: 0.3s;
      border: none;
      color: #357eeb;
      @include font(500, 20px);

      &::placeholder {
        @include font(400, 2vw, 17px);
        color: #656465;
      }
    }
  }

  &_input {
    width: 100%;
    // height: 100%;
    display: grid;
    position: relative;
    z-index: 2;
  }

  &_image {
    @include position_t_r(absolute, auto, 10px);
    align-self: center;
    width: 26px;
    height: 22px;
    cursor: pointer;
  }
}

.error {
  border: 1px solid red !important;
}
