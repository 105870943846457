@import 'src/scss/variable';
@import '../../scss/mixin.scss';

a {
  text-decoration: none;
  // @media only screen and (min-width: 375px) and (max-width: 480px) {
  //   padding: 2%;
  // }
  // @media only screen and (min-width: 320px) and (max-width: 375px) {
  //   padding: 2%;
  // }
}

.container {
  width: 100%;
  margin-top: 40px;
  box-sizing: border-box;

  @media only screen and (min-width: 375px) and (max-width: 480px) {
    padding: 1rem;
  }
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    padding: 1rem;
  }

  h4 {
    margin-bottom: 40px;
  }
  &_courses {
    display: flex;
    align-items: baseline;

    &_dropdown {
      max-width: fit-content;
      margin: 0 auto;
      button {
        margin-top: 0;
      }
    }
  }
}

.courses_card_block {
  width: 100%;
  padding: 2rem 0;
  display: grid;
  gap: 2rem 3rem;
  grid-template-columns: repeat(3, 1fr);
  align-self: stretch;
  flex-wrap: wrap;
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 3%;
  }
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 3%;
  }
}
.mini_card_img {
  width: 52px;
  height: 52px;
  border-radius: 7px;
  margin-right: 16px;
}
.mini_card_container {
  @include display(flex, row, flex-start, center);
  width: 272px;
  margin: 16px 0;
  border-radius: 7px;
  background-color: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  padding: 16px;
  transition: background-color 0.4s, transform 0.4s;

  &:hover {
    background-color: rgb(255, 246, 250);
    transform: scale(1.1);
  }
}

.mini_card_name {
  color: #4d5766;
  font-size: 14px;
  margin-bottom: 10px;
}
.mini_card_list {
  @include display(flex);
  color: $font-color-rose;
  font-size: 10px;
  li {
    margin-left: 19px;
    span {
      color: #a5a9b4;
    }
  }
}
.wrapper {
  position: relative;
  display: flex;
  width: 23.75rem;
  height: 8.3125rem;
  padding: 1rem;
  border-radius: 1rem;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 2px 25px 0px rgba(53, 126, 235, 0.5);
  transition: all ease-in 0.15s;
  @media only screen and (min-width: 1477px) and (max-width: 1790px) {
    width: 19.79rem;
  }
  @media only screen and (min-width: 14px) and (max-width: 1476px) {
    width: 16.79rem;
  }


  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    background-color: rgba(116, 116, 116, 0.8);
    z-index: 1;
  }

  &:hover::before {
    background-color: rgba(116, 116, 116, 0);
  }

  &:hover {
    box-shadow: 0px 2px 25px 0px #0d28bbcc;

    .wrapper_shadow {
      background: linear-gradient(90deg, rgba(51, 47, 54, 0.3) -5.88%, #3e80e2 100%);
    }
  }

  &_shadow {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    transition: all 0.5s;
    z-index: 10;
    border-radius: 1rem;
  }

  &_settings {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    z-index: 20;
    cursor: pointer;
  }

  &_text {
    display: flex;
    z-index: 20;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.625rem;
    transition: all ease-in 0.2s;
    cursor: pointer;

    &_title {
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      font-family: 'SFPRORegular';
      color: var(--White, #fff);
      transition: all ease-in 0.2s;
    }

    &_description {
      color: var(--White, #fff);
      text-align: center;
      font-family: 'SFPRORegular';
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      display: flex;
      align-items: center;
      gap: 0.62rem;
    }
  }
}