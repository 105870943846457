@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Allison&display=swap');



.main {
    display: flex;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    // color: rgb(255, 251, 251);
    //max-width: 3400px;
    // max-height: 2400px;
    width: 842px;
    height: 595px;
    margin: 0 auto;
    // overflow: auto;
    padding: 10px 10px 10px 10px;
    border: 1px solid black;
    // background: linear-gradient(224deg, #EC40FF -5.54%, #9B219E 11.79%, #4D0FAF 75.62%);
    background: linear-gradient(to bottom left, #F57AF5 -250%, white);
    border: 2px solid black;
    margin-bottom: 10px;
    margin-top: 30px;
}


.tape {
    position: relative;
	left: 655px;
	top: -10px;
    width: 80px;
    height: 520px; 
    border-width: 50px 30px 20px;
    border-style: solid;
    border-color: #F57AF5 #F57AF5 transparent;
    }

.complete {
    width: 200px;
    height: 200px;
    position: relative;
    left: 370px;
    top: -350px;
}


.mainSkills {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    color: black;
    //max-width: 3400px;
    // max-height: 2400px;
    min-height: 595px;
    width: 842px;
    height: auto;
    margin: 0 auto;
    // overflow: auto;
    padding: 60px 10px 10px 10px;
    border: 1px solid black;
    background-color: white;
    background: linear-gradient(to bottom left, #9e99fa -80%, white ,  #eddfed 170%);
    border: 2px solid black;
    color: #ffff;
    position: relative;
    

    &__title {
        text-align: center;
        font-weight: 600;
        font-size: 30px;
        padding-bottom: 1rem;
        color: #323232;
    }

    &__content {

        &_skills {
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            margin-bottom: 2rem;
            color: #323232;
        }

        &_modules {
            margin-bottom: 100px;
        }
            
    }
   
}

.wrapper {
    display: grid;
    grid-template-columns: 20% 80%;
   

    @media screen and (min-width: 320px) and (max-width: 1024px) {
        flex-flow: column nowrap;
    }

}

.logo {
    display: flex;
    position: relative;
    // max-width: 1041px;
    // max-height: 1648px;
    // width: 60%;
    height: 90px;
    //margin-right: 10px;    
    top: 200px;
    left: -200px;
    rotate: -90deg;
    font-size: 30px;
    font-weight: 600;
    line-height: 42px;
    color: #F57AF5;
    text-transform: uppercase;
    width: 450px;

    &__over {
        position: relative;
        top: 0px;
        left: 100px;
        rotate: 90deg;
        width: 50px;
        height: 33.69px;
    }
   

    @media screen and (max-width: 1024px) {
        margin-right: 0;
        margin-bottom: 15rem;
    }

        &__img {
            width: 90px;
            line-height: 0;
        }

        &__imgSkills {
            position:static;
            rotate: 90deg;
            width: 90px;
            line-height: 0;
            right: 350px;
        }
}

.border {
    height: 100%;
    width: 110%;
    position: absolute;
    top: -10%;
    left: -5%;
    

    &__text {
        margin-top: 10px;
        padding-bottom: 5px;
    }
}

.certificate {
    display: flex;
    flex-direction: column;
    position: relative;
    left: -100px;

		&__header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 30px;
            margin-top: 10px;
            font-weight: 700;
            font-size: 50px;
            text-align: center;
            height: auto;
            color: #F57AF5;
            text-transform: uppercase;
            letter-spacing: 8px;
            
            
                &_middle {
                    position: relative;
                    width: 144px;
                    height: 40px;
                    font-family: "Allison", cursive;
                    font-weight: 400;
                    font-size: 40px;
                    line-height: 39.76px;
                    color: #323232;
                    top: -25px;
                    letter-spacing: 10px;
                    padding-left: 25px;
                    text-transform: none;
                }

            &_completion {
                height: 28px;
                display: flex;
                flex-direction: row;
                color: #323232;
                font-size: 20px;
                font-weight: 600;
                text-transform: none;
                margin-top: 2rem;
                letter-spacing: normal;
                line-height: 28px;
            }

            &_down {
                margin-top: -50px;
            }
		}

        &_line {
            width: 50px;
            height: 10px;
            background-color: #F57AF5;
        }

		&__producer {
            position: relative;
            width: max-content;
            margin-bottom: 20px;
            // padding: 0.9375rem 0; 

            font-weight: 600;
            font-size: 2rem;
            text-align: center;
            text-transform: uppercase;

            // margin: 0 auto;
            padding-bottom: 20px;
		}

		&__graduate {
            display: flex;
            width: 70%;
            height: 56px;
            font-weight: 600;
            font-size: 40px;
            margin-bottom: 1.5rem;
            margin-top: 1.5rem;
		}

		&__content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            font-size: 16px;
            font-weight: 500;
            color: #323232;
            margin-bottom: 3rem;
            
            &_nameCourse{
                background: linear-gradient(to top,  #F57AF5 50%, transparent 50%);
            }

            &_skills {
                display: flex;
                font-weight: 400;
                font-size: 2rem;
                text-align: start;
                margin-bottom: 5%;
                padding-left: 3%;
            }

            &_modal {
                display: flex;
                flex-direction: column;
                font-weight: 400;
                font-size: 2rem;
                text-align: start;
                padding-left: 8%;
                margin-bottom: 15%;

                &_lessons{
                    display: flex;
                    font-weight: 400;
                    font-size: 1.5rem;
                    text-align: start;
                    padding-left: 12%;
                    margin-bottom: 15%;
                }
            }
		}
}

.bold {
    font-weight: 600;
    text-transform: uppercase;
    font-size: 30px;
    color: #323232;

}

.signs {
    display: flex;
    flex-direction: row;
    position: relative;
    left: 200px;
    width: 400px;

    &__date {
        position: relative;
        display: flex;
        flex-direction: column;
        left: -80px;

       

        &_text {
            border-bottom: 1px solid black;
            font-size: 20px;
            font-weight: 600;
            line-height: 28px;
           


            &_issue {
                font-size: 14px;
                font-weight: 600;
                color: #969696;
            }
        }
    }

    // &__content {
        
    // }
}

.sign {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    font-weight: 600;
    color: #969696;

    &__QR {
        position: relative;
        left: -150px;
        width: 60px;
        height: 60px;
    }

    &__signature {
        position: relative;
        width: 200px;
        height: 150px;
        top: -60px;
    }


    &__signatory {
        position: absolute;
        display: flex;
        flex-direction: row;
        border-top: 1px solid black;
        top: 27px;
        
    }

    &__stamp {
        width: 100px;
        height: 100px;
    }
}

.courseName {
    display: flex;
    background: no-repeat bottom url('./img/courseName.svg');
    width: 100%;
    height: 150px;
    background-size: contain;
}


.courseNameSkillsAll {
    display: flex;
    justify-content: space-evenly;
    max-width: 1024px;
    align-items: center;
    position: absolute;
    bottom: 0;
}

.courseNameSkills {
    background: no-repeat bottom url('./img/courseNameSkills.svg');
    width: 850px;
    height: 150px;
    background-size: contain;
    display: flex;
    justify-content: flex-end;   
}




.backCourse {
    position: relative;
    top: -280px;
    font-size: 100px;
    font-weight: 900;
    background: linear-gradient(224deg, #EC40FF -5.54%, #9B219E 11.79%, #4D0FAF 75.62%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: .4;
    filter: blur(5px);
    -webkit-background-clip: text;
    background-clip: text;
    transform: rotate(-30deg);
    margin: 0 auto;
  }
  
  .backCourse::before {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    
}
