@import 'scss/mixin.scss';
@import 'scss/variable.scss';

@font-face {
  font-family: 'SFPRORegular';
  src: url(../../../../../../../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}

.previous {
  position: relative;
  max-width: 1360px;
  width: 100%;
  min-height: 376px;
  font-family: 'SFPRORegular';
  font-weight: 500;
  border-radius: 24px;
  background-image: url(../../../../../../../../assets/img/createCourse/course-background.jpeg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 2;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 24px;
    background: rgba(51, 47, 54, 0.5);
    z-index: 3;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: 300px;
  }

  &_buttonAccept {
    button {
      width: 203px;
      height: 36px;
      font-size: 16px;
      font-weight: 700;
      padding: 0;
    }

    @include position_b_r(absolute, 50px, 44px, 4);

    @media screen and (min-width: 375px) and (max-width: 480px) {
      @include position_b_r(absolute, 55px, 16px, 4);
    }
  }

  &_bcgrShadow {
    @include position_t_l(absolute, 0, 0);
    opacity: 0.5;
    width: 100%;
    height: 100%;
    border-radius: 24px;
  }

  .background_image_course {
    width: 100%;
    height: 100%;
    border-radius: 24px;
    position: relative;
    z-index: 4;

    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 24px;
    }

    &_error {
      z-index: 5;
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 20px;
      padding: 10px;
      border-radius: 10px;
      color: #ffff;
      background: rgba(224, 24, 24, 0.3);
      margin-top: 5px;
      text-align: center;
    }
  }

  &_onlineCourses {
    @include position_t_l(absolute, 145px, 0, 4);
    width: 100%;
    border-radius: 24px;
    padding: 24px 64px;
    background: linear-gradient(90deg, #332F36 -5.88%, rgba(51, 47, 54, 0) 100%);

    input {
      width: 100%;
      padding: 5px;
      color: $color-white;
      background: none;
      border: none;
      border-radius: 5px;
      outline: none;
      @include font(500, 46px, 62px);
      text-transform: uppercase;

      @media only screen and (min-width: 320px) and (max-width: 375px) {
        @include position_t_l(absolute, 107px, 16px);
        @include font(500, 16px, 17px);
      }
      @media only screen and (min-width: 375px) and (max-width: 480px) {
        @include position_t_l(absolute, 107px, 16px);
        @include font(500, 16px, 17px);
      }
    }

    input:hover {
      background: rgba(248, 229, 255, 0.1);
    }
  }
}
.previous_title_name {
  @include position_t_l(absolute, 260px, 64px, 4);
  width: 700px;

  textarea {
    @include font(400, 24px, 28.64px);
    width: 100%;
    background: none;
    color: #dddddd;
    padding: 5px 5px;
    text-align: left;
    border: none;
    border-radius: 5px;
    outline: none;
    font-family: 'SFPRORegular';
    resize: none;
  }

  textarea:hover {
    background: rgba(248, 229, 255, 0.1);
  }

  @media only screen and (min-width: 320px) and (max-width: 375px) {
    @include position_t_l(absolute, 140px, 16px, 4);
  }
  @media only screen and (min-width: 375px) and (max-width: 480px) {
    @include position_t_l(absolute, 140px, 16px, 4);
  }
}