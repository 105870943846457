@import '../../scss/variable.scss';
@import '../../scss/mixin.scss';
@font-face {
  font-family: 'SF Pro Display';
  src: url(../../assets/fonts/SFProText/SFProText-Regular.ttf) format("opentype");
}
.header {
  width: 424px;
  max-width: min(70.84vw, 1360px);
  height: 80px;
  // border: 3px solid #357eeb;
  border-radius: 30px;
  background: rgba(207, 226, 255, 0.2);
  backdrop-filter: blur(25px);
  gap: 18px;
  box-shadow: 0px 0px 25px 0px #357eeb;
  // @include display(flex, row, center, center);
  display: flex;
  flex-direction: row;
  align-items: center;
  // padding: 0 64px;
  position: fixed;
  left: 50%;
  top: 2rem;
  margin: 0 auto;
  z-index: 90;
  transition: all ease-in-out $trans-dur05;

  @media screen and (max-width: 1725px) {
    .header_socialIcons {
      display: none;
    }
  }

  @media screen and (max-width: 1030px) {
    .tariffPlan {
      display: none;
    }
  }

  &_socialIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  &_socialIcons2 {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  &_logOut {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    border-radius: $bord-rad10;
    padding: 0.75rem 1rem;
    cursor: pointer;

    &:hover {
      background: rgba(53, 126, 235, 0.1);
    }

    p {
      font-size: 16px;
      font-weight: 600;
      color: #357eeb;
    }

    svg path {
      fill: #357eeb;
    }
  }

  &_logo {
    height: 55px;
    width: 98.78px;
    display: flex;
    cursor: pointer;
    img {
      object-fit: contain;
      object-position: center;
      margin: 0 auto;
    }
  }

  &_hiddenBlock {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    opacity: 0;
    visibility: hidden;
    width: 0;
  }
}

.header:hover {
  transition: all ease-in-out $trans-dur05;
  // width: 85%;
  width: 100%;
  background: rgba(207, 226, 255, 1);
  justify-content: space-between;
  padding: 10px 45px;

  @media screen and (max-width: 1430px) {
    padding: 10px 16px;
    gap: 10px;

    & .tariffPlan {
      padding: 0;
      min-width: 166px;
    }
  }

  & .header_hiddenBlock {
    transition: all ease-in-out $trans-dur05 $trans-dur02;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    visibility: visible;
    opacity: 1;
  }
}

.header:not(:hover) {
  transition: all ease-in-out $trans-dur05 1s;

  & .header_hiddenBlock {
    transition: all ease-in-out $trans-dur05 0.9s;
  }

  & .header_logo {
    margin: 0 auto;
  }
}

.headerActive {
  transition: all ease-in-out $trans-dur05;
  width: 100%;
  padding: 10px 45px;
  background: rgba(207, 226, 255, 1);
  justify-content: space-between;

  & .header_hiddenBlock {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    opacity: 1;
    visibility: visible;
    // margin-left: 1rem;
  }
}

.notifications {
  height: 60px;
  display: flex;
  margin-right: 10px;
  padding: 0.375rem;
  color: #e4605e;
  align-items: center;
  border-radius: 0.5rem;

  &:hover {
    background-color: #ffe5e2;
  }

  @include font(500, 14px);
}

.textAreaBack{
  width: min(547px, 76.5vw);
  height: min(27.2vh, 42.66vw);
  max-height: 305px;
  background-color: #cfe2ff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: min(15px, 2.1vw);
}

.textarea {
  width: min(515px, 72.03vw);
  height: min(18.8vh, 29.51vw);
  max-height: 211px;
  border: none;
  border-radius: min(15px, 2.1vw);
  resize: none;
  margin: min(15px, 2.1vw) auto 0;
  padding: min(16px, 2.24vw);
  overflow: auto !important;
  border-color: #ba75ff !important;

  &:focus {
    outline: none !important;
    border: none;
    box-shadow: none;
  }

  &::placeholder {
    padding-left: 10px;
    padding-top: 5px;
  }

  @include font(400, 17px);
}

.tariffPlan {
  padding: 0.375rem 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  color: #303030c6;

  min-width: 200px;
  cursor: pointer;
  height: 60px;

  &:hover {
    background-color: rgba(53, 126, 235, 0.1);
  }

  &_icon {
    margin-right: 0.375rem;
  }

  &_text {
    font-family: 'SFPRORegular', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 23.87px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.1rem;
  }
}

.messageBtn {
  &:hover {
    svg path {
      fill: white;
    }
  }
}

.popoverWrapper {
  div:nth-child(3) {
    box-shadow: 0px 0px 25px 0px #357eeb;
    border-radius: $bord-rad7;
    margin-top: 5px;

    ul {
      background: #cfe2ff;
      padding: 1rem;
      border-radius: $bord-rad7;

      li {
        border-bottom: 1px solid #3241951a;
        gap: 10px;

        svg path {
          fill: #324195;
        }

        a,
        span {
          //styleName: Desktop/Head/H5;
          padding: 5px 0;
          font-family: 'SFPRORegular';
          font-size: 15px;
          font-weight: 600;
          line-height: 19.09px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #324195;
        }
      }
    }
  }
}

.goToTariff {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 246px;
  height: 69px;
  border-radius: $bord-rad10;
  cursor: pointer;
  padding-left: 10px;
  background: linear-gradient(90deg, #3170e7 13.5%, #7a90f7 100%);

  p {
    //styleName: Desktop/Head/H5;
    font-family: 'SFPROBold';
    font-size: 12px;
    font-weight: 500;
    line-height: 19.09px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: white;
  }

  img {
    height: 100%;
  }
}

.MuiDialogContent_root {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: min(5.87px, 0.98vw);
    background-color: #CFE2FF;
    border-radius: min(4.2px, 0.7vw);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3170E7;
    border-radius: min(4.2px, 0.7vw);
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #255BB2;
  }
}

.customCheckbox {
  padding: min(8px, 1.12vw);
  width: min(24px, 3.35vw);
  height: min(42px, 5.9vw);
}

.customButton{
  font-size: clamp(14px, 2.24vw, 16px);
  font-family: 'SF Pro Display', sans-serif;
  font-weight: 500;
  padding: min(11px, 1.54vw) min(12px, 1.68vw);
  border-radius: min(6px, 0.84vw);
  margin-left: min(10px, 1.4vw);
  margin-right: min(10px, 1.4vw);
}

.closeButton {
  position: absolute;
  right: min(16px, 2.24vw);
  top: min(16px, 2.24vw);
  color: gray;
  background: none;
  border: none;

  &:hover {
    color: black;
  }
}