@import '../../scss/mixin.scss';
@import '../../scss/variable.scss';

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0px 0px 8px 0px #3241954d;
  border-radius: 20px;
  padding: 1rem;

  &_mathBlock {
    border: 1px solid #dcdcdc !important;
    border-radius: 10px;
    padding: 30px 20px;
    width: 100%;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;

  &_navBlock {
    @include display(flex, row, space-between, center);
    width: 100%;

    &_div {
      background: #f3f4f6;
      border: none;
      border-radius: 7px;
      width: 36px;
      height: 36px;
      text-align: center;
      @include display(flex, row, center, center);
      margin-bottom: 8px;
      cursor: pointer;
    }

    &_grabBtn {
      display: flex;
      cursor: grab;
    }

    &_delete {
      padding: 10px 25px;
      @include font(500, 16px, 19.09px);
    }
  }
}
